import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { PreferenceState } from '../../../../../store/preference/preference.state'
import { UserInterface } from '../../../../../shared/model/user.model'
import { fromEvent, interval, Observable, Subscription, timer } from 'rxjs'
import { PccState } from '../../../../../store/pcc/pcc.state'
import { ScreenBlockedStatus } from 'projects/aiomed/src/shared/model/preference.model'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ReportState } from '../../../../../store/report/report.state'
import { DepartmentState } from '../../../../../store/department/department.state'

@UntilDestroy()
@Component({
	selector: 'aiomed-mobile-menu',
	templateUrl: './mobile-menu.component.html',
	styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {
	@Input() user: UserInterface | null

	sourceClick$: Observable<Event>
	sourceTouchmove$: Observable<Event>
	subscriptionInterval$: Subscription

	constructor(
		public router: Router,
		private preferenceState: PreferenceState,
		private pccState: PccState,
		private reportState: ReportState,
		public departmentState: DepartmentState
	) {}

	handlerClickLogout() {
		this.pccState.pccLogout()
		this.departmentState.logout()
	}

	ngOnInit(): void {
		this.subscribeOnWindowsClickAndTouchmove()
		this.checkUserActivity()
		timer(1000)
			.pipe(untilDestroyed(this))
			.subscribe(() => this.reportState.getReportsData())
	}

	subscribeOnWindowsClickAndTouchmove() {
		this.sourceClick$ = fromEvent(window, 'click')
		this.sourceTouchmove$ = fromEvent(window, 'touchmove')
		this.sourceClick$.pipe(untilDestroyed(this)).subscribe(() => {
			this.checkUserActivity()
		})
		this.sourceTouchmove$.pipe(untilDestroyed(this)).subscribe(() => {
			this.checkUserActivity()
		})
	}

	checkUserActivity() {
		if (this.subscriptionInterval$) {
			this.subscriptionInterval$.unsubscribe()
			this.preferenceState.setScreenBlockedStatus('')
		}
		this.subscriptionInterval$ = interval(5 * 60000)
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.preferenceState.setScreenBlockedStatus(ScreenBlockedStatus.Blocked)
			})
	}
}
