<section class='assign-new-device'>
  <nz-modal [(nzVisible)]='isVisible'
            nzClassName='custom-assign-new-device-modal'>
    <div *nzModalContent>
      <div class='assign-new-device-container'>
        <div class='assign-new-device-title'>ASSIGN NEW DEVICE</div>
        <div class='assign-new-device-item'>
          <div class='name'>Choose vendor</div>
          <div class='choose-vendor-select'>
            <nz-select [(ngModel)]='selectedModel' nzDropdownClassName='custom-choose-vendor-select'>
              <nz-option *ngFor='let m of modelData' [nzLabel]='m' [nzValue]='m'></nz-option>
            </nz-select>
          </div>
        </div>
        <div class='assign-new-device-item'>
          <div class='name'>Enter serial number</div>
          <div class='serial-number-input'>
            <ng-container *ngIf='biobeatWatchDevice$ | async as biobeatWatchDevice'>
              <input (ngModelChange)='handlerSerialNumberChange($event, biobeatWatchDevice)'
                     [(ngModel)]='serialNumberValue'
                     [ngClass]='{"error": isInputValidationError}' placeholder='Start typing here' type='text'>
              <span *ngIf='isInputValidationError' class='error'><i
                nz-icon
                nzType='icons:modal-error'
              ></i>{{inputValidationErrorValue}}</span>
            </ng-container>
          </div>
        </div>
        <div class='assign-new-device-item'>
          <div class='name'>Choose patients</div>
          <ng-container *ngIf='biobeatWatchDevice$ | async as biobeatWatchDevice'>
            <div *ngIf='searchPatient$ | async as searchPatient' class='choose-patients-select'>
              <nz-select (ngModelChange)='handlerPatientsSelectChange($event, searchPatient, biobeatWatchDevice)'
                         (nzOnSearch)='changeSearchPatientEmitter.emit($event.trim())' [(ngModel)]='selectedValue'
                         nzDropdownClassName='custom-choose-patient-select'
                         nzPlaceHolder='Start typing here'
                         nzShowSearch>
                <nz-option *ngFor='let patient of searchPatient'
                           [nzLabel]='patient.lastName+ " " + patient.firstName'
                           [nzValue]='patient.id'
                           nzCustomContent>
                  <div class='avatar'>
                    <nz-avatar *ngIf='!patient["avatar"]; else haveAvatar' [nzText]='patient.name | shortName'
                               class='aiomed-avatar'
                               nzSize='default'></nz-avatar>
                    <ng-template #haveAvatar>
                      <nz-avatar [nzSrc]='patient["avatar"]["signedUrl"]'
                                 nzSize='default'></nz-avatar>
                    </ng-template>
                  </div>
                  <div class='details'>
                    <div class='name'>
                      {{patient.lastName}}, {{patient.firstName}}
                      <i *ngIf='patient.gender === "FEMALE"'
                         class='gender'
                         nz-icon
                         nzType='icons:female' style='color: #EA61A9'></i>
                      <i
                        *ngIf='patient.gender === "MALE"'
                        class='gender' nz-icon nzType='icons:male'
                        style='color: #00BFFF'></i>
                      <span class='age'>{{patient.dateOfBirth | ageFromBirth}} yrs</span>
                    </div>
                    <div class='room'>{{patient.room}}</div>
                  </div>
                  <div></div>
                </nz-option>
              </nz-select>
            </div>
          </ng-container>
        </div>
        <div class='assign-new-device-buttons'>
          <button (click)='closeModalEmitter.emit(false)' class='cancel'>Cancel</button>
          <button (click)='handlerAssignDeviceClick()' [disabled]='isButtonDisabled' class='confirm'>Assign device
          </button>
        </div>
      </div>
    </div>
  </nz-modal>
</section>

