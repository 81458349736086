import { Pipe, PipeTransform } from '@angular/core'
import { DeviceCriticalStatus } from '../../shared/model/device.model'

@Pipe({
	name: 'deviceStatusIcon'
})
export class DeviceStatusIconPipe implements PipeTransform {
	transform(data: any, ...args: unknown[]): string {
		if (!data) return ''
		if (data === DeviceCriticalStatus.NoConnection) {
			return 'icons:critical-device-status-connect'
		} else if (data === DeviceCriticalStatus.BadReading) {
			return 'icons:critical-device-status-reading'
		} else if (data === DeviceCriticalStatus.LowBattery) {
			return 'icons:critical-device-status-low-battery'
		} else if (data === DeviceCriticalStatus.NoBattery) {
			return 'icons:critical-device-status-buttery'
		}
		return ''
	}
}
