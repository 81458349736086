<ion-menu contentId='main-content' menuId='mainMenu'>
  <ion-content *ngIf='user'>
    <section class='aiomed-modal-menu'>
      <div class='aiomed-modal-menu__container'>
        <div class='aiomed-modal-menu__user'>
          <div class='aiomed-modal-menu__avatar-content'>
            <nz-avatar *ngIf='user.avatar && user.avatar.signedUrl; else imageName'
                       [nzSrc]='user.avatar.signedUrl'
                       nzSize='default'
            ></nz-avatar>
            <ng-template #imageName>
              <nz-avatar [nzText]='(user.name.firstName + " " + user.name.lastName)  | shortName'
                         nzSize='default'></nz-avatar>
            </ng-template>
          </div>
          <div class='aiomed-modal-menu__user-name'>{{user.name.firstName}} {{user.name.lastName}}</div>
        </div>
        <div (click)='handlerClickLogout()' class='aiomed-modal-menu__logout'><i nz-icon
                                                                                 nzType='icons:nav-logout'></i>
          <span>Log out</span>
        </div>
      </div>
    </section>
  </ion-content>
</ion-menu>
