import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { EMPTY, Observable } from 'rxjs'
import { ShiftPlanerDTO } from '../../shared/model/shift-planer.model'
import { PatientState } from '../patient/patient.state'
import { PatientInterface } from '../../shared/model/patient'
import { ExportState } from '../export/export.state'
import { ExportAllInterface } from '../../shared/model/export'
import { TreatmentPlanDTO } from '../../shared/model/treatment-plan'
import { checkVitalsToTreatmentPlan } from '../../core/helpers/check-vitals-to-treatment-plan'
import { combineTreatmentPlans } from '../../core/helpers/combine-treatment-plans'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { entitiesFilter } from '../../core/helpers/filter'

export const shiftPlanerFeatureName = 'shiftPlaner'

@StateRepository()
@State<ShiftPlanerDTO>({
	name: shiftPlanerFeatureName,
	defaults: {
		notRequired: [],
		vitalsRound: [],
		date: new Date().toISOString(),
		textFilter: ''
	}
})
@Injectable()
export class ShiftPlanerState extends NgxsDataRepository<ShiftPlanerDTO> {
	constructor() {
		super()
	}

	@Selector([
		PatientState.allDepartmentPatients,
		ExportState.patientsAllExports
	])
	public static shiftPlaner(
		state: ShiftPlanerDTO,
		patients: Partial<PatientInterface>[],
		patientsAllExports: ExportAllInterface[]
	): Partial<ShiftPlanerDTO> {
		return ShiftPlanerState.hydrate(
			entitiesFilter(state.textFilter, patients).map((p) => {
				const patientAllExport = patientsAllExports.find(
					(e) => e.patientId === p.id
				)
				const businessTreatmentPlans = p.treatmentPlan?.filter(
					(t: TreatmentPlanDTO) => t.endTime && new Date(t.endTime) > new Date()
				)
				return {
					...p,
					hasBusinessRule: !!(
						businessTreatmentPlans && businessTreatmentPlans.length
					),
					checkShiftSentEmrInformation: p.treatmentPlan
						? ShiftPlanerState.toReportShiftCheckVitalsToTreatmentPlan(
								p.treatmentPlan.filter(
									(t: TreatmentPlanDTO) =>
										!t.endTime || new Date(t.endTime) > new Date()
								),
								// @ts-ignore
								!patientAllExport
									? { patientId: p.id, data: [] }
									: patientAllExport,
								state.date
						  )
						: null
				}
			})
		)
	}

	private static hydrate(
		patients: Partial<PatientInterface>[]
	): Partial<ShiftPlanerDTO> {
		return {
			vitalsRound: patients.filter(
				(p) =>
					p.checkShiftSentEmrInformation &&
					p.checkShiftSentEmrInformation.status !== 'NOT_REQUIRED'
			),
			notRequired: patients.filter(
				(p) =>
					!p.checkShiftSentEmrInformation ||
					p.checkShiftSentEmrInformation.status === 'NOT_REQUIRED'
			)
		}
	}

	private static toReportShiftCheckVitalsToTreatmentPlan(
		treatmentPlans: TreatmentPlanDTO[],
		{ data }: ExportAllInterface,
		date: string
	) {
		if (!treatmentPlans.length) {
			return null
		}
		return checkVitalsToTreatmentPlan(
			combineTreatmentPlans(treatmentPlans),
			data,
			date
		)
	}

	@DataAction()
	setShiftPlanDate(@Payload('date') date: string) {
		this.patchState({ date })
	}

	@DataAction()
	setTextFilter(@Payload('text') text: string) {
		this.patchState({ textFilter: text })
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}
}
