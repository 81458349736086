<section class='alert'>
  <nz-modal (nzOnCancel)='handlerClickCancelButton()'
            [(nzVisible)]='isVisible'
            nzClassName='alert-modal' nzWrapClassName='alert-modal-wrap'>
    <!--    <div *nzModalTitle class='alert__header-title'>-->
    <!--      <p>{{'alertModal.resolving' | translate}}</p>-->
    <!--    </div>-->
    <div *nzModalContent class='alert__container'>
      <!--      <ng-template [isNdPermission]='currentUser'>-->
      <div (click)='handlerClickCancelButton()' class='alert__cancel'>
        <span nz-icon nzTheme='outline' nzType='close'></span>
        <div>Cancel</div>
      </div>
      <!--      </ng-template>-->
      <div class='alert__content'>
        <div class='alert__title'>
          <div class='top-icon'>
            <span [nzType]='title === "Alert resolved" ? "check" : "close"' nz-icon nzTheme='outline'></span>
          </div>
          {{title}}?
        </div>
        <div class='alert__patient-container'>
          <div class='alert__user-container'>
            <div class='alert__user-Image-box'>
              <nz-avatar *ngIf='patient.avatar && patient.avatar.signedUrl; else imageName'
                         [nzSrc]='patient.avatar.signedUrl'
                         nzSize='large'
              ></nz-avatar>
              <ng-template #imageName>
                <nz-avatar [nzText]='patient.name | shortName' nzSize='large'></nz-avatar>
              </ng-template>
            </div>
            <div class='alert__user-content'>
              <div
                class='alert__name'>{{patient.lastName | titlecase | slice: 0 : 1}}
                , {{patient.firstName | titlecase}}</div>
              <div class='alert__room'>
                <span>{{'home.criticalPatients.room' | translate}}</span> {{ patient?.room | titlecase }}
              </div>
            </div>
          </div>
          <!--          <ul *ngIf='alerts.length > 1' class='alert__patient-alerts'>-->
          <!--            <li *ngFor='let alert of alerts'>-->
          <!--              <div>-->
          <!--                <label (click)='handlerAlertClick(alert)' [attr.alertID]='alert.id' nz-checkbox><i-->
          <!--                  [attr.severity]='alert.severity'-->
          <!--                  [nzType]='alert.subject | alertSubjectIcon'-->
          <!--                  nz-icon></i> <span-->
          <!--                  class='alert-name'>{{'alertSubject.' + alert.subject | translate | titlecase | slice: 0 : 6}}</span></label>-->
          <!--              </div>-->
          <!--              <span class='alert-time'>{{  alert.lastModifiedTime | amTimeAgo : undefined: agoFormatter}}</span>-->
          <!--            </li>-->
          <!--          </ul>-->
          <div class='alert__patient-alerts'>
            <ng-container
              *ngIf='currentUser?._degree !== "NO_DEGREE" && currentUser?._degree !== "MEDICAL_ASSISTANT"; else degreeAlertContent'>
              <i
                [attr.severity]='alerts.length && alerts[0].severity'
                [nzType]='alerts.length && alerts[0].subject | alertSubjectIcon'
                nz-icon></i>
              <span
                class='alert-name'>{{'alertSubject.' + alerts.length && alerts[0].subject | alertSubjectName | translate | titlecase}}</span>
            </ng-container>
            <ng-template #degreeAlertContent>
              <ng-container
                *ngIf='alerts.length && alerts[0].subject === AlertSubject.Mobility || alerts.length &&alerts[0].subject === AlertSubject.ExitBedRisk; else medicalAlertContent'>
                <i
                  [attr.severity]='alerts.length && alerts[0].severity'
                  [nzType]='alerts.length && alerts[0].subject | alertSubjectIcon'
                  nz-icon></i>
                <span
                  class='alert-name'>{{'alertSubject.' + alerts.length && alerts[0].subject | alertSubjectName | translate | titlecase}}</span>
              </ng-container>
              <ng-template #medicalAlertContent>
                <i [attr.severity]='alerts.length && alerts[0].severity'
                   nz-icon nzType='icons:medical'
                ></i>
                <span
                  class='alert-name'>Medical</span>
              </ng-template>
            </ng-template>
          </div>
        </div>
        <ng-container
          *ngIf='currentUser?._degree !== "NO_DEGREE" && currentUser?._degree !== "MEDICAL_ASSISTANT"; else degreeContent'>
          <div class='alert__dismiss-container'>
            <div class='alert__dismiss-resolution'>
              <button (click)='resolution = "false"' [attr.check]='resolution === "false"'
                      class='alert__dismiss-resolution-button'>{{'alertModal.resolution.false' | translate}}</button>
              <button (click)='resolution = "na"' [attr.check]='resolution === "na"'
                      class='alert__dismiss-resolution-button'>{{'alertModal.resolution.na' | translate}}</button>
              <button (click)='resolution = "duplicate"' [attr.check]='resolution === "duplicate"'
                      class='alert__dismiss-resolution-button'>{{'alertModal.resolution.done' | translate}}</button>
            </div>
            <div *ngIf='resolution === "snoozed"' class='alert__dismiss-resolution-time'>
              <div class='alert__dismiss-resolution-time-title'>Please indicate for how many hours</div>
              <div class='alert__dismiss-resolution-time-content'>
                <div (click)='increment("decrease")'>-</div>
                <nz-input-number [(ngModel)]='timeValue' [nzMin]='1' [nzStep]='1'
                                 class='alert__timer'></nz-input-number>
                <div (click)='increment("increase")'>+</div>
              </div>
            </div>
          </div>
          <div *ngIf='resolution !== "snoozed"; else muteAlert' class='alert__buttons-container'>
            <div (click)='resolution = "snoozed"'>
              <i
                nz-icon
                nzType='icons:bell-off'></i>
              <span class='button-text'>Mute alert</span>
            </div>
            <div (click)='submitForm()'>
              <i nz-icon nzTheme='outline' nzType='check-circle'></i>
              <span class='button-text'>Resolved</span>
            </div>
          </div>
          <ng-template #muteAlert>
            <div class='alert__button-container'>
              <div (click)='submitForm()'>
                <i
                  nz-icon
                  nzType='icons:bell-off'></i>
                <span class='button-text'>Mute alert</span>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #degreeContent>
          <div class='alert__buttons-container' style='margin-top: 25px'>
            <div (click)='handlerClickCancelButton()' class='cancel'>
              <span class='button-text'>Cancel</span>
            </div>
            <div (click)='handlerDegreeResolvedClick()'>
              <i [nzType]='title === "Alert resolved" ? "check-circle" : "close-circle"' nz-icon nzTheme='outline'></i>
              <span class='button-text'>{{title === 'Alert resolved' ? 'Resolved' : 'False Alert'}}</span>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div *nzModalFooter>
      <!--      <form nz-form nzLayout='vertical'>-->
      <!--        <button (click)='handlerClickCancelButton()' nz-button nzBlock-->
      <!--                nzType='primary'>{{'alertModal.button.cancel' | translate}}-->
      <!--        </button>-->
      <!--        <button (click)='submitForm()' class='btn-prime' nz-button nzBlock nzType='primary'-->
      <!--                type='submit'>{{'alertModal.button.submit' | translate}}-->
      <!--        </button>-->
      <!--      </form>-->
    </div>
  </nz-modal>
</section>
