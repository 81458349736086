<ion-tabs>
  <ion-tab-bar slot='bottom'>
    <div [routerLinkActiveOptions]='{exact:true}' [routerLinkActive]='"active"' class='tab-item'
         routerLink='/home'>
      <i
        class='alert-icon'
        nz-icon nzType='icons:tab-home'
      ></i>
      <span>Home</span>
    </div>
    <div [routerLinkActiveOptions]='{exact:true}' [routerLinkActive]='"active"' class='tab-item' routerLink='/patients'>
      <i
        class='alert-icon'
        nz-icon nzType='icons:tab-patients'
      ></i>
      <span>Patients</span>
    </div>
    <div (click)='handlerClick()' class='tab-item tab-item-menu'>
      <i nz-icon nzTheme='outline' nzType='more'></i>
      <span>More</span>
    </div>
  </ion-tab-bar>
</ion-tabs>

