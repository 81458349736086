import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core'
import { ObservationFields } from '../../../../../shared/model/patient-observation'
import { PatientInterface } from '../../../../../shared/model/patient'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
	finalize,
	Observable,
	Subscription,
} from 'rxjs'
import { tap } from 'rxjs/operators'
import {
	getUserLocale,
	transformCelsiusToFahrenhei,
	transformFahrenheiToCelsius
} from '../../../../../core/helpers/functions'
import { AuthState } from '../../../../../store/auth/auth.state'
import { DeviceState } from '../../../../../store/device/device.state'
import { Select } from '@ngxs/store'
import { DeviceDTO } from '../../../../../shared/model/device.model'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { checkTypoRules } from '../../../../../core/helpers/check-typo-rules'
import { MeasurementState } from 'projects/aiomed/src/store/measurement/measurement.state'

@UntilDestroy()
@Component({
	selector: 'aiomed-report-manual-edit-modal',
	templateUrl: './report-manual-edit-modal.component.html',
	styleUrls: ['./report-manual-edit-modal.component.scss']
})
export class ReportManualEditModalComponent
	implements OnInit, OnChanges
{
	@Input() patient: PatientInterface
	@Input() isModalVisible: boolean = false
	@Output('closeModal') closeModalEmitter = new EventEmitter()
	@Output() setManualVitalsEmitter = new EventEmitter()
	@Output() closeModalNotSaved = new EventEmitter()
	ObservationFields = ObservationFields
	selectedProvince = 0
	needLogin: boolean = true
	form: FormGroup
	passwordVisible = false
	password?: string
	diastolicError: boolean = false
	systolicError: boolean = false
	heartRateError: boolean = false
	respirationRateError: boolean = false
	spO2Error: boolean = false
	bodyTemperatureError: boolean = false
	bloodPressureManual: boolean = true
	heartRateManual: boolean = true
	spO2Manual: boolean = true
	respirationRateManual: boolean = true
	bodyTemperatureManual: boolean = true
	devicesLoading: boolean = false
	diastolicValue: number | null = null
	systolicValue: number | null = null
	heartRateValue: number | null = null
	respirationRateValue: number | null = null
	spO2Value: number | null = null
	bodyTemperatureValue: number | null = null
	firstData: {
		diastolic: number | any
		systolic: number | any
		heartRate: number | any
		respirationRate: number | any
		spo2: number | any
		bodyTemperature: number | any
	} = {
		diastolic: null,
		systolic: null,
		heartRate: null,
		respirationRate: null,
		spo2: null,
		bodyTemperature: null
	}

	@Select(DeviceState.sharedDevices)
	sharedDevices$: Observable<DeviceDTO[] | undefined>
	private sharedDevicesSubscription: Subscription

	constructor(
		private fb: FormBuilder,
		private authState: AuthState,
		private deviceState: DeviceState,
		private measurementState: MeasurementState,
	) {
		this.form = this.fb.group({
			username: [null, Validators.compose([Validators.required])],
			password: [
				null,
				Validators.compose([Validators.required, Validators.minLength(8)])
			]
		})
	}

	private static toTransformFahrenheiToCelsius(value: number): number {
		return getUserLocale() === 'en-US'
			? transformFahrenheiToCelsius(value)
			: value
	}

	ngOnInit(): void {
		this.subscribeMqttDeviceUpdates();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.setPatientMeasurements();
	}

	async submitForm() {
		if (
			(!this.diastolicValue &&
				!this.systolicValue &&
				!this.heartRateValue &&
				!this.respirationRateValue &&
				!this.spO2Value &&
				!this.bodyTemperatureValue) ||
			this.bodyTemperatureError ||
			this.respirationRateError ||
			this.heartRateError ||
			this.diastolicError ||
			this.systolicError ||
			this.spO2Error
		) {
			return
		}
		if (!Object.values(this.setCheckedPatientCurrentObservations()).length) {
			this.closeModalEmitter.emit(false)
			return
		}
		this.setManualVitalsEmitter.emit({
			observedPatient: this.patient.id,
			...this.setCheckedPatientCurrentObservations()
		})
	}

	setCheckedPatientCurrentObservations() {
		let tmpObj: any = {}
		const systolicRules: any = checkTypoRules.get(
			ObservationFields.SystolicPressure
		)
		const diastolicRules: any = checkTypoRules.get(
			ObservationFields.DiastolicPressure
		)
		const heartRateRules: any = checkTypoRules.get(ObservationFields.HeartRate)
		const respirationRateRules: any = checkTypoRules.get(
			ObservationFields.RespirationRate
		)
		const spo2RateRules: any = checkTypoRules.get(ObservationFields.SpO2)
		const bodyTemperatureRateRules: any = checkTypoRules.get(
			ObservationFields.BodyTemperature
		)
		if (
			this.diastolicValue &&
			this.diastolicValue <= diastolicRules.max &&
			this.diastolicValue >= diastolicRules.min &&
			this.systolicValue &&
			this.systolicValue <= systolicRules.max &&
			this.systolicValue >= systolicRules.min
		) {
			if (
				this.diastolicValue !== this.firstData.diastolic ||
				this.systolicValue !== this.firstData.systolic
			) {
				tmpObj[ObservationFields.DiastolicPressure] = this.diastolicValue
				tmpObj[ObservationFields.SystolicPressure] = this.systolicValue
			}
		}
		if (
			this.heartRateValue &&
			this.heartRateValue <= heartRateRules.max &&
			this.heartRateValue >= heartRateRules.min &&
			this.heartRateValue !== this.firstData.heartRate
		) {
			tmpObj.heartRate = this.heartRateValue
		}
		if (
			this.respirationRateValue &&
			this.respirationRateValue <= respirationRateRules.max &&
			this.respirationRateValue >= respirationRateRules.min &&
			this.respirationRateValue !== this.firstData.respirationRate
		) {
			tmpObj.respirationRate = this.respirationRateValue
		}
		if (
			this.spO2Value &&
			this.spO2Value <= spo2RateRules.max &&
			this.spO2Value >= spo2RateRules.min &&
			this.spO2Value !== this.firstData.spo2
		) {
			tmpObj[ObservationFields.SpO2] = this.spO2Value
		}
		if (
			this.bodyTemperatureValue &&
			this.bodyTemperatureValue <= bodyTemperatureRateRules.max &&
			this.bodyTemperatureValue >= bodyTemperatureRateRules.min &&
			getUserLocale() === 'en-US' &&
			Number(this.bodyTemperatureValue) !==
				Number(this.firstData.bodyTemperature)
		) {
			tmpObj.bodyTemperature = transformFahrenheiToCelsius(
				this.bodyTemperatureValue
			)
		}
		if (
			this.bodyTemperatureValue &&
			this.bodyTemperatureValue <= bodyTemperatureRateRules.max &&
			this.bodyTemperatureValue >= bodyTemperatureRateRules.min &&
			getUserLocale() !== 'en-US' &&
			Number(this.bodyTemperatureValue) !==
				Number(this.firstData.bodyTemperature)
		) {
			tmpObj.bodyTemperature = Number(this.bodyTemperatureValue)
		}
		return tmpObj
	}

	async handlerClickCancelButton() {
		let haveChangedData = false
		const {
			systolic,
			diastolic,
			spo2,
			respirationRate,
			bodyTemperature,
			heartRate
		} = this.firstData
		if (
			this.systolicValue !== systolic ||
			this.diastolicValue !== diastolic ||
			this.spO2Value !== spo2 ||
			this.respirationRateValue !== respirationRate ||
			this.bodyTemperatureValue !== bodyTemperature ||
			this.heartRateValue !== heartRate
		) {
			haveChangedData = true
		}

		if (haveChangedData) {
			if (!Object.values(this.setCheckedPatientCurrentObservations()).length) {
				this.closeModalEmitter.emit(false)
			} else {
				this.closeModalNotSaved.emit({
					observedPatient: this.patient.id,
					...this.setCheckedPatientCurrentObservations()
				})
			}
			// this.closeModalNotSaved.emit()
		} else {
			this.closeModalEmitter.emit(false)
		}
	}

	handlerInputChange($event: number, type: string) {
		if (type === ObservationFields.SystolicPressure) {
			const systolicRules: any = checkTypoRules.get(
				ObservationFields.SystolicPressure
			)
			const diastolicRules: any = checkTypoRules.get(
				ObservationFields.DiastolicPressure
			)
			this.bloodPressureManual = true
			this.systolicError =
				(type === ObservationFields.SystolicPressure &&
					$event > systolicRules?.max) ||
				(type === ObservationFields.SystolicPressure &&
					$event < systolicRules?.min)
			this.diastolicError =
				// @ts-ignore
				this.diastolicValue > diastolicRules?.max ||
				// @ts-ignore
				this.diastolicValue < diastolicRules?.min

			if (
				this.firstData.systolic === $event &&
				this.firstData.diastolic === this.diastolicValue
			) {
				this.systolicError = false
				this.diastolicError = false
			}
		}
		if (type === ObservationFields.DiastolicPressure) {
			const systolicRules: any = checkTypoRules.get(
				ObservationFields.SystolicPressure
			)
			const diastolicRules: any = checkTypoRules.get(
				ObservationFields.DiastolicPressure
			)
			this.bloodPressureManual = true
			this.diastolicError =
				(type === ObservationFields.DiastolicPressure &&
					$event > diastolicRules?.max) ||
				(type === ObservationFields.DiastolicPressure &&
					$event < diastolicRules?.min)
			this.systolicError =
				// @ts-ignore
				this.systolicValue > systolicRules?.max ||
				// @ts-ignore
				this.systolicValue < systolicRules?.min
			if (
				this.firstData.systolic === this.systolicValue &&
				this.firstData.diastolic === $event
			) {
				this.systolicError = false
				this.diastolicError = false
			}
		}
		if (type === ObservationFields.HeartRate) {
			const rules: any = checkTypoRules.get(ObservationFields.HeartRate)
			this.heartRateManual = true
			this.heartRateError =
				(type === ObservationFields.HeartRate && $event > rules.max) ||
				(type === ObservationFields.HeartRate && $event < rules.min)
			if (this.firstData.heartRate === $event) this.heartRateError = false
		}
		if (type === ObservationFields.SpO2) {
			const rules: any = checkTypoRules.get(ObservationFields.SpO2)
			this.spO2Manual = true
			this.spO2Error =
				(type === ObservationFields.SpO2 && $event > rules.max) ||
				(type === ObservationFields.SpO2 && $event < rules.min)
			if (this.firstData.spo2 === $event) this.spO2Error = false
		}
		if (type === ObservationFields.RespirationRate) {
			const rules: any = checkTypoRules.get(ObservationFields.RespirationRate)
			this.respirationRateError =
				(type === ObservationFields.RespirationRate && $event > rules.max) ||
				(type === ObservationFields.RespirationRate && $event < rules.min)
			if (this.firstData.respirationRate === $event)
				this.respirationRateError = false
		}
		if (type === ObservationFields.BodyTemperature) {
			const rules: any = checkTypoRules.get(ObservationFields.BodyTemperature)
			this.bodyTemperatureError =
				(type === ObservationFields.BodyTemperature && $event > rules.max) ||
				(type === ObservationFields.BodyTemperature && $event < rules.min)
			if (this.firstData.bodyTemperature === $event)
				this.bodyTemperatureError = false
		}
	}

	handlerClickRetrieveDevices() {
		this.retrieveDevices()
	}

	retrieveDevices() {
		let lastHeartRateUpdateTime: Date
		this.devicesLoading = true
		this.sharedDevices$
			.pipe(
				finalize(() => (this.devicesLoading = false)),
				untilDestroyed(this)
			)
			.subscribe((devices) => {
				devices?.forEach((device) => {
					if (device) {
						if (device?.status?.spo2) {
							this.spO2Manual = false
							this.spO2Value = device?.status.spo2
						}
						if (device?.status?.bp_systolic || device?.status?.bp_diastolic) {
							this.bloodPressureManual = false
							this.systolicValue = device?.status.bp_systolic
							this.diastolicValue = device?.status.bp_diastolic
						}
						if (
							device.status.heart_rate &&
							(!lastHeartRateUpdateTime ||
								device.status.last_update_time > lastHeartRateUpdateTime)
						) {
							this.heartRateManual = false
							this.heartRateValue = device.status.heart_rate
							lastHeartRateUpdateTime = device.status.last_update_time
						}
					}
				})
			})
	}

	async handlerClickCancelModal() {
		await this.handlerClickCancelButton()
	}

	protectionNumbers($event: KeyboardEvent) {
		if (
			[
				'0',
				'1',
				'2',
				'3',
				'4',
				'5',
				'6',
				'7',
				'8',
				'9',
				'0',
				'.',
				'Backspace'
			].includes($event.key)
		) {
		} else {
			$event.preventDefault()
		}
	}

	private setPatientMeasurements(): void {
		if (this.patient && this.patient.measurement?.latestPerVital?.diastolicPressure) {
			this.firstData.diastolic = this.diastolicValue = Math.round(
				this.patient.measurement?.latestPerVital?.diastolicPressure?.value
			)
		}
		if (this.patient && this.patient.measurement?.latestPerVital?.systolicPressure) {
			this.firstData.systolic = this.systolicValue = Math.round(
				this.patient.measurement?.latestPerVital?.systolicPressure?.value
			)
			this.bloodPressureManual = true;
		}
		if (this.patient && this.patient.measurement?.latestPerVital?.heart_rate) {
			this.firstData.heartRate = this.heartRateValue = Math.round(
				this.patient.measurement?.latestPerVital?.heart_rate?.value
			)
			this.heartRateManual = true;
		}
		if (this.patient && this.patient.measurement?.latestPerVital?.respiration_rate) {
			this.firstData.respirationRate = this.respirationRateValue = Math.round(
				this.patient.measurement?.latestPerVital?.respiration_rate?.value
			)
			this.respirationRateManual = true;
		}
		if (this.patient && this.patient.measurement?.latestPerVital?.spo2) {
			this.firstData.spo2 = this.spO2Value = Math.round(
				this.patient.measurement?.latestPerVital?.spo2?.value
			)
			this.spO2Manual = true;
		}
		if (this.patient && this.patient.measurement?.latestPerVital?.body_temperature) {
			getUserLocale() === 'en-US'
				? // @ts-ignore
				(this.firstData.bodyTemperature = this.bodyTemperatureValue =
					Math.floor(
						transformCelsiusToFahrenhei(
							this.patient.measurement?.latestPerVital?.body_temperature?.value
						) * 10
					) / 10)
				: // @ts-ignore
				(this.firstData.bodyTemperature = this.bodyTemperatureValue =
					Math.floor(
						this.patient.measurement?.latestPerVital?.body_temperature?.value * 10
					) / 10)
			this.bodyTemperatureManual = true;		
		}
	}

	private subscribeMqttDeviceUpdates(): void {
		this.measurementState.deviceStatusFieldsWSOnMessage$
		.pipe(
			tap(measurement => {
				if (measurement.diastolicPressure) {
					this.diastolicValue = measurement.diastolicPressure;
				}
				if (measurement.systolicPressure) {
					this.systolicValue = measurement.systolicPressure;
					this.bloodPressureManual = false;
				}
				if (measurement.heart_rate) {
					this.heartRateValue = measurement.heart_rate;
					this.heartRateManual = false;
				}
				if (measurement.respiration_rate) {
					this.respirationRateValue = measurement.respiration_rate;
					this.respirationRateManual = false;
				}
				if (measurement.spo2) {
					this.spO2Value = measurement.spo2;
					this.spO2Manual = false;
				}
				if (measurement.body_temperature) {
					this.bodyTemperatureValue = getUserLocale() === 'en-US'
						? (Math.floor(transformCelsiusToFahrenhei(measurement.body_temperature) * 10) / 10)
						: (this.bodyTemperatureValue = Math.floor(measurement.body_temperature * 10) / 10)

					this.bodyTemperatureManual = false;
				}
			}),
			untilDestroyed(this),
		)
		.subscribe();
	}
}
