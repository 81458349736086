import { NgModule } from '@angular/core'
import { AgeFromBirthPipe } from './pipe/age-from-birth.pipe'
import { ShortNamePipe } from './pipe/short-name.pipe'
import { DateFnsModule } from 'ngx-date-fns'
import { PosturePipe } from './pipe/posture.pipe'
import { DeviceImagePipe } from './pipe/device-image.pipe'
import { RoundNumberPipe } from './pipe/round-number.pipe'
import { RoundFixNumberPipe } from './pipe/round-fix-number.pipe'
import { MedicalSignSeverityPipe } from './pipe/sign-severity/medical-sign-severity.pipe'
import { AiomedSpinnerDirective } from './directives/aiomed-spinner.directive'
import { BloodPressureSignSeverityPipe } from './pipe/sign-severity/blood-pressure-sign-severity.pipe'
import { AlertSubjectIconPipe } from './pipe/alert/alert-subject-icon.pipe'
import { AlertStatusIconPipe } from './pipe/alert/alert-status-icon.pipe'
import { AlertSeverityIconPipe } from './pipe/alert/alert-severity-icon.pipe'
import { AlertSubjectNamePipe } from './pipe/alert/alert-subject-name.pipe'
import { CheckTimeActiveMeasurementPipe } from './pipe/check-measurement/check-time-active-measurement.pipe'
import { AlertSubjectUnitPipe } from './pipe/alert/alert-subject-unit.pipe'
import { AlertStatusNamePipe } from './pipe/alert/alert-status-name.pipe'
import { AlertDeltaPipe } from './pipe/alert/alert-delta.pipe'
import { AiomedIsMdPermissionDirective } from './directives/aiomed-is-md-permission.directive'
import { AiomedIsNpPermissionDirective } from './directives/aiomed-is-np-permission.directive'
import { AiomedIsNdPermissionDirective } from './directives/aiomed-is-nd-permission.directive'
import { AiomedIsAdminPermissionDirective } from './directives/aiomed-is-admin-permission.directive'
import { TaskTreatmentActionIconPipe } from './pipe/task/task-treatment-action-icon.pipe'
import { TaskTreatmentActionNamePipe } from './pipe/task/task-treatment-action-name.pipe'
import { TaskStatusPipe } from './pipe/task/task-status.pipe'
import { TaskAssigneeNamePipe } from './pipe/task/task-assignee-name.pipe'
import { TimesDayPipe } from './pipe/times-day.pipe'
import { TimePipe } from './pipe/time.pipe'
import { TransformCelsiusToFahrenheiPipe } from './pipe/transform-celsius-to-fahrenhei.pipe'
import { DeviceStatusIconPipe } from './pipe/device-status-icon.pipe'
import { TimeAgoPipe } from './pipe/time-ago.pipe'
import { CelsiusFahrenheiIconPipe } from './pipe/celsius-fahrenhei-icon.pipe'
import { MedicalCriticalSignPipe } from './pipe/sign-severity/medical-critical-sign.pipe'
import { DeviceResolutionPipe } from './pipe/device-resolution.pipe'
import { DeviceBatteryLevelIconPipe } from './pipe/device-battery-level-icon.pipe'
import { DeviceReportIconPipe } from './pipe/device-report-icon.pipe'
import { DayFullNamePipe } from './pipe/day-full-name.pipe'
import { VitalFullNamePipe } from './pipe/vital-full-name.pipe'
import { ExtractSpecificInsightBySubjectPipe } from './pipe/insight/extract-specific-insight-by-subject.pipe'
import { CheckVitalEmrRulesPipe } from './pipe/sign-severity/check-vital-emr-rules.pipe'
import { CapitalizePipe } from './pipe/capitalize.pipe'
import { CheckFreshVitalTimePipe } from './pipe/report/check-fresh-vital-time.pipe'
import { IsManualPipe } from './pipe/report/is-manual.pipe'

@NgModule({
	declarations: [
		AgeFromBirthPipe,
		ShortNamePipe,
		RoundNumberPipe,
		RoundFixNumberPipe,
		PosturePipe,
		TimesDayPipe,
		DeviceImagePipe,
		MedicalSignSeverityPipe,
		AiomedSpinnerDirective,
		BloodPressureSignSeverityPipe,
		AlertSubjectIconPipe,
		AlertStatusIconPipe,
		AlertSeverityIconPipe,
		AlertSubjectNamePipe,
		CheckTimeActiveMeasurementPipe,
		AlertSubjectUnitPipe,
		AlertStatusNamePipe,
		AlertDeltaPipe,
		AiomedIsMdPermissionDirective,
		AiomedIsNpPermissionDirective,
		AiomedIsNdPermissionDirective,
		AiomedIsAdminPermissionDirective,
		TaskTreatmentActionIconPipe,
		TaskTreatmentActionNamePipe,
		TaskStatusPipe,
		TaskAssigneeNamePipe,
		TimePipe,
		TransformCelsiusToFahrenheiPipe,
		DeviceStatusIconPipe,
		TimeAgoPipe,
		MedicalCriticalSignPipe,
		CelsiusFahrenheiIconPipe,
		DeviceResolutionPipe,
		DeviceBatteryLevelIconPipe,
		DeviceReportIconPipe,
		DayFullNamePipe,
		VitalFullNamePipe,
		ExtractSpecificInsightBySubjectPipe,
		CapitalizePipe,
		CheckVitalEmrRulesPipe,
		CheckFreshVitalTimePipe,
		IsManualPipe
	],
	imports: [DateFnsModule.forRoot()],
	exports: [
		AgeFromBirthPipe,
		ShortNamePipe,
		RoundNumberPipe,
		RoundFixNumberPipe,
		PosturePipe,
		TimesDayPipe,
		TimePipe,
		DeviceImagePipe,
		MedicalSignSeverityPipe,
		AiomedSpinnerDirective,
		BloodPressureSignSeverityPipe,
		AlertSubjectIconPipe,
		AlertStatusIconPipe,
		AlertSeverityIconPipe,
		AlertSubjectNamePipe,
		CheckTimeActiveMeasurementPipe,
		AlertSubjectUnitPipe,
		AlertStatusNamePipe,
		AlertDeltaPipe,
		AiomedIsMdPermissionDirective,
		AiomedIsNpPermissionDirective,
		AiomedIsNdPermissionDirective,
		AiomedIsAdminPermissionDirective,
		TaskTreatmentActionIconPipe,
		TaskTreatmentActionNamePipe,
		TaskStatusPipe,
		TaskAssigneeNamePipe,
		TransformCelsiusToFahrenheiPipe,
		DeviceStatusIconPipe,
		TimeAgoPipe,
		MedicalCriticalSignPipe,
		CelsiusFahrenheiIconPipe,
		DeviceResolutionPipe,
		DeviceBatteryLevelIconPipe,
		DeviceReportIconPipe,
		DayFullNamePipe,
		VitalFullNamePipe,
		ExtractSpecificInsightBySubjectPipe,
		CheckVitalEmrRulesPipe,
		CapitalizePipe,
		CheckFreshVitalTimePipe,
		IsManualPipe
	]
})
export class CoreModule {}
