import { Component, EventEmitter, OnInit, Output } from '@angular/core'

@Component({
	selector: 'aiomed-warning-modal',
	templateUrl: './warning-modal.component.html',
	styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent implements OnInit {
	@Output('closeModal') closeModalEmitter = new EventEmitter()
	@Output() saveModalDataEmitter = new EventEmitter()

	constructor() {}

	ngOnInit(): void {}
}
