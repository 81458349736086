import { DepartmentDTO } from "./permission.model"

export interface PreferenceInterface {
	departmentId: string | null
	isCollapseMenu: boolean
	soundNotifications: boolean | null
	notification: boolean | null
	lg: string | null
	mfaRequired: boolean
	needChooseDepartment: boolean
	screenBlockedStatus: string
	department: DepartmentDTO | null
}

export enum ScreenBlockedStatus {
	Blocked = 'blocked',
	RedBlocked = 'red-blocked',
	BlueBlocked = 'blue-blocked'
}
