import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import {
	catchError,
	EMPTY,
	ignoreElements,
	interval,
	Observable,
	of,
	Subscription,
	switchMap,
	tap
} from 'rxjs'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import {
	PatientExportDTO,
	PccDTO,
	PccFacilityDTO,
	PccPatientDTO,
	PCCStatus,
	PccUpdateEmrMeasurement
} from '../../shared/model/pcc.model'
import { BackendService } from '../../shared/services/backend.service'
import { mapToVoid } from '@angular-ru/cdk/rxjs'
import { cloneDeep } from 'lodash-es'
import { PatientDTO, PatientInterface } from '../../shared/model/patient'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { PatientState } from '../patient/patient.state'
import { NotificationService } from '../../shared/services/notification.service'
import { StoreEventsService } from '../store-events.service'
import { GenericEntityResponse } from '@biot-client/biot-client-generic-entity'
import { ExportState } from '../export/export.state'

export const pccFeatureName = 'pcc'

@StateRepository()
@State<PccDTO>({
	name: pccFeatureName,
	defaults: {
		pccPatients: [],
		hasMore: true,
		tokenStatus: null,
		loading: false,
		pccFacilities: [],
		hasMoreFacilities: true,
		sentPatientVitalsToPcc: [],
		detailCurrentLoading: null
	}
})
@Injectable()
export class PccState extends NgxsDataRepository<PccDTO> {
	checkPccTokenStatusSubscription: Subscription

	constructor(
		private backendService: BackendService,
		private notification: NzNotificationService,
		private patientState: PatientState,
		private ntfService: NotificationService,
		private exportState: ExportState,
		private actions: Actions,
		private storeEvents: StoreEventsService
	) {
		super()
	}

	@Selector()
	public static pccPatients(state: PccDTO): PccPatientDTO[] {
		return state.pccPatients.map((p) => ({
			...p,
			check: false
		}))
	}

	@Selector()
	public static pccHasMorePatients(state: PccDTO): boolean {
		return state.hasMore
	}

	@Selector()
	public static pccSentPatientVitals(state: PccDTO): string[] {
		return state.sentPatientVitalsToPcc
	}

	@Selector()
	public static pccDetailCurrentLoading(
		state: PccDTO
	): { id: string; status: boolean } | null {
		return state.detailCurrentLoading
	}

	@Selector()
	public static pccHasMoreFacilities(state: PccDTO): boolean {
		return state.hasMoreFacilities
	}

	@Selector()
	public static pccPatientLoading(state: PccDTO): boolean {
		return state.loading
	}

	@Selector()
	public static tokenStatus(state: PccDTO): string | null {
		return state.tokenStatus
	}

	@Selector()
	public static pccFacilities(state: PccDTO): PccFacilityDTO[] {
		return state.pccFacilities
	}

	public override ngxsOnInit() {

		this.storeEvents.userModified$.pipe(
			tap(() => {
				if (this.checkPccTokenStatusSubscription) this.checkPccTokenStatusSubscription.unsubscribe();
				this.checkPccTokenStatusSubscription = interval(10000).pipe(switchMap(() => this.getPccIsAuthStatus())).subscribe();
			})
		).subscribe();


		this.storeEvents.logout$.pipe(
			tap(() => {
				this.reset();
				if (this.checkPccTokenStatusSubscription) this.checkPccTokenStatusSubscription.unsubscribe();
			})
		).subscribe();
	}

	@DataAction()
	getPccPatientList(
		@Payload('page') page: number,
		@Payload('patientId') patientId: string,
		@Payload('patientName') patientName: string,
		@Payload('facilityId') facilityId: number | null
	) {
		this.ctx.patchState({ loading: true })
		return this.backendService
			.getPccPatients(page, patientId, patientName, facilityId)
			.pipe(
				tap(({ pccPatients, hasMore }) => {
					const tmp = cloneDeep(this.ctx.getState().pccPatients)
					this.patchState({
						pccPatients: [...tmp, ...pccPatients],
						hasMore,
						loading: false
					})
				}),
				mapToVoid()
			)
	}

	@DataAction()
	getPccFacilities(@Payload('page') page: number) {
		return this.backendService.getPccFacilities(page).pipe(
			tap(({ pccFacilities, hasMoreFacilities }) => {
				const tmp = cloneDeep(this.ctx.getState().pccFacilities)
				this.patchState({
					pccFacilities: [...tmp, ...pccFacilities],
					hasMoreFacilities
				})
			}),
			mapToVoid()
		)
	}

	@DataAction()
	updatePccEmrMeasurement(@Payload('data') data: PccUpdateEmrMeasurement) {
		this.patchState({
			detailCurrentLoading: {
				id: data.observedPatient,
				status: true
			}
		})
		return this.backendService.updatePccEmrMeasurement(data).pipe(
			tap((data) => {
				this.patientState.upsertOne(data.patient)
				if (data.exportStatus === PCCStatus.Completed) {
					this.patchState({
						sentPatientVitalsToPcc: [
							...this.getState().sentPatientVitalsToPcc,
							data.patient.id
						]
					})
					this.exportState.addNewPatientsExports([
						data.biotObservationExportEntity
					])
				} 
				this.showNotificationByStatus(data);
				this.patchState({
					detailCurrentLoading: {
						id: data.patient.id,
						status: false
					}
				})
			}),
			mapToVoid()
		)
	}

	@DataAction()
	updatePccEmrMeasurementBulk(
		@Payload('data')
		data: {
			observations: PccUpdateEmrMeasurement[]
		}
	) {
		return this.backendService.updatePccEmrMeasurementBulk(data).pipe(
			tap(
				({
					result
				}: {
					result: {
						exportStatus: string
						biotObservationExportEntity: GenericEntityResponse
						patient: {
							_id: string
						}
					}[]
				}) => {
					// toPatientExportDTO(
					const errorPatientRetort = result.filter(
						(data) => data.exportStatus === 'FAILED_EMR'
					)
					const successPatientRetort = result.filter(
						(data) => data.exportStatus !== 'FAILED_EMR'
					)
					this.patchState({
						sentPatientVitalsToPcc: result
							.filter((data) => data.exportStatus !== 'FAILED_EMR')
							.map((data) => data.patient._id)
					})
					if (errorPatientRetort.length > 0) {
						this.ntfService.error(
							// @ts-ignore
							`${errorPatientRetort.length} patients have been failed to EMR`
						)
					}
					if (successPatientRetort.length > 0) {
						this.ntfService.success(
							// @ts-ignore
							`${successPatientRetort.length} patients have been reported to EMR`
						)
						this.exportState.addNewPatientsExports(
							successPatientRetort.map((res) =>
								BackendService.toPatientExportDTO(
									res.biotObservationExportEntity
								)
							)
						)
					}
					this.patchState({ loading: false })
				}
			),
			catchError(() => {
				this.ntfService.error(`Something went wrong`)
				this.patchState({ loading: false })
				return of()
			}),
			mapToVoid()
		)
	}

	@DataAction()
	pccLogout(): Observable<void> {
		return this.backendService.pccLogout().pipe(ignoreElements())
	}

	// @DataAction({ subscribeRequired: false })
	getPccIsAuthStatus() {
		return this.backendService.getPccIsAuthStatus().pipe(
			tap((tokenStatus) => {
				this.patchState({ tokenStatus })
			}),
			mapToVoid()
		)
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}

	private showNotificationByStatus(data: { exportStatus: string, patient: PatientDTO, biotObservationExportEntity?: PatientExportDTO }): void {
		if (data.exportStatus === PCCStatus.Completed) {
			this.ntfService.success(`${data.patient.name} vitals successfully sent to EMR`)
		} else if (data.exportStatus === PCCStatus.Timeout) {
			this.ntfService.error(`EMR session has expired`);
		}  else if (
			data.exportStatus === PCCStatus.InProgress ||
			data.exportStatus === PCCStatus.Starting
		) {
			this.ntfService.warning(
				`${data.exportStatus
					.split('_')
					.join(' ')
					.toLowerCase()} Reported to EMR`
			)
		} else if (data.exportStatus === PCCStatus.Aborted || data.exportStatus === PCCStatus.FailedEMR) {
			this.ntfService.error(`EMR report failed`);
			this.exportState.insertFailedEMRTemporaryExport(data.patient.id, data?.biotObservationExportEntity!);
		} else if (data.exportStatus === PCCStatus.FailedInvalidPatient) {
			this.ntfService.error(`Patient linkage error: Incorrect PCC record`);
		} else if (data.exportStatus === PCCStatus.FailedNoEMRId) {
			this.ntfService.error(`Failed - ${data.patient.name} not found in PCC system`);
		} else {
			this.ntfService.error(`${data.exportStatus.split('_').join(' ').toLowerCase()} Reported to EMR`)
		}
	}
}
