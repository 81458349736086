import { Pipe, PipeTransform } from '@angular/core'
import { AlertSubject } from '../../../shared/model/alert'
import { getUserLocale } from '../../helpers/functions'

@Pipe({
	name: 'alertSubjectUnit'
})
export class AlertSubjectUnitPipe implements PipeTransform {
	AlertSubject = AlertSubject

	transform(data: any, ...args: unknown[]): string {
		if (!data) return ''
		if (data === AlertSubject.HeartRate) {
			return 'BMP'
		} else if (data === AlertSubject.RespirationRate) {
			return 'RPM'
		} else if (data === AlertSubject.BodyTemperature) {
			return getUserLocale() === 'en-US' ? '°F' : '°C'
		} else if (
			data === AlertSubject.SystolicPressure ||
			data === AlertSubject.DiastolicPressure
		) {
			return 'mmHg'
		} else if (data === AlertSubject.SPO2) {
			return '%'
		} else if (data === AlertSubject.BloodGlucose) {
			return 'mg/dL'
		}
		return ''
	}
}
