<section class='pcc-patients'>
  <nz-modal [(nzVisible)]='isVisible'
            nzClassName='custom-pcc-vitals-modal'>
    <div *nzModalContent>
      <div class='update-emr-popover__container'>
        <span (click)='handlerClickCancelButton()' class='icon-close' nz-icon nzTheme='outline'
              nzType='close'></span>
        <div class='update-emr-popover__top-content'>
          <div class='update-emr-popover__title' style='text-transform: uppercase;'>
            UPDATE PointClickCare MANUALLY
          </div>
          <div class='update-emr-popover__sub-title'>
            Last updated on {{patient?.observations?.timestamp?.value | date : 'M/d/yy, HH:mm'}}
          </div>
        </div>
        <span class='update-emr-popover__selector-province'>
     <nz-select (ngModelChange)='handlerSelectProvinceChange($event)' *ngIf='needLogin' [(ngModel)]='selectedProvince'
                [nzDropdownClassName]='"update-emr-popover-selector"'>
        <nz-option [nzValue]='0' nzLabel='Current'></nz-option>
        <nz-option [nzLabel]='"1 " + ("province.hour" | translate)' [nzValue]='1'></nz-option>
        <nz-option [nzLabel]='"2 " + ("province.hour" | translate)' [nzValue]='2'></nz-option>
        <nz-option [nzLabel]='"3 " + ("province.hour" | translate)' [nzValue]='5'></nz-option>
        <nz-option [nzLabel]='"12 " + ("province.hour" | translate)' [nzValue]='12'></nz-option>
        <nz-option [nzLabel]='"1 " + ("province.day" | translate)' [nzValue]='24'></nz-option>
      </nz-select>
  </span>
        <div *ngIf='needLogin' class='update-emr-popover__main-content'>
          <label
            [(ngModel)]='checkedBloodPressure'
            [disabled]='selectedProvince === 0 ? !patient.observations?.systolicPressure?.value : !patientMeasurementHistorical?.systolicPressure?.value'
            nz-checkbox>
            <i
              [ngClass]="{'critical': selectedProvince === 0  ? patient.observations?.systolicPressure?.isCritical : patientMeasurementHistorical?.systolicPressure?.isCritical , 'critical-d': selectedProvince === 0 ? patient.observations?.diastolicPressure?.isCritical : patientMeasurementHistorical?.diastolicPressure?.isCritical}"
              [nzType]='ObservationFields.SystolicPressure | alertSubjectIcon'
              nz-icon
            ></i>
            <span
              [ngClass]="{'critical': selectedProvince === 0  ? patient.observations?.systolicPressure?.isCritical : patientMeasurementHistorical?.systolicPressure?.isCritical, 'critical-d': selectedProvince === 0 ? patient.observations?.diastolicPressure?.isCritical : patientMeasurementHistorical?.diastolicPressure?.isCritical}"
              class='item-text'>BLOOD PRESSURE</span>
            <span
              [ngClass]="{'critical': selectedProvince === 0  ? patient.observations?.systolicPressure?.isCritical : patientMeasurementHistorical?.systolicPressure?.isCritical, 'critical-d': selectedProvince === 0 ? patient.observations?.diastolicPressure?.isCritical : patientMeasurementHistorical?.diastolicPressure?.isCritical}"
              class='item-dashed item-dashed-bp'></span>
            <span *ngIf='patient.observations?.systolicPressure && selectedProvince === 0; else historicalPressure'
                  [ngClass]="{'critical': patient.observations?.systolicPressure?.isCritical || patient.observations?.diastolicPressure?.isCritical}"
                  class='item-value'>{{[patient.observations?.systolicPressure.value | roundNumber, patient.observations?.diastolicPressure.value | roundNumber].join('/')}}</span>
            <ng-template #historicalPressure>
              <span *ngIf='patientMeasurementHistorical?.systolicPressure'
                    [ngClass]="{'critical': patientMeasurementHistorical?.systolicPressure?.isCritical ||  patientMeasurementHistorical?.diastolicPressure?.isCritical}"
                    class='item-value'>{{[patientMeasurementHistorical?.systolicPressure?.value | roundNumber, patientMeasurementHistorical?.diastolicPressure.value | roundNumber].join('/')}}</span>
            </ng-template>
          </label>
          <label
            [(ngModel)]='checkedHeartRate'
            [disabled]='selectedProvince === 0 ? !patient.observations?.heart_rate?.value : !patientMeasurementHistorical?.heart_rate?.value'
            nz-checkbox>
            <i
              [ngClass]="{'critical': selectedProvince === 0 ? patient.observations?.heart_rate?.isCritical : patientMeasurementHistorical?.heart_rate?.isCritical}"
              [nzType]='ObservationFields.HeartRate | alertSubjectIcon'
              nz-icon
            ></i>
            <span
              [ngClass]="{'critical': selectedProvince === 0 ? patient.observations?.heart_rate?.isCritical : patientMeasurementHistorical?.heart_rate?.isCritical}"
              class='item-text'>HEART RATE</span>
            <span
              [ngClass]="{'critical': selectedProvince === 0 ? patient.observations?.heart_rate?.isCritical : patientMeasurementHistorical?.heart_rate?.isCritical}"
              class='item-dashed item-dashed-hr'></span>
            <span *ngIf='patient.observations?.heart_rate && selectedProvince === 0; else historicalHeartRate'
                  [ngClass]="{'critical': patient.observations?.heart_rate?.isCritical}"
                  class='item-value'>{{patient.observations?.heart_rate.value | roundNumber}}</span>
            <ng-template #historicalHeartRate>
              <span *ngIf='patientMeasurementHistorical?.heart_rate'
                    [ngClass]="{'critical': patientMeasurementHistorical?.heart_rate?.isCritical}"
                    class='item-value'>{{patientMeasurementHistorical?.heart_rate?.value | roundNumber}}</span>
            </ng-template>
          </label>
          <label
            [(ngModel)]='checkedGlucose'
            [disabled]='selectedProvince === 0 ? !patient.observations?.bloodGlucose?.value : !patientMeasurementHistorical?.bloodGlucose?.value'
            nz-checkbox>
            <i
              [ngClass]="{'critical':selectedProvince === 0 ? patient.observations?.bloodGlucose?.isCritical : patientMeasurementHistorical?.bloodGlucose?.isCritical}"
              [nzType]='ObservationFields.BloodGlucose | alertSubjectIcon'
              nz-icon
            ></i>
            <span
              [ngClass]="{'critical': selectedProvince === 0 ? patient.observations?.bloodGlucose?.isCritical : patientMeasurementHistorical?.bloodGlucose?.isCritical}"
              class='item-text'>GLOCOSE</span>
            <span
              [ngClass]="{'critical': selectedProvince === 0 ? patient.observations?.bloodGlucose?.isCritical : patientMeasurementHistorical?.bloodGlucose?.isCritical}"
              class='item-dashed item-dashed-cg'></span>
            <span *ngIf='patient.observations?.bloodGlucose && selectedProvince === 0; else historicalGlucose'
                  [ngClass]="{'critical':patient.observations?.bloodGlucose?.isCritical}"
                  class='item-value'>{{patient.observations?.bloodGlucose?.value | roundNumber}}</span>
            <ng-template #historicalGlucose>
              <span *ngIf='patientMeasurementHistorical?.bloodGlucose'
                    [ngClass]="{'critical': patientMeasurementHistorical?.bloodGlucose?.isCritical}"
                    class='item-value'>{{patientMeasurementHistorical.bloodGlucose.value | roundNumber}}</span>
            </ng-template>
          </label>
          <label
            [(ngModel)]='checkedSpo2'
            [disabled]='selectedProvince === 0 ? !patient.observations?.spo2?.value : !patientMeasurementHistorical?.spo2?.value'
            nz-checkbox>
            <i
              [ngClass]="{'critical': selectedProvince === 0 ? patient.observations?.spo2?.isCritical : patientMeasurementHistorical?.spo2?.isCritical}"
              [nzType]='ObservationFields.SpO2 | alertSubjectIcon'
              nz-icon
            ></i>
            <span
              [ngClass]="{'critical': selectedProvince === 0 ? patient.observations?.spo2?.isCritical : patientMeasurementHistorical?.spo2?.isCritical}"
              class='item-text'>SPO2</span>
            <span
              [ngClass]="{'critical':selectedProvince === 0 ? patient.observations?.spo2?.isCritical : patientMeasurementHistorical?.spo2?.isCritical}"
              class='item-dashed item-dashed-sp'></span>
            <span *ngIf='patient.observations?.spo2 && selectedProvince === 0; else historicalSpo2'
                  [ngClass]="{'critical':patient.observations?.spo2?.isCritical}"
                  class='item-value'>{{patient.observations?.spo2?.value | roundNumber}}</span>
            <ng-template #historicalSpo2>
              <span *ngIf='patientMeasurementHistorical?.spo2'
                    [ngClass]="{'critical': patientMeasurementHistorical?.spo2?.isCritical}"
                    class='item-value'>{{patientMeasurementHistorical?.spo2?.value | roundNumber}}</span>
            </ng-template>
          </label>
          <label
            [(ngModel)]='checkedBodyTemperature'
            [disabled]='selectedProvince === 0 ? !patient.observations?.body_temperature?.value : !patientMeasurementHistorical?.body_temperature?.value'
            nz-checkbox>
            <i
              [ngClass]="{'critical':selectedProvince === 0 ? patient.observations?.body_temperature?.isCritical : patientMeasurementHistorical?.body_temperature?.isCritical}"
              [nzType]='ObservationFields.BodyTemperature | alertSubjectIcon'
              nz-icon
            ></i>
            <span
              [ngClass]="{'critical':selectedProvince === 0 ? patient.observations?.body_temperature?.isCritical : patientMeasurementHistorical?.body_temperature?.isCritical}"
              class='item-text'>BODY TEMPERATURE</span>
            <span
              [ngClass]="{'critical': selectedProvince === 0 ? patient.observations?.body_temperature?.isCritical : patientMeasurementHistorical?.body_temperature?.isCritical}"
              class='item-dashed item-dashed-bt'></span>
            <span *ngIf='patient.observations?.body_temperature && selectedProvince === 0; else historicalTemperature'
                  [ngClass]="{'critical':patient.observations?.body_temperature?.isCritical}"
                  class='item-value'>{{patient.observations?.body_temperature.value | roundNumber | transformCelsiusToFahrenhei}}</span>
            <ng-template #historicalTemperature>
              <span *ngIf='patientMeasurementHistorical?.body_temperature'
                    [ngClass]="{'critical':patientMeasurementHistorical?.body_temperature?.isCritical}"
                    class='item-value'>{{patientMeasurementHistorical.body_temperature?.value | roundNumber | transformCelsiusToFahrenhei}}</span>
            </ng-template>
          </label>
          <label
            [(ngModel)]='checkedRespirationRate'
            [disabled]='selectedProvince === 0 ? !patient.observations?.respiration_rate?.value : !patientMeasurementHistorical?.respiration_rate?.value'
            nz-checkbox>
            <i
              [ngClass]="{'critical':selectedProvince === 0 ? patient.observations?.respiration_rate?.isCritical : patientMeasurementHistorical?.respiration_rate?.isCritical}"
              [nzType]='ObservationFields.RespirationRate | alertSubjectIcon'
              nz-icon
            ></i>
            <span
              [ngClass]="{'critical':selectedProvince === 0 ? patient.observations?.respiration_rate?.isCritical : patientMeasurementHistorical?.respiration_rate?.isCritical}"
              class='item-text'>Respiration Rate</span>
            <span
              [ngClass]="{'critical': selectedProvince === 0 ? patient.observations?.respiration_rate?.isCritical : patientMeasurementHistorical?.respiration_rate?.isCritical}"
              class='item-dashed item-dashed-rr'></span>
            <span
              *ngIf='patient.observations?.respiration_rate && selectedProvince === 0; else historicalRespirationRate'
              [ngClass]="{'critical':patient.observations?.respiration_rate?.isCritical}"
              class='item-value'>{{patient.observations?.respiration_rate.value | roundNumber}}</span>
            <ng-template #historicalRespirationRate>
              <span *ngIf='patientMeasurementHistorical?.respiration_rate'
                    [ngClass]="{'critical':patientMeasurementHistorical?.respiration_rate?.isCritical}"
                    class='item-value'>{{patientMeasurementHistorical.respiration_rate?.value | roundNumber}}</span>
            </ng-template>
          </label>
        </div>
        <div *ngIf='!needLogin' class='update-emr-popover__main-content'>
          <section class='login-form'>
            <form [formGroup]='form' nz-form nzLayout='vertical'>
              <nz-form-item class='login-form__item'>
                <nz-form-label class='login-form__label'>User name</nz-form-label>
                <nz-form-control
                  class='login-form__control'
                  nzErrorTip='Please enter User name'>
                  <input class='login-form__input' formControlName='username' nz-input />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label class='login-form__label'>Password</nz-form-label>
                <nz-form-control class='login-form__control' nzErrorTip='Password must be at least 6 characters!'>
                  <input
                    [type]="passwordVisible ? 'text' : 'password'"
                    class='login-form__input login-form__input-password'
                    formControlName='password' nz-input type='password'
                  />
                  <i (click)='passwordVisible = !passwordVisible'
                     [nzType]="passwordVisible ? 'ns:hide-password' : 'ns:show-password'"
                     class='login-form__password-icon'
                     nz-icon></i>
                </nz-form-control>
              </nz-form-item>
            </form>
          </section>
        </div>
        <button (click)='submitForm()' *ngIf='tokenStatus && tokenStatus !== "INVALID"' class='btn-prime update-emr'
                nz-button nzBlock nzType='primary'
                type='submit'>Update
        </button>
        <button (click)='linkToPcc()' *ngIf='tokenStatus && tokenStatus === "INVALID"' class='btn-prime update-emr'
                nz-button nzBlock nzType='primary'
                type='submit'>Login to PointClickCare
        </button>
      </div>
    </div>
  </nz-modal>
</section>

