import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'roundNumber'
})
export class RoundNumberPipe implements PipeTransform {
	transform(number: number, ...args: unknown[]): any {
		if (!number) return ''
		return Math.round(number)
	}
}
