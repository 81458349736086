<header [ngClass]="{'patient-header': type === 'patient'}" class='header'>
  <ul>
    <li
      *ngIf='type !== "alerts" && type !== "patient"'
      [ngClass]="{'have-alerts': entitiesHaveAlertLength, 'havent-alerts': !entitiesHaveAlertLength}">
      <div class='entities-length'>{{!entitiesHaveAlertLength ? 0 : entitiesHaveAlertLength}}</div>
      <div>
        <i
          nz-icon
          nzType='icons:header-alerted'></i>
        <span>{{'header.alerted' | translate }}</span>
      </div>
    </li>
    <li
      *ngIf='type === "alerts"'
      [ngClass]="{'have-alerts': entitiesHaveAlertLength, 'havent-alerts': !entitiesHaveAlertLength}">
      <div class='entities-length'>{{!entitiesHaveAlertLength ? 0 : entitiesHaveAlertLength}}</div>
      <div>
        <i
          *ngIf='entitiesHaveAlertLength > 0; else heventAlerts'
          nz-icon
          nzType='icons:header-ongoind'></i>
        <ng-template #heventAlerts>
          <i
            nz-icon
            nzType='icons:alert-status-ongoing'></i>
        </ng-template>
        <span>{{'header.ongoing' | translate}}</span>
      </div>
    </li>
    <li *ngIf='type === "patients" || type === "all-patients"'>
      <div class='entities-length'>{{!followEntitiesLength ? 0 : followEntitiesLength}}</div>
      <div>
        <i
          nz-icon
          nzType='icons:header-follow-up'></i>
        <span>{{'header.follow' | translate}}</span>
      </div>
    </li>
    <li *ngIf='type === "devices"'>
      <div class='entities-length'>{{!totalDisconnect ? 0 : totalDisconnect}}</div>
      <div>
        <i
          nz-icon
          nzType='icons:header-follow-up'></i>
        <span>{{'header.disconnected' | translate}}</span>
      </div>
    </li>
    <li *ngIf='type === "alerts"'>
      <div class='entities-length'>{{!entitiesMuteLength ? 0 : entitiesMuteLength}}</div>
      <div>
        <i
          nz-icon
          nzType='icons:header-muted'></i>
        <span>{{'header.muted' | translate}}</span>
      </div>
    </li>
    <li *ngIf='type === "patients" || type === "all-patients"'>
      <div class='entities-length'>{{!totalCount ? 0 : totalCount}}</div>
      <div>
        <i nz-icon nzType='icons:header-total'></i> <span>{{'header.totalPatients' | translate}}</span>
      </div>
    </li>
    <li *ngIf='type === "devices"'>
      <div class='entities-length'>{{!totalCount ? 0 : totalCount}}</div>
      <div>
        <i nz-icon nzType='icons:header-total'></i> <span>{{'header.totalDevices' | translate}}</span>
      </div>
    </li>
    <li *ngIf='type === "alerts"'>
      <div class='entities-length'>{{!totalCount ? 0 : totalCount}}</div>
      <div>
        <i nz-icon nzType='icons:header-alerted' style='color: #263558'></i>
        <span>{{'header.totalAlerts' | translate}}</span>
      </div>
    </li>
  </ul>
  <div class="header__right">
    <div class='header__search'>
      <nz-select
        (ngModelChange)="router.navigate(['/patient', $event])"
        (nzOnSearch)='searchPatient.emit($event.trim())'
        [(ngModel)]='textSearch'
        [nzPlaceHolder]='"header.search" | translate'
        [nzShowArrow]='false'
        nzServerSearch
        nzShowSearch
      >
        <nz-option *ngFor='let item of searchPatient$ | async' [nzLabel]='item.name' [nzValue]='item.id'></nz-option>
      </nz-select>
    </div>
    <div>
      <nz-form-control style="width: 150px">
        <nz-select [(ngModel)]='selectedValue' (ngModelChange)="departmentChange($event)" [ngModelOptions]='{standalone: true}' nzPlaceHolder='' class="header-change-department" nzDropdownClassName="header-change-department">
          <nz-option *ngFor='let department of departments$ | async' [nzLabel]='department.name' [nzValue]='department.id'></nz-option>
        </nz-select>
      </nz-form-control>
    </div>
  </div>
</header>
