import { Pipe, PipeTransform } from '@angular/core'
import moment from 'moment/moment'
import { getUserLocale } from '../helpers/functions'

@Pipe({
	name: 'time'
})
export class TimePipe implements PipeTransform {
	transform(date: any, ...args: unknown[]): string {
		if (!date) return ''
		if (args.includes('short')) {
			return getUserLocale() === 'en-US'
				? moment(date).format('HH:mm')
				: moment(date).format('HH:mm')
		}
		if (args.includes('date')) {
			return getUserLocale() === 'en-US'
				? moment(date).format('M/D/YY')
				: moment(date).format('D/M/YY')
		}
		return getUserLocale() === 'en-US'
			? moment(date).format('MM/DD/yy HH:mm')
			: moment(date).format('DD/MM/yy HH:mm')
	}
}
