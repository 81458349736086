<div class='aimed-search-mobile'>
  <i nz-icon nzType='icons:mobile-seach-icon'></i>
  <nz-select
    (click)="router.navigate(['/patients'])"
    *ngIf='isPatients'
    [(ngModel)]='textSearch'
    [nzBorderless]='true'
    [nzShowArrow]='false'
    nzDropdownClassName='custom-search-dropdown-not'
    nzPlaceHolder='Search Patient'
    nzServerSearch
    nzShowSearch
  >
    <nz-option *ngFor='let item of searchPatient$ | async' [nzLabel]='item.name'
               [nzValue]='item.id'></nz-option>
  </nz-select>

  <input
    (ngModelChange)='searchPatient.emit($event.trim())'
    *ngIf='!isPatients'
    [(ngModel)]='textSearch'
    class='input-search'
    placeholder='Search Patient'
  >
</div>
