import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'roundFixNumber',
})
export class RoundFixNumberPipe implements PipeTransform {
  transform(number: number, ...args: unknown[]): number {
    if (!number) return 0
    return Number(number.toFixed(1))
  }
}
