<ng-container *ngIf="beforeIcon && afterIcon">
  <nz-input-group class="aiomed-input {{typeStyle}}" [nzSuffix]="suffixTemplateInfo" [nzPrefix]="prefixTemplateUser">
    <input [ngClass]="{'input-error': isError}" (ngModelChange)="update.emit($event)" nz-input [formControl]="control" [placeholder]="placeholder"
           [value]="value" [type]="type"/>
  </nz-input-group>
  <ng-template #prefixTemplateUser>
    <i nz-icon [nzType]="beforeIcon"></i>
  </ng-template>
  <ng-template #suffixTemplateInfo>
    <i nz-icon nz-tooltip nzTooltipTitle="Extra information" [nzType]="afterIcon"></i>
  </ng-template>
</ng-container>

<ng-container *ngIf="beforeIcon && !afterIcon">
  <nz-input-group class="aiomed-input {{typeStyle}}" [nzPrefix]="prefixTemplateUser">
    <input [ngClass]="{'input-error': isError}" (ngModelChange)="update.emit($event)" nz-input [formControl]="control" [placeholder]="placeholder"
           [value]="value" [type]="type"/>
  </nz-input-group>
  <ng-template #prefixTemplateUser>
    <i nz-icon [nzType]="beforeIcon"></i>
  </ng-template>
</ng-container>

<ng-container *ngIf="afterIcon && !beforeIcon">
  <nz-input-group class="aiomed-input {{typeStyle}}" [nzSuffix]="suffixTemplateInfo">
    <input [ngClass]="{'input-error': isError}" (ngModelChange)="update.emit($event)" nz-input [formControl]="control" [placeholder]="placeholder"
           [value]="value" [type]="type"/>
  </nz-input-group>
  <ng-template #suffixTemplateInfo>
    <i nz-icon nz-tooltip nzTooltipTitle="Extra information" [nzType]="afterIcon"></i>
  </ng-template>
</ng-container>

<ng-container *ngIf="!afterIcon && !beforeIcon">
  <input [ngClass]="{'input-error': isError}" class="aiomed-input" nz-input [formControl]="control"
         [placeholder]="placeholder" (ngModelChange)="update.emit($event)" [value]="value" [type]="type"/>
</ng-container>
