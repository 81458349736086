import { Pipe, PipeTransform } from '@angular/core'
import { AlertSubject } from '../../../shared/model/alert'

@Pipe({
	name: 'alertSubjectIcon'
})
export class AlertSubjectIconPipe implements PipeTransform {
	AlertSubject = AlertSubject

	transform(data: any, ...args: unknown[]): string {
		if (!data) return ''
		if (data === AlertSubject.HeartRate) {
			return 'icons:heart-rate'
		} else if (data === AlertSubject.DeviceBattery) {
			return 'icons:battery-low'
		} else if (data === AlertSubject.Device) {
			return 'icons:alert-device'
		} else if (data === AlertSubject.RespirationRate) {
			return 'icons:respiration'
		} else if (data === AlertSubject.BodyTemperature) {
			return 'icons:body-temp'
		} else if (
			data === AlertSubject.SystolicPressure ||
			data === AlertSubject.DiastolicPressure ||
			data === 'systolicPressure' ||
			data === 'diastolicPressure'
		) {
			return 'icons:blood-press'
		} else if (data === AlertSubject.SPO2) {
			return 'icons:spo2'
		} else if (data === AlertSubject.ExitBedRisk) {
			return 'icons:exit-bed-risk'
		} else if (data === AlertSubject.BloodGlucose || data === 'bloodGlucose') {
			return 'icons:blood-clucoze'
		} else if (data === AlertSubject.Mobility) {
			return 'icons:mobility'
		}
		return 'icons:nav-alerts'
	}
}
