import { Pipe, PipeTransform } from '@angular/core'
import { DepartmentDTO } from '../../../shared/model/permission.model'

@Pipe({
	name: 'isManual'
})
export class IsManualPipe implements PipeTransform {
	transform(isManual: boolean | undefined, department: DepartmentDTO): boolean {
		if (!isManual || !department || (isManual && department.id === 'all'))
			return false
		else return !!department.isAutomatic
	}
}
