import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'app-ho-have-permission',
	templateUrl: './ho-have-permission.component.html'
})
export class HoHavePermissionComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
