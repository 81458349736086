import { NgxsDataEntityCollectionsRepository } from '@angular-ru/ngxs/repositories'
import {
	EntityCollections,
	EntityDictionary,
	EntityIdType
} from '@angular-ru/cdk/entity'
import { DataAction, Payload } from '@angular-ru/ngxs/decorators'
import { EMPTY, Observable, tap } from 'rxjs'
import { EntityContext } from '@angular-ru/ngxs/typings'
import { SortModel } from '../shared/model/sort.model'
import { PAGE_SIZE } from '../core/helpers/variables'
import { PatientObservationDTO } from '../shared/model/patient-observation'
import { DepartmentDTO } from '../shared/model/permission.model'
import { TaskDTO } from '../shared/model/task.model'
import {
	ManualMeasurementsDTO,
	MeasurementSummaryInterface
} from '../shared/model/measurement'
import { AlertDTO } from '../shared/model/alert'
import { defaultAllDepartment } from '../shared/model/departments.model'
import { InsightDTO } from '../shared/model/insight.model'

export class EntityCollectionsRepository<
	T extends { id: string },
	C = {}
> extends NgxsDataEntityCollectionsRepository<T, string, C> {
	public has(i: EntityIdType): boolean {
		return i in this.entities
	}
}

export interface EntityCollation {
	totalCount: number
	freeTextFilter: string | undefined
	pageSize: number
	page: number
	sort: SortModel[]
	deviceFilter: string
	isLoading: boolean
	focusOnId: string | null
	subtractHours: number
	searchText: string
	historicalEcg: { value: number; timestamp: string | Date }[] | []
	historicalMeasurements: EntityDictionary<string, PatientObservationDTO> | null
	allAlerts: AlertDTO[] | []
	allDepartments: DepartmentDTO[]
	currentDepartment: DepartmentDTO | null
	patientOverdueTask: number
	patientActiveTask: number
	overduePatientsTaskList: TaskDTO[]
	overduePatientTaskList: TaskDTO[]
	reports: MeasurementSummaryInterface[]
	lastObservationsTime: string | null
	manualMeasurement: ManualMeasurementsDTO[]
	withoutDepartment: boolean
	pccCurrentPatientImage: Blob | null
	newFileId: string | null
	currentInsights: InsightDTO | null
	insightMeasurements: any
}

export function defaultEntityCollation(): EntityCollation {
	return {
		totalCount: 0,
		deviceFilter: 'all',
		freeTextFilter: undefined,
		searchText: '',
		isLoading: false,
		sort: [],
		pageSize: PAGE_SIZE,
		page: 0,
		currentDepartment: null,
		focusOnId: null,
		subtractHours: 1,
		historicalEcg: [],
		historicalMeasurements: null,
		allAlerts: [],
		allDepartments: [defaultAllDepartment as DepartmentDTO],
		patientOverdueTask: 0,
		patientActiveTask: 0,
		overduePatientsTaskList: [],
		overduePatientTaskList: [],
		reports: [],
		lastObservationsTime: null,
		manualMeasurement: [],
		withoutDepartment: false,
		pccCurrentPatientImage: null,
		newFileId: null,
		currentInsights: null,
		insightMeasurements: []
	}
}

export type BaseEntityCollections<
	T extends { id: string },
	C = {}
> = EntityCollections<T, string, C>
export type CollatableEntityCollections<T extends { id: string }> =
	BaseEntityCollections<T, EntityCollation>

export abstract class CollatableEntityCollectionsRepository<
	T extends { id: string },
	C extends EntityCollation = EntityCollation
> extends NgxsDataEntityCollectionsRepository<T, string, C> {
	@DataAction()
	public loadEntities(@Payload('ids') ids?: string[]): Observable<void> {
		const state = this.getState();
		this.setEntitiesState({
			...state,
			isLoading: true
		})
		return this.loadEntitiesFromBackend(ids).pipe(
			tap((_) =>
				this.setEntitiesState({
					...state,
					isLoading: false
				})
			)
		)
	}

	@DataAction()
	public setFreeTextFilter(
		@Payload('freeTextFilter') freeTextFilter: string
	): Observable<void> {
		const state = this.getState();
		this.setEntitiesState({
			...state,
			freeTextFilter: freeTextFilter,
			isLoading: true,
			pageSize: PAGE_SIZE
		})

		return this.loadEntitiesFromBackend().pipe(
			tap((_) => {
				this.setEntitiesState({
					...state,
					isLoading: false
				})
			})
		)
	}

	@DataAction()
	public setSubtractHours(
		@Payload('subtractHours') subtractHours: number
	): Observable<void> {
		const state = this.getState();
		this.setEntitiesState({
			...state,
			subtractHours
		})
		return EMPTY
	}

	@DataAction()
	public setQueryParams(
		@Payload('pageSize') pageSize: number,
		@Payload('sort') sort: any[] = [],
		@Payload('freeTextFilter') freeTextFilter: string = '',
		@Payload('type') type: 'haveAlert' | 'default' = 'default'
	): Observable<void> {
		const state = this.getState();
		this.setEntitiesState({
			...state,
			pageSize,
			sort,
			subtractHours: 1,
			freeTextFilter
		})
		return this.setPaginationSetting(type)
	}

	@DataAction()
	public focusOn(@Payload('id') id: string): Observable<void> {
		const state = this.getState();
		this.setEntitiesState({
			...state,
			focusOnId: id,
			isLoading: true
		})

		return this.loadEntitiesFromBackend([id]).pipe(
			tap((_) =>
				this.setEntitiesState({
					...state,
					isLoading: false
				})
			)
		)
	}

	protected abstract loadEntitiesFromBackend(ids?: string[]): Observable<any>

	/**
	 * Some stupid workaround to the fact that I can't use "ctx" directly with type extending collation
	 * @protected
	 */
	protected extendedCtx<E extends EntityCollation = C>(): EntityContext<
		T,
		string,
		E
	> {
		// @ts-ignore
		return super.ctx
	}

	protected abstract setPaginationSetting(type?: string): Observable<any>

	/**
	 * Some stupid workaround to the fact that I can't use "ctx" directly with type extending collation
	 * @protected
	 */
	protected extendCtx<E extends EntityCollation = C>(): EntityContext<
		T,
		string,
		E
	> {
		// @ts-ignore
		return super.ctx
	}
}
