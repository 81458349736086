import { Pipe, PipeTransform } from '@angular/core'
import { DeviceCriticalStatus } from '../../shared/model/device.model'

@Pipe({
	name: 'deviceReportIcon'
})
export class DeviceReportIconPipe implements PipeTransform {
	transform(data: any, ...args: unknown[]): string {
		if (data === undefined) return ''
		if (data === DeviceCriticalStatus.NoConnection) {
			return 'icons:report-bad-reading'
		} else if (data === DeviceCriticalStatus.BadReading) {
			return 'icons:report-no-connection'
		} else if (
			data === DeviceCriticalStatus.NoBattery ||
			data === DeviceCriticalStatus.LowBattery
		) {
			return 'icons:report-battery'
		} else if (data === DeviceCriticalStatus.NoConfiguration) {
			return 'icons:report-not-calibrate'
		} else {
			return 'icons:report-active'
		}
	}
}
