import {
	ComponentFactoryResolver,
	Directive,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	TemplateRef,
	ViewContainerRef
} from '@angular/core'
import { interval, tap } from 'rxjs'
import { SpinnerComponent } from '../../app/layouts/UI/spinners/spinner/spinner.component'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Directive({
	selector: '[aiomedSpinner]'
})
export class AiomedSpinnerDirective implements OnInit, OnChanges {
	@Input('aiomedSpinner') showSpinner: boolean
	@Output() public pageLoading = new EventEmitter()

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private viewContainerRef: ViewContainerRef,
		private templateRef: TemplateRef<any>
	) {}

	ngOnInit(): void {
		interval(2000)
			.pipe(
				untilDestroyed(this),
				tap(() => this.pageLoading.emit(false))
			)
			.subscribe()
	}

	ngOnChanges(): void {
		if (this.showSpinner) {
			this.viewContainerRef.clear()
			const spinnerComponent =
				this.componentFactoryResolver.resolveComponentFactory(SpinnerComponent)
			this.viewContainerRef.createComponent(spinnerComponent)
		} else {
			this.viewContainerRef.clear()
			this.viewContainerRef.createEmbeddedView(this.templateRef)
		}
	}
}
