<nz-modal [(nzVisible)]='isVisible'
          nzClassName='report-review-abnormal-vitals'>
  <div *nzModalContent>
    <div class='report-review-abnormal-vitals__container'>
      <div class='report-review-abnormal-vitals__title'>{{this.currentReportPatients?.length}} OF {{patientLength}}
        NOT REPORTED
        <span (click)='isModalVisible = true' class='icon-close' nz-icon
              nzType='icons:close'></span>
      </div>
      <div class='report-review-abnormal-vitals__text'>There are a few patients with missing vitals or abnormal
        values. Please review each one and report individually
      </div>
      <div>
        <section class='entities-table'>
          <nz-table
            [nzData]='reportPatients'
            [nzScroll]="{ y: '480px'}"
            [nzShowPagination]='false'
            nzTableLayout='fixed'
          >
            <thead>
            <tr>
              <th class='item-name checkbox' nzWidth='180px'><span>Name</span>
              </th>
              <th>Room</th>
              <th>BP</th>
              <th>HR</th>
              <th>RR</th>
              <th>SpO2</th>
              <th>Temp</th>
              <th nzWidth='90px'>Edit</th>
              <th nzWidth='65px'>EMR</th>
            </tr>
            </thead>
            <tbody>
            <tr
              *ngFor='let patient of currentReportPatients; trackBy: trackByMethod'
            >
              <td class='checkbox absolute'>
                <div class='table-name white content'>
                  <nz-avatar *ngIf='!patient["avatar"]; else haveAvatar' [nzText]='patient.name | shortName'
                             class='aiomed-avatar'
                             nzSize='small'></nz-avatar>
                  <ng-template #haveAvatar>
                    <nz-avatar [nzSrc]='patient["avatar"]["signedUrl"]'
                               nzSize='small'></nz-avatar>
                  </ng-template>
                  <span>{{ patient["lastName"] }}, {{ patient["firstName"][0] }}</span>
                </div>
              </td>
              <td class='absolute'>
                <div class='white content'></div>
              </td>
              <td>
                <div class='white content'>
                  {{ patient["room"] | titlecase }}
                </div>
              </td>
              <td>
                <div
                  *ngIf='patient?.reports?.measurements?.systolicPressure?.value && !patient.checked'
                  [ngClass]='{"red-value": (patient?.reports?.measurements?.systolicPressure?.value | checkVitalEmrRules:  ObservationFields.SystolicPressure : patient), "red-value-d": (patient.reports?.measurements?.diastolicPressure?.value | checkVitalEmrRules: ObservationFields.DiastolicPressure : patient)}'
                  class=' white content'>
                  <i *ngIf='patient?.reports?.measurements?.systolicPressure?.isManual | isManual : currentDepartment'
                     class='manual' nz-icon
                     nzType='icons:table-manual'></i>
                  {{patient?.reports?.measurements?.systolicPressure?.value | roundNumber}}<span
                  *ngIf='patient?.reports?.measurements?.diastolicPressure?.value'><ng-container
                  *ngIf='patient?.reports?.measurements?.diastolicPressure?.value'>/</ng-container></span>{{ patient.reports?.measurements?.diastolicPressure?.value | roundNumber}}
                </div>
                <div
                  *ngIf='currentEditPatient?.reports?.measurements?.systolicPressure.checked && patient.checked || currentEditPatient?.reports?.measurements?.systolicPressure.value && patient.checked'
                  [ngClass]='{"red-value": diastolicError || systolicError}'
                  class='white content bp-input-box'>
                  <i
                    *ngIf='(currentEditPatient?.reports?.measurements?.systolicPressure?.isManual | isManual : currentDepartment) || (currentEditPatient?.reports?.measurements?.diastolicPressure?.isManual| isManual : currentDepartment)'
                    class='manual'
                    nz-icon
                    nzType='icons:table-manual'></i>
                  <input
                    (click)='handlerEmptyLineClick("systolicPressure")'
                    (keydown)='protectionNumbers($event)'
                    (ngModelChange)='handlerInputChange($event, ObservationFields.SystolicPressure)'
                    [(ngModel)]='currentEditPatient.reports.measurements.systolicPressure.value'
                  ><span
                  [ngClass]='{"manual": currentEditPatient?.reports?.measurements?.systolicPressure?.isManual | isManual : currentDepartment}'>/</span><input
                  (click)='handlerEmptyLineClick("diastolicPressure")'
                  (keydown)='protectionNumbers($event)'
                  (ngModelChange)='handlerInputChange($event, ObservationFields.DiastolicPressure)'
                  [(ngModel)]='currentEditPatient.reports.measurements.diastolicPressure.value'
                >
                </div>
                <div (click)='handlerEmptyLineClick("systolicPressure")'
                     *ngIf='!patient?.reports?.measurements?.systolicPressure?.value && !patient.checked || !currentEditPatient?.reports?.measurements?.systolicPressure.checked && !currentEditPatient?.reports?.measurements?.systolicPressure.value && patient.checked'
                     class=' white content'><i
                  class='empty-line' nz-icon nzType='icons:empty-line'></i></div>
              </td>
              <td>
                <div *ngIf='patient?.reports?.measurements?.heartRate?.value && !patient?.checked'
                     [ngClass]='{"red-value": (patient?.reports?.measurements?.heartRate?.value | checkVitalEmrRules: ObservationFields.HeartRate : patient)}'
                     class='white content'>
                  <i *ngIf='patient?.reports?.measurements?.heartRate?.isManual | isManual : currentDepartment'
                     class='manual' nz-icon
                     nzType='icons:table-manual'></i>{{ patient?.reports?.measurements?.heartRate?.value | roundNumber}}
                </div>
                <div
                  *ngIf='currentEditPatient?.reports?.measurements?.heartRate?.checked && patient.checked || currentEditPatient?.reports?.measurements?.heartRate?.value && patient.checked'
                  [ngClass]='{"red-value": heartRateError}'
                  class='white content input-box'>
                  <i
                    *ngIf='currentEditPatient?.reports?.measurements?.heartRate?.isManual | isManual : currentDepartment'
                    class='manual'
                    nz-icon
                    nzType='icons:table-manual'></i>
                  <input (click)='handlerEmptyLineClick("heartRate")' (keydown)='protectionNumbers($event)'
                         (ngModelChange)='handlerInputChange($event, ObservationFields.HeartRate)'
                         [(ngModel)]='currentEditPatient.reports.measurements.heartRate.value'
                  >
                </div>
                <div
                  (click)='handlerEmptyLineClick("heartRate")'
                  *ngIf='!patient?.reports?.measurements?.heartRate?.value && !patient?.checked || !currentEditPatient?.reports?.measurements?.heartRate.checked && !currentEditPatient?.reports?.measurements?.heartRate.value && patient.checked'
                  class='white content'>
                  <i class='empty-line' nz-icon nzType='icons:empty-line'></i>
                </div>
              </td>
              <td>
                <div *ngIf='patient?.reports?.measurements?.respirationRate?.value && !patient?.checked'
                     [ngClass]='{"red-value": (patient?.reports?.measurements?.respirationRate?.value | checkVitalEmrRules: ObservationFields.RespirationRate : patient)}'
                     class='white content'>
                  <i *ngIf='patient?.reports?.measurements?.respirationRate?.isManual | isManual : currentDepartment'
                     class='manual' nz-icon
                     nzType='icons:table-manual'></i> {{ patient?.reports?.measurements?.respirationRate?.value | roundNumber}}
                </div>
                <div
                  *ngIf='currentEditPatient?.reports?.measurements?.respirationRate?.checked && patient.checked || currentEditPatient?.reports?.measurements?.respirationRate?.value && patient.checked'
                  [ngClass]='{"red-value": respirationRateError}'
                  class='white content input-box'>
                  <i
                    *ngIf='currentEditPatient?.reports?.measurements?.respirationRate?.isManual | isManual : currentDepartment'
                    class='manual'
                    nz-icon
                    nzType='icons:table-manual'></i>
                  <input (click)='handlerEmptyLineClick("respirationRate")'
                         (keydown)='protectionNumbers($event)'
                         (ngModelChange)='handlerInputChange($event, ObservationFields.RespirationRate)'
                         [(ngModel)]='currentEditPatient.reports.measurements.respirationRate.value'
                  >
                </div>
                <div
                  (click)='handlerEmptyLineClick("respirationRate")'
                  *ngIf='!patient?.reports?.measurements?.respirationRate?.value && !patient?.checked || !currentEditPatient?.reports?.measurements?.respirationRate.checked && !currentEditPatient?.reports?.measurements?.respirationRate.value && patient.checked'
                  class='white content'>
                  <i class='empty-line' nz-icon nzType='icons:empty-line'></i>
                </div>
              </td>
              <td>
                <div *ngIf='patient?.reports?.measurements?.spo2?.value && !patient?.checked'
                     [ngClass]='{"red-value": (patient?.reports?.measurements?.spo2?.value | checkVitalEmrRules: ObservationFields.SpO2 : patient)}'
                     class='white content'>
                  <i *ngIf='patient?.reports?.measurements?.spo2?.isManual | isManual : currentDepartment'
                     class='manual' nz-icon
                     nzType='icons:table-manual'></i>{{ patient?.reports?.measurements?.spo2?.value | roundNumber}} %
                </div>
                <div
                  *ngIf='currentEditPatient?.reports?.measurements?.spo2?.checked && patient.checked || currentEditPatient?.reports?.measurements?.spo2?.value && patient.checked'
                  [ngClass]='{"red-value": spO2Error}'
                  class='white content input-box'>
                  <i *ngIf='currentEditPatient?.reports?.measurements?.spo2?.isManual | isManual : currentDepartment'
                     class='manual'
                     nz-icon
                     nzType='icons:table-manual'></i>
                  <input (click)='handlerEmptyLineClick("spo2")' (keydown)='protectionNumbers($event)'
                         (ngModelChange)='handlerInputChange($event, ObservationFields.SpO2)'
                         [(ngModel)]='currentEditPatient.reports.measurements.spo2.value'
                  >
                </div>
                <div
                  (click)='handlerEmptyLineClick("spo2")'
                  *ngIf='!patient?.reports?.measurements?.spo2?.value && !patient?.checked || !currentEditPatient?.reports?.measurements?.spo2.checked && !currentEditPatient?.reports?.measurements?.spo2.value && patient.checked'
                  class='white content'>
                  <i class='empty-line' nz-icon nzType='icons:empty-line'></i>
                </div>
              </td>
              <td>
                <div *ngIf='patient?.reports?.measurements?.bodyTemperature?.value && !patient?.checked'
                     [ngClass]='{"red-value": (patient.reports?.measurements?.bodyTemperature?.value  | transformCelsiusToFahrenhei) | checkVitalEmrRules: ObservationFields.BodyTemperature : patient}'
                     class='white content'>
                  <i *ngIf='patient?.reports?.measurements?.bodyTemperature?.isManual | isManual : currentDepartment'
                     class='manual' nz-icon
                     nzType='icons:table-manual'></i>
                  {{ patient?.reports?.measurements?.bodyTemperature?.value  | transformCelsiusToFahrenhei}}
                  <span
                    class='temp'>{{'temp' | celsiusFahrenheiIcon}}</span>
                </div>
                <div
                  *ngIf='currentEditPatient?.reports?.measurements?.bodyTemperature?.checked && patient.checked || currentEditPatient?.reports?.measurements?.bodyTemperature?.value && patient.checked'
                  [ngClass]='{"red-value": bodyTemperatureError}'
                  class='white content input-box'>
                  <i
                    *ngIf='currentEditPatient?.reports?.measurements?.bodyTemperature?.isManual | isManual : currentDepartment'
                    class='manual'
                    nz-icon
                    nzType='icons:table-manual'></i>
                  <input (click)='handlerEmptyLineClick("bodyTemperature")'
                         (keydown)='protectionNumbers($event)'
                         (ngModelChange)='handlerInputChange($event, ObservationFields.BodyTemperature)'
                         [(ngModel)]='currentEditPatient.reports.measurements.bodyTemperature.value'
                         style='width: 37%'
                  >
                  <span
                    class='temp'>{{'temp' | celsiusFahrenheiIcon}}</span>
                </div>
                <div
                  (click)='handlerEmptyLineClick("bodyTemperature")'
                  *ngIf='!patient?.reports?.measurements?.bodyTemperature?.value && !patient?.checked || !currentEditPatient?.reports?.measurements?.bodyTemperature.checked && !currentEditPatient?.reports?.measurements?.bodyTemperature.value && patient.checked'
                  class='white content'>
                  <i class='empty-line' nz-icon nzType='icons:empty-line'></i>
                </div>
              </td>
              <td>
                <div class='white content'>
                  <div (click)='handlerEditPatientClick(patient)' *ngIf='!patient?.checked' class='report-edit'>
                    <i class='report-edit' nz-icon nzType='icons:report-edit' style='font-size: 22px'></i>
                  </div>
                  <div *ngIf='patient?.checked'>
                    <i (click)='handlerCancelEditClick(patient)' class='report-action' nz-icon
                       nzType='icons:cancel-report'
                       style='font-size: 12px; padding-left: 5px'></i>
                    <i
                      (click)='handlerConfirmEditClick(patient)'
                      *ngIf='!diastolicError && !systolicError && !heartRateError && !respirationRateError && !spO2Error && !bodyTemperatureError'
                      class='report-action' nz-icon
                      nzType='icons:confirm-report' style='font-size: 13px; padding-left: 16px;'></i>
                  </div>
                </div>
              </td>
              <td>
                <div class='white content'>
                  <button
                    #report
                    (click)='handlerReportToEmrClick(patient)'
                    [disabled]='!patient.reports?.measurements ||
                    patient.checked || pccDetailCurrentLoading && pccDetailCurrentLoading.status'
                    class='report-edit'>
                    <span
                      *ngIf='pccDetailCurrentLoading && pccDetailCurrentLoading.id === patient.id && pccDetailCurrentLoading.status'
                      [nzSpin]='true' [nzType]="'sync'" class='loading' nz-icon></span>
                    <i *ngIf='!patient?.checkShiftSentEmrInformation' class='report-emr' nz-icon
                       nzType='icons:report-emr'></i>
                    <i
                      *ngIf='patient?.checkShiftSentEmrInformation && patient?.checkShiftSentEmrInformation?.status === "NEED_EXPORT_TO_EMR"'
                      class='report-emr' nz-icon
                      nzType='icons:report-need-export-emr'></i>
                    <i
                      *ngIf='patient?.checkShiftSentEmrInformation && patient?.checkShiftSentEmrInformation?.status === "COMPLETED"'
                      class='report-emr' nz-icon
                      nzType='icons:report-complete-export-emr'></i>
                    <i
                      *ngIf='patient?.checkShiftSentEmrInformation && patient?.checkShiftSentEmrInformation?.status === "CRITICAL_REPORT_TIME"'
                      class='report-emr' nz-icon
                      nzType='icons:report-critical-time-emr'></i>
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </nz-table>
        </section>
      </div>
    </div>
  </div>
</nz-modal>
<aiomed-alert-rule-unsaved-changes (alertRuleUnsavedChangesEmitter)='handlerAlertRuleUnsavedChangesEmitter($event)'
                                   *ngIf='isModalVisible'
                                   [isVisible]='isModalVisible'></aiomed-alert-rule-unsaved-changes>

