import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Select } from '@ngxs/store'
import { PatientState } from '../../../../../store/patient/patient.state'
import { Observable } from 'rxjs'
import { PatientDTO } from '../../../../../shared/model/patient'
import { DeviceState } from '../../../../../store/device/device.state'
import { DeviceDTO } from '../../../../../shared/model/device.model'

@Component({
	selector: 'aiomed-assign-new-device',
	templateUrl: './assign-new-device.component.html',
	styleUrls: ['./assign-new-device.component.scss']
})
export class AssignNewDeviceComponent implements OnInit {
	@Output() assignNewDeviceEmitter = new EventEmitter()
	@Output() changeSearchPatientEmitter = new EventEmitter()
	@Output() closeModalEmitter = new EventEmitter()
	@Input() isVisible: boolean = false
	selectedModel = 'BioBeat'
	modelData = ['BioBeat']
	serialNumberValue = ''
	selectedValue = null
	@Select(PatientState.searchPatient)
	searchPatient$: Observable<PatientDTO[]>
	@Select(DeviceState.biobeatWatchDevice)
	biobeatWatchDevice$: Observable<DeviceDTO>
	isInputValidationError: boolean = false
	inputValidationErrorValue: string = ''
	isButtonDisabled: boolean = true
	currentDevice: DeviceDTO | null = null
	currentPatient: PatientDTO | null = null
	currentAssignedDevice: DeviceDTO | null = null

	constructor() {}

	ngOnInit(): void {}

	handlerSerialNumberChange($event: string, biobeatWatchDevice: DeviceDTO[]) {
		const devices = biobeatWatchDevice.filter((d) => d.serialNumber === $event)
		if (!devices.length) {
			this.currentDevice = null
			this.isInputValidationError = true
			this.inputValidationErrorValue = 'Serial number/ device not found'
		} else if (devices.length && devices[0].patient) {
			this.currentDevice = null
			this.isInputValidationError = true
			this.inputValidationErrorValue = 'Device assigned to other patient'
		} else {
			this.currentDevice = devices[0]
			this.isInputValidationError = false
			this.inputValidationErrorValue = ''
		}
		this.isButtonDisabled = !this.currentPatient || !this.currentDevice
	}

	handlerPatientsSelectChange(
		$event: string,
		searchPatient: PatientDTO[],
		biobeatWatchDevice: DeviceDTO[]
	) {
		let assignedDevice = biobeatWatchDevice.find(
			(d) => d.patient && d.patient.id === $event
		)
		// @ts-ignore
		this.currentPatient = searchPatient.find((p) => p.id === $event)
		this.isButtonDisabled = !this.currentDevice
		this.currentAssignedDevice = !assignedDevice ? null : assignedDevice
	}

	handlerAssignDeviceClick() {
		const data: {
			device?: DeviceDTO | any
			patient?: PatientDTO | any
			assignedDevice: DeviceDTO | null
		} = {
			device: { ...this.currentDevice },
			patient: { ...this.currentPatient },
			assignedDevice: null
		}
		if (this.currentAssignedDevice) {
			data.assignedDevice = { ...this.currentAssignedDevice }
		}
		this.assignNewDeviceEmitter.emit(data)
	}
}
