import { ChangeDetectionStrategy, Component } from '@angular/core'
import { AlertModal } from '../../../../../shared/classes/alert-modal/alertModal'

@Component({
	selector: 'aiomed-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent extends AlertModal {
	constructor() {
		super()
	}
}
