import { Pipe, PipeTransform } from '@angular/core'
import { TaskDTO, TaskStatus } from '../../../shared/model/task.model'

@Pipe({
	name: 'taskStatus'
})
export class TaskStatusPipe implements PipeTransform {
	transform(data: TaskDTO, ...args: unknown[]): string {
		if (data.taskStatus === TaskStatus.Canceled) {
			return TaskStatus.Canceled
		} else if (data.taskStatus === TaskStatus.Completed) {
			return TaskStatus.Completed
		} else if (
			data.taskStatus === TaskStatus.Active &&
			data.expirationTime &&
			data.expirationTime < new Date().toISOString()
		) {
			return 'overdue'
		} else if (
			data.taskStatus === TaskStatus.Active &&
			data.expirationTime &&
			data.expirationTime > new Date().toISOString()
		) {
			return 'ongoing'
		}
		return ''
	}
}
