import { Pipe, PipeTransform } from '@angular/core'
import { AlertSubject } from '../../../shared/model/alert'

@Pipe({
	name: 'alertSubjectName'
})
export class AlertSubjectNamePipe implements PipeTransform {
	AlertSubject = AlertSubject

	transform(data: any, ...args: unknown[]): string {
		if (!data) return ''
		return data.split('_').join(' ')
	}
}
