import { Pipe, PipeTransform } from '@angular/core'
import { checkEmrRules } from '../../helpers/check-emr-rules'
import { cloneDeep } from 'lodash-es'
import { PatientInterface } from '../../../shared/model/patient'

@Pipe({
	name: 'checkVitalEmrRules',
	pure: false
})
export class CheckVitalEmrRulesPipe implements PipeTransform {
	transform(value: number, type: string, patient: PatientInterface): boolean {
		if (!value) return false
		const rules = cloneDeep(checkEmrRules)
		rules.set(
			!patient.patientAlertRules
				? patient.defaultAlertRules
				: patient.patientAlertRules
		)
		if (rules) {
			return value < rules.get(type).min || value > rules.get(type).max
		} else {
			return false
		}
	}
}
