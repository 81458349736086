import { Pipe, PipeTransform } from '@angular/core'
import { getUserLocale } from '../helpers/functions'

@Pipe({
	name: 'celsiusFahrenheiIcon'
})
export class CelsiusFahrenheiIconPipe implements PipeTransform {
	transform(data: any, ...args: unknown[]): string {
		if (!data) return ''
		return getUserLocale() === 'en-US' ? '°F' : '°C'
	}
}
