import { Pipe, PipeTransform } from '@angular/core'
import { TaskTreatmentAction } from '../../../shared/model/task.model'

@Pipe({
	name: 'taskTreatmentActionIcon'
})
export class TaskTreatmentActionIconPipe implements PipeTransform {
	transform(data: string, ...args: unknown[]): string {
		if (data === TaskTreatmentAction.ChangeDiaper) {
			return 'icons:mobile-action-change-deaper'
		} else if (data === TaskTreatmentAction.RepositionPatient) {
			return 'icons:mobile-action-reposition-patient'
		} else if (data === TaskTreatmentAction.MeasureAllVitals) {
			return 'icons:mobile-action-manual'
		}
		return ''
	}
}
