import { Pipe, PipeTransform } from '@angular/core'
import { MedicalSignAlertRuleDTO } from '../../../shared/model/alert-rules.model'
import { AlertSeverity } from '../../../shared/model/alert'

@Pipe({
	name: 'medicalSignSeverity'
})
export class MedicalSignSeverityPipe implements PipeTransform {
	transform(
		value: any,
		rules: MedicalSignAlertRuleDTO | any
	): AlertSeverity | undefined {
		if (!value || !rules) return undefined
		if (value <= rules.minCritical && value >= rules.maxCritical) {
			return AlertSeverity.Info
		} else {
			return AlertSeverity.Critical
		}
	}
}
