import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild
} from '@angular/core'
import {
	INotification,
	NotificationService,
	NotificationType
} from '../../../../../shared/services/notification.service'
import { takeWhile } from 'rxjs'

@Component({
	selector: 'aiomed-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
	@ViewChild('notificationContainer') container: ElementRef<HTMLDivElement>
	private subscribed: boolean = true
	private classMap: Map<NotificationType, string>

	constructor(
		private service: NotificationService,
		private renderer: Renderer2
	) {}

	ngOnInit(): void {
		this.classMap = new Map<NotificationType, string>()
		this.classMap.set(NotificationType.Success, 'success')
		this.classMap.set(NotificationType.Warning, 'warning')
		this.classMap.set(NotificationType.Error, 'error')

		this.service.notification
			.pipe(takeWhile(() => this.subscribed))
			.subscribe((notification) => {
				if (notification) this.render(notification)
			})
	}

	ngOnDestroy() {
		this.subscribed = false
	}

	private render(notification: INotification) {
		let notificationBox = this.renderer.createElement('div')
		let icon = this.renderer.createElement('div')
		let content = this.renderer.createElement('div')
		let close = this.renderer.createElement('div')

		const boxColorClass = this.classMap.get(notification.type)
		let classesToAdd = ['message-box', boxColorClass]
		// @ts-ignore
		classesToAdd.forEach((x) => this.renderer.addClass(notificationBox, x))
		this.renderer.addClass(content, 'text')
		this.renderer.addClass(icon, 'icon')
		this.renderer.addClass(close, 'close')
		this.renderer.setStyle(
			notificationBox,
			'transition',
			`opacity ${notification.duration}ms`
		)
		this.renderer.setStyle(notificationBox, 'opacity', '1')

		const text = this.renderer.createText(notification.message)
		this.renderer.appendChild(content, text)
		if (!this.container) {
			return
		}
		this.renderer.appendChild(this.container.nativeElement, notificationBox)
		this.renderer.appendChild(notificationBox, icon)
		this.renderer.appendChild(notificationBox, content)
		this.renderer.appendChild(notificationBox, close)

		setTimeout(() => {
			// @ts-ignore
			document.querySelector('div.close').addEventListener('click', () => {
				this.renderer.removeChild(this.container.nativeElement, notificationBox)
			})
		}, 500)

		setTimeout(() => {
			this.renderer.setStyle(notificationBox, 'opacity', '0')
			setTimeout(() => {
				this.renderer.removeChild(this.container.nativeElement, notificationBox)
			}, notification.duration)
		}, notification.duration)
	}
}
