import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State, Store } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import { EMPTY, ignoreElements, Observable, Subscription, tap } from 'rxjs'
import { AlertRuleDTO } from '../../shared/model/alert-rules.model'
import { BackendService } from '../../shared/services/backend.service'
import { NotificationService } from '../../shared/services/notification.service'
import { StoreEventsService } from '../store-events.service'
import { PatientState } from '../patient/patient.state'

export const alertRuleFeatureName = 'alertRule'

@StateRepository()
@State<CollatableEntityCollections<AlertRuleDTO>>({
	name: alertRuleFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class AlertRuleState extends CollatableEntityCollectionsRepository<
	AlertRuleDTO,
	EntityCollation
> {
	subscriptionBackendUpdates$: Subscription
	subscriptionGetAllAlertRules$: Subscription
	private alertRules$: Subscription;
	private alertRuleStateSubscription: Subscription;

	constructor(
		private backendService: BackendService,
		private actions: Actions,
		private ntfService: NotificationService,
		private storeEvents: StoreEventsService,
		private store: Store
	) {
		super()
	}

	// public get backendUpdates$(): Observable<void> {
	// 	this.subscriptionGetAllAlertRules$ = this.backendService
	// 		.getAllAlertRules(undefined, true)
	// 		.subscribe((res) => {
	// 			this.upsertMany(res)
	// 			this.subscriptionGetAllAlertRules$.unsubscribe()
	// 		})
	// 		return of();
	// 	// return this.backendService.subscribeAllAlertRules().pipe(
	// 	// 	tap((res) => {
	// 	// 		this.upsertOne(res)
	// 	// 	}),
	// 	// 	ignoreElements()
	// 	// )
	// }

	@Selector()
	public static alertRules(
		state: CollatableEntityCollections<AlertRuleDTO>
	): EntityDictionary<string, AlertRuleDTO> {
		return state.entities
	}

	@DataAction()
	public updateAlert(
		@Payload('entityId') id: string,
		@Payload('entityDiff') entityDiff: AlertRuleDTO
	): Observable<void> {
		return this.backendService.updateAlertRule(id, entityDiff).pipe(
			tap((res) => {
				this.upsertOne(res)
				this.ntfService.success(`Alert rule has been updated`)
			}),
			ignoreElements()
		)
	}

	@DataAction()
	public deleteAlertRule(@Payload('entityId') id: string): Observable<void> {
		return this.backendService.deleteAlertRule(id).pipe(
			tap(() => {
				this.removeOne(id)
			}),
			ignoreElements()
		)
	}

	updateWithModifiedAlertRules(alertRulesIds?: string[]) {
		return this.backendService.findAllAlertRules(alertRulesIds).pipe(
			tap(alertRules => {
				this.upsertMany(alertRules);
			}));
	}

	public override ngxsOnInit() {

		this.storeEvents.patientsModified$.pipe(
			tap(() => {
				if (this.alertRuleStateSubscription) this.alertRuleStateSubscription.unsubscribe();
				const alertRulesIds = [...this.store.selectSnapshot(PatientState.allPatientAlertRules)]; // alertRulesIds as string[] - if we need to filter via patientAlertRulesIds
				this.alertRuleStateSubscription = this.updateWithModifiedAlertRules().subscribe();
			})
		).subscribe();

		this.storeEvents.logout$.pipe(
			tap(() => {
				this.reset();
				if (this.alertRuleStateSubscription) this.alertRuleStateSubscription.unsubscribe();
			})
		).subscribe();
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(): Observable<void> {
		return EMPTY
	}
}
