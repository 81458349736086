<nz-modal [(nzVisible)]='isModalVisible'
          nzClassName='custom-pcc-update-manual-modal'>
  <div *nzModalContent>
    <div class='update-emr-popover__container'>
        <span (click)='handlerClickCancelButton()' class='icon-close' nz-icon nzTheme='outline'
              nzType='close'></span>
      <div class='title'>EDIT VITALS</div>
      <div class='update-emr-popover__top-content'>
        <div class='update-emr-popover__avatar'>
          <nz-avatar *ngIf='!patient["avatar"]; else haveAvatar' [nzText]='patient.name | shortName'
                     class='aiomed-avatar'
                     nzSize='default'></nz-avatar>
          <ng-template #haveAvatar>
            <nz-avatar [nzSrc]='patient["avatar"]["signedUrl"]'
                       nzSize='default'></nz-avatar>
          </ng-template>
        </div>
        <div class='update-emr-popover__title-content'>
          <div class='update-emr-popover__title' style='text-transform: uppercase;'>
            {{patient.lastName}}, {{patient.firstName}}<span>{{patient?.room}}</span>
          </div>
          <div class='update-emr-popover__sub-title'>
            Last updated on {{patient?.measurement?.lastMeasurementTime | time}}
          </div>
        </div>
      </div>
      <div *ngIf='needLogin' class='update-emr-popover__main-content'>
        <div class='update-emr-popover__item'>
          <label>
            <i
              *ngIf='!diastolicError && !systolicError && !(systolicValue | checkVitalEmrRules:  ObservationFields.SystolicPressure : patient) && !(diastolicValue | checkVitalEmrRules: ObservationFields.DiastolicPressure : patient)'
              nz-icon
              nzType='icons:modal-pressure'
            ></i>
            <i
              *ngIf='diastolicError || systolicError || (systolicValue | checkVitalEmrRules:  ObservationFields.SystolicPressure : patient) || (diastolicValue | checkVitalEmrRules: ObservationFields.DiastolicPressure : patient)'
              nz-icon
              nzType='icons:modal-pressure-red'
            ></i>
            <span
              [ngClass]="{'critical': diastolicError || systolicError || (systolicValue | checkVitalEmrRules:  ObservationFields.SystolicPressure : patient) || (diastolicValue | checkVitalEmrRules: ObservationFields.DiastolicPressure : patient)}"
              class='item-text'>BLOOD PRESSURE</span>
            <span
              [ngClass]="{'critical': diastolicError || systolicError}"
              class='item-dashed item-dashed-sbp'></span>
          </label>
          <div
            [ngClass]="{'critical': diastolicError || systolicError || (systolicValue | checkVitalEmrRules:  ObservationFields.SystolicPressure : patient) || (diastolicValue | checkVitalEmrRules: ObservationFields.DiastolicPressure : patient)}"
            class='input-box'>
            <input (keydown)='protectionNumbers($event)'
                   (ngModelChange)='handlerInputChange($event, ObservationFields.SystolicPressure)'
                   [(ngModel)]='systolicValue'
                   type='number'>/<input
            (keydown)='protectionNumbers($event)'
            (ngModelChange)='handlerInputChange($event, ObservationFields.DiastolicPressure)'
            [(ngModel)]='diastolicValue' type='number'>
          </div>
          <i *ngIf='systolicValue && diastolicValue && !bloodPressureManual'
             class='retrieved'
             nz-icon
             nzType='icons:device-data-received'
          ></i>
          <span
            *ngIf='systolicError || diastolicError'
            class='warning'><i
            nz-icon
            nzType='icons:modal-error'
          ></i>Double-check vital info for accuracy</span>
          <span
            *ngIf='!systolicError && !diastolicError && (systolicValue | checkVitalEmrRules: ObservationFields.SystolicPressure : patient) || !systolicError && !diastolicError && (diastolicValue | checkVitalEmrRules: ObservationFields.DiastolicPressure : patient)'
            class='warning'><i
            nz-icon
            nzType='icons:modal-error'
          ></i>Abnormal blood pressure value.</span>
        </div>
        <div class='update-emr-popover__item'>
          <label>
            <i
              [ngClass]="{'critical': heartRateError || (heartRateValue | checkVitalEmrRules: ObservationFields.HeartRate : patient)}"
              [nzType]='ObservationFields.HeartRate | alertSubjectIcon'
              nz-icon
            ></i>
            <span
              [ngClass]="{'critical': heartRateError || (heartRateValue | checkVitalEmrRules: ObservationFields.HeartRate : patient)}"
              class='item-text'>HEART RATE</span>
            <span
              [ngClass]="{'critical': heartRateError || (heartRateValue | checkVitalEmrRules: ObservationFields.HeartRate : patient)}"
              class='item-dashed item-dashed-hr'></span>
          </label>
          <input (keydown)='protectionNumbers($event)'
                 (ngModelChange)='handlerInputChange($event, ObservationFields.HeartRate)'
                 [(ngModel)]='heartRateValue'
                 [ngClass]="{'critical': heartRateError || (heartRateValue | checkVitalEmrRules: ObservationFields.HeartRate : patient)}"
                 type='number'>
          <i
            *ngIf='heartRateValue && !heartRateManual'
            class='retrieved'
            nz-icon
            nzType='icons:device-data-received'
          ></i>
          <span *ngIf='heartRateError'
                class='warning'> <i
            nz-icon
            nzType='icons:modal-error'
          ></i>Double-check vital info for accuracy</span>
          <span
            *ngIf='!heartRateError && (heartRateValue | checkVitalEmrRules: ObservationFields.HeartRate : patient)'
            class='warning'><i
            nz-icon
            nzType='icons:modal-error'
          ></i>Abnormal heart rate value.</span>
        </div>
        <div class='update-emr-popover__item'>
          <label>
            <i
              *ngIf='!spO2Error && !(spO2Value | checkVitalEmrRules: ObservationFields.SpO2 : patient)'
              nz-icon
              nzType='icons:modal-spo'
            ></i>
            <i
              *ngIf='spO2Error || (spO2Value | checkVitalEmrRules: ObservationFields.SpO2 : patient)'
              nz-icon
              nzType='icons:modal-spo-red'
            ></i>
            <span
              [ngClass]="{'critical': spO2Error || (spO2Value | checkVitalEmrRules: ObservationFields.SpO2 : patient)}"
              class='item-text'>SPO2</span>
            <span
              [ngClass]="{'critical': spO2Error || (spO2Value | checkVitalEmrRules: ObservationFields.SpO2 : patient)}"
              class='item-dashed item-dashed-sp'></span>
          </label>
          <input (keydown)='protectionNumbers($event)'
                 (ngModelChange)='handlerInputChange($event, ObservationFields.SpO2)' [(ngModel)]='spO2Value'
                 [ngClass]="{'critical': spO2Error || (spO2Value | checkVitalEmrRules: ObservationFields.SpO2 : patient)}"
                 type='number'>
          <i *ngIf='spO2Value && !spO2Manual'
             class='retrieved'
             nz-icon
             nzType='icons:device-data-received'
          ></i>
          <span *ngIf='spO2Error' class='warning'> <i
            nz-icon
            nzType='icons:modal-error'
          ></i>Double-check vital info for accuracy</span>
          <span
            *ngIf='!spO2Error && (spO2Value | checkVitalEmrRules: ObservationFields.SpO2 : patient)'
            class='warning'><i
            nz-icon
            nzType='icons:modal-error'
          ></i>Abnormal spO2 value.</span>
        </div>
        <div class='update-emr-popover__item'>
          <label>
            <i
              *ngIf='!bodyTemperatureError && !(bodyTemperatureValue | checkVitalEmrRules: ObservationFields.BodyTemperature : patient)'
              nz-icon
              nzType='icons:modal-temp'
            ></i>
            <i
              *ngIf='bodyTemperatureError || (bodyTemperatureValue | checkVitalEmrRules: ObservationFields.BodyTemperature : patient)'
              nz-icon
              nzType='icons:modal-temp-red'
            ></i>
            <span
              [ngClass]="{'critical': bodyTemperatureError || (bodyTemperatureValue | checkVitalEmrRules: ObservationFields.BodyTemperature : patient)}"
              class='item-text'>BODY TEMPERATURE</span>
            <span
              [ngClass]="{'critical': bodyTemperatureError || (bodyTemperatureValue | checkVitalEmrRules: ObservationFields.BodyTemperature : patient)}"
              class='item-dashed item-dashed-bt'></span>
          </label>
          <input (keydown)='protectionNumbers($event)'
                 (ngModelChange)='handlerInputChange($event, ObservationFields.BodyTemperature)'
                 [(ngModel)]='bodyTemperatureValue'
                 [ngClass]="{'critical': bodyTemperatureError || (bodyTemperatureValue | checkVitalEmrRules: ObservationFields.BodyTemperature : patient)}"
                 type='number'>
            <i
            *ngIf='bodyTemperatureValue && !bodyTemperatureManual'
            class='retrieved'
            nz-icon
            nzType='icons:device-data-received'
          ></i>
          <span
            *ngIf='bodyTemperatureError'
            class='warning'>
              <i
                nz-icon
                nzType='icons:modal-error'
              ></i>
             Double-check vital info for accuracy
            </span>
          <span
            *ngIf='!bodyTemperatureError && (bodyTemperatureValue | checkVitalEmrRules: ObservationFields.BodyTemperature : patient)'
            class='warning'><i
            nz-icon
            nzType='icons:modal-error'
          ></i>Abnormal body temperature value.</span>
        </div>
        <div class='update-emr-popover__item'>
          <label>
            <i
              *ngIf='!respirationRateError && !(respirationRateValue | checkVitalEmrRules: ObservationFields.RespirationRate : patient)'
              nz-icon
              nzType='icons:modal-respiration'
            ></i>
            <i
              *ngIf='respirationRateError || (respirationRateValue | checkVitalEmrRules: ObservationFields.RespirationRate : patient)'
              nz-icon
              nzType='icons:modal-respiration-red'
            ></i>
            <span
              [ngClass]="{'critical': respirationRateError || (respirationRateValue | checkVitalEmrRules: ObservationFields.RespirationRate : patient)}"
              class='item-text'>Respiration Rate</span>
            <span
              [ngClass]="{'critical': respirationRateError || (respirationRateValue | checkVitalEmrRules: ObservationFields.RespirationRate : patient)}"
              class='item-dashed item-dashed-rr'></span>
          </label>
          <input (keydown)='protectionNumbers($event)'
                 (ngModelChange)='handlerInputChange($event, ObservationFields.RespirationRate)'
                 [(ngModel)]='respirationRateValue'
                 [ngClass]="{'critical': respirationRateError || (respirationRateValue | checkVitalEmrRules: ObservationFields.RespirationRate : patient)}"
                 type='number'>
            <i
            *ngIf='respirationRateValue && !respirationRateManual'
            class='retrieved'
            nz-icon
            nzType='icons:device-data-received'
          ></i>
          <span *ngIf='respirationRateError' class='warning'> <i
            nz-icon
            nzType='icons:modal-error'
          ></i>Double-check vital info for accuracy</span>
          <span
            *ngIf='!respirationRateError && (respirationRateValue | checkVitalEmrRules: ObservationFields.RespirationRate : patient)'
            class='warning'><i
            nz-icon
            nzType='icons:modal-error'
          ></i>Abnormal respiration rate value.</span>
        </div>
      </div>
      <button (click)='handlerClickRetrieveDevices()' *ngIf='false'
              [disabled]='devicesLoading'
              class='btn-prime retrieve'
              nz-button nzBlock nzType='primary'
              type='submit'>Retrieve from device
      </button>
      <button (click)='submitForm()'
              [disabled]='(!this.diastolicValue &&
				!this.systolicValue &&
				!this.heartRateValue &&
				!this.respirationRateValue &&
				!this.spO2Value &&
				!this.bodyTemperatureValue) ||
			this.bodyTemperatureError ||
			this.respirationRateError ||
			this.heartRateError ||
			this.diastolicError ||
			this.systolicError ||
			this.spO2Error'
              class='btn-prime update-emr'
              nz-button nzBlock nzType='primary'
              type='submit'>Save
      </button>
    </div>
  </div>
</nz-modal>
