import { Pipe, PipeTransform } from '@angular/core'
import moment from 'moment'

@Pipe({
	name: 'checkTimeActiveMeasurement'
})
export class CheckTimeActiveMeasurementPipe implements PipeTransform {
	transform(lastUpdated: any, minutes: number): boolean {
		if (!lastUpdated || !minutes) return false
		return moment(new Date()).diff(moment(lastUpdated), 'minutes') <= minutes
	}
}
