<section class='device-assigment-action'>
  <nz-modal [(nzVisible)]='isVisible'
            nzClassName='custom-device-assigment-action-modal'>
    <div *nzModalContent>
      <div class='device-assigment-action-container'>
        <div class='device-assigment-action-title'>{{title}}</div>
        <div class='device-assigment-action-patient'>
          <div class='image-box'>
            <nz-avatar *ngIf='!patient["avatar"]; else haveAvatar' [nzText]='patient.name | shortName'
                       class='aiomed-avatar'
                       nzSize='default'></nz-avatar>
            <ng-template #haveAvatar>
              <nz-avatar [nzSrc]='patient["avatar"]["signedUrl"]'
                         nzSize='default'></nz-avatar>
            </ng-template>
          </div>
          <div class='details-box'>
            <div class='name'>{{patient.lastName}}, {{patient.firstName}}</div>
            <div class='room'>Room {{patient.room}}</div>
          </div>
        </div>
        <div class='device-assigment-action-text'>{{text}}
        </div>
        <div class='device-assigment-action-buttons'>
          <button (click)='deviceAssigmentActionConfirmEmitter.emit(false)' class='cancel'>Cancel</button>
          <button (click)='deviceAssigmentActionConfirmEmitter.emit(true)'
                  class='confirm'>{{buttonConfirmText}}</button>
        </div>
      </div>
    </div>
  </nz-modal>
</section>

