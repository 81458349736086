import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { EMPTY, Observable, Subscription, tap } from 'rxjs'
import { PatientDTO, PatientInterface } from '../../shared/model/patient'
import { MeasurementState } from '../measurement/measurement.state'
import moment from 'moment'
import { PatientState } from '../patient/patient.state'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { cloneDeep, uniq } from 'lodash-es'
import {
	ManualVitalsInterface,
	ReportInterface,
	ReportMode,
	ReportsVitalsInterface,
	ReportType,
	ReportVitalsFields
} from '../../shared/model/report.model'
import { BackendService } from '../../shared/services/backend.service'
import {
	getUserLocale,
	transformCelsiusToFahrenhei
} from '../../core/helpers/functions'
import { PccState } from '../pcc/pcc.state'
import { DeviceState } from '../device/device.state'
import { TreatmentPlanDTO } from '../../shared/model/treatment-plan'
import { ExportAllInterface } from '../../shared/model/export'
import { ExportState } from '../export/export.state'
import { PatientExportDTO } from '../../shared/model/pcc.model'
import { NotificationService } from '../../shared/services/notification.service'
import { DepartmentState } from '../department/department.state'
import { entitiesFilter } from '../../core/helpers/filter'
import {
	DeviceCriticalStatus,
	DeviceDTO,
	DeviceInterface,
	DeviceModel
} from '../../shared/model/device.model'
import { StoreEventsService } from '../store-events.service'
import { DepartmentFilter } from '../../shared/model/departments.model'
import { checkEmrRules } from '../../core/helpers/check-emr-rules'
import { ObservationFields } from '../../shared/model/patient-observation'
import { RootStore } from '../root-store'
import { combineTreatmentPlans } from '../../core/helpers/combine-treatment-plans'
import { checkVitalsToTreatmentPlan } from '../../core/helpers/check-vitals-to-treatment-plan'
import { WebSocketService } from '../../shared/services/web-socket.service'
import { AuthState } from '../auth/auth.state'
import {
	forceReadErrorCode,
	forceReadStatus,
	NewReadSocketModel
} from '../../shared/model/new-read-socket-model'
import { environment } from '../../environments/environment'

export const reportFeatureName = 'report'

@StateRepository()
@State<ReportInterface>({
	name: reportFeatureName,
	defaults: {
		requestPatientsVitals: [],
		requestVitals: [],
		monitorPatientIds: [],
		forceReadDevicesStatus: [],
		textFilter: '',
		type: ReportType.CurrentShift,
		time: null,
		loading: false,
		isForceReadProcess: false
	}
})
@Injectable()
export class ReportState extends NgxsDataRepository<ReportInterface> {
	reportsVitalsData: ReportsVitalsInterface = {
		patientActions: []
	}
	updatePatientsIds: string[] = []
	subscriptionUpdatePatientVitalsManualWSOnMessage$: Subscription
	subscriptionNewReadSocket$: Subscription
	timeout: any
	lastPatientReportTime: any = {}
	private reportStateSubscription: Subscription

	constructor(
		private patientState: PatientState,
		private deviceState: DeviceState,
		private measurementState: MeasurementState,
		private backendService: BackendService,
		private ntfService: NotificationService,
		private pccState: PccState,
		private departmentState: DepartmentState,
		private actions: Actions,
		private storeEvents: StoreEventsService,
		private webSocketService: WebSocketService,
		private authState: AuthState
	) {
		super()
	}

	@Selector([
		PatientState.reportPatients,
		ExportState.patientsAllExports,
		DeviceState.reportDevices
	])
	public static report(
		state: ReportInterface,
		reportPatients: Partial<PatientInterface>[],
		patientsAllExports: ExportAllInterface[],
		devices: DeviceInterface[]
	): Partial<PatientInterface>[] {
		const data = ReportState.hydrate(
			state.monitorPatientIds,
			state.requestPatientsVitals,
			entitiesFilter(
				state.textFilter,
				reportPatients.filter((p) => p.enabled)
			),
			patientsAllExports,
			devices.filter((d) => d.patient && d.patient.id),
			state.forceReadDevicesStatus
		)
		return !data ? [] : data
	}

	@Selector([
		PatientState.reportPatients,
		ExportState.patientsAllExports,
		DeviceState.reportDevices
	])
	public static reportShift(
		state: ReportInterface,
		reportPatients: Partial<PatientInterface>[],
		patientsAllExports: ExportAllInterface[],
		devices: DeviceInterface[]
	): Partial<PatientInterface>[] {
		const data = ReportState.hydrate(
			state.monitorPatientIds,
			state.requestPatientsVitals,
			entitiesFilter(
				state.textFilter,
				reportPatients.filter((p) => p.enabled)
			),
			patientsAllExports,
			devices.filter((d) => d.patient && d.patient.id),
			state.forceReadDevicesStatus
		)
		return !data ? [] : data
	}

	@Selector()
	public static forceReadProcess(state: ReportInterface) {
		return state.isForceReadProcess
	}

	@Selector([
		PatientState.reportPatients,
		ExportState.patientsAllExports,
		DeviceState.reportDevices,
		RootStore.pccSentPatientVitals
	])
	public static reportAbnormalOrBlank(
		state: ReportInterface,
		reportPatients: PatientInterface[],
		patientsAllExports: ExportAllInterface[],
		devices: DeviceInterface[],
		pccSentPatientVitals: string[]
	): PatientInterface[] {
		const data = ReportState.hydrate(
			state.monitorPatientIds,
			state.requestPatientsVitals,
			// @ts-ignore
			pccSentPatientVitals.length
				? reportPatients.filter(
						(p) => p.enabled && !pccSentPatientVitals.find((id) => id === p.id)
				  )
				: reportPatients.filter((p) => p.enabled),
			patientsAllExports,
			devices.filter((d) => d.patient && d.patient.id),
			state.forceReadDevicesStatus
		)
		return !data ? [] : data
	}

	private static requestPatientsVitalsSetting(
		requestPatientsVitals: string[],
		patients: PatientInterface[],
		reportsVitalsData: ReportsVitalsInterface
	) {
		requestPatientsVitals.forEach((id) => {
			const patient = patients.find((p) => p.id === id)
			if (patient) {
				if (
					patient.reports &&
					!Object.values(patient.reports.measurements).length
				) {
					const dataPatientIdx = reportsVitalsData.patientActions.findIndex(
						(d) => d.patientId === patient.id
					)
					if (dataPatientIdx !== -1) {
						reportsVitalsData.patientActions[dataPatientIdx].treatmentActions =
							[ReportVitalsFields.MeasureAllVitals]
					} else {
						reportsVitalsData.patientActions.push({
							patientId: patient.id,
							treatmentActions: [ReportVitalsFields.MeasureAllVitals]
						})
					}
				} else {
					const data: {
						patientId: string
						treatmentActions: string[]
					} = {
						patientId: patient.id,
						treatmentActions: []
					}
					if (!patient.reports?.measurements?.bodyTemperature) {
						data.treatmentActions = uniq([
							...data.treatmentActions,
							ReportVitalsFields.MeasureAllVitals
						])
					}
					if (!patient.reports?.measurements?.heartRate) {
						data.treatmentActions = uniq([
							...data.treatmentActions,
							ReportVitalsFields.MeasureAllVitals
						])
					}
					if (!patient.reports?.measurements?.respirationRate) {
						data.treatmentActions = uniq([
							...data.treatmentActions,
							ReportVitalsFields.MeasureAllVitals
						])
					}
					if (
						!patient.reports?.measurements?.systolicPressure ||
						!patient.reports?.measurements?.diastolicPressure
					) {
						data.treatmentActions = uniq([
							...data.treatmentActions,
							ReportVitalsFields.MeasureAllVitals
						])
					}
					if (!patient.reports?.measurements?.spo2) {
						data.treatmentActions = uniq([
							...data.treatmentActions,
							ReportVitalsFields.MeasureAllVitals
						])
					}
					const dataPatientIdx = reportsVitalsData.patientActions.findIndex(
						(d) => d.patientId === patient.id
					)

					if (dataPatientIdx !== -1) {
						reportsVitalsData.patientActions[dataPatientIdx].treatmentActions =
							uniq([
								...reportsVitalsData.patientActions[dataPatientIdx]
									.treatmentActions,
								...data.treatmentActions
							])
					} else {
						if (!data.treatmentActions.length) return
						reportsVitalsData.patientActions.push(data)
					}
				}
			}
		})
	}

	private static setAllToEmrSetting(
		patient: PatientInterface,
		exports: PatientExportDTO[]
	) {
		const emrRules = cloneDeep(checkEmrRules)
		emrRules.set(
			!patient.patientAlertRules
				? patient.defaultAlertRules
				: patient.patientAlertRules
		)
		const patientExport: PatientExportDTO | undefined = exports.find(
			(e: PatientExportDTO) => e.observedPatient === patient.id
		)
		let data: {
			observedPatient?: string
			systolicPressure?: number
			diastolicPressure?: number
			heartRate?: number
			respirationRate?: number
			spo2?: number
			bodyTemperature?: number
			bloodGlucose?: number
			bloodPressureMethod?: string
			heartRateMethod?: string
			spo2Method?: string
			bodyTemperatureMethod?: string
		} = {}
		if (
			patient.reports &&
			patient.reports?.measurements?.systolicPressure &&
			patient.reports?.measurements?.systolicPressure.value <=
				emrRules.get(ObservationFields.SystolicPressure).max &&
			patient.reports?.measurements?.systolicPressure.value >=
				emrRules.get(ObservationFields.SystolicPressure).min &&
			patient.reports?.measurements?.diastolicPressure &&
			patient.reports?.measurements?.diastolicPressure.value <=
				emrRules.get(ObservationFields.DiastolicPressure).max &&
			patient.reports?.measurements?.diastolicPressure.value >=
				emrRules.get(ObservationFields.DiastolicPressure).min &&
			patient.reports?.measurements?.heartRate &&
			patient.reports?.measurements?.heartRate.value <=
				emrRules.get(ObservationFields.HeartRate).max &&
			patient.reports?.measurements?.heartRate.value >=
				emrRules.get(ObservationFields.HeartRate).min &&
			patient.reports?.measurements?.spo2 &&
			patient.reports?.measurements?.spo2.value <=
				emrRules.get(ObservationFields.SpO2).max &&
			patient.reports?.measurements?.spo2.value >=
				emrRules.get(ObservationFields.SpO2).min
		) {
			data.systolicPressure =
				patient.reports?.measurements?.systolicPressure?.value
			data.diastolicPressure =
				patient.reports?.measurements?.diastolicPressure?.value
		}
		if (
			patient.reports &&
			patient.reports?.measurements?.heartRate &&
			patient.reports?.measurements?.heartRate.value <= 100 &&
			patient.reports?.measurements?.heartRate.value >= 60
		) {
			data.heartRate = patient.reports?.measurements?.heartRate?.value
			data.spo2 = patient.reports?.measurements?.spo2?.value
			if (patient.reports && patient.reports?.measurements?.bodyTemperature) {
				if (
					(getUserLocale() === 'en-US' &&
						Math.floor(
							transformCelsiusToFahrenhei(
								patient.reports?.measurements?.bodyTemperature.value
							) * 10
						) /
							10 <=
							emrRules.get(ObservationFields.BodyTemperature).max &&
						Math.floor(
							transformCelsiusToFahrenhei(
								patient.reports?.measurements?.bodyTemperature.value
							) * 10
						) /
							10 >=
							emrRules.get(ObservationFields.BodyTemperature).min) ||
					(getUserLocale() !== 'en-US' &&
						patient.reports?.measurements?.bodyTemperature.value <=
							emrRules.get(ObservationFields.BodyTemperature).max &&
						patient.reports?.measurements?.bodyTemperature.value >=
							emrRules.get(ObservationFields.BodyTemperature).min)
				) {
					data.bodyTemperature =
						patient.reports?.measurements?.bodyTemperature?.value
				} else {
					data = {}
				}
			}
			if (patient.reports && patient.reports?.measurements?.respirationRate) {
				if (
					patient.reports?.measurements?.respirationRate.value <=
						emrRules.get(ObservationFields.RespirationRate).max &&
					patient.reports?.measurements?.respirationRate.value >=
						emrRules.get(ObservationFields.RespirationRate).min
				) {
					data.respirationRate =
						patient.reports?.measurements?.respirationRate?.value
				} else {
					data = {}
				}
			}
		}
		data.bloodPressureMethod = !patientExport?.bloodPressureMethod
			? 'Sitting r/arm'
			: patientExport?.bloodPressureMethod
		data.heartRateMethod = !patientExport?.heartRateMethod
			? 'regular'
			: patientExport?.heartRateMethod
		data.spo2Method = !patientExport?.spo2Method
			? 'Room Air'
			: patientExport?.spo2Method
		data.bodyTemperatureMethod = !patientExport?.bodyTemperatureMethod
			? 'Forehead (non-contact)'
			: patientExport?.bodyTemperatureMethod
		if (
			!data.spo2 ||
			!data.diastolicPressure ||
			!data.systolicPressure ||
			!data.heartRate
		) {
			return null
		}
		return { observedPatient: patient.id, ...data }
	}

	private static hydrate(
		monitorPatientIds: string[],
		requestPatientsVitals: string[],
		patients: PatientInterface[],
		patientsAllExports: ExportAllInterface[],
		devices: DeviceInterface[],
		forceReadDevicesStatus: NewReadSocketModel[]
	): PatientInterface[] {
		return patients.map((p) => {
			const currentDevices = devices
				.filter((d) => d.model === DeviceModel.BiobeatWatch)
				.find((d) => d.patient.id === p.id)
			const patientAllExport = patientsAllExports.find(
				(e) => e.patientId === p.id
			)
			const patientForceReadDevicesStatus = forceReadDevicesStatus.filter(
				(fr) => fr.deviceId === currentDevices?.id
			)
			return {
				...p,
				forceReadLoading: ReportState.forceReadLoadingSetting(
					patientForceReadDevicesStatus.length
						? patientForceReadDevicesStatus[0]
						: null
				),
				statusDetailInformation: currentDevices?.statusDetailInformation,
				checked: !!requestPatientsVitals.filter((id) => id === p.id).length,
				monitored: !!monitorPatientIds.filter((id) => id === p.id).length,
				checkShiftSentEmrInformation: p.treatmentPlan
					? ReportState.toReportShiftCheckVitalsToTreatmentPlan(
							p.treatmentPlan.filter(
								(t) => !t.endTime || new Date(t.endTime) > new Date()
							),
							// @ts-ignore
							!patientAllExport
								? { patientId: p.id, data: [] }
								: patientAllExport
					  )
					: null
			}
		})
	}

	private static forceReadLoadingSetting(
		forceReadDeviceStatus: NewReadSocketModel | null
	) {
		return !(
			!forceReadDeviceStatus ||
			forceReadDeviceStatus.forceReadStatus === forceReadStatus.Monitor ||
			forceReadDeviceStatus.forceReadStatus === forceReadStatus.Removed ||
			forceReadDeviceStatus.forceReadStatus === forceReadStatus.Disconnected ||
			forceReadDeviceStatus.errorCode
		)
	}

	private static deviceStatusDetailInformation(
		forceReadDeviceStatus: NewReadSocketModel | null,
		statusDetailInformation: string | undefined
	): string | undefined {
		if (
			(forceReadDeviceStatus &&
				forceReadDeviceStatus.forceReadStatus === forceReadStatus.Monitor) ||
			(forceReadDeviceStatus &&
				forceReadDeviceStatus.forceReadStatus ===
					forceReadStatus.Disconnected) ||
			(forceReadDeviceStatus &&
				forceReadDeviceStatus.errorCode &&
				forceReadDeviceStatus.errorCode !== forceReadErrorCode.Timeout &&
				forceReadDeviceStatus.forceReadStatus === forceReadStatus.BadSignal)
		) {
			if (
				forceReadDeviceStatus.forceReadStatus === forceReadStatus.Monitor &&
				statusDetailInformation !== DeviceCriticalStatus.LowBattery &&
				statusDetailInformation !== DeviceCriticalStatus.NoBattery
			) {
				return DeviceCriticalStatus.Monitor
			} else if (
				forceReadDeviceStatus.forceReadStatus ===
					forceReadStatus.Disconnected &&
				statusDetailInformation !== DeviceCriticalStatus.LowBattery &&
				statusDetailInformation !== DeviceCriticalStatus.NoBattery
			) {
				return DeviceCriticalStatus.NoConnection
			} else if (
				forceReadDeviceStatus.forceReadStatus === forceReadStatus.BadSignal &&
				statusDetailInformation !== DeviceCriticalStatus.LowBattery &&
				statusDetailInformation !== DeviceCriticalStatus.NoBattery
			) {
				return DeviceCriticalStatus.BadReading
			} else {
				return statusDetailInformation
			}
		} else {
			return statusDetailInformation
		}
	}

	// @ts-ignore
	private static toReportShiftCheckVitalsToTreatmentPlan(
		treatmentPlans: TreatmentPlanDTO[],
		{ data }: ExportAllInterface
	) {
		if (!treatmentPlans.length) {
			return null
		}
		return checkVitalsToTreatmentPlan(
			combineTreatmentPlans(treatmentPlans),
			data
		)
	}

	private static checkFreshVitalTimeSetting(
		value: string,
		time: string | undefined
	): string {
		if (!time) return ''
		return moment(new Date()).diff(moment(time), 'hours') >= 4 ? '' : value
	}

	public override ngxsOnInit() {
		this.storeEvents.devicesModified$
			.pipe(
				tap((devicesAction) => {
					this.getMonitoredPatients(devicesAction.action.entities)
					if (this.reportStateSubscription)
						this.reportStateSubscription.unsubscribe()
					this.reportStateSubscription =
						this.backendService.updatePatientVitalsManualWSOnMessage$.subscribe(
							(res: any) => {
								clearTimeout(this.timeout)
								if (this.getState().type !== ReportType.CurrentShift) return
								if (
									(this.checkVitalsInData(cloneDeep(res)) &&
										!this.lastPatientReportTime[res.patientId]) ||
									(this.checkVitalsInData(cloneDeep(res)) &&
										this.lastPatientReportTime[res.patientId] !== res.timestamp)
								) {
									this.updatePatientsIds = uniq([
										...this.updatePatientsIds,
										res.patientId
									])
									this.lastPatientReportTime[res.patientId] = res.timestamp
								}
							}
						)
				})
			)
			.subscribe()

		this.storeEvents.patientsModified$
			.pipe(
				tap((action) => {
					this.getReportsData(action.action.entities)
				})
			)
			.subscribe()

		this.storeEvents.departmentChange$
			.pipe(
				tap(() => {
					this.measurementState.patchState({ isLoading: true })
					this.getReportsData()
				})
			)
			.subscribe()

		this.storeEvents.logout$
			.pipe(
				tap(() => {
					this.updatePatientsIds = []
					clearTimeout(this.timeout)
					this.reset()
					this.lastPatientReportTime = {}
					if (this.reportStateSubscription)
						this.reportStateSubscription.unsubscribe()
				})
			)
			.subscribe()
	}

	@DataAction()
	setNewRead() {
		let patients = Object.values(this.patientState.entities)
		const department = Object.values(this.departmentState.entities).length
			? Object.values(this.departmentState.entities)[0]
			: this.departmentState.getState().currentDepartment
		if (department) {
			patients = patients.filter(
				// @ts-ignore
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		const currentDevices = Object.values(this.deviceState.entities).filter(
			(d) =>
				d.model === DeviceModel.BiobeatWatch &&
				d.forceReadEnabled &&
				d.statusInformation !== DeviceCriticalStatus.NoConnection &&
				d.patient &&
				patients.find((p) => p.id === d.patient.id)
		)
		if (!currentDevices) return
		this.ntfService.warning(
			'The system is retrieving fresh data from devices. This process may take a few minutes'
		)
		this.forceReadSocketInitialization(currentDevices.map((d) => d.id))
	}

	@DataAction()
	setReportsAllToEmrData(
		@Payload('patients') patients: PatientInterface[],
		@Payload('exports') exports: PatientExportDTO[]
	) {
		this.pccState.patchState({ loading: true })
		const observations = patients
			.filter((p) => p.emrid && p.reports && Object.values(p.reports).length)
			.map((p) => ReportState.setAllToEmrSetting(p, exports))
			.filter((d) => d)
		if (!observations.length) {
			setTimeout(() => {
				this.pccState.patchState({ loading: false })
				this.ntfService.error(
					`There are no good patients for reporting to the EMR`
				)
			}, 0)
			return
		}

		this.pccState.updatePccEmrMeasurementBulk({
			// @ts-ignore
			observations
		})
	}

	@DataAction()
	setTextFilter(@Payload('text') text: string) {
		this.patchState({ textFilter: text })
	}

	@DataAction()
	exportPDFSetting(@Payload('patients') patients: PatientInterface[]) {
		let department = !!this.departmentState.getState().currentDepartment
			? this.departmentState.getState().currentDepartment
			: Object.values(this.departmentState.entities)[0]
		const rows = patients
			.filter((p) => p.enabled)
			.map((p) => ({
				name: p.name,
				room: !p.room ? '' : p.room,
				bp: ReportState.checkFreshVitalTimeSetting(
					p.reports && p.reports?.measurements?.systolicPressure
						? String(
								Math.round(p.reports?.measurements?.systolicPressure?.value)
						  ) +
								'/' +
								String(
									Math.round(
										<number>p.reports?.measurements?.diastolicPressure?.value
									)
								)
						: '',
					p.reports?.measurements?.diastolicPressure?.timestamp as any
				),
				hr: ReportState.checkFreshVitalTimeSetting(
					p.reports && p.reports?.measurements?.heartRate
						? String(Math.round(p.reports?.measurements?.heartRate?.value))
						: '',
					p.reports?.lastMeasurement
				),
				rr: ReportState.checkFreshVitalTimeSetting(
					p.reports && p.reports?.measurements?.respirationRate
						? String(
								Math.round(p.reports?.measurements?.respirationRate?.value)
						  )
						: '',
					p.reports?.measurements?.respirationRate?.timestamp as any
				),
				spo: ReportState.checkFreshVitalTimeSetting(
					p.reports && p.reports?.measurements?.spo2
						? String(Math.round(p.reports?.measurements?.spo2?.value))
						: '',
					p.reports?.measurements?.spo2?.timestamp as any
				),
				temp: ReportState.checkFreshVitalTimeSetting(
					p.reports && p.reports?.measurements?.bodyTemperature
						? String(
								getUserLocale() === 'en-US'
									? Math.floor(
											transformCelsiusToFahrenhei(
												p.reports?.measurements?.bodyTemperature?.value
											) * 10
									  ) / 10
									: Math.floor(
											p.reports?.measurements?.bodyTemperature?.value * 10
									  ) / 10
						  )
						: '',
					p.reports?.measurements?.bodyTemperature?.timestamp as any
				),
				lastMeasurements:
					getUserLocale() === 'en-US'
						? p.reports?.lastMeasurement
							? moment(p.reports?.lastMeasurement).format('MM/DD/yy HH:mm')
							: ''
						: p.reports?.lastMeasurement
						? moment(p.reports?.lastMeasurement).format('DD/MM/yy HH:mm')
						: ''
			}))
		if (!rows) {
			return
		}
		return this.backendService.reports({
			// @ts-ignore
			facilityName: !department.departmentEmrId
				? ''
				: // @ts-ignore
				  department.departmentEmrId,
			// @ts-ignore
			departmentName: department.name,
			rows
		})
	}

	@DataAction()
	setReportsVitalsData(
		@Payload('patients') patients: PatientInterface[],
		@Payload('idx') ids?: string[]
	) {
		this.reportsVitalsData = {
			patientActions: []
		}
		const requestPatientsVitals: string[] = ids
			? cloneDeep(ids)
			: this.ctx.getState().requestPatientsVitals
		if (requestPatientsVitals.length) {
			ReportState.requestPatientsVitalsSetting(
				requestPatientsVitals,
				patients,
				this.reportsVitalsData
			)
		}
		return this.backendService
			.setManualVitals(this.reportsVitalsData)
			.pipe(tap(() => this.ntfService.success('Task(s) were created')))
	}

	@DataAction()
	setManualCNAReport(
		@Payload('data') data: ManualVitalsInterface,
		@Payload('type') type: string
	) {
		return this.backendService.setCNAManualVitals(data).pipe(
			tap(() => {
				this.patchState({ type })
				this.ntfService.success(`Update Manual Vitals completed`)
			})
		)
	}

	@DataAction()
	getReportsData(patientsData?: PatientDTO[]): void {
		let patients = patientsData || Object.values(this.patientState.entities)
		const department = Object.values(this.departmentState.entities).length
			? Object.values(this.departmentState.entities)[0]
			: this.departmentState.getState().currentDepartment

		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				// @ts-ignore
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		const activePatientIds = patients.filter((p) => p.enabled).map((p) => p.id)
		if (!activePatientIds.length) return
		if (this.getState().type === ReportType.CurrentShift) {
			this.getCurrentShiftLatestData(activePatientIds)
		} else {
			const time = this.getState().time
			if (!time) return
			this.measurementState.getMeasurementSummary(
				activePatientIds,
				moment(time).subtract(8, 'hours').toISOString(),
				moment(time).add(20, 'minutes').toISOString(),
				ReportMode.Latest
			)
		}
		this.dispatch({ type: 'CAN TREATMENT_PLANS LOAD' })
	}

	@DataAction()
	getMonitoredPatients(devices: DeviceDTO[]) {
		let patients = Object.values(this.patientState.entities)
		const department = Object.values(this.departmentState.entities).length
			? Object.values(this.departmentState.entities)[0]
			: this.departmentState.getState().currentDepartment

		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				// @ts-ignore
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		const monitorPatientIds = devices
			.filter((d) => d.patient)
			.map((d) => d.patient.id)

		this.ctx.patchState({
			monitorPatientIds: [
				...new Set([...monitorPatientIds, ...this.getState().monitorPatientIds])
			]
		})
	}

	@DataAction()
	setDevicesForceRead(@Payload('type') type = 'default') {
		let patients = Object.values(this.patientState.entities)
		const department = Object.values(this.departmentState.entities).length
			? Object.values(this.departmentState.entities)[0]
			: this.departmentState.getState().currentDepartment
		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				// @ts-ignore
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		const currentDevices = Object.values(this.deviceState.entities).filter(
			(d) =>
				d.model === DeviceModel.BiobeatWatch &&
				d.forceReadEnabled &&
				d.patient &&
				patients.find((p) => p.id === d.patient.id)
		)
		return this.backendService
			.setDevicesForceRead(currentDevices.map((d) => d.id))
			.pipe(
				tap(
					(
						res: {
							deviceId: string
							forceReadStatus: string
						}[]
					) => {
						if (type !== 'default') return
						res.forEach((r) => {
							const device = currentDevices.find((d) => d.id === r.deviceId)
							if (r.forceReadStatus === 'DONE') {
								this.ntfService.success(`${device?.serialNumber} New Read Done`)
							} else {
								this.ntfService.error(`${device?.serialNumber} New Read Failed`)
							}
						})
					}
				)
			)
	}

	@DataAction()
	updatePatientVitalsManualWSOnMessageSetting(
		@Payload('patientIds') ids: string[]
	) {
		if (this.getState().type === ReportType.CurrentShift) {
			this.getCurrentShiftLatestData(ids)
		}
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}

	private forceReadSocketInitialization(deviceIds: string[]) {
		this.webSocketService.setUrl(
			`${environment.forceReadWsUrl}/v2/device/force-read?authorization=${this.authState.snapshot.accessJwt?.token}`
		)
		this.webSocketService.send({
			deviceIds,
			timeout: 180
		})
		this.patchState({ isForceReadProcess: true, forceReadDevicesStatus: [] })
		let finishedDevicesLength = 0
		this.subscriptionNewReadSocket$ = this.webSocketService
			.connect()
			.subscribe((data: NewReadSocketModel) => {
				let forceReadDevicesStatus = cloneDeep(
					this.getState().forceReadDevicesStatus
				)
				const idx = forceReadDevicesStatus.findIndex(
					(item) => item.deviceId === data.deviceId
				)
				if (idx !== -1) {
					forceReadDevicesStatus[idx] = data
				} else {
					forceReadDevicesStatus = [...forceReadDevicesStatus, data]
				}
				this.patchState({ forceReadDevicesStatus })
				if (
					data.forceReadStatus === forceReadStatus.Monitor ||
					data.forceReadStatus === forceReadStatus.Removed ||
					data.forceReadStatus === forceReadStatus.Disconnected ||
					data.errorCode
				) {
					finishedDevicesLength += 1
					if (finishedDevicesLength === deviceIds.length) {
						this.webSocketService.close()
						this.patchState({
							isForceReadProcess: false,
							forceReadDevicesStatus: []
						})
						this.subscriptionNewReadSocket$.unsubscribe()
					}
				}
			})
	}

	private checkVitalsInData(res: any) {
		if (res.context) {
			delete res.context
		}
		if (res.deviceId) {
			delete res.deviceId
		}
		if (res.monitoringStatus) {
			delete res.monitoringStatus
		}
		if (res.patientId) {
			delete res.patientId
		}
		if (res.sessionId) {
			delete res.sessionId
		}
		if (res.timestamp) {
			delete res.timestamp
		}
		return !!Object.values(res).length
	}

	private getCurrentShiftLatestData(activePatientIds: string[]) {
		this.measurementState.getMeasurementSummary(
			activePatientIds,
			moment(new Date()).subtract(8, 'hours').toISOString(),
			moment(new Date()).add(20, 'minutes').toISOString(),
			ReportMode.Latest
		)
	}
}
