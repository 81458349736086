import { ChangeDetectionStrategy, Component } from '@angular/core'
import { AlertSubject } from 'projects/aiomed/src/shared/model/alert'
import { AlertModal } from '../../../../../../shared/classes/alert-modal/alertModal'

@Component({
	selector: 'aiomed-mobile-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertMobileComponent extends AlertModal {
	AlertSubject = AlertSubject

	constructor() {
		super()
	}

	handlerDegreeResolvedClick() {
		this.resolution = 'escalated'
		this.submitForm()
	}
}
