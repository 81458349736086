import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

import LogRocket from 'logrocket';
LogRocket.init(environment.logrocketAppID);

import { productFruits } from 'product-fruits';
productFruits.init(
  environment.productFruitsCode,
  'en',
  { username: 'aiomed admin' },
);


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
