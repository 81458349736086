import { Pipe, PipeTransform } from '@angular/core'
import { AlertDTO, AlertStatus } from '../../../shared/model/alert'

@Pipe({
	name: 'alertStatusName'
})
export class AlertStatusNamePipe implements PipeTransform {
	AlertStatus = AlertStatus

	transform(data: AlertDTO, ...args: unknown[]): string {
		if (!data) return ''
		if (AlertStatus.Open === data.status) {
			return 'Ongoing'
		} else if (AlertStatus.Closed === data.status) {
			return 'Resolved'
		} else if (AlertStatus.Snoozed === data.status) {
			return `MutedFor`
		}
		return ''
	}
}
