import { Id } from './common'

export interface PatientObservationId extends Id {}

export enum ObservationFields {
	Activity = 'activity',
	BodyTemperature = 'body_temperature',
	HeartRate = 'heart_rate',
	Posture = 'posture',
	RespirationRate = 'respiration_rate',
	SpO2 = 'spo2',
	DiastolicPressure = 'diastolicPressure',
	SystolicPressure = 'systolicPressure',
	ExitBedRisk = 'exitBedRisk',
	BloodGlucose = 'bloodGlucose',
	MonitoringStatus = 'monitoringStatus'
}

export interface ObservationStats {
	timestamp: Date
	average: number | any
}

export enum ObservationType {
	Live = 'live',
	Historical = 'historical'
}

export interface ObservationEcg {
	data: string
	metadata: {
		frequency: number
	}
}

export interface ObservationField {
	activity: number
	body_temperature: number
	heart_rate: number
	posture: number
	respiration_rate: number
	spo2: number
	diastolicPressure: number
	systolicPressure: number
	exitBedRisk?: number
	bloodGlucose?: number
	context?: string
	deviceId?: number
	patientId: string
	sessionId?: string
	skin_temperature?: number
	monitoringStatus?: number
	ecg: ObservationEcg
	timestamp: Date | string
}

export interface ObservationLatest {
  timestamp: Date | string
  value: number | any
  deviceId?: string
  isManual?: boolean
}

export type ObservationsLatestMap = {[key in ObservationFields] : ObservationLatest}

export type ObservationsSortedMap = {[timestamp: number] : ObservationField}

export interface PatientObservationDTO extends PatientObservationId {
  // because we will keep one-to-one observation to the patient the id will be patient's id
  latest: ObservationField[]

  observations: ObservationsSortedMap;
  latestPerVital: ObservationsLatestMap;
	lastMeasurementTime?: string | Date;
}