<ng-container *ngIf='!isMobile()'>
  <nz-layout>
    <nz-sider *ngIf='!(needChooseDepartment$ | async)' [ngClass]="{'aiomed__sider-Collapsed': isCollapseMenu}"
              class='aiomed__sider'>
      <aiomed-menu (collapseMenuEmitter)='handlerCollapseMenuEmitter($event)'
                   [isCollapseMenu]='isCollapseMenu'
                   [ngClass]="{'menu-collapsed': isCollapseMenu}" class='menu'
      ></aiomed-menu>
    </nz-sider>
    <nz-layout>
      <nz-content>
        <main>
          <router-outlet></router-outlet>
        </main>
      </nz-content>
    </nz-layout>
  </nz-layout>
  <ng-container *ngIf='screenBlockedStatus$ | async as screenBlockedStatus'>
    <div
      [ngClass]="{'red': screenBlockedStatus === ScreenBlockedStatus.RedBlocked, 'blue': screenBlockedStatus === ScreenBlockedStatus.BlueBlocked}"
      class='screen-blocked'>
      AiOmed
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf='isMobile()'>
  <router-outlet *ngIf='!(isAuthorized$ | async )'></router-outlet>
  <ng-container *ngIf='!!(user$ | async)'>
    <ion-router-outlet id='main-content'></ion-router-outlet>
    <aiomed-mobile-menu [user]='user$ | async'></aiomed-mobile-menu>
  </ng-container>
  <ng-container *ngIf='screenBlockedStatus$ | async as screenBlockedStatus'>
    <div
      [ngClass]="{'red': screenBlockedStatus === ScreenBlockedStatus.RedBlocked, 'blue': screenBlockedStatus === ScreenBlockedStatus.BlueBlocked}"
      class='screen-blocked'>
      AiOmed
    </div>
  </ng-container>
</ng-container>
