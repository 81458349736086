import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	AlertDTO,
	alertSeverityComparator,
	alertUrgencyComparator
} from '../../shared/model/alert'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import {
	concatMap,
	EMPTY,
	finalize,
	ignoreElements,
	mergeMap,
	Observable,
	of,
	Subscription,
	switchMap,
	tap,
	timer
} from 'rxjs'
import { AlertState } from '../alert/alert.state'
import { Injectable } from '@angular/core'
import { BackendPatientDTO } from '../../shared/model/backend-device-model'
import {
	PatientDTO,
	PatientInterface,
	patientsSort,
	PatientSymptomFields
} from '../../shared/model/patient'
import { FileState } from '../file/file.state'
import { FileDTO } from '../../shared/model/file'
import { MeasurementState } from '../measurement/measurement.state'
import {
	ObservationField,
	ObservationFields,
	PatientObservationDTO
} from '../../shared/model/patient-observation'
import { AlertRuleState } from '../alert-rule/alert-rule.state'
import {
	AlertRuleDTO,
	AlertRuleName
} from '../../shared/model/alert-rules.model'
import { cloneDeep, orderBy } from 'lodash-es'
import { PAGE_SIZE } from '../../core/helpers/variables'
import { entitiesFilter } from '../../core/helpers/filter'
import { DepartmentState } from '../department/department.state'
import { DepartmentDTO } from '../../shared/model/permission.model'
import { base64ToFloat32Array, dataShaping } from '../../core/helpers/functions'
import { InsightDTO } from '../../shared/model/insight.model'
import { SignsIllnessState } from '../signsIllness/signsIllness.state'
import {
	ConditionDTO,
	SignsIllnessDTO,
	SymptomDTO
} from '../../shared/model/simptom-condition.model'
import { PatientLogState } from '../patient-log/patient-log.state'
import { PatientLogDTO } from '../../shared/model/patient-log.model'
import { BackendService } from '../../shared/services/backend.service'
import {
	ManualMeasurementsDTO,
	MeasurementSummaryInterface
} from '../../shared/model/measurement'
import { TreatmentPlanDTO } from '../../shared/model/treatment-plan'
import { NotificationService } from '../../shared/services/notification.service'
import { TemplateAlertRuleState } from '../template-alert-rule/template-alert-rule.state'
import { TreatmentPlanState } from '../treatment-plan/treatment-plan.state'
import { OneToManyRelationship } from '../common'
import { StoreEventsService } from '../store-events.service'
import { InsightState } from '../insight/insight.state'
import { DepartmentFilter } from '../../shared/model/departments.model'

export const patientFeatureName = 'patient'

@StateRepository()
@State<CollatableEntityCollections<PatientDTO>>({
	name: patientFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation(),
		isLoading: true
	}
})
@Injectable()
export class PatientState extends CollatableEntityCollectionsRepository<
	PatientDTO,
	EntityCollation
> {
	private patientStateSubscription: Subscription

	constructor(
		private alertState: AlertState,
		private fileState: FileState,
		private backendService: BackendService,
		private symptomConditionState: SignsIllnessState,
		private templateAlertRuleState: TemplateAlertRuleState,
		private ntfService: NotificationService,
		private storeEvents: StoreEventsService
	) {
		super()
	}

	public get backendFocusOnUpdates$(): Observable<number> {
		return timer(60000, 60000).pipe(
			switchMap((n) => {
				const focusOnId = this.getState().focusOnId
				return this.focusOnPatient(String(focusOnId)).pipe(
					concatMap((_) => of(n))
				)
			})
		)
	}

	@Selector()
	public static entities(state: CollatableEntityCollections<PatientDTO>) {
		return state.entities
	}

	@Selector()
	public static allPatientAlertRules(
		state: CollatableEntityCollections<PatientDTO>
	) {
		return new Set(
			Object.values(state.entities)
				.map((v) => v.alertRules?.id)
				.filter(Boolean)
		)
	}

	@Selector()
	public static ids(state: CollatableEntityCollections<PatientDTO>) {
		return state.ids
	}

	// 2f99df0a-4d9e-4cbb-b65e-1fe8497f3419
	@Selector([
		AlertState.patientOpenAlerts,
		FileState.files,
		MeasurementState.measurement,
		AlertRuleState.alertRules,
		TemplateAlertRuleState.templateAlertRules,
		MeasurementState.measurementReports,
		InsightState.insights,
		SignsIllnessState.signsIllness,
		PatientLogState.patientLog,
		TreatmentPlanState.treatmentPlans
	])
	public static patient(
		state: CollatableEntityCollections<PatientDTO>,
		alerts: OneToManyRelationship<AlertDTO>,
		files: EntityDictionary<string, FileDTO>,
		measurement: EntityDictionary<string, PatientObservationDTO>,
		alertRules: EntityDictionary<string, AlertRuleDTO>,
		templateAlertRule: EntityDictionary<string, AlertRuleDTO>,
		reports: MeasurementSummaryInterface[],
		insights: InsightDTO[],
		signsIllness: SignsIllnessDTO,
		patientLog: PatientLogDTO[],
		treatmentPlans: TreatmentPlanDTO[]
	): PatientInterface | null {
		if (state.focusOnId) {
			const patient = state.entities[state.focusOnId]
			return PatientState.hydrate(
				patient,
				alerts,
				files,
				measurement,
				Object.values(alertRules),
				Object.values(templateAlertRule),
				reports,
				insights,
				signsIllness,
				patientLog,
				treatmentPlans
			)
		}
		return null
	}

	@Selector([AlertRuleState.alertRules, MeasurementState.measurementHistorical])
	public static patientHistoricalObservation(
		state: CollatableEntityCollections<PatientDTO>,
		alertRules: EntityDictionary<string, AlertRuleDTO>,
		measurement: EntityDictionary<string, PatientObservationDTO>
	):
		| {
				[key in ObservationFields]: {
					lastUpdated: string
					isCritical?: boolean
					value: number | string
				}
		  }
		| any {
		if (state.focusOnId) {
			const patient = state.entities[state.focusOnId]
			const defaultAlertRules = Object.values(alertRules).filter(
				(alertRule: AlertRuleDTO) =>
					alertRule.name.split(' ').join('') === AlertRuleName.DefaultAlertRules
			)
			const patientAlertRules = Object.values(alertRules).filter(
				(alertRule: AlertRuleDTO) =>
					patient.alertRules &&
					patient.alertRules.id === alertRule.id &&
					patient.alertRules.name.split(' ').join('') !==
						AlertRuleName.DefaultAlertRules
			)
			return measurement[patient.id] &&
				measurement[patient.id].latest[
					measurement[patient.id].latest.length - 1
				]
				? PatientState.toPatientObservationsTransform(
						measurement[patient.id].latest[
							measurement[patient.id].latest.length - 1
						],
						patientAlertRules.length
							? patientAlertRules[0]
							: defaultAlertRules.length
							? defaultAlertRules[0]
							: null
				  )
				: null
		}
		return null
	}

	@Selector([
		AlertState.alerts,
		FileState.files,
		MeasurementState.measurement,
		AlertRuleState.alertRules,
		TemplateAlertRuleState.templateAlertRules,
		DepartmentState.department,
		MeasurementState.measurementReports,
		InsightState.insights,
		SignsIllnessState.signsIllness,
		PatientLogState.patientLog,
		TreatmentPlanState.treatmentPlans
	])
	public static patientsHaveAlertsNoDegree(
		state: CollatableEntityCollections<PatientDTO>,
		alerts: EntityDictionary<string, AlertDTO>,
		files: EntityDictionary<string, FileDTO>,
		measurement: EntityDictionary<string, PatientObservationDTO>,
		alertRules: EntityDictionary<string, AlertRuleDTO>,
		templateAlertRule: EntityDictionary<string, AlertRuleDTO>,
		department: DepartmentDTO | undefined,
		reports: MeasurementSummaryInterface[],
		insights: InsightDTO[],
		signsIllness: SignsIllnessDTO,
		patientLog: PatientLogDTO[],
		treatmentPlans: TreatmentPlanDTO[]
	): PatientInterface[] {
		let patients = Object.values(state.entities)
		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		const tmpAlert = Object.values(alerts)
			.filter((a) => a.status === 'open')
			.map((alert) => ({
				alerts: [alert],
				patient: patients.filter(
					(patient) => patient.id === alert.patient?.id
				)[0],
				timestamp: alert.creationTime
			}))
		return orderBy(tmpAlert, 'timestamp', 'asc')
			.map((item: any) =>
				PatientState.hydrate(
					item.patient,
					item.alerts,
					files,
					measurement,
					Object.values(alertRules),
					Object.values(templateAlertRule),
					reports,
					insights,
					signsIllness,
					patientLog,
					treatmentPlans,
					'noDegree'
				)
			)
			.filter((patient: PatientInterface) => patient.alerts.length)
	}

	@Selector([
		AlertState.patientAlerts,
		FileState.files,
		MeasurementState.measurement,
		AlertRuleState.alertRules,
		TemplateAlertRuleState.templateAlertRules,
		DepartmentState.department,
		MeasurementState.measurementReports,
		InsightState.insights,
		SignsIllnessState.signsIllness,
		PatientLogState.patientLog,
		TreatmentPlanState.treatmentPlans
	])
	public static followUpPatients(
		state: CollatableEntityCollections<PatientDTO>,
		alerts: OneToManyRelationship<AlertDTO>,
		files: EntityDictionary<string, FileDTO>,
		measurement: EntityDictionary<string, PatientObservationDTO>,
		alertRules: EntityDictionary<string, AlertRuleDTO>,
		templateAlertRules: EntityDictionary<string, AlertRuleDTO>,
		department: DepartmentDTO | undefined,
		reports: MeasurementSummaryInterface[],
		insights: InsightDTO[],
		signsIllness: SignsIllnessDTO,
		patientLog: PatientLogDTO[],
		treatmentPlans: TreatmentPlanDTO[]
	): PatientInterface[] {
		let patients = Object.values(state.entities)
		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		return orderBy(
			patients.map((patient: PatientDTO) =>
				PatientState.hydrate(
					patient,
					alerts,
					files,
					measurement,
					Object.values(alertRules),
					Object.values(templateAlertRules),
					reports,
					insights,
					signsIllness,
					patientLog,
					treatmentPlans
				)
			),
			['hasMuteAlert'],
			'desc'
		).filter((patient) => patient.hasMuteAlert || patient.insights?.length)
	}

	@Selector([
		AlertState.patientOpenAlerts,
		FileState.files,
		MeasurementState.measurement,
		AlertRuleState.alertRules,
		TemplateAlertRuleState.templateAlertRules,
		DepartmentState.department,
		MeasurementState.measurementReports,
		InsightState.insights,
		SignsIllnessState.signsIllness,
		PatientLogState.patientLog,
		TreatmentPlanState.treatmentPlans
	])
	public static patients(
		state: CollatableEntityCollections<PatientDTO>,
		alerts: OneToManyRelationship<AlertDTO>,
		files: EntityDictionary<string, FileDTO>,
		measurement: EntityDictionary<string, PatientObservationDTO>,
		alertRules: EntityDictionary<string, AlertRuleDTO>,
		templateAlertRule: EntityDictionary<string, AlertRuleDTO>,
		department: DepartmentDTO | null,
		reports: MeasurementSummaryInterface[],
		insights: InsightDTO[],
		signsIllness: SignsIllnessDTO,
		patientLog: PatientLogDTO[],
		treatmentPlans: TreatmentPlanDTO[]
	): PatientInterface[] {
		let patients = Object.values(state.entities).filter(p => p.room);
		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		patients = entitiesFilter(
			state.freeTextFilter,
			patientsSort(state.sort, orderBy(patients, 'room', 'asc'))
			// patientsSort(state.sort, orderBy(patients, 'creationTime', 'desc')))
		)
		return patients
			.map((patient: PatientDTO) =>
				PatientState.hydrate(
					patient,
					alerts,
					files,
					measurement,
					Object.values(alertRules),
					Object.values(templateAlertRule),
					reports,
					insights,
					signsIllness,
					patientLog,
					treatmentPlans
				)
			)
			.slice(0, state.pageSize)
	}

	@Selector([
		FileState.files,
		DepartmentState.department,
		MeasurementState.measurementReports,
		TreatmentPlanState.treatmentPlans,
		InsightState.insights,
		MeasurementState.measurement,
		AlertRuleState.alertRules,
		TemplateAlertRuleState.templateAlertRules
	])
	public static reportPatients(
		state: CollatableEntityCollections<PatientDTO>,
		files: EntityDictionary<string, FileDTO>,
		department: DepartmentDTO | undefined,
		reports: MeasurementSummaryInterface[],
		treatmentPlans: TreatmentPlanDTO[],
		insights: InsightDTO[],
		measurements: EntityDictionary<string, PatientObservationDTO>,
		alertRules: EntityDictionary<string, AlertRuleDTO>,
		templateAlertRule: EntityDictionary<string, AlertRuleDTO>
	): Partial<PatientInterface>[] {
		let patients = Object.values(state.entities).filter(p => p.room);
		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		return orderBy(
			patients.map((patient: PatientDTO) =>
				PatientState.toReportPatientsHydrate(
					patient,
					files,
					reports,
					treatmentPlans,
					insights,
					measurements[patient.id],
					Object.values(alertRules),
					Object.values(templateAlertRule)
				)
			),
			'room',
			'asc'
		)
	}

	@Selector([
		FileState.files,
		DepartmentState.department,
		TreatmentPlanState.treatmentPlans
	])
	public static allDepartmentPatients(
		state: CollatableEntityCollections<PatientDTO>,
		files: EntityDictionary<string, FileDTO>,
		department: DepartmentDTO,
		treatmentPlans: TreatmentPlanDTO[]
	): Partial<PatientInterface>[] {
		let patients = Object.values(state.entities)
		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		return patients.map((patient: PatientDTO) =>
			PatientState.toShiftPlanerPatientsHydrate(patient, files, treatmentPlans)
		)
	}

	@Selector([
		AlertState.patientOpenAlerts,
		FileState.files,
		MeasurementState.measurement,
		AlertRuleState.alertRules,
		TemplateAlertRuleState.templateAlertRules,
		DepartmentState.department,
		MeasurementState.measurementReports,
		InsightState.insights,
		SignsIllnessState.signsIllness,
		PatientLogState.patientLog,
		TreatmentPlanState.treatmentPlans
	])
	public static patientsHaveAlerts(
		state: CollatableEntityCollections<PatientDTO>,
		alerts: OneToManyRelationship<AlertDTO>,
		files: EntityDictionary<string, FileDTO>,
		measurement: EntityDictionary<string, PatientObservationDTO>,
		alertRules: EntityDictionary<string, AlertRuleDTO>,
		templateAlertRule: EntityDictionary<string, AlertRuleDTO>,
		department: DepartmentDTO | undefined,
		reports: MeasurementSummaryInterface[],
		insights: InsightDTO[],
		signsIllness: SignsIllnessDTO,
		patientLog: PatientLogDTO[],
		treatmentPlans: TreatmentPlanDTO[]
	): PatientInterface[] {
		let patients = Object.values(state.entities)
		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		return patients
			.map((patient: PatientDTO) =>
				PatientState.hydrate(
					patient,
					alerts,
					files,
					measurement,
					Object.values(alertRules),
					Object.values(templateAlertRule),
					reports,
					insights,
					signsIllness,
					patientLog,
					treatmentPlans
				)
			)
			.filter((patient: PatientInterface) => patient.alerts.length)
	}

	@Selector([
		AlertState.patientAlerts,
		FileState.files,
		MeasurementState.measurement,
		AlertRuleState.alertRules,
		TemplateAlertRuleState.templateAlertRules,
		DepartmentState.department,
		MeasurementState.measurementReports,
		InsightState.insights,
		SignsIllnessState.signsIllness,
		PatientLogState.patientLog,
		TreatmentPlanState.treatmentPlans
	])
	public static patientsHaveAlertsTotalCount(
		state: CollatableEntityCollections<PatientDTO>,
		alerts: OneToManyRelationship<AlertDTO>,
		files: EntityDictionary<string, FileDTO>,
		measurement: EntityDictionary<string, PatientObservationDTO>,
		alertRules: EntityDictionary<string, AlertRuleDTO>,
		templateAlertRules: EntityDictionary<string, AlertRuleDTO>,
		department: DepartmentDTO | undefined,
		reports: MeasurementSummaryInterface[],
		insights: InsightDTO[],
		signsIllness: SignsIllnessDTO,
		patientLog: PatientLogDTO[],
		treatmentPlans: TreatmentPlanDTO[]
	): number {
		let patients = Object.values(state.entities)
		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				(patient) => patient.department.id === department.id
			)
		}
		return patients
			.map((patient: PatientDTO) =>
				PatientState.hydrate(
					patient,
					alerts,
					files,
					measurement,
					Object.values(alertRules),
					Object.values(templateAlertRules),
					reports,
					insights,
					signsIllness,
					patientLog,
					treatmentPlans
				)
			)
			.filter((patient: PatientInterface) => patient.alerts.length).length
	}

	@Selector([DepartmentState.department])
	public static totalCount(
		state: CollatableEntityCollections<PatientDTO>,
		department: DepartmentDTO | undefined
	): number {
		let patients = Object.values(state.entities)
		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		return patients.length
	}

	@Selector([DepartmentState.department])
	public static patientPaginationCount(
		state: CollatableEntityCollections<PatientDTO>,
		department: DepartmentDTO | undefined
	): number {
		let patients = entitiesFilter(
			state.freeTextFilter,
			Object.values(state.entities)
		)
		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		return patients.length
	}

	@Selector()
	public static patientSymptomFields(): Array<
		keyof typeof PatientSymptomFields
	> {
		return (
			Object.values(PatientSymptomFields) as Array<
				keyof typeof PatientSymptomFields
			>
		).map((value) => value)
	}

	@Selector()
	public static isLoading(
		state: CollatableEntityCollections<PatientDTO>
	): boolean {
		return state.isLoading
	}

	@Selector()
	public static focusOnPatientId(
		state: CollatableEntityCollections<PatientDTO>
	): string | null {
		return state.focusOnId
	}

	@Selector([DepartmentState.department, FileState.files])
	public static searchPatient(
		state: CollatableEntityCollections<PatientDTO>,
		department: DepartmentDTO,
		files: EntityDictionary<string, FileDTO>
	): PatientDTO[] {
		let patients = Object.values(state.entities)
		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		// @ts-ignore
		return patients
			.map((p) => ({
				...p,
				avatar:
					p.avatar && files[p.avatar.id] && files[p.avatar.id]?.signedUrl
						? files[p.avatar.id]
						: null
			}))
			.filter((p) =>
				p.name.toLowerCase().includes(!state.searchText ? '' : state.searchText)
			)
	}

	@Selector([MeasurementState.measurement])
	public static patientEcg(
		state: CollatableEntityCollections<PatientDTO>,
		measurements: EntityDictionary<string, PatientObservationDTO>
	): {
		value: number
		timestamp: string
	}[] {
		if (!state.focusOnId || !measurements[state.focusOnId]) return []
		const ecg = measurements[state.focusOnId].latest
			.filter((data) => data.ecg)
			.map((data) => {
				const tmpArray: any[] = []
				let timestamp = data.timestamp
				base64ToFloat32Array(data.ecg.data)
					.reverse()
					.forEach((value: number, idx) => {
						timestamp =
							idx === 0
								? data.timestamp
								: dataShaping(timestamp, data.ecg.metadata.frequency)
						tmpArray.push({
							value: value,
							timestamp
						})
					})
				return tmpArray
			})
			.flat()
		let ecdOrderByDesc = orderBy(ecg, 'timestamp', 'desc')
		ecdOrderByDesc.splice(120)
		return ecdOrderByDesc.length >= 120
			? orderBy(ecdOrderByDesc, 'timestamp', 'asc')
			: []
	}

	private static toShiftPlanerPatientsHydrate(
		patient: PatientDTO,
		files: EntityDictionary<string, FileDTO>,
		treatmentPlan: TreatmentPlanDTO[]
	): Partial<PatientInterface> {
		const tPlan = treatmentPlan.filter((e) => e.patientId === patient.id)
		return {
			...patient,
			checked: false,
			avatar:
				patient.avatar &&
				files[patient.avatar.id] &&
				files[patient.avatar.id]?.signedUrl
					? files[patient.avatar.id]
					: null,
			treatmentPlan: tPlan.length ? tPlan : []
		}
	}

	private static toReportPatientsHydrate(
		patient: PatientDTO,
		files: EntityDictionary<string, FileDTO>,
		reports: MeasurementSummaryInterface[],
		treatmentPlan: TreatmentPlanDTO[],
		insights: InsightDTO[],
		measurement: PatientObservationDTO,
		alertRules: AlertRuleDTO[],
		templateAlertRules: AlertRuleDTO[]
	): Partial<PatientInterface> {
		const report = reports.find((r: any) => r.observedPatient === patient.id)
		const insight = insights.filter(
			(insight) => insight.patient.id === patient.id
		)
		const tPlan = treatmentPlan.filter((e) => e.patientId === patient.id)
		let patientAlertRules: AlertRuleDTO[]
		patientAlertRules = alertRules.filter(
			(alertRule: AlertRuleDTO) =>
				patient.alertRules && patient.alertRules.id === alertRule.id
		)
		if (!patientAlertRules.length) {
			patientAlertRules = templateAlertRules.filter(
				(alertRule: AlertRuleDTO) =>
					patient.alertRules && patient.alertRules.id === alertRule.id
			)
		}
		return {
			...patient,
			checked: false,
			avatar:
				patient.avatar &&
				files[patient.avatar.id] &&
				files[patient.avatar.id]?.signedUrl
					? files[patient.avatar.id]
					: null,
			reports: report ? report : null,
			measurement,
			insights: insight ? insight : null,
			treatmentPlan: tPlan.length ? tPlan : [],
			lastEMRUpdateTime: !patient.last_emr_update_time
				? null
				: patient.last_emr_update_time,
			defaultAlertRules: templateAlertRules.length
				? templateAlertRules[0]
				: null,
			patientAlertRules: patientAlertRules.length ? patientAlertRules[0] : null
		}
	}

	private static hydrate(
		patient: PatientDTO,
		alerts: OneToManyRelationship<AlertDTO>,
		files: EntityDictionary<string, FileDTO>,
		observations: EntityDictionary<string, PatientObservationDTO>,
		alertRules: AlertRuleDTO[],
		templateAlertRules: AlertRuleDTO[],
		reports: MeasurementSummaryInterface[],
		insights: InsightDTO[],
		signsIllness: SignsIllnessDTO,
		patientLog: PatientLogDTO[],
		treatmentPlan: TreatmentPlanDTO[],
		type: string = 'default'
	): PatientInterface {
		let patientAlertRules: AlertRuleDTO[]
		patientAlertRules = alertRules.filter(
			(alertRule: AlertRuleDTO) =>
				patient.alertRules && patient.alertRules.id === alertRule.id
		)
		if (!patientAlertRules.length) {
			patientAlertRules = templateAlertRules.filter(
				(alertRule: AlertRuleDTO) =>
					patient.alertRules && patient.alertRules.id === alertRule.id
			)
		}
		const patientAlerts =
			type === 'default'
				? alerts(patient.id)
						.filter((a) => a.status == 'open')
						// .sort((a, b) => alertSeverityComparator(a.severity, b.severity))
						.sort(alertUrgencyComparator)
				: alerts(patient.id)
		// const patientInsights = insights.filter((i) => i.patientId === patient.id)
		const symptoms = signsIllness.symptoms.map((item: SymptomDTO) =>
			PatientState.toPatientSignsIllnessTransform(
				Array.isArray(patient.symptoms)
					? patient.symptoms
					: (patient.symptoms as any).split(','),
				item,
				'symptom'
			)
		)
		const conditions = signsIllness.conditions.map((item: SymptomDTO) =>
			PatientState.toPatientSignsIllnessTransform(
				Array.isArray(patient.conditions)
					? patient.conditions
					: (patient.conditions as any).split(','),
				item,
				'condition'
			)
		)
		const report = reports.find((r: any) => r.observedPatient === patient.id)
		const tPlan = treatmentPlan.filter((e) => e.patientId === patient.id)
		const vitals = {} as any
		if (
			observations &&
			observations?.[patient.id] &&
			observations[patient.id].latestPerVital
		) {
			vitals.body_temperature =
				observations?.[patient.id]?.latestPerVital?.body_temperature ||
				patient.body_temperature
			vitals.heart_rate =
				observations[patient.id].latestPerVital.heart_rate || patient.heart_rate
			vitals.respiration_rate =
				observations?.[patient.id]?.latestPerVital?.respiration_rate ||
				patient.respiration_rate
			vitals.diastolicPressure =
				observations?.[patient.id]?.latestPerVital?.diastolicPressure ||
				patient.diastolicPressure
			vitals.systolicPressure =
				observations?.[patient.id]?.latestPerVital?.systolicPressure ||
				patient.systolicPressure
			vitals.spo2 =
				observations?.[patient.id]?.latestPerVital?.spo2 || patient.spo2
		}
		// @ts-ignore
		return {
			...patient,
			hasMuteAlert: alerts(patient.id).some((a) => a.status == 'snoozed'),
			alerts: patientAlerts,
			maxAlertSeverity: patientAlerts.length ? patientAlerts[0].severity : null,
			patientLog: orderBy(
				patientLog.filter(
					(pl) => pl.logEntryPatient && pl.logEntryPatient.id === patient.id
				),
				'creationTime',
				'asc'
			),
			checked: false,
			avatar:
				patient.avatar &&
				files[patient.avatar.id] &&
				files[patient.avatar.id]?.signedUrl
					? files[patient.avatar.id]
					: null,
			treatmentPlan: tPlan.length ? tPlan : [],
			symptoms: orderBy(symptoms, 'checked', 'desc'),
			conditions: orderBy(conditions, 'checked', 'desc'),
			unreadMessages: patientLog.filter(
				(pl) =>
					pl.logEntryPatient && pl.logEntryPatient.id === patient.id && !pl.read
			).length,
			activeSignsIllness: [
				...conditions.filter((c) => c.checked),
				...symptoms.filter((c) => c.checked)
			],
			insights: insights.filter((i) => i.patient.id === patient.id),
			criticalObservation:
				observations &&
				observations[patient.id] &&
				observations[patient.id].latest &&
				observations[patient.id].latest[
					observations[patient.id].latest.length - 1
				]
					? PatientState.toPatientCriticalObservationsTransform(
							observations[patient.id].latest[
								observations[patient.id].latest.length - 1
							],
							patientAlertRules.length
								? patientAlertRules[0]
								: templateAlertRules.length
								? templateAlertRules[0]
								: null
					  )
					: [],
			observations:
				observations &&
				observations[patient.id] &&
				observations[patient.id].latest &&
				observations[patient.id].latest[
					observations[patient.id].latest.length - 1
				]
					? PatientState.toPatientObservationsTransform(
							observations[patient.id].latest[
								observations[patient.id].latest.length - 1
							],
							patientAlertRules.length
								? patientAlertRules[0]
								: templateAlertRules.length
								? templateAlertRules[0]
								: null
					  )
					: {},
			reports: report ? report : null,
			...vitals,
			lastEMRUpdateTime: !patient.last_emr_update_time
				? null
				: patient.last_emr_update_time,
			lastObservationsTime: PatientState.toPatientLastObservationsTimeTransform(
				{ ...patient, ...vitals }
			),
			defaultAlertRules: templateAlertRules.length
				? templateAlertRules[0]
				: null,
			patientAlertRules: patientAlertRules.length ? patientAlertRules[0] : null
		}
	}

	private static toPatientSignsIllnessTransform(
		patientSignsIllness: string[],
		data: SymptomDTO | ConditionDTO,
		type: string
	) {
		return {
			...data,
			type,
			checked: !!patientSignsIllness.find((si) => si === data.key)
		}
	}

	private static toPatientLastObservationsTimeTransform(patient: PatientDTO) {
		let latestTimestamp: any = null
		;[
			{ key: ObservationFields.BloodGlucose, ...patient.bloodGlucose },
			{ key: ObservationFields.HeartRate, ...patient.heart_rate },
			{ key: ObservationFields.BodyTemperature, ...patient.body_temperature },
			{ key: ObservationFields.RespirationRate, ...patient.respiration_rate },
			{ key: ObservationFields.SpO2, ...patient.spo2 },
			{
				key: ObservationFields.DiastolicPressure,
				...patient.diastolicPressure
			},
			{ key: ObservationFields.SystolicPressure, ...patient.systolicPressure }
		]
			.filter((el) => el.value)
			.forEach((obs) => {
				const timestampDate = new Date(obs.timestamp)
				if (!latestTimestamp || timestampDate > latestTimestamp) {
					latestTimestamp = timestampDate
				}
			})
		return latestTimestamp
	}

	private static toPatientObservationsTransform(
		observation: ObservationField | any,
		alertRules: AlertRuleDTO | null,
		manualObservation?: ManualMeasurementsDTO | any
	):
		| {
				[key in ObservationFields]: {
					lastUpdated: string
					value: number | string
				}
		  }
		| {} {
		const transformObservation:
			| {
					[key in ObservationFields]: {
						lastUpdated: string
						value: number | string
					}
			  }
			| any = {}
		let measurements = { ...observation }
		if (manualObservation && Object.values(manualObservation).length) {
			measurements = { ...measurements, ...manualObservation }
		}
		if (!Object.values(measurements).length || !alertRules) return {}
		// @ts-ignore
		Object.keys(measurements).forEach((key) => {
			const tmp = key.split('_')
			const alertRulesKey =
				tmp.length === 2 && tmp[1].length
					? `${tmp[0]}${tmp[1][0].toUpperCase() + tmp[1].slice(1)}`
					: `${tmp[0]}`
			// @ts-ignore
			const value: string = measurements[key]
			const currentAlertRule =
				// @ts-ignore
				alertRules[alertRulesKey === 'spo2' ? 'spO2' : alertRulesKey]
			transformObservation[key] = {
				lastUpdated: measurements.timestamp,
				// @ts-ignore
				value
			}
			if (!currentAlertRule) return
			if (
				value >= currentAlertRule.maxNormal ||
				value <= currentAlertRule.minNormal
			) {
				transformObservation[key].isCritical = true
			}
		})
		return transformObservation
	}

	private static toPatientCriticalObservationsTransform(
		observation: ObservationField,
		defaultAlertRules: AlertRuleDTO | null
	): any[] {
		if (!defaultAlertRules) return []
		const transformObservation: any[] = []

		Object.keys(observation).forEach((key: string) => {
			const tmp = key.split('_')
			const alertRulesKey =
				tmp.length === 2 && tmp[1].length
					? `${tmp[0]}${tmp[1][0].toUpperCase() + tmp[1].slice(1)}`
					: `${tmp[0]}`
			// @ts-ignore
			const value: string = observation[key]
			const currentAlertRule =
				// @ts-ignore
				defaultAlertRules[alertRulesKey === 'spo2' ? 'spO2' : alertRulesKey]
			if (!currentAlertRule) return
			else if (
				value <= currentAlertRule.maxCritical ||
				value >= currentAlertRule.minCritical
			) {
				transformObservation.push({
					subject: key,
					lastUpdated: observation.timestamp,
					value
				})
			}
		})
		return transformObservation
	}

	// @DataAction()
	updateWithModifiedPatients() {
		this.patchState({ isLoading: true })
		return this.backendService.findAllPatients().pipe(
			tap((patients) => {
				this.upsertMany(patients)
				this.patchState({ isLoading: false })
				this.setPaginationSetting()
				this.setPaginationSetting('haveAlert')
				// this.insightsState.setInsights();
				// this.measurementState.lastObservations(Object.values(this.ctx.getState().entities));
				const fileIds = patients
					.map((patient: BackendPatientDTO | PatientDTO) => patient.avatar?.id)
					.filter((i: string | any) => i)
				this.fileState.loadEntities(fileIds)
				this.symptomConditionState.setSignsIllness()
			}),
			finalize(() => this.patchState({ isLoading: false }))
		)
	}

	public override ngxsOnInit() {
		this.storeEvents.departmentModifiedAndRefreshToken$
			.pipe(
				tap(() => {
					if (this.patientStateSubscription)
						this.patientStateSubscription.unsubscribe()
					this.patientStateSubscription =
						this.updateWithModifiedPatients().subscribe()
				})
			)
			.subscribe()

		// this.storeEvents.departmentChange$.pipe(
		// 	tap(() => this.patchState({ isLoading: true }))
		// ).subscribe();

		this.storeEvents.logout$
			.pipe(
				tap(() => {
					this.reset()
					if (this.patientStateSubscription)
						this.patientStateSubscription.unsubscribe()
				})
			)
			.subscribe()
	}

	@DataAction()
	focusOnPatient(@Payload('id') id: string): Observable<void> {
		this.ctx.patchState({
			focusOnId: id,
			isLoading: false
		})
		const currentPatient = Object.values(this.getState().entities).filter(
			(patient) => patient.id === id
		)
		if (currentPatient.length) {
			this.setPatientItemsSetting(currentPatient)
		}
		this.ctx.patchState({ isLoading: false })
		return of()
	}

	@DataAction()
	setDisabledPatient(@Payload('id') id: string): Observable<void> {
		return this.backendService.setDisabledPatient(id).pipe(
			tap((d) => this.upsertOne(d)),
			ignoreElements()
		)
	}

	@DataAction()
	setEnablePatient(@Payload('id') id: string): Observable<void> {
		return this.backendService.setEnablePatient(id).pipe(
			tap((d) => this.upsertOne(d)),
			ignoreElements()
		)
	}

	@DataAction()
	public setSearchPatientName(@Payload('text') text: string): void {
		this.ctx.patchState({
			searchText: text.toLowerCase()
		})
	}

	@DataAction()
	public loadPatientImages(@Payload('ids') ids: string[]) {
		const fileIds = Object.values(this.ctx.getState().entities)
			.map((patient: BackendPatientDTO | PatientDTO) => patient.avatar?.id)
			.filter((i: string | any) => i)
		this.fileState.loadEntities(fileIds)
	}

	@DataAction()
	updatePatient(
		@Payload('id') id: string,
		@Payload('data') data: Partial<PatientDTO>
	): Observable<void> {
		return this.backendService.updatePatient(id, data).pipe(
			mergeMap((patient) => {
				this.upsertOne(patient)
				return of()
			}),
			ignoreElements()
		)
	}

	@DataAction()
	createPatient(
		@Payload('data') data: Partial<PatientDTO>,
		@Payload('department') department: DepartmentDTO,
		@Payload('fileId') fileId?: string
	): Observable<void> {
		return this.backendService.createPatient(data).pipe(
			tap((patient) => {
				const dep = {
					id: department.id,
					name: department.name,
					parentEntityId: null,
					departmentEmrId: department.departmentEmrId,
					templateId: (department as any)._template.id,
					isAutomatic: department.isAutomatic
				}
				this.upsertOne({ ...patient, department: dep })
				if (fileId) {
					this.updatePatient(patient.id, {
						department: dep,
						// @ts-ignore
						avatar: {
							id: fileId
						},
						// @ts-ignore
						alertRules: Object.values(this.templateAlertRuleState.entities)[0]
					})
				} else {
					this.updatePatient(patient.id, {
						department: dep,
						// @ts-ignore
						alertRules: Object.values(this.templateAlertRuleState.entities)[0]
					})
				}
				setTimeout(() => {
					this.ntfService.success(`New Patient Has Been Created`)
					if (fileId) {
						this.fileState.loadFiles(fileId)
					}
					this.fileState.patchState({
						newFileId: null,
						pccCurrentPatientImage: null
					})
				}, 1000)
			}),
			ignoreElements()
		)
	}

	protected setPaginationSetting(type?: string): Observable<void> {
		const state = this.ctx.getState()
		const patients = cloneDeep(Object.values(state.entities))
		if (type && type === 'haveAlert') {
			let patientsHaveAlert: PatientDTO[] = []
			const ids: string[] | any = this.alertState.entitiesArray
				.sort((a, b) => alertSeverityComparator(a.severity, b.severity))
				.map((alert: AlertDTO) => alert.patient?.id)
				.filter((i) => i)
			patients.forEach((patient) => {
				const idx = ids.findIndex((id: string) => id === patient.id)
				if (idx === -1) return
				patientsHaveAlert = [...patientsHaveAlert, patient]
			})
			this.setPatientItemsSetting(
				patientsHaveAlert.slice(state.pageSize - PAGE_SIZE, state.pageSize)
			)
		} else {
			this.setPatientItemsSetting(
				patients.slice(state.pageSize - PAGE_SIZE, state.pageSize)
			)
		}
		return of()
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}

	private setPatientItemsSetting(patients: BackendPatientDTO[] | PatientDTO[]) {
		// const fileIds = patients
		// 	.map((patient: BackendPatientDTO | PatientDTO) => patient.avatar?.id)
		// 	.filter((i: string | any) => i)
		this.ctx.patchState({ isLoading: false })
		// this.fileState.loadEntities(fileIds)
	}
}
