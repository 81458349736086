import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core'
import {
	delay,
	fromEvent,
	interval,
	merge,
	Observable,
	of,
	Subscription,
	switchMap,
	tap,
	timer
} from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import LogRocket from 'logrocket'
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer'
import { Select } from '@ngxs/store'
import { AlertState } from '../../../../../store/alert/alert.state'
import { Router } from '@angular/router'
import { AlertDTO, AlertSeverity } from '../../../../../shared/model/alert'
import { EntityDictionary } from '@angular-ru/cdk/entity'
import { DepartmentState } from '../../../../../store/department/department.state'
import { RootStore } from '../../../../../store/root-store'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { PreferenceState } from '../../../../../store/preference/preference.state'
import { UserState } from '../../../../../store/user/user.state'
import { UserInterface } from '../../../../../shared/model/user.model'
import { DepartmentDTO } from '../../../../../shared/model/permission.model'
import { environment } from '../../../../../environments/environment'
import { PccState } from '../../../../../store/pcc/pcc.state'
import { ScreenBlockedStatus } from '../../../../../shared/model/preference.model'
import { DepartmentType } from 'projects/aiomed/src/shared/model/departments.model'

@UntilDestroy()
@Component({
	selector: 'aiomed-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit, OnDestroy {
	@Input() isCollapseMenu: boolean | null
	@Output('collapseMenuEmitter') collapseMenu = new EventEmitter()
	@Select(UserState.currentUser)
	user$: Observable<UserInterface | null>
	@Select(RootStore.devicesAlertsCount)
	devicesAlertsCount$: Observable<number>
	@Select(RootStore.patientsAlertsCount)
	patientsAlertsCount$: Observable<number>
	@Select(AlertState.alerts)
	alerts$: Observable<EntityDictionary<string, AlertDTO>>
	@Select(PreferenceState.preferenceSoundNotifications)
	preferenceSoundNotifications$: Observable<null | boolean>
	@Select(DepartmentState.departments)
	department$: Observable<DepartmentDTO[]>
	@Select(DepartmentState.withoutDepartment)
	withoutDepartment$: Observable<boolean>
	@Select(PreferenceState.language)
	language$: Observable<string>
	@Select(DepartmentState.departmentType)
	departmentType$: Observable<DepartmentType>;
	placement: NzDrawerPlacement = 'left'
	request: XMLHttpRequest = new XMLHttpRequest()
	context: AudioContext = new AudioContext()
	source: AudioBufferSourceNode
	timerSub: Subscription
	confirmModal?: NzModalRef
	source$: Observable<Event>
	popoverVisible: boolean = false
	reportIssueModalVisible: boolean = false
	public readonly DepartmentType = DepartmentType;

	sourceClick$: Observable<Event> = fromEvent(window, 'click');
	sourceTouchmove$: Observable<Event> = fromEvent(window, 'mousemove');

	constructor(
		private preferenceState: PreferenceState,
		public router: Router,
		public departmentState: DepartmentState,
		private pccState: PccState,
		private ngZone: NgZone,
	) { }

	ngOnInit(): void {
		this.initializeListeners()
		this.setAudioContext()
		this.localStoreListener()
		this.ngZone.runOutsideAngular(() => {
			this.subscribeOnWindowsClickAndTouchmove().subscribe();
		})
	}

	subscribeOnWindowsClickAndTouchmove() {
		return merge(
			this.sourceClick$,
			this.sourceTouchmove$
		).pipe(
			switchMap((state) => {
				if (this.preferenceState.getState().screenBlockedStatus === ScreenBlockedStatus.Blocked) {
					this.preferenceState.setScreenBlockedStatus('');
				}
				return state ? timer(5 * 60000) : of(null);
			}),
			tap(() => this.preferenceState.setScreenBlockedStatus(ScreenBlockedStatus.Blocked)),
			untilDestroyed(this)
		)
	}

	localStoreListener() {
		this.source$ = fromEvent(window, 'storage')
		this.source$
			.pipe(delay(500), untilDestroyed(this))
			.subscribe((data: Event) => {
				if (data instanceof StorageEvent) {
					if (
						data.key === null ||
						(data.key === 'auth.accessJwt' &&
							data.newValue === null &&
							data.oldValue != null)
					) {
						this.systemLogOut()
					}
				}
			})
	}

	initializeListeners(): void {
		this.preferenceSoundNotifications$
			.pipe(untilDestroyed(this))
			.subscribe((data) => {
				if (!data || !environment.production) return
				this.setMediaSourceWhenHaveCriticalAlert()
			})
		this.withoutDepartment$.pipe(untilDestroyed(this)).subscribe((i) => {
			if (!i) return
			this.handlerClickLogout()
		})
	}

	setMediaSourceWhenHaveCriticalAlert(): void {
		this.alerts$
			.pipe(untilDestroyed(this))
			.subscribe((alerts: EntityDictionary<string, AlertDTO>) => {
				const alertsDTO: AlertDTO[] = Object.values(alerts)
				const alert: AlertDTO | undefined = alertsDTO.find(
					(alert) => alert.severity === AlertSeverity.Critical
				)
				if (!alert || !alertsDTO.length) {
					if (this.timerSub) {
						this.timerSub.unsubscribe()
						this.request.abort()
					}
					return
				}
				navigator.mediaDevices
					.getUserMedia({ audio: true })
					.then((stream) => {
						this.timerSub = timer(0, 50000)
							.pipe(untilDestroyed(this))
							.subscribe(() => {
								this.source.start(0)
								setTimeout(() => {
									this.source.stop(0)
									this.request.abort()
									this.setAudioContext()
								}, 2000)
							})
					})
					.catch((error) => console.log(error))
			})
	}

	setAudioContext() {
		this.source = this.context.createBufferSource()
		this.source.connect(this.context.destination)
		this.request.open('GET', 'assets/door_bell.aac', true)
		this.request.timeout = 10000
		this.request.responseType = 'arraybuffer'
		this.request.onload = () => {
			this.context.decodeAudioData(
				this.request.response,
				(response) => {
					this.source.buffer = response
					this.source.loop = true
				},
				function () {
					console.error('The request failed.')
				}
			)
		}
		this.request.send()
	}

	close(): void {
		this.collapseMenu.emit(false)
	}

	handlerChangeDepartment(departmentId: string) {
		this.preferenceState.setPreferenceDepartmentId(departmentId)
	}

	handlerClickLogout() {
		this.systemLogOut()
		this.pccState.pccLogout()
	}

	systemLogOut() {
		this.departmentState.logout()
	}

	handlerChangeLanguageEmitter($event: string) {
		this.preferenceState.setLanguage($event)
	}

	openReportIssueModal() {
		this.reportIssueModalVisible = true
		this.popoverVisible = false
	}

	closeReportIssueModal() {
		this.reportIssueModalVisible = false
	}

	reportIssueToLogRocket(event: string) {
		this.closeReportIssueModal()
		LogRocket.captureMessage(event, {
			tags: {
				userReport: 'user-report'
			}
		})
	}

	ngOnDestroy(): void {
		this.preferenceState.setScreenBlockedStatus('');
	}
}
