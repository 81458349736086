import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { BaseControlValueAccessor } from '../../../../../../core/directives/control-value-accessor'
import { abstractControlProvider } from '../../../../../../core/helpers/abstract-control-provider'

@Component({
  selector: 'aiomed-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [abstractControlProvider(InputComponent)],
})
export class InputComponent extends BaseControlValueAccessor implements OnInit {
  @Input() typeStyle: 'def' | 'search' = 'def'
  @Input() type: 'password' | 'text' = 'text'
  @Input() beforeIcon: string
  @Input() afterIcon: string
  @Input() isError = false

  @Output('ngModelChange') update = new EventEmitter()

  ngOnInit(): void {}
}
