import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core'
import { Observable, tap } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Select } from '@ngxs/store'
import { UserState } from '../../../../../store/user/user.state'
import { UserInterface } from '../../../../../shared/model/user.model'
import { NotificationState } from '../../../../../store/notification/notification.state'
import { PatientState } from '../../../../../store/patient/patient.state'
import { PatientDTO } from '../../../../../shared/model/patient'
import { Router } from '@angular/router'
import { DepartmentDTO } from '../../../../../shared/model/permission.model'
import { DepartmentState } from '../../../../../store/department/department.state'

@UntilDestroy()
@Component({
	selector: 'aiomed-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
	@Input() type:
		| 'patients'
		| 'alerts'
		| 'devices'
		| 'patient'
		| 'all-patients' = 'patients'
	@Input() entitiesHaveAlertLength: number | any
	@Input() followEntitiesLength: number | any
	@Input() totalCount: number | any
	@Input() totalDisconnect: number | any
	@Input() entitiesMuteLength: number | any
	@Output('searchPatientEmitter') searchPatient = new EventEmitter()
	@Select(UserState.currentUser)
	user$: Observable<UserInterface | null>
	@Select(PatientState.searchPatient)
	searchPatient$: Observable<PatientDTO[]>
	@Select(DepartmentState.allDepartments)
	departments$: Observable<DepartmentDTO[]>
	@Select(DepartmentState.currentDepartment)
	currentDepartment$: Observable<DepartmentDTO>
	textSearch: string = ''
	selectedValue: string | null;

	constructor(
		private userState: UserState,
		private cdRef: ChangeDetectorRef,
		private notificationState: NotificationState,
		private departmentState: DepartmentState,
		public router: Router,
		) {
		this.subscribeCurrentDepartmentChange();
	}

	ngOnInit(): void {
		this.initializeListeners()
	}

	initializeListeners(): void {
		this.notificationState.receiveMessage()
	}

	public departmentChange(selectedDepartmentId: string): void {
		const user = this.userState.snapshot.user;
		const departments = this.departmentState.snapshot.allDepartments;
		const department = this.departmentState.snapshot.allDepartments.find(d => d.id === selectedDepartmentId);
		if (!user || !department) return;

		let currentPatientDepartments: DepartmentDTO[] = [];
		departments.forEach((d) => {
			if (d.onDutyAssistantIds?.length && d.onDutyAssistantIds.find((id) => id === user.id)) {
				currentPatientDepartments = [...currentPatientDepartments, d];
			}
		});


		const isShiftManager = department.shiftManager?.id === user.id;
		if (isShiftManager) {
			this.departmentState.updateShiftManagerDepartment(
				department.id,
				{
					shiftManager: {
						id: user?.id!,
						name: `${user?.name.lastName} ${user?.name.firstName}`,
						templateId: (user as any)._template.id
					}
				},
				false,
				department
			)?.pipe(
				tap(() => this.departmentState.departmentChange(department))
			)
			
		} else {
			this.departmentState.departmentChange(department)
		}
	}

	private subscribeCurrentDepartmentChange(): void {
		this.currentDepartment$.pipe(
			tap(v => this.selectedValue = v?.id || null),
			untilDestroyed(this)
		).subscribe();
	}
}
