import moment from 'moment'

export const checkShiftTime = {
	morning_shift: {
		min: 7,
		max: 15
	},
	morning_shift_name: 'Day Shift',
	day_shift: {
		min: 15,
		max: 23
	},
	day_shift_name: 'Evening Shift',
	night_shift: {
		min: 23,
		max: 7
	},
	night_shift_name: 'Night Shift',
	getShiftTime: function (shift: string) {
		if (shift === 'morning_shift') {
			return this.morning_shift
		} else if (shift === 'day_shift') {
			return this.day_shift
		} else {
			return this.night_shift
		}
	},
	getShiftByTime: function (date: string) {
		if (
			moment(date).get('hours') >= this.morning_shift.min &&
			moment(date).get('hours') < this.morning_shift.max
		) {
			return this.morning_shift_name
		} else if (
			moment(date).get('hours') >= this.day_shift.min &&
			moment(date).get('hours') < this.day_shift.max
		) {
			return this.day_shift_name
		} else if (
			moment(date).get('hours') >= this.night_shift.min ||
			moment(date).get('hours') < this.night_shift.max
		) {
			return this.night_shift_name
		} else {
			return ''
		}
	}
}
