import { Component, Input } from '@angular/core'

@Component({
  selector: 'aiomed-btn',
  templateUrl: './btn.component.html',
  styleUrls: ['./btn.component.scss'],
})
export class BtnComponent {
  @Input() type: 'button' | 'submit' = 'button'
  @Input() typeStyle: 'def' | 'menu' | 'auth' = 'def'
  @Input() icon: string
  @Input() disabled: boolean
  @Input() loading: boolean
  @Input() isRed: boolean = false
}
