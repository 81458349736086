import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	ViewChild
} from '@angular/core'
import { PatientInterface } from '../../../../../shared/model/patient'
import {
	getUserLocale,
	transformCelsiusToFahrenhei,
	transformFahrenheiToCelsius
} from '../../../../../core/helpers/functions'
import { ObservationFields } from '../../../../../shared/model/patient-observation'
import {
	PatientExportDTO,
	PccUpdateEmrMeasurement
} from '../../../../../shared/model/pcc.model'
import { ManualVitalsInterface } from '../../../../../shared/model/report.model'
import { ReportState } from '../../../../../store/report/report.state'
import { PccState } from '../../../../../store/pcc/pcc.state'
import { Select } from '@ngxs/store'
import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { isNotNil } from '@angular-ru/cdk/utils'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { cloneDeep, orderBy } from 'lodash-es'
import { DepartmentDTO } from '../../../../../shared/model/permission.model'

@UntilDestroy()
@Component({
	selector: 'aiomed-report-review-abnormal-vitals',
	templateUrl: './report-review-abnormal-vitals.component.html',
	styleUrls: ['./report-review-abnormal-vitals.component.scss']
})
export class ReportReviewAbnormalVitalsComponent implements OnInit, OnChanges {
	@Input() isVisible: boolean = false
	@Input() reportPatients: PatientInterface[]
	@Input() patientsExports: PatientExportDTO[]
	@Input() patientLength: number
	@Input() currentDepartment: DepartmentDTO | null
	@Input() activeFilter: {
		active: boolean
		text: string
		type: string
	}
	@Select(PccState.pccDetailCurrentLoading)
	pccDetailCurrentLoading$: Observable<{ id: string; status: boolean } | null>
	@Output() closeModalEmitter = new EventEmitter()
	@ViewChild('report', { static: false }) report!: ElementRef
	currentReportPatients: PatientInterface[] = []
	oldPatientData: PatientInterface | null = null
	currentEditPatient: PatientInterface | null = null
	editedPatients: PatientInterface[] = []
	diastolicError: boolean = false
	systolicError: boolean = false
	heartRateError: boolean = false
	respirationRateError: boolean = false
	spO2Error: boolean = false
	bodyTemperatureError: boolean = false
	tempMethodSelect = 'Forehead (non-contact)'
	spMethodSelect = 'Room Air'
	hrMethodSelect = 'regular'
	bpMethodSelect = 'Sitting r/arm'
	isModalVisible: boolean = false
	preActiveItemType: string | null
	pccDetailCurrentLoading: { id: string; status: boolean } | null = null
	protected readonly ObservationFields = ObservationFields

	constructor(private reportState: ReportState, private pccState: PccState) {}

	private static toTransformFahrenheiToCelsius(value: number) {
		return getUserLocale() === 'en-US'
			? transformFahrenheiToCelsius(value)
			: value
	}

	private static toTransformCelsiusToFahrenhei(value: number) {
		return getUserLocale() === 'en-US'
			? transformCelsiusToFahrenhei(value)
			: Number(value)
	}

	ngOnInit(): void {
		this.pccDetailCurrentLoading$
			.pipe(filter(isNotNil), untilDestroyed(this))
			.subscribe((data) => {
				this.pccDetailCurrentLoading = data
				if (!data.status) {
					this.editedPatients = this.editedPatients.filter(
						(patient) => patient.id !== data.id
					)
				}
			})
	}

	trackByMethod(index: number, el: any): string {
		return el.id
	}

	ngOnChanges(): void {
		if (!this.reportPatients) return
		this.currentReportPatients = [...this.reportPatients]
		this.currentReportPatients.forEach((p) => (p.checked = false))
		if (this.editedPatients.length) {
			this.editedPatients.forEach((patient) => {
				this.currentReportPatients = this.currentReportPatients.filter(
					(p) => p.id !== patient.id
				)
				this.currentReportPatients = orderBy(
					[...this.currentReportPatients, patient],
					'room',
					'asc'
				)
			})
		}
		if (this.oldPatientData) {
			const idx = this.currentReportPatients.findIndex(
				(p) => p.id === this.oldPatientData?.id
			)
			this.currentReportPatients[idx].checked = true
		}
	}

	setPatientExportSetting(patient: PatientInterface) {
		const patientExport: PatientExportDTO | undefined =
			this.patientsExports.find(
				(e: PatientExportDTO) => e.observedPatient === patient.id
			)
		if (!patientExport) return
		const {
			bodyTemperatureMethod,
			spo2Method,
			heartRateMethod,
			bloodPressureMethod
		} = patientExport
		if (bodyTemperatureMethod) {
			this.tempMethodSelect = bodyTemperatureMethod
		}
		if (spo2Method) {
			this.spMethodSelect = spo2Method
		}
		if (heartRateMethod) {
			this.hrMethodSelect = heartRateMethod
		}
		if (bloodPressureMethod) {
			this.bpMethodSelect = bloodPressureMethod
		}
	}

	protectionNumbers($event: KeyboardEvent) {
		if (
			[
				'0',
				'1',
				'2',
				'3',
				'4',
				'5',
				'6',
				'7',
				'8',
				'9',
				'0',
				'.',
				'Backspace'
			].includes($event.key)
		) {
		} else {
			$event.preventDefault()
		}
	}

	handlerEditPatientClick(patient: PatientInterface) {
		if (this.currentEditPatient) {
			const idx = this.currentReportPatients.findIndex(
				(p) => p.id === this.currentEditPatient?.id
			)
			this.currentReportPatients[idx].checked = false
		}
		this.oldPatientData = { ...patient }
		patient.checked = true
		this.currentEditPatient = { ...patient }
		this.currentEditPatient.reports = {
			observedPatient: patient.id,
			measurements: {
				bodyTemperature: {
					checked: false,
					isManual: !!patient.reports?.measurements?.bodyTemperature?.isManual,
					timestamp: new Date().toISOString(),
					// @ts-ignore
					value:
						patient.reports?.measurements?.bodyTemperature &&
						patient.reports?.measurements?.bodyTemperature.value
							? Math.floor(
									ReportReviewAbnormalVitalsComponent.toTransformCelsiusToFahrenhei(
										patient.reports.measurements.bodyTemperature.value
									) * 10
							  ) / 10
							: null
				},
				respirationRate: {
					checked: false,
					isManual: !!patient.reports?.measurements?.respirationRate?.isManual,
					timestamp: new Date().toISOString(),
					// @ts-ignore
					value: patient.reports?.measurements?.respirationRate
						? patient.reports.measurements.respirationRate.value
						: null
				},
				systolicPressure: {
					checked: false,
					isManual: !!patient.reports?.measurements?.systolicPressure?.isManual,
					timestamp: new Date().toISOString(),
					// @ts-ignore
					value: patient.reports?.measurements?.systolicPressure
						? patient.reports.measurements.systolicPressure.value
						: null
				},
				diastolicPressure: {
					checked: false,
					isManual:
						!!patient.reports?.measurements?.diastolicPressure?.isManual,
					timestamp: new Date().toISOString(),
					// @ts-ignore
					value: patient.reports?.measurements?.diastolicPressure
						? patient.reports.measurements.diastolicPressure.value
						: null
				},
				spo2: {
					checked: false,
					isManual: !!patient.reports?.measurements?.spo2?.isManual,
					timestamp: new Date().toISOString(),
					// @ts-ignore
					value: patient.reports?.measurements?.spo2
						? patient.reports.measurements.spo2.value
						: null
				},
				heartRate: {
					checked: false,
					isManual: !!patient.reports?.measurements?.heartRate?.isManual,
					timestamp: new Date().toISOString(),
					// @ts-ignore
					value: patient.reports?.measurements?.heartRate
						? patient.reports.measurements.heartRate.value
						: null
				}
			}
		}
	}

	handlerEmptyLineClick(vital: any) {
		if (
			this.currentEditPatient &&
			this.currentEditPatient.reports?.measurements &&
			(this.currentEditPatient as any).reports?.measurements[vital]
		) {
			if (
				this.preActiveItemType &&
				this.currentEditPatient.reports.measurements
			) {
				this.vitalChangeDetectionSetting(this.preActiveItemType)
			}
			this.preActiveItemType =
				vital === 'systolicPressure' || vital === 'systolicPressure'
					? 'pressure'
					: vital
			// @ts-ignore
			this.currentEditPatient.reports.measurements[vital].checked = true
		}
	}

	vitalChangeDetectionSetting(vital: string, $event?: number, type?: string) {
		let value
		if (type || vital === 'pressure') {
			value = !$event ? null : $event
		} else {
			value = (this.currentEditPatient as any).reports.measurements[vital].value
		}
		const systolicValue =
			type && type === 'systolicPressure'
				? $event
				: (this.currentEditPatient as any).reports.measurements.systolicPressure
						.value
		const diastolicValue =
			type && type === 'diastolicPressure'
				? $event
				: (this.currentEditPatient as any).reports.measurements
						.diastolicPressure.value
		if (
			(vital === 'pressure' &&
				!this.oldPatientData?.reports?.measurements &&
				!this.oldPatientData?.reports?.measurements.systolicPressure &&
				!this.oldPatientData?.reports?.measurements.diastolicPressure &&
				this.currentEditPatient?.reports?.measurements &&
				systolicValue === null &&
				diastolicValue === null) ||
			(vital === 'pressure' &&
				this.oldPatientData?.reports?.measurements &&
				!this.oldPatientData?.reports?.measurements.systolicPressure &&
				!this.oldPatientData?.reports?.measurements.diastolicPressure &&
				this.currentEditPatient?.reports?.measurements &&
				systolicValue === null &&
				diastolicValue === null) ||
			(vital === 'pressure' &&
				this.oldPatientData?.reports?.measurements &&
				this.oldPatientData?.reports?.measurements.systolicPressure &&
				this.oldPatientData?.reports?.measurements.diastolicPressure &&
				this.currentEditPatient?.reports?.measurements &&
				this.oldPatientData?.reports?.measurements.diastolicPressure.value ===
					diastolicValue &&
				this.oldPatientData?.reports?.measurements.systolicPressure.value ===
					systolicValue)
		) {
			if (this.currentEditPatient.reports.measurements.systolicPressure) {
				this.currentEditPatient.reports.measurements.systolicPressure.checked =
					false
			}
			if (this.currentEditPatient.reports.measurements.diastolicPressure) {
				this.currentEditPatient.reports.measurements.diastolicPressure.checked =
					false
			}
			this.diastolicError = false
			this.systolicError = false
		} else if (
			(vital !== 'pressure' &&
				!this.oldPatientData?.reports?.measurements &&
				!(this.oldPatientData as any)?.reports?.measurements[vital] &&
				this.currentEditPatient?.reports?.measurements &&
				value === null) ||
			(vital !== 'pressure' &&
				this.oldPatientData?.reports?.measurements &&
				(this.oldPatientData as any).reports.measurements[vital] &&
				this.currentEditPatient?.reports?.measurements &&
				(this.oldPatientData as any).reports.measurements[vital].value ===
					value) ||
			(vital !== 'pressure' &&
				this.oldPatientData?.reports?.measurements &&
				!(this.oldPatientData as any)?.reports?.measurements[vital] &&
				this.currentEditPatient?.reports?.measurements &&
				value === null)
		) {
			;(this.currentEditPatient as any).reports.measurements[vital].checked =
				false
			if (vital === 'spo2') {
				this.spO2Error = false
			} else if (vital === 'bodyTemperature') {
				this.bodyTemperatureError = false
			} else if (vital === 'respirationRate') {
				this.respirationRateError = false
			} else if (vital === 'heartRate') {
				this.heartRateError = false
			}
		}
	}

	handlerInputChange($event: number, type: string) {
		if (type === ObservationFields.SystolicPressure) {
			this.systolicError =
				(type === ObservationFields.SystolicPressure && $event > 220) ||
				(type === ObservationFields.SystolicPressure && $event < 70)
			this.diastolicError =
				(this.currentEditPatient as any).reports?.measurements.diastolicPressure
					?.value > 120 ||
				(this.currentEditPatient as any).reports?.measurements.diastolicPressure
					?.value < 40
		}
		if (type === ObservationFields.DiastolicPressure) {
			this.diastolicError =
				(type === ObservationFields.DiastolicPressure && $event > 120) ||
				(type === ObservationFields.DiastolicPressure && $event < 40)
			this.systolicError =
				(this.currentEditPatient as any).reports?.measurements?.systolicPressure
					?.value > 220 ||
				(this.currentEditPatient as any).reports?.measurements?.systolicPressure
					?.value < 70
		}
		if (type === ObservationFields.HeartRate) {
			this.heartRateError =
				(type === ObservationFields.HeartRate && $event > 150) ||
				(type === ObservationFields.HeartRate && $event < 40)
		}
		if (type === ObservationFields.SpO2) {
			this.spO2Error =
				(type === ObservationFields.SpO2 && $event > 100) ||
				(type === ObservationFields.SpO2 && $event < 80)
		}
		if (type === ObservationFields.RespirationRate) {
			this.respirationRateError =
				(type === ObservationFields.RespirationRate && $event > 35) ||
				(type === ObservationFields.RespirationRate && $event < 5)
		}
		if (type === ObservationFields.BodyTemperature) {
			getUserLocale() === 'en-US'
				? (this.bodyTemperatureError =
						(type === ObservationFields.BodyTemperature && $event > 106) ||
						(type === ObservationFields.BodyTemperature && $event < 93))
				: (this.bodyTemperatureError =
						(type === ObservationFields.BodyTemperature && $event > 41) ||
						(type === ObservationFields.BodyTemperature && $event < 34))
		}
		if (
			this.preActiveItemType &&
			this.currentEditPatient?.reports?.measurements
		) {
			this.vitalChangeDetectionSetting(this.preActiveItemType, $event, type)
		}
	}

	handlerCancelEditClick(patient: PatientInterface) {
		this.oldPatientData = null
		this.currentEditPatient = null
		const idx = this.currentReportPatients.findIndex(
			(p) => p.id === patient?.id
		)
		this.currentReportPatients[idx].checked = false
		this.diastolicError = false
		this.systolicError = false
		this.heartRateError = false
		this.respirationRateError = false
		this.spO2Error = false
		this.bodyTemperatureError = false
	}

	handlerConfirmEditClick(patient: PatientInterface) {
		if (
			this.currentEditPatient &&
			this.currentEditPatient?.reports?.measurements &&
			Object.values(this.currentEditPatient.reports.measurements).filter(
				(p) => p.value
			).length
		) {
			if (this.preActiveItemType) {
				this.vitalChangeDetectionSetting(this.preActiveItemType)
				this.preActiveItemType = null
			}
			this.setEditedPatientVitalsSetting()
			this.setEditedPatientsSetting()
			setTimeout(() => this.setManualReportSetting(patient), 0)
		} else {
			this.setDefaultData(patient)
		}
	}

	setManualReportSetting(patient: PatientInterface) {
		if (this.currentEditPatient) {
			this.reportState.setManualCNAReport(
				this.setNewVitalsData(this.currentEditPatient).manualData,
				this.activeFilter.type
			)
			const idx = this.currentReportPatients.findIndex(
				(p) => p.id === patient?.id
			)
			if (
				getUserLocale() === 'en-US' &&
				this.currentEditPatient?.reports?.measurements?.bodyTemperature &&
				this.currentEditPatient.reports.measurements.bodyTemperature.value
			) {
				this.currentEditPatient.reports.measurements.bodyTemperature.value =
					Number(
						ReportReviewAbnormalVitalsComponent.toTransformFahrenheiToCelsius(
							this.currentEditPatient.reports.measurements.bodyTemperature.value
						)
					)
			}
			this.currentReportPatients[idx] = { ...this.currentEditPatient }
		}
		this.setDefaultData(patient)
	}

	setDefaultData(patient: PatientInterface) {
		patient.checked = false
		this.diastolicError = false
		this.systolicError = false
		this.heartRateError = false
		this.respirationRateError = false
		this.spO2Error = false
		this.bodyTemperatureError = false
		this.currentEditPatient = null
		this.oldPatientData = null
	}

	setEditedPatientsSetting() {
		if (this.currentEditPatient) {
			const idx = this.editedPatients.findIndex(
				(p) => p.id === this.currentEditPatient?.id
			)
			if (idx !== -1) {
				this.editedPatients[idx] = { ...this.currentEditPatient }
			} else {
				this.editedPatients = [...this.editedPatients, this.currentEditPatient]
			}
		}
	}

	setEditedPatientVitalsSetting() {
		if (
			this.currentEditPatient &&
			this.currentEditPatient?.reports?.measurements
		) {
			this.currentEditPatient.checked = false
			Object.keys(this.currentEditPatient.reports.measurements).forEach((key) =>
				this.setIsManualVitalsSetting(key)
			)
			if (
				this.currentEditPatient.reports.measurements &&
				this.currentEditPatient.reports.measurements.diastolicPressure &&
				this.currentEditPatient.reports.measurements.diastolicPressure.value >
					0 &&
				this.currentEditPatient.reports.measurements.diastolicPressure?.isManual
			) {
				if (
					this.currentEditPatient.reports.measurements &&
					this.currentEditPatient.reports.measurements.systolicPressure
				) {
					this.currentEditPatient.reports.measurements.systolicPressure.isManual =
						true
				}
			}
			if (
				this.currentEditPatient.reports.measurements &&
				this.currentEditPatient.reports.measurements.systolicPressure &&
				this.currentEditPatient.reports.measurements.systolicPressure.value >
					0 &&
				this.currentEditPatient.reports.measurements.systolicPressure?.isManual
			) {
				if (
					this.currentEditPatient.reports.measurements &&
					this.currentEditPatient.reports.measurements.diastolicPressure
				) {
					this.currentEditPatient.reports.measurements.diastolicPressure.isManual =
						true
				}
			}
		}
	}

	handlerReportToEmrClick(patient: PatientInterface) {
		if (
			!patient?.reports?.measurements ||
			!Object.values(patient?.reports?.measurements).filter((p) => p.value)
				.length ||
			patient.checked
		) {
			return
		}
		const p = cloneDeep(patient)
		if (
			p?.reports?.measurements?.bodyTemperature &&
			p?.reports?.measurements?.bodyTemperature?.value
		) {
			p.reports.measurements.bodyTemperature.value = Number(
				ReportReviewAbnormalVitalsComponent.toTransformCelsiusToFahrenhei(
					p.reports.measurements.bodyTemperature.value
				)
			)
		}
		this.setPatientExportSetting(p)
		this.pccState.updatePccEmrMeasurement(this.setNewVitalsData(p).pccData)
		if (
			p?.reports?.measurements?.bodyTemperature &&
			p?.reports?.measurements?.bodyTemperature?.value
		) {
			p.reports.measurements.bodyTemperature.value = Number(
				ReportReviewAbnormalVitalsComponent.toTransformFahrenheiToCelsius(
					p.reports.measurements.bodyTemperature.value
				)
			)
		}
	}

	setNewVitalsData(patient: PatientInterface) {
		const manualData: ManualVitalsInterface = {
			observedPatient: patient.id,
			emrId: patient.emrid
		}
		const pccData: PccUpdateEmrMeasurement = {
			observedPatient: patient.id,
			emrId: patient.emrid
		}
		if (
			patient.reports?.measurements?.bodyTemperature &&
			patient.reports?.measurements?.bodyTemperature.value
		) {
			if (patient.reports?.measurements?.bodyTemperature?.isManual) {
				manualData.bodyTemperature = Number(
					ReportReviewAbnormalVitalsComponent.toTransformFahrenheiToCelsius(
						patient.reports?.measurements?.bodyTemperature.value
					)
				)
			}
			pccData.bodyTemperature = Number(
				ReportReviewAbnormalVitalsComponent.toTransformFahrenheiToCelsius(
					patient.reports?.measurements?.bodyTemperature.value
				)
			)
		}
		if (
			patient.reports?.measurements?.spo2 &&
			patient.reports?.measurements?.spo2.value
		) {
			if (patient.reports?.measurements?.spo2?.isManual) {
				manualData.spo2 = patient.reports?.measurements?.spo2.value
			}
			pccData.spo2 = patient.reports?.measurements?.spo2.value
		}
		if (
			patient.reports?.measurements?.diastolicPressure &&
			patient.reports?.measurements?.diastolicPressure.value
		) {
			if (patient.reports?.measurements?.diastolicPressure?.isManual) {
				manualData.diastolicPressure =
					patient.reports?.measurements?.diastolicPressure.value
			}
			pccData.diastolicPressure =
				patient.reports?.measurements?.diastolicPressure.value
		}
		if (
			patient.reports?.measurements?.heartRate &&
			patient.reports?.measurements?.heartRate.value
		) {
			if (patient.reports?.measurements?.heartRate?.isManual) {
				manualData.heartRate = patient.reports?.measurements?.heartRate.value
			}
			pccData.heartRate = patient.reports?.measurements?.heartRate.value
		}
		if (
			patient.reports?.measurements?.respirationRate &&
			patient.reports?.measurements?.respirationRate.value
		) {
			if (patient.reports?.measurements?.respirationRate?.isManual) {
				manualData.respirationRate =
					patient.reports?.measurements?.respirationRate.value
			}
			pccData.respirationRate =
				patient.reports?.measurements?.respirationRate.value
		}
		if (
			patient.reports?.measurements?.systolicPressure &&
			patient.reports?.measurements?.systolicPressure.value
		) {
			if (patient.reports?.measurements?.systolicPressure?.isManual) {
				manualData.systolicPressure =
					patient.reports?.measurements?.systolicPressure.value
			}
			pccData.systolicPressure =
				patient.reports?.measurements?.systolicPressure.value
		}
		pccData.bloodPressureMethod = this.bpMethodSelect
		pccData.heartRateMethod = this.hrMethodSelect
		pccData.spo2Method = this.spMethodSelect
		pccData.bodyTemperatureMethod = this.tempMethodSelect
		return {
			manualData,
			pccData
		}
	}

	handlerAlertRuleUnsavedChangesEmitter($event: boolean) {
		this.isModalVisible = false
		if (!$event) return
		this.closeModalEmitter.emit(false)
	}

	private setIsManualVitalsSetting(variable: any) {
		if (
			(this.currentEditPatient as any).reports?.measurements?.[variable] &&
			(this.currentEditPatient as any).reports?.measurements?.[variable]
		) {
			if (
				(this.currentEditPatient as any)?.reports?.measurements[variable]
					.value > 0 &&
				(this.currentEditPatient as any)?.reports?.measurements[variable]
					.value ===
					(this.oldPatientData as any)?.reports?.measurements[variable]
						?.value &&
				(this.oldPatientData as any)?.reports?.measurements[variable]?.isManual
			) {
				;(this.currentEditPatient as any).reports.measurements[
					variable
				].isManual = true
				;(this.currentEditPatient as any).reports.measurements[
					variable
				].checked = false
			} else {
				;(this.currentEditPatient as any).reports.measurements[
					variable
				].isManual =
					(this.currentEditPatient as any)?.reports?.measurements[variable]
						.value > 0 &&
					(this.currentEditPatient as any)?.reports?.measurements[variable]
						.value !==
						(this.oldPatientData as any)?.reports?.measurements[variable]?.value
				;(this.currentEditPatient as any).reports.measurements[
					variable
				].checked = false
			}
		} else if (
			!(this.oldPatientData as any).reports?.measurements?.[variable] &&
			(this.currentEditPatient as any).reports?.measurements[variable].value > 0
		) {
			;(this.currentEditPatient as any).reports.measurements[
				variable
			].isManual = true
			;(this.currentEditPatient as any).reports.measurements[variable].checked =
				false
		}
	}
}
