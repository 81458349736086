import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash-es';
import { InsightDTO } from '../../../shared/model/insight.model';
import { AlertSubject } from '../../../shared/model/alert';

@Pipe({
  name: 'extractSpecificInsightBySubject'
})
export class ExtractSpecificInsightBySubjectPipe implements PipeTransform {

  transform(insights: (InsightDTO & { insightSubject: AlertSubject, lastModifiedTime: Date | string })[], subject: AlertSubject): InsightDTO | null {
    if (!insights) return null;
    const copyInsights = [...insights].filter(insight => insight.insightSubject === subject);
    if (!copyInsights || !copyInsights.length) return null;
    return orderBy(
      copyInsights,
      'lastModifiedTime',
      'asc')
    [0];
  }
}
