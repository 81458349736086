import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'dayFullName'
})
export class DayFullNamePipe implements PipeTransform {
	transform(value: any, ...args: unknown[]): string {
		if (value === 'sun') {
			return 'Sunday'
		} else if (value === 'mon') {
			return 'Monday'
		} else if (value === 'tue') {
			return 'Tuesday'
		} else if (value === 'wed') {
			return 'Wednesday'
		} else if (value === 'thu') {
			return 'Thursday'
		} else if (value === 'fri') {
			return 'Friday'
		} else {
			return 'Saturday'
		}
	}
}
