import { Pipe, PipeTransform } from '@angular/core'
import { AlertStatus } from '../../../shared/model/alert'

@Pipe({
	name: 'alertDelta'
})
export class AlertDeltaPipe implements PipeTransform {
	AlertStatus = AlertStatus

	transform(data: string, ...args: unknown[]): string {
		if (!data) return ''
		const endTime = new Date(data)
		// @ts-ignore
		const delta = Math.floor((endTime - new Date()) / 1000)
		let minutes = Math.floor(delta / 60) % 60
		let hours = Math.floor(delta / 3600) % 24
		return `${hours}:${minutes}`
	}
}
