import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import {
	EMPTY,
	ignoreElements,
	interval,
	Observable,
	of,
	Subject,
	take,
	takeUntil,
	tap,
	timer
} from 'rxjs'
import { FileDTO } from '../../shared/model/file'
import { BackendService } from '../../shared/services/backend.service'
import { StoreEventsService } from '../store-events.service'

export const fileFeatureName = 'file'

@StateRepository()
@State<CollatableEntityCollections<FileDTO>>({
	name: fileFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class FileState extends CollatableEntityCollectionsRepository<
	FileDTO,
	EntityCollation
> {
	unsubscribeLoadProcessFile$ = new Subject()
	private loadProcessFileIdx: string[] = []
	private newFileIdx: string[] = []
	private isFirstLoading: boolean = true

	constructor(
		private backendService: BackendService,
		private actions: Actions,
		private storeEvents: StoreEventsService
	) {
		super()
	}

	@Selector()
	public static files(
		state: CollatableEntityCollections<FileDTO>
	): EntityDictionary<string, FileDTO> {
		return state.entities
	}

	@Selector()
	public static pccPatientImage(
		state: CollatableEntityCollections<FileDTO>
	): Blob | null {
		return state.pccCurrentPatientImage
	}

	@Selector()
	public static createdFileId(
		state: CollatableEntityCollections<FileDTO>
	): string | null {
		return state.newFileId
	}

	public override ngxsOnInit() {
		this.storeEvents.logout$.pipe(
			tap(() => {
				this.unsubscribeLoadProcessFile$.next(null);
				this.unsubscribeLoadProcessFile$.complete();
				this.loadProcessFileIdx = [];
				this.newFileIdx = [];
				this.isFirstLoading = true;
				this.reset();
			})
		).subscribe();
	}

	@DataAction()
	public loadFiles(@Payload('id') id: string) {
		if (!id) return
		return this.backendService.getFile(id).pipe(
			tap((res) => {
				this.upsertOne(res)
			})
		)
	}

	@DataAction()
	public getPccPatientImage(@Payload('id') id: string) {
		if (!id) return
		return this.backendService
			.getPccImage(id)
			.pipe(
				tap((pccCurrentPatientImage) =>
					this.patchState({ pccCurrentPatientImage })
				)
			)
	}

	@DataAction()
	public createNewPatientFile(
		@Payload('data')
		data: {
			name: string
			mimeType: string
		},
		@Payload('file') pccFile: Blob
	) {
		return this.backendService.createFile(data).pipe(
			tap((file) => {
				// @ts-ignore
				this.updateCreatedFile(file.signedUrl, pccFile)
				this.patchState({ newFileId: file.id })
			})
		)
	}

	@DataAction()
	public updateCreatedFile(
		@Payload('url') url: string,
		@Payload('data') data: Blob
	) {
		return this.backendService.updateFileBody(url, data).pipe(ignoreElements())
	}

	@DataAction()
	public logout() {
		this.loadProcessFileIdx = []
		this.newFileIdx = []
		this.isFirstLoading = true
		this.patchState({
			newFileId: null,
			pccCurrentPatientImage: null
		})
	}

	protected setPaginationSetting(): Observable<any> {
		throw new Error('Method not implemented.')
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		if (!ids) return EMPTY
		const size: number = 5
		const idsToLoad = ids

		for (let i = 0; i < idsToLoad.length; i++) {
			const idx = this.loadProcessFileIdx.findIndex((f) => f === idsToLoad[i])
			if (idx === -1 && this.isFirstLoading) {
				this.loadProcessFileIdx.push(idsToLoad[i])
			} else if (idx === -1 && !this.isFirstLoading) {
				this.newFileIdx.push(idsToLoad[i])
			}
		}
		timer(1500).subscribe(() => {
			if (!this.isFirstLoading && !this.newFileIdx.length) {
				return
			} else if (!this.isFirstLoading && this.newFileIdx.length) {
				this.loadProcessFileIdx = [
					...this.loadProcessFileIdx,
					...this.newFileIdx
				]
				this.newFileIdx = []
			}
			this.isFirstLoading = false
			const loadProcessFileObserver = {
				next: async (x: number) => {
					this.loadFiles(this.loadProcessFileIdx[x])
				}
			}
			interval(1000)
				.pipe(
					takeUntil(this.unsubscribeLoadProcessFile$),
					take(this.loadProcessFileIdx.length)
				)
				.forEach(loadProcessFileObserver.next)
		})
		return of()
	}

	private upsertAllFiles(res: FileDTO[]) {
		this.upsertMany(res)
	}
}
