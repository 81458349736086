import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	HostListener,
	OnInit
} from '@angular/core'
import { combineLatest, delay, Observable, take } from 'rxjs'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Select } from '@ngxs/store'
import { ErrorState } from '../store/error/error.state'
import { AuthState } from '../store/auth/auth.state'
import { PreferenceState } from '../store/preference/preference.state'
import { TranslateService } from '@ngx-translate/core'
import moment from 'moment/moment'
import { registerLocaleData } from '@angular/common'
import { UserState } from '../store/user/user.state'
import { UserInterface } from '../shared/model/user.model'
import { Router } from '@angular/router'
import { AlertState } from '../store/alert/alert.state'
import { PatientState } from '../store/patient/patient.state'
import { DepartmentState } from '../store/department/department.state'
import { NotificationState } from '../store/notification/notification.state'
import { NzModalService } from 'ng-zorro-antd/modal'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MobileRoutes } from '../shared/model/mobile-routes'
import { isMobile } from '../core/helpers/functions'
import { routingAnimation } from '../shared/animations/routing-animation'
import { RootStore } from '../store/root-store'
import { ScreenBlockedStatus } from '../shared/model/preference.model'
import { DeviceDetectorService } from 'ngx-device-detector'
import { AnimationOptions } from 'ngx-lottie'

@UntilDestroy()
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [routingAnimation],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
	@Select(ErrorState.errorMessage)
	errorMessage$: Observable<string | null>

	@Select(AuthState.isAuthenticated)
	isAuthorized$: Observable<boolean>
	@Select(UserState.userProfile)
	userProfile$: Observable<any>
	@Select(PreferenceState.language)
	language$: Observable<string>
	@Select(UserState.currentUser)
	user$: Observable<UserInterface | null>
	@Select(RootStore.screenBlockedStatus)
	screenBlockedStatus$: Observable<string>
	@Select(PreferenceState.isMfaRequired)
	isMfaRequired$: Observable<boolean>
	@Select(PreferenceState.notification)
	notification$: Observable<boolean>
	@Select(PreferenceState.needChooseDepartment)
	needChooseDepartment$: Observable<boolean>
	isCollapseMenu: boolean = true
	ScreenBlockedStatus = ScreenBlockedStatus
	options: AnimationOptions = {
		path: '/assets/animation.json'
	}
	@HostBinding('@routingAnimation') private routing: any

	constructor(
		private errorState: ErrorState,
		private notification: NzNotificationService,
		private preferenceState: PreferenceState,
		private translate: TranslateService,
		private alertState: AlertState,
		private patientState: PatientState,
		private permissionState: DepartmentState,
		private notificationState: NotificationState,
		private modal: NzModalService,
		private router: Router,
		private authState: AuthState,
		private deviceService: DeviceDetectorService
	) {
		translate.setDefaultLang('en')
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.setWindowZoom()
	}

	ngOnInit(): void {
		this.initializeListeners()
		this.pccBroadcastChannelListener()

		document.addEventListener('visibilitychange', () => {
			if (document.visibilityState === 'visible') {
				this.authState.checkTokenStatus()
			}
		})
	}

	initializeListeners(): void {
		this.setMfaListenerSetting()
		this.errorMessageListenerSetting()
		this.languageListenerSetting()
		this.setNotificationPermissionSetting()
		this.setRouterConfigLogic()
		this.setWindowZoom()
		this.authState.checkTokenStatus()
	}

	setWindowZoom() {
		if (this.isMobile()) return
		// @ts-ignore
		// document.body.style.zoom = window.innerWidth / 1920
	}

	errorMessageListenerSetting() {
		this.errorMessage$
			.pipe(untilDestroyed(this))
			.subscribe((message: string | null) => {
				if (!message) return
				this.notification.create('error', 'Error', message)
				setTimeout(() => this.errorState.clear(), 5000)
			})
	}

	setMfaListenerSetting() {
		this.isMfaRequired$.pipe(untilDestroyed(this)).subscribe((mfaRequired) => {
			this.authState.patchState({
				mfaRequired
			})
		})
	}

	languageListenerSetting() {
		this.language$
			.pipe(untilDestroyed(this))
			.subscribe((data: string | null) => {
				if (data) {
					moment.locale(data)
					registerLocaleData(data)
					this.translate.use(data)
					return
				}
				this.preferenceState.setLanguage(
					// @ts-ignore
					navigator.language !== 'en' || navigator.language !== 'es'
						? 'en'
						: navigator.language
				)
			})
	}

	setNotificationPermissionSetting() {
		combineLatest(this.userProfile$, this.notification$)
			.pipe(delay(2000), untilDestroyed(this), take(2))
			.subscribe((data) => {
				let [user, notification] = [data[0], data[1]]
				if (!user) return
				if (typeof notification === 'object') {
					this.modal.confirm({
						nzTitle: 'Do you want to receive notification for alerts?',
						nzOnOk: () => {
							this.preferenceState.setNotification(true)
							this.setSoundNotificationsModalSetting()
						},
						nzOnCancel: () => {
							this.preferenceState.setNotification(false)
						}
					})
				}
			})

		combineLatest(this.userProfile$, this.notification$)
			.pipe(delay(2000), untilDestroyed(this))
			.subscribe((data) => {
				let [user, notification] = [data[0], data[1]]
				if (!user) return
				if (typeof notification === 'boolean' && notification) {
					this.notificationState.requestPermission()
				}
			})
	}

	setSoundNotificationsModalSetting() {
		this.modal.confirm({
			nzTitle: 'Do you want to receive sound notifications for alerts?',
			nzOnOk: () => {
				navigator.mediaDevices
					.getUserMedia({ audio: true })
					.then((stream) => {
						this.preferenceState.setPreferenceSoundNotifications(true)
					})
					.catch((error) =>
						this.preferenceState.setPreferenceSoundNotifications(false)
					)
			},
			nzOnCancel: () => {
				this.preferenceState.setPreferenceSoundNotifications(false)
			}
		})
	}

	handlerCollapseMenuEmitter($event: boolean): void {
		this.preferenceState.setPreferenceIsCollapseMenu($event)
	}

	setRouterConfigLogic() {
		if (!this.isMobile()) return
		this.router.resetConfig([...MobileRoutes])
	}

	isMobile() {
		const userAgent =
			window.navigator.userAgent ||
			navigator.userAgent ||
			navigator.vendor ||
			// @ts-ignore
			window.opera
		return (
			!!(!this.deviceService.isDesktop(userAgent) && isMobile.any()) ||
			(!!userAgent.match(/Version\/[\d\.]+.*Safari/) && isMobile.any())
		)
	}

	private pccBroadcastChannelListener() {
		const bc = new BroadcastChannel('aiomed_pcc_channel')
		bc.postMessage('loggedToPCC')
	}
}
