import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'taskAssigneeName'
})
export class TaskAssigneeNamePipe implements PipeTransform {
	transform(data: string, ...args: unknown[]): string {
		if (!data) return ''
		const tmpArray = data.split(' ')
		return `${tmpArray[0]} ${tmpArray[1]}`
	}
}
