import { Pipe, PipeTransform } from '@angular/core'
import moment from 'moment'

@Pipe({
	name: 'checkFreshVitalTime'
})
export class CheckFreshVitalTimePipe implements PipeTransform {
	transform(lastUpdated: string): boolean {
		if (!lastUpdated) return false
		return moment(new Date()).diff(moment(lastUpdated), 'hours') >= 4
	}
}
