import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'ageFromBirth'
})
export class AgeFromBirthPipe implements PipeTransform {
	transform(value: any, ...args: unknown[]): unknown {
		if (!value) {
			return
		}
		const dateBirth = new Date(value)
		const now = new Date()
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
		const dob = new Date(
			Number(dateBirth.getFullYear()),
			Number(dateBirth.getMonth() - 1),
			Number(dateBirth.getDate())
		)
		const dobNow = new Date(today.getFullYear(), dob.getMonth(), dob.getDate())
		let age

		age = today.getFullYear() - dob.getFullYear()
		if (today < dobNow) {
			age = age - 1
		}

		return age
	}
}
