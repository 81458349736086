import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'deviceBatteryLevelIcon'
})
export class DeviceBatteryLevelIconPipe implements PipeTransform {
	transform(data: any, ...args: unknown[]): string {
		if (!data) return ''
		if (data >= 76) {
			return 'icons:full-battery'
		} else if (data < 76 && data >= 26) {
			return 'icons:middle-battery'
		} else if (data <= 25 && data >= 16) {
			return 'icons:low-battery'
		} else {
			return 'icons:no-battery'
		}
	}
}
