import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { BackendService } from '../../shared/services/backend.service'
import {
	combineLatest,
	ignoreElements,
	Observable,
	of,
	Subscription,
	switchMap,
	tap,
	timer
} from 'rxjs'
import { PatientState } from '../patient/patient.state'
import { PatientExportDTO } from '../../shared/model/pcc.model'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { ExportAllInterface, ExportInterface } from '../../shared/model/export'
import { cloneDeep, uniq, uniqBy } from 'lodash-es'
import { DepartmentState } from '../department/department.state'
import moment from 'moment/moment'
import { StoreEventsService } from '../store-events.service'
import { DepartmentDTO } from '../../shared/model/permission.model'
import { Changeable } from '../../shared/model/common'

export const exportFeatureName = 'export'

@StateRepository()
@State<ExportInterface>({
	name: exportFeatureName,
	defaults: {
		lastPatientsExport: [],
		allPatientsExport: []
	}
})
@Injectable()
export class ExportState extends NgxsDataRepository<ExportInterface> {
	subscriptionBackendUpdates$: Subscription | null
	subscriptionExportsRecursively$: Subscription | null
	subscriptionExportShiftTimeUpdates$: Subscription | null
	private exportStateSubscription: Subscription;

	constructor(
		private backendService: BackendService,
		private actions: Actions,
		private patientState: PatientState,
		private departmentState: DepartmentState,
		private storeEvents: StoreEventsService
	) {
		super()
	}

	// public get backendUpdates$(): Observable<any> {
	// 	const department = !!this.departmentState.getState().currentDepartment
	// 		? this.departmentState.getState().currentDepartment
	// 		: Object.values(this.departmentState.entities)[0]
	// 	this.subscriptionExportsRecursively$ = this.backendService
	// 		.getPatientExportsRecursively(
	// 			Object.values(this.patientState.getState().entities)
	// 				.filter(
	// 					(p) =>
	// 						p.enabled &&
	// 						p.emrid &&
	// 						p.department &&
	// 						p.department.id === department?.id
	// 				)
	// 				.map((p) => p.id)
	// 		)
	// 		.subscribe((res) => {
	// 			if (!res) {
	// 				if (this.subscriptionExportsRecursively$) {
	// 					this.subscriptionExportsRecursively$.unsubscribe()
	// 				}
	// 				return
	// 			}
	// 			if (!res.data.length) return
	// 			uniq(res.data.map((e) => e.observedPatient)).forEach((ex) => {
	// 				const data = res.data.filter((e) => e.observedPatient === ex)
	// 				this.ctx.patchState({
	// 					lastPatientsExport: [
	// 						...this.getState().lastPatientsExport,
	// 						data[data.length - 1]
	// 					],
	// 					allPatientsExport: [
	// 						...this.getState().allPatientsExport,
	// 						{
	// 							patientId: data[0].observedPatient,
	// 							data: data
	// 						}
	// 					]
	// 				})
	// 			})
	// 		})
	// 	return this.backendService
	// 		.subscribeAllPatientExports(this.patientState.ids)
	// 		.pipe(
	// 			tap((res) => {
	// 				const patientExport = this.getState().allPatientsExport.find(
	// 					(e) => e.patientId === res.observedPatient
	// 				)
	// 				this.ctx.patchState({
	// 					lastPatientsExport: [
	// 						...this.getState().lastPatientsExport.filter(
	// 							(e) => e.observedPatient !== res.observedPatient
	// 						),
	// 						res
	// 					]
	// 				})
	// 				if (patientExport) {
	// 					this.ctx.patchState({
	// 						allPatientsExport: [
	// 							...this.getState().allPatientsExport.filter(
	// 								(e) => e.patientId !== res.observedPatient
	// 							),
	// 							{ ...patientExport, data: [...patientExport.data, res] }
	// 						]
	// 					})
	// 				}
	// 			}),
	// 			ignoreElements()
	// 		)
	// }

	@Selector()
	public static patientsExports(state: ExportInterface): PatientExportDTO[] {
		return state.lastPatientsExport
	}

	@Selector()
	public static patientsAllExports(
		state: ExportInterface
	): ExportAllInterface[] {
		return state.allPatientsExport
	}

	private static filterPatientsExtortShift(data: PatientExportDTO[]) {
		const date = moment()
		if (date.get('hours') >= 7 && date.get('hours') < 15) {
			return data.filter(
				(exports) =>
					exports.creationTime >=
						moment(new Date())
							.set('hours', 7)
							.set('minutes', 0)
							.toISOString() &&
					exports.creationTime <
						moment(new Date()).set('hours', 15).set('minutes', 0).toISOString()
			)
		} else if (date.get('hours') >= 15 && date.get('hours') < 23) {
			return data.filter(
				(exports) =>
					exports.creationTime >=
						moment(new Date())
							.set('hours', 15)
							.set('minutes', 0)
							.toISOString() &&
					exports.creationTime <
						moment(new Date()).set('hours', 23).set('minutes', 0).toISOString()
			)
		}
		return data
	}

	@DataAction()
	addNewPatientsExports(
		@Payload('patientsExports') patientsExports: PatientExportDTO[]
	) {
		uniq(patientsExports.map((e) => e.observedPatient)).forEach((ex) => {
			const patientExport = this.getState().allPatientsExport.find(
				(e) => e.patientId === ex
			)
			const idx = patientsExports.findIndex((pe) => pe.observedPatient === ex)
			this.ctx.patchState({
				lastPatientsExport: [
					...this.getState().lastPatientsExport.filter(
						(e) => e.observedPatient !== ex
					),
					patientsExports[idx]
				]
			})
			if (patientExport) {
				this.ctx.patchState({
					allPatientsExport: [
						...this.getState().allPatientsExport.filter(
							(e) => e.patientId !== ex
						),
						{
							...patientExport,
							data: [
								...ExportState.filterPatientsExtortShift(patientExport.data),
								patientsExports[idx]
							]
						}
					]
				})
			} else {
				this.ctx.patchState({
					allPatientsExport: [
						...this.getState().allPatientsExport.filter(
							(e) => e.patientId !== ex
						),
						{
							patientId: patientsExports[idx].observedPatient,
							data: [patientsExports[idx]]
						}
					]
				})
			}
		})
	}

	public exportShiftTimeUpdates$(): Observable<number> {
		return timer(10000, 10000).pipe(
			switchMap((n) => {
				const allPatientsExport: ExportAllInterface[] = [
					...cloneDeep(this.getState().allPatientsExport)
				]
				this.patchState({ allPatientsExport: [] })
				this.patchState({ allPatientsExport })
				return of(n)
			})
		)
	}
	
	public updateWithModifiedExports(patientIds: string[]) {
		return this.backendService.findAllPatientExports(patientIds).pipe(
			tap((res) => {
				if (!res || !res.length) return;
				uniq(res.map((e) => e.observedPatient)).forEach((ex) => {
					const data = res.filter((e) => e.observedPatient === ex)
					this.setPatientExportsData(ex, data);
				})
			})
		);
	}

	public override ngxsOnInit() {

		this.storeEvents.patientsModified$.pipe(
			tap(() => {
				if (this.exportStateSubscription) this.exportStateSubscription.unsubscribe();
				const department = !!this.departmentState.getState().currentDepartment ? this.departmentState.getState().currentDepartment : Object.values(this.departmentState.entities)[0];
				const patientIds = Object.values(this.patientState.getState().entities).filter((p) => p.enabled && p.emrid && p.department && p.department.id === department?.id).map((p) => p.id);
				this.exportStateSubscription = combineLatest([this.updateWithModifiedExports(patientIds), this.exportShiftTimeUpdates$()]).subscribe();
			})
		).subscribe();


		this.storeEvents.logout$.pipe(
			tap(() => {
				this.ctx.patchState({
					lastPatientsExport: [],
					allPatientsExport: []
				});
				this.reset();
				if (this.exportStateSubscription) this.exportStateSubscription.unsubscribe();
			})
		).subscribe();
	}

	@DataAction()
	insertFailedEMRTemporaryExport(@Payload('patientId') patientId: string, @Payload('data') data: PatientExportDTO) {
		this.setPatientExportsData(patientId, [{...data, export_status: 'failed', observedPatient: patientId }]);
	}

	private setPatientExportsData(ex: string, data: (PatientExportDTO & Changeable)[]): void {
		const ifObservedPatientExist = this.getState().allPatientsExport.findIndex(exp => exp.patientId === ex);
		let allPatientsExport;
		if (ifObservedPatientExist === -1) {
			allPatientsExport = [...this.getState().allPatientsExport, { patientId: data[0].observedPatient, data }];
		} else {
			allPatientsExport = this.getState().allPatientsExport.map(exp => exp.patientId === data[0].observedPatient ? { ...exp, data: uniqBy([...exp.data, ...data], obj => obj.id) } : exp);
		}
		this.ctx.patchState({
			lastPatientsExport: [...this.getState().lastPatientsExport, data[data.length - 1]],
			allPatientsExport,
		})
	}
}
