import { Pipe, PipeTransform } from '@angular/core'
import { getUserLocale } from '../helpers/functions'

@Pipe({
	name: 'transformCelsiusToFahrenhei'
})
export class TransformCelsiusToFahrenheiPipe implements PipeTransform {
	transform(data: any, ...args: unknown[]): number {
		if (!data) return 0
		return getUserLocale() === 'en-US'
			? Math.floor(((data * 9) / 5 + 32) * 10) / 10
			: Math.floor(data * 10) / 10
	}
}
