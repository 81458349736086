import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import { EMPTY, Observable, Subscription, tap } from 'rxjs'
import { InsightDTO, InsightsBackend } from '../../shared/model/insight.model'
import { BackendService } from '../../shared/services/backend.service'
import { filter } from 'rxjs/operators'
import { PatientState } from '../patient/patient.state'
import { FileState } from '../file/file.state'
import { PatientDTO } from '../../shared/model/patient'
import { FileDTO } from '../../shared/model/file'
import moment from 'moment/moment'
import { StoreEventsService } from '../store-events.service'
import { DepartmentDTO } from '../../shared/model/permission.model'
import { DepartmentState } from '../department/department.state'
import { DepartmentFilter } from '../../shared/model/departments.model'

export const insightFeatureName = 'insightBrowsing'

@StateRepository()
@State<CollatableEntityCollections<InsightDTO>>({
	name: insightFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class InsightBrowsingState extends CollatableEntityCollectionsRepository<
	InsightDTO,
	EntityCollation
> {
	private subscriptionGetAllInsights$: Subscription

	constructor(
		private backendService: BackendService,
		private actions: Actions,
		private patientState: PatientState,
		private storeEvents: StoreEventsService,
	) {
		super()
	}

	@Selector()
	public static isLoading(
		state: CollatableEntityCollections<InsightDTO>
	): boolean {
		return state.isLoading
	}

	@Selector([PatientState.entities, FileState.files])
	public static currentInsight(
		state: CollatableEntityCollections<InsightDTO>,
		patients: CollatableEntityCollections<PatientDTO>,
		files: EntityDictionary<string, FileDTO>
	): InsightDTO | null {
		if (state.currentInsights) {
			const patient = Object.values(patients).find(
				(p) => p.id === state?.currentInsights?.patient.id
			)
			return {
				...state?.currentInsights,
				patient: {
					...patient,
					avatar:
						patient.avatar &&
						files[patient.avatar.id] &&
						files[patient.avatar.id]?.signedUrl
							? files[patient.avatar.id]
							: null
				}
			}
		}
		return state?.currentInsights
	}

	@Selector([PatientState.entities, FileState.files, DepartmentState.department])
	public static insights(
		state: CollatableEntityCollections<InsightDTO>,
		patient: CollatableEntityCollections<PatientDTO>,
		files: EntityDictionary<string, FileDTO>,
		department: DepartmentDTO | undefined,
	): InsightDTO[] {
		let patients = Object.values(patient);
		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		const currentInsights: InsightDTO[] = []
		Object.values(state.entities).forEach((insight) => {
			if (insight.patient && insight.patient.id) {
				const patient: PatientDTO | undefined = Object.values(patients).find(
					(p) => p.id === insight.patient?.id
				)
				if (patient) {
					currentInsights.push({
						...insight,
						patient: {
							...patient,
							// @ts-ignore
							avatar:
								patient.avatar &&
								files[patient.avatar.id] &&
								files[patient.avatar.id]?.signedUrl
									? files[patient.avatar.id]
									: null
						}
					})
				}
			}
		})
		return currentInsights
	}

	@Selector()
	public static insightsRelatedType(
		state: CollatableEntityCollections<InsightDTO>
	): string {
		let insightsRelatedType = ''
		Object.values(state.entities)
			.filter(
				(i) =>
					moment(new Date()).diff(moment(i.insightStartTime), 'hours') <= 48
			)
			.forEach((i) => {
				if (!insightsRelatedType) {
					insightsRelatedType = i.insightSubject.split('_').join(' ')
				} else {
					insightsRelatedType = `${insightsRelatedType}, ${i.insightSubject
						.split('_')
						.join(' ')}`
				}
			})
		return insightsRelatedType
	}

	@Selector()
	public static totalCount(
		state: CollatableEntityCollections<InsightDTO>
	): number {
		return state.totalCount
	}

	public override ngxsOnInit() {
		this.storeEvents.logout$.pipe(
			tap(() => {
				this.reset();
			})
		).subscribe();
	}

	@DataAction()
	public loadPatientInsights(
		@Payload('page') page: number,
		@Payload('freeTextFilter') freeTextSearch: string,
		@Payload('patientId') patientId?: string,
		@Payload('patientsIds') patientsIds?: string[],
		@Payload('status') status?: string
	) {
		this.ctx.patchState({
			isLoading: true
		})
		return this.backendService
			.getBrowsingInsights(page, freeTextSearch, patientId, patientsIds, status)
			.pipe(
				tap((res: InsightsBackend) => {
					const insights = Object.values(this.getState().entities)
					this.removeAll()
					this.upsertMany(
						page === 0 ? [...res.data] : [...insights, ...res.data]
					)
					this.ctx.patchState({ isLoading: false })
					if (freeTextSearch) return
					this.patchState({
						totalCount: res.metadata.page?.totalResults
					})
				})
			)
	}

	@DataAction()
	public loadPatientInsightsForThreeMonth(
		@Payload('patientId') patientId: string,
		@Payload('insightId') insightId: string
	) {
		this.setMany([])
		return (this.subscriptionGetAllInsights$ = this.backendService
			.getAllInsightsBrowsingRecursively(0, patientId)
			.subscribe((res) => {
				if (!res) {
					this.subscriptionGetAllInsights$.unsubscribe()
					return
				}
				this.setMany(res.data.filter((i) => i.id !== insightId))
			}))
	}

	@DataAction()
	public getPatientInsight(@Payload('id') id: string) {
		this.ctx.patchState({
			isLoading: true
		})
		return this.backendService.getBrowsingInsight(id).pipe(
			tap((res: InsightDTO[]) => {
				const currentInsights = res.find((insight) => insight.id === id)
				if (currentInsights) {
					this.patchState({
						currentInsights
					})
				}
			})
		)
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}
}
