<ion-header *ngIf='!isPatient'>
  <ion-toolbar>
    <ion-title>{{title}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-header *ngIf='isPatient' style='box-shadow: none'>
  <ion-toolbar>
    <ion-buttons slot='start'>
      <ion-back-button [routerLink]="['/']" default-href='*'></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
