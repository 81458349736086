import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { Select } from '@ngxs/store'
import { PatientState } from '../../../../../store/patient/patient.state'
import { Observable } from 'rxjs'
import { PatientDTO } from '../../../../../shared/model/patient'

@Component({
	selector: 'aiomed-search-mobile',
	templateUrl: './search-mobile.component.html',
	styleUrls: ['./search-mobile.component.scss']
})
export class SearchMobileComponent implements OnInit {
	@Output('searchPatientEmitter') searchPatient = new EventEmitter()
	@Input() isPatients: boolean = true
	@Select(PatientState.searchPatient)
	searchPatient$: Observable<PatientDTO[]>
	textSearch: string = ''

	constructor(public router: Router) {}

	ngOnInit(): void {
		setTimeout(() => {
			if (document.querySelector('input.input-search')) {
				// @ts-ignore
				document.querySelector('input.input-search').focus()
			}
		}, 0)
	}
}
