import { Component, Input } from '@angular/core'

@Component({
	selector: 'aiomed-header-mobile',
	templateUrl: './header-mobile.component.html',
	styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent {
	@Input() title: string = 'Home'
	@Input() isPatient: boolean = false

	constructor() {}
}
