import { Pipe, PipeTransform } from '@angular/core'
import { AlertStatus } from '../../../shared/model/alert'

@Pipe({
	name: 'alertStatusIcon'
})
export class AlertStatusIconPipe implements PipeTransform {
	AlertStatus = AlertStatus

	transform(data: any, ...args: unknown[]): string {
		if (!data) return ''
		if (data === AlertStatus.Open) {
			return 'icons:alert-status-ongoing'
		} else if (data === AlertStatus.Closed) {
			return 'icons:alert-status-resolve'
		} else if (data === AlertStatus.Snoozed) {
			return 'icons:alert-status-mute'
		}
		return 'container'
	}
}
