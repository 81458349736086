<section class='emr-history-time'>
  <nz-modal [(nzVisible)]='isVisible'
            nzClassName='custom-emr-history-time-modal'>
    <div *nzModalContent>
      <div class='emr-history-time-container'>
        <div class='emr-history-time-title'>PREVIOUS DATA</div>
        <div class='emr-history-time-text'>Please choose day and time</div>
        <ul class='emr-history-time-type'>
          <li (click)='handlerTimeTypeClick(timeType)' *ngFor='let timeType of timeTypes'
              [ngClass]='{"active" : timeType.active}'>{{timeType.text}}</li>
        </ul>
        <div class='emr-history-time-selector-container'>
          <nz-time-picker
            (ngModelChange)='log($event)'
            (nzOpenChange)='isOpened = $event'
            [(ngModel)]='time'
            [nzAllowEmpty]='false'
            [nzInputReadOnly]='true'
            [nzMinuteStep]='15'
            [nzStatus]='isError ?"error" : ""'
            [nzUse12Hours]='false'
            nzFormat='HH:mm'
            nzPopupClassName='custom-time-picker-popup'
          ></nz-time-picker>
          <span *ngIf='isError' class='warning'><i
            nz-icon
            nzType='icons:modal-error'
          ></i>The time is set incorrectly.</span>
        </div>
        <div class='emr-history-time-buttons'>
          <button (click)='emrHistoryCancelEmitter.emit(false)' class='cancel'>Cancel</button>
          <button (click)='handlerButtonConfirmClick()' [disabled]='isError || isOpened || !time' class='confirm'>Done
          </button>
        </div>
      </div>
    </div>
  </nz-modal>
</section>

