import { Pipe, PipeTransform } from '@angular/core'
import { formatDistanceToNow } from 'date-fns'
import { enUS } from 'date-fns/locale'

@Pipe({
  name: 'deviceImage',
})
export class DeviceImagePipe implements PipeTransform {
  transform(data: any, ...args: unknown[]): string {
    if (!data) return ''
    return 'gitlab'
  }
}
