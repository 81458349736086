import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import { createEntityCollections } from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import {
	EMPTY,
	ignoreElements,
	mergeMap,
	Observable,
	of,
	Subscription,
	tap
} from 'rxjs'
import {
	BackendDepartmentDTO,
	DepartmentDTO
} from '../../shared/model/permission.model'
import { mapToVoid } from '@angular-ru/cdk/rxjs'
import { BackendService } from '../../shared/services/backend.service'
import { UserState } from '../user/user.state'
import { UserInterface } from '../../shared/model/user.model'
import { cloneDeep, isEqual, orderBy, uniq } from 'lodash-es'
import { AuthState } from '../auth/auth.state'
import { StoreEventsService } from '../store-events.service'
import { defaultAllDepartment, DepartmentFilter, DepartmentType } from '../../shared/model/departments.model'
import { PreferenceState } from '../preference/preference.state'

export const departmentFeatureName = 'department'

@StateRepository()
@State<CollatableEntityCollections<DepartmentDTO>>({
	name: departmentFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class DepartmentState extends CollatableEntityCollectionsRepository<
	DepartmentDTO,
	EntityCollation
> {
	subscriptionDepartments$: Subscription
	subscriptionAllDepartmentsUpdates$: Subscription
	private departmentStateSubscription: Subscription;

	constructor(
		private backendService: BackendService,
		private actions: Actions,
		private userState: UserState,
		private authState: AuthState,
		private storeEvents: StoreEventsService,
		private preferenceState: PreferenceState,
	) {
		super()
	}

	// public get backendUpdates$(): Observable<void> {
	// 	return this.backendService
	// 		.subscribeAllDepartments(this.userState.snapshot.user?.id)
	// 		.pipe(
	// 			tap((res) => {
	// 				if (!isEqual(Object.values(this.entities), res)) {
	// 					this.setAll(res)
	// 				}
	// 			}),
	// 			ignoreElements()
	// 		)
	// }

	// public get backendAllDepartmentsUpdates$(): Observable<void> {
	// 	this.getCurrentDepartments()
	// 	return this.backendService.subscribeDepartments().pipe(
	// 		tap((res) => {
	// 			let currentDepartment: DepartmentDTO | null = null
	// 			if (Object.values(this.entities).length) {
	// 				const currentShift = res
	// 					.filter((d) => d.shiftManager && d.shiftManager.id)
	// 					.find(
	// 						(d) => d.shiftManager?.id === this.userState?.snapshot?.user?.id
	// 					)
	// 				if (!!currentShift) {
	// 					this.setAll([currentShift])
	// 				} else {
	// 					this.setAll([])
	// 				}
	// 			}
	// 			res.forEach((d) => {
	// 				if (
	// 					d.onDutyAssistantIds &&
	// 					d.onDutyAssistantIds?.find(
	// 						(id) => id === this.userState.snapshot.user?.id
	// 					)
	// 				) {
	// 					currentDepartment = d
	// 				}
	// 			})
	// 			setTimeout(() => {
	// 				if (!currentDepartment && !Object.values(this.entities).length) {
	// 					this.patchState({ withoutDepartment: true })
	// 				}
	// 			}, 1000)
	// 			if (!isEqual(this.getState().currentDepartment, currentDepartment)) {
	// 				this.patchState({
	// 					currentDepartment
	// 				})
	// 			}
	// 		}),
	// 		ignoreElements()
	// 	)
	// }

	@Selector()
	public static departments(
		state: CollatableEntityCollections<DepartmentDTO>
	): DepartmentDTO[] {
		return Object.values(state.entities)
	}

	@Selector()
	public static allDepartments(
		state: CollatableEntityCollections<DepartmentDTO>
	): DepartmentDTO[] {
		const departmentsExceptAll = state.allDepartments.filter(d => d.id !== DepartmentFilter.All);
		return [defaultAllDepartment as DepartmentDTO, ...orderBy(Object.values(departmentsExceptAll), 'name', 'asc')];
	}

	@Selector([UserState.medicalAssistants])
	static departmentMedicalAssistants(
		state: CollatableEntityCollections<DepartmentDTO>,
		medicalAssistants: UserInterface[]
	): UserInterface[] {
		const departmentMedicalAssistants: UserInterface[] = []
		if (
			Object.values(state.entities) &&
			Object.values(state.entities).length &&
			Object.values(state.entities)[0].onDutyAssistantIds &&
			medicalAssistants.length
		) {
			Object.values(state.entities)[0].onDutyAssistantIds?.forEach((id) => {
				const mA = medicalAssistants.find((ma) => ma.id === id)
				if (mA) {
					departmentMedicalAssistants.push(mA)
				}
			})
		}
		return uniq(departmentMedicalAssistants)
	}

	@Selector([UserState.medicalAssistants])
	static medicalAssistantWithoutDepartments(
		state: CollatableEntityCollections<DepartmentDTO>,
		medicalAssistants: UserInterface[]
	): UserInterface[] {
		let medicalAssistantWithoutDepartments: UserInterface[] = []
		if (
			Object.values(state.entities) &&
			Object.values(state.entities).length &&
			Object.values(state.entities)[0].onDutyAssistantIds
		) {
			medicalAssistants.forEach((ma) => {
				const idx = Object.values(
					state.entities
				)[0].onDutyAssistantIds?.findIndex((dId) => dId === ma.id)
				if (idx !== -1) return
				medicalAssistantWithoutDepartments.push(ma)
			})
		} else {
			if (!Object.values(state.entities).length)
				return medicalAssistantWithoutDepartments
			medicalAssistantWithoutDepartments = cloneDeep([...medicalAssistants])
		}
		return medicalAssistantWithoutDepartments
	}

	@Selector()
	public static department(
		state: CollatableEntityCollections<DepartmentDTO>
	): DepartmentDTO | null {
		return !!state.currentDepartment
			? state.currentDepartment
			: Object.values(state.entities).length
			? Object.values(state.entities)[0]
			: null
	}

	@Selector()
	public static withoutDepartment(
		state: CollatableEntityCollections<DepartmentDTO>
	): boolean {
		return state.withoutDepartment
	}

	@Selector()
	public static currentDepartment(
		state: CollatableEntityCollections<DepartmentDTO>
	): DepartmentDTO | null {
		return state.currentDepartment;
	}

	@Selector([UserState.currentUser])
	public static departmentType(
		state: CollatableEntityCollections<DepartmentDTO>,
		user: UserInterface,
	) {
		let departmentType: DepartmentType | null = null;
		const currentDepartment = state.currentDepartment || JSON.parse(localStorage.getItem('preference.department')!) as unknown as DepartmentDTO;
		if (!currentDepartment) return;
		if (state?.currentDepartment?.id !== DepartmentFilter.All) {
			departmentType = currentDepartment?.isAutomatic ? DepartmentType.Automatic : DepartmentType.SemiAutomatic;
		} else {
			const userDefaultDepartment = state.allDepartments.find(d => d.id === user?.onDutyDepartment?.id);
			if (!userDefaultDepartment) {
				departmentType = state.allDepartments.filter(d => d.id !== DepartmentFilter.All)[0].isAutomatic ? DepartmentType.Automatic : DepartmentType.SemiAutomatic;;
			} else {
				departmentType = userDefaultDepartment.isAutomatic ? DepartmentType.Automatic : DepartmentType.SemiAutomatic;;
			}
		}
		return departmentType;
	}

	public isShiftManager(): boolean {
		return !!Object.values(this.snapshot.entities).length
	}

	// @DataAction()
	public updateOnDutyAssistantIds(
		@Payload('entityId') idCNA: string,
		@Payload('departmentId') departmentId?: string
	): Observable<any> {
		if (departmentId === DepartmentFilter.All) return of ('');
		if (!departmentId) return of('');
		// @ts-ignore
		const { id, onDutyAssistantIds } = !departmentId
			? cloneDeep(Object.values(this.snapshot.entities)[0])
			: cloneDeep(
					this.snapshot.allDepartments.find((d) => d.id === departmentId)
			  )

		const assistantIdsString =
			onDutyAssistantIds && onDutyAssistantIds?.length
				? cloneDeep(
						[...cloneDeep(onDutyAssistantIds), cloneDeep(idCNA)].join(',')
				  )
				: cloneDeep([idCNA]).join(',')
		return this.backendService
			.updateOnDutyAssistantIds(!departmentId ? id : departmentId, {
				onDutyAssistantIds: assistantIdsString
			})
	}

	@DataAction()
	public getAllDepartments(): Observable<void> {
		return this.backendService.getAllDepartments().pipe(
			tap((res) => {
				this.patchState({
					allDepartments: [...this.getState().allDepartments, ...res]
				})
			}),
			mapToVoid()
		)
	}

	@DataAction()
	public getCurrentDepartments(): Observable<void> {
		return this.backendService.getAllDepartments().pipe(
			tap((res) => {
				let currentDepartment: DepartmentDTO | null = null
				res.forEach((d) => {
					if (
						d.onDutyAssistantIds &&
						d.onDutyAssistantIds?.find(
							(id) => id === this.userState.snapshot.user?.id
						)
					) {
						currentDepartment = d
					}
				})
				this.patchState({
					currentDepartment
				})
			}),
			mapToVoid()
		)
	}

	@DataAction()
	public updateShiftManagerDepartment(
		@Payload('entityId') entityId: string,
		@Payload('entityDiff') entityDiff: Partial<BackendDepartmentDTO>,
		@Payload('isAuthenticated') isAuthenticated: boolean = false,
		@Payload('department') department?: DepartmentDTO,
	) {
		if (department?.id === DepartmentFilter.All) {
			this.departmentChange(department);
			return;
		}
		return this.backendService
			.updateOnDutyAssistantIds(entityId, entityDiff)
			.pipe(
				tap((res) => {
					this.upsertOne(res)
					if (isAuthenticated) {
						this.authState.logout()
					}
					if (department) {
						this.departmentChange(department);
					}
				}),
				ignoreElements()
			)
	}

	@DataAction()
	public removeOnDutyAssistantIds(
		@Payload('entityId') idCNA: string,
		@Payload('departmentId') departmentId?: string,
		@Payload('department') department?: DepartmentDTO,
		@Payload('isAuthenticated') isAuthenticated: boolean = false
	): Observable<void> {
		let id: string = ''
		let onDutyAssistantIds: string[] = []
		if (department) {
			id = department.id
			onDutyAssistantIds = !department.onDutyAssistantIds
				? []
				: department.onDutyAssistantIds
		} else {
			const currentDepartment = !departmentId
				? cloneDeep(Object.values(this.snapshot.entities)[0])
				: cloneDeep(
						Object.values(this.snapshot.entities).find(
							(d) => d.id === departmentId
						)
				  )
			// @ts-ignore
			id = currentDepartment.id
			onDutyAssistantIds = !currentDepartment?.onDutyAssistantIds
				? []
				: currentDepartment?.onDutyAssistantIds
		}
		const assistantIdsString =
			onDutyAssistantIds && onDutyAssistantIds?.length
				? cloneDeep(
						[...onDutyAssistantIds].filter((ids) => ids !== idCNA).join(',')
				  )
				: ''
		return this.backendService
			.updateOnDutyAssistantIds(id, { onDutyAssistantIds: assistantIdsString })
			.pipe(
				mergeMap((res) => {
					this.upsertOne(res)
					if (isAuthenticated) {
						this.authState.logout()
					}
					return of()
				}),
				ignoreElements()
			)
	}

	@DataAction()
	public logout(): Observable<void> {
		this.authState.logout();
		const user = this.userState.getState().user
		this.patchState({ currentDepartment: null })
		this.preferenceState.setPreferenceDepartment(null);
		return this.backendService.getAllDepartments().pipe(
			tap((res) => {
				if (Object.values(this.snapshot.entities).length) {
					const { id, shiftManager } = cloneDeep(
						Object.values(this.snapshot.entities)[0]
					)
					if (
						shiftManager &&
						shiftManager?.id &&
						shiftManager?.id === user?.id
					) {
						// @ts-ignore
						this.updateShiftManagerDepartment(id, { shiftManager: null }, true)
						return
					}
				}
			}),
			mapToVoid()
		)
	}

	// @DataAction()
	public updateWithModifiedDepartments() {
		return this.backendService.findAllDepartments().pipe(
			tap(res => {
				let currentDepartment: DepartmentDTO | null = defaultAllDepartment as DepartmentDTO;
				const uniqueDepartments = [...this.getState().allDepartments, ...res as DepartmentDTO[]].reduce((prev: DepartmentDTO[], cur) => [...prev.filter(d => d.id !== cur.id), cur], []);
				this.patchState({
					allDepartments: uniqueDepartments as DepartmentDTO[]
				});

				if (Object.values(this.entities).length) {
					const currentShift = res
						.filter((d) => d.shiftManager && d.shiftManager.id)
						.find(
							(d) => d.shiftManager?.id === this.userState?.snapshot?.user?.id
						)
					if (!!currentShift) {
						this.setAll([currentShift])
					} else {
						this.setAll([])
					}
				}
				res.forEach((d) => {
					if (
						d.onDutyAssistantIds &&
						d.onDutyAssistantIds?.find(
							(id) => id === this.userState.snapshot.user?.id
						)
					) {
						currentDepartment = d
					}
				})
				if (!currentDepartment && !Object.values(this.entities).length) {
					this.patchState({ withoutDepartment: true })
				}
				if (!isEqual(this.getState().currentDepartment, currentDepartment)) {
					if (this.preferenceState.getState().department) {
						currentDepartment = this.getState().allDepartments.find(d => d.id === this.preferenceState.getState().department!.id)!;
					} 
					this.patchState({
						currentDepartment
					})
				}

				this.dispatch({ type: 'DEPARTMENT UPDATE' });
			})
		)
	}

	public override ngxsOnInit() {
		this.storeEvents.userModified$.pipe(
			tap(() => {
				if (this.departmentStateSubscription) this.departmentStateSubscription.unsubscribe();
				this.departmentStateSubscription = this.updateWithModifiedDepartments().subscribe();
			})
		).subscribe();


		this.storeEvents.logout$.pipe(
      tap(() => {
        this.patchState({
					allDepartments: [],
					currentDepartment: null,
					withoutDepartment: false
				});
				this.reset();
				if (this.departmentStateSubscription) this.departmentStateSubscription.unsubscribe();
      })
    ).subscribe();
	}

	public departmentChange(department: DepartmentDTO): void {
		this.patchState({ currentDepartment: department });
		this.preferenceState.setPreferenceDepartment(department);
		this.dispatch({ type: 'DEPARTMENT CHANGE' });
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}
}
