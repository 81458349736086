import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import { AlertDTO, alertSeverityComparator } from '../../shared/model/alert'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import {
	DeviceCriticalStatus,
	DeviceDTO,
	DeviceInterface,
	DeviceModel,
	deviceSort,
	DeviceStatusDetailInformation
} from '../../shared/model/device.model'
import { EMPTY, forkJoin, Observable, of, Subscription, tap } from 'rxjs'
import { AlertState } from '../alert/alert.state'
import { Injectable } from '@angular/core'
import {
	UsageSessionDTO,
	UsageSessionState as UsageSessionStateEnum
} from '../../shared/model/usage-session'
import { SessionState } from '../session/session.state'
import { cloneDeep, orderBy } from 'lodash-es'
import { entitiesDeviceFilter } from '../../core/helpers/filter'
import { PatientState } from '../patient/patient.state'
import { PatientDTO } from '../../shared/model/patient'
import { FileState } from '../file/file.state'
import { FileDTO } from '../../shared/model/file'
import { DepartmentState } from '../department/department.state'
import { DepartmentDTO } from '../../shared/model/permission.model'
import { BackendService } from '../../shared/services/backend.service'
import { finalize, share } from 'rxjs/operators'
import moment from 'moment/moment'
import { NotificationService } from '../../shared/services/notification.service'
import { FrequencyUpdatesTimings } from '../../shared/model/frequency-updates-timings'
import { StoreEventsService } from '../store-events.service'

export const deviceFeatureName = 'device'

@StateRepository()
@State<CollatableEntityCollections<DeviceDTO>>({
	name: deviceFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation(),
		isLoading: true
	}
})
@Injectable()
export class DeviceState extends CollatableEntityCollectionsRepository<
	DeviceDTO,
	EntityCollation
> {
	subscriptionBackendUpdates$: Subscription
	subscriptionTimerDeviceSession$: Subscription
	subscriptionGetAllDevices$: Subscription
	private isFirstLoad: boolean = true
	private devicesUpdates$: Subscription
	private deviceStateSubscription: Subscription

	constructor(
		private backendService: BackendService,
		private alertState: AlertState,
		private usageSessionState: SessionState,
		private patientState: PatientState,
		private departmentState: DepartmentState,
		private actions: Actions,
		private ntfService: NotificationService,
		private storeEvents: StoreEventsService
	) {
		super()
	}

	// public get backendUpdates$(): Observable<void> {
	// 	this.subscriptionGetAllDevices$ = this.backendService
	// 		.getAllDevices()
	// 		.subscribe((res) => {
	// 			this.upsertMany(res)
	// 			this.dispatch({ type: 'CAN LOAD REPORTS' })
	// 			this.subscriptionGetAllDevices$.unsubscribe()
	// 		})
	// 	return this.backendService.subscribeAllDevices().pipe(
	// 		tap((res) => {
	// 			// @ts-ignore
	// 			const currentDevice = Object.values(this.entities).find(
	// 				(d) => d.id === res.id
	// 			)
	// 			if (!currentDevice || (currentDevice && !isEqual(currentDevice, res))) {
	// 				this.upsertOne(res)
	// 			}
	// 		}),
	// 		ignoreElements()
	// 	)
	// }

	@Selector([DepartmentState.department])
	public static sharedDevices(
		state: CollatableEntityCollections<DeviceDTO>,
		department: DepartmentDTO | undefined
	) {
		return Object.values(state.entities).filter(
			(d) =>
				!d.room &&
				department &&
				d.department &&
				d.department.id === department.id
		)
	}

	@Selector()
	public static biobeatWatchDevice(
		state: CollatableEntityCollections<DeviceDTO>
	): DeviceDTO[] {
		return Object.values(state.entities).filter(
			(d) => d.model === DeviceModel.BiobeatWatch
		)
	}

	@Selector()
	public static entities(state: CollatableEntityCollections<DeviceDTO>) {
		return state.entities
	}

	@Selector()
	public static devicesPageSize(
		state: CollatableEntityCollections<DeviceDTO>
	): number {
		return state.pageSize
	}

	@Selector([
		AlertState.alerts,
		SessionState.usageSession,
		PatientState.allDepartmentPatients
	])
	public static devicesDisconnected(
		state: CollatableEntityCollections<DeviceDTO>,
		alerts: EntityDictionary<string, AlertDTO>,
		usageSessions: EntityDictionary<string, UsageSessionDTO>,
		patients: PatientDTO[]
	): number {
		return Object.values(state.entities)
			.filter(
				(d) =>
					d.patient &&
					d.patient.id &&
					patients.find((p) => p.id === d.patient.id)
			)
			.map((device: DeviceDTO) =>
				DeviceState.hydrate(
					device,
					Object.values(alerts),
					Object.values(usageSessions)
				)
			)
			.filter((device) => !device.isConnected || !device.isTransmitting).length
	}

	@Selector([
		AlertState.alerts,
		SessionState.usageSession,
		PatientState.allDepartmentPatients,
		FileState.files
	])
	public static devices(
		state: CollatableEntityCollections<DeviceDTO>,
		alerts: EntityDictionary<string, AlertDTO>,
		usageSessions: EntityDictionary<string, UsageSessionDTO>,
		patients: PatientDTO[],
		files: EntityDictionary<string, FileDTO>
	): DeviceInterface[] {
		const currentDevices: DeviceDTO[] = []
		Object.values(state.entities).forEach((device) => {
			if (device.patient && device.patient.id) {
				const patient: PatientDTO | undefined = patients.find(
					(p) => p.id === device.patient?.id
				)
				if (patient) {
					currentDevices.push({
						...device,
						patient: {
							...patient,
							avatar:
								patient.avatar &&
								files[patient.avatar.id] &&
								files[patient.avatar.id]?.signedUrl
									? files[patient.avatar.id]
									: null
						}
					})
				}
			}
			// else {
			// 	currentDevices.push({
			// 		...device
			// 	})
			// }
		})
		const tmpArray = orderBy(
			currentDevices.map((device: DeviceDTO) =>
				DeviceState.hydrate(
					device,
					Object.values(alerts).filter((a) => a.status === 'open'),
					Object.values(usageSessions)
				)
			),
			'patient.room',
			'asc'
		)
		return entitiesDeviceFilter(
			state.freeTextFilter,
			deviceSort(
				state.sort,
				state.deviceFilter !== 'all'
					? tmpArray.filter(
							(d) => d.statusDetailInformation === state.deviceFilter
					  )
					: tmpArray
			)
		).slice(0, state.pageSize)
	}

	@Selector([PatientState.focusOnPatientId])
	public static patientDevices(
		state: CollatableEntityCollections<DeviceDTO>,
		patientId: string | null
	): DeviceInterface[] {
		if (!patientId) {
			return []
		}
		return Object.values(state.entities)
			.filter((d) => d.patient && d.patient.id === patientId)
			.map((device: DeviceDTO) => DeviceState.hydrate(device, [], []))
	}

	@Selector([PatientState.allDepartmentPatients])
	public static criticalDevices(
		state: CollatableEntityCollections<DeviceDTO>,
		patients: PatientDTO[]
	): DeviceStatusDetailInformation {
		let currentDevices: DeviceInterface[] = []
		Object.values(state.entities)
			.filter((d) => d.patient && d.patient.id)
			.forEach((d) => {
				if (patients.find((p) => p.id === d.patient.id)) {
					currentDevices = [...currentDevices, DeviceState.hydrate(d, [], [])]
				}
			})

		// const currentDevices: DeviceInterface[] = Object.values(state.entities)
		// 	.filter((d) => d?.patient?.id)
		// 	.map((device: DeviceDTO) => DeviceState.hydrate(device, [], []))
		return {
			noConnectionLength: currentDevices.filter(
				(d) => d.statusDetailInformation === DeviceCriticalStatus.NoConnection
			).length,
			badReadingLength: currentDevices.filter(
				(d) => d.statusDetailInformation === DeviceCriticalStatus.BadReading
			).length,
			lowBatteryLength: currentDevices.filter(
				(d) => d.statusDetailInformation === DeviceCriticalStatus.LowBattery
			).length,
			noBatteryLength: currentDevices.filter(
				(d) => d.statusDetailInformation === DeviceCriticalStatus.NoBattery
			).length,
			status:
				currentDevices.filter(
					(d) => d.statusDetailInformation === DeviceCriticalStatus.NoBattery
				).length > 0 ||
				currentDevices.filter(
					(d) => d.statusDetailInformation === DeviceCriticalStatus.LowBattery
				).length > 0 ||
				currentDevices.filter(
					(d) => d.statusDetailInformation === DeviceCriticalStatus.BadReading
				).length > 0 ||
				currentDevices.filter(
					(d) => d.statusDetailInformation === DeviceCriticalStatus.NoConnection
				).length > 0
		}
	}

	@Selector()
	public static allDevices(
		state: CollatableEntityCollections<DeviceDTO>
	): DeviceDTO[] {
		return Object.values(state.entities)
	}

	@Selector()
	public static currentDeviceFilter(
		state: CollatableEntityCollections<DeviceDTO>
	): string {
		return state.deviceFilter
	}

	@Selector()
	public static reportDevices(
		state: CollatableEntityCollections<DeviceDTO>
	): DeviceInterface[] {
		return Object.values(state.entities).map((device: DeviceDTO) =>
			DeviceState.hydrate(device, [], [])
		)
	}

	@Selector([PatientState.allDepartmentPatients])
	public static totalCount(
		state: CollatableEntityCollections<DeviceDTO>,
		patients: PatientDTO[]
	): number {
		return Object.values(state.entities).filter(
			(d) =>
				d.patient && d.patient.id && patients.find((p) => p.id === d.patient.id)
		).length
	}

	@Selector()
	public static devicePaginateCount(
		state: CollatableEntityCollections<DeviceDTO>
	): number {
		return entitiesDeviceFilter(
			state.freeTextFilter,
			Object.values(state.entities)
		).length
	}

	@Selector()
	public static isLoading(
		state: CollatableEntityCollections<DeviceDTO>
	): boolean {
		return state.isLoading
	}

	@Selector([AlertState.alerts, SessionState.usageSession])
	public static devicesHaveActiveSession(
		state: CollatableEntityCollections<DeviceDTO>,
		alerts: EntityDictionary<string, AlertDTO>,
		usageSessions: EntityDictionary<string, UsageSessionDTO>
	): DeviceInterface[] {
		return Object.values(state.entities)
			.map((device: DeviceDTO) =>
				DeviceState.hydrate(
					device,
					Object.values(alerts),
					Object.values(usageSessions)
				)
			)
			.filter((device) => device.activeSession && device.activeSession.length)
	}

	private static hydrateDeviceStatusType(
		lastStatusUpdate: any,
		status: string | null,
		butteryLevel: number | null,
		lastMeasurementTime: any,
		configuration: boolean
	): string {
		if (
			(!butteryLevel &&
				moment(new Date()).diff(moment(lastStatusUpdate), 'hours') >= 3) ||
			(!butteryLevel && status === 'device_disconnected_short') ||
			(!!butteryLevel &&
				butteryLevel > 15 &&
				moment(new Date()).diff(moment(lastStatusUpdate), 'hours') >= 3) ||
			(!!butteryLevel &&
				butteryLevel > 15 &&
				status === 'device_disconnected_short')
		) {
			return DeviceCriticalStatus.NoConnection
		} else if (
			!!butteryLevel &&
			butteryLevel > 15 &&
			moment(new Date()).diff(moment(lastMeasurementTime), 'hours') >= 1
		) {
			return DeviceCriticalStatus.BadReading
		} else if (butteryLevel && butteryLevel <= 15) {
			return DeviceCriticalStatus.NoBattery
		} else if (!configuration) {
			return DeviceCriticalStatus.NoConfiguration
		} else if (butteryLevel && butteryLevel > 15 && butteryLevel < 20) {
			return DeviceCriticalStatus.LowBattery
		}
		return ''
	}

	private static hydrate(
		device: DeviceDTO,
		alerts: AlertDTO[],
		usageSessions: UsageSessionDTO[]
	): DeviceInterface {
		let statusDetailInformation = ''
		const deviceAlerts = alerts
			.filter((a: AlertDTO) => a.alertedDevice?.id == device.id)
			.sort((a: AlertDTO, b: AlertDTO) =>
				alertSeverityComparator(a.severity, b.severity)
			)

		const activeSession = usageSessions.filter(
			(s) =>
				s.device?.id == device.id && s.state == UsageSessionStateEnum.Active
		)

		const isConnected =
			moment(new Date()).diff(moment(device.lastStatusUpdate), 'minutes') <=
				30 &&
			device.statusInformation &&
			device.statusInformation !== 'device_disconnected_short'

		if (device.model === 'biobeat_watch') {
			statusDetailInformation = DeviceState.hydrateDeviceStatusType(
				device.lastStatusUpdate,
				device.statusInformation,
				device.batteryLevel,
				device.lastMeasurementTime,
				device.configuration
			)
		}

		return {
			...device,
			alerts: deviceAlerts,
			maxAlertSeverity: deviceAlerts.length ? deviceAlerts[0].severity : null,
			// @ts-ignore
			isConnected,
			activeSession,
			statusDetailInformation,
			// @ts-ignore
			isTransmitting:
				// activeSession.length &&
				moment(new Date()).diff(
					moment(device.lastMeasurementTime),
					'minutes'
				) <= 30
		}
	}

	@DataAction()
	setDeviceForceRead(@Payload('device') device: DeviceInterface) {
		return this.backendService.setDevicesForceRead([device.id]).pipe(
			tap(
				(
					res: {
						deviceId: string
						forceReadStatus: string
					}[]
				) => {
					res.forEach((r) => {
						if (r.forceReadStatus === 'DONE') {
							this.ntfService.success(`${device?.serialNumber} New Read Done`)
						} else {
							this.ntfService.error(`${device?.serialNumber} New Read Failed`)
						}
					})
				}
			)
		)
	}

	@DataAction()
	setDeviceFilter(@Payload('deviceFilter') deviceFilter: string) {
		this.patchState({ deviceFilter })
	}

	@DataAction()
	anAssignDevice(
		@Payload('newDeviceId') newDeviceId: string,
		@Payload('newDeviceData') newDeviceData: any,
		@Payload('removeDeviceId') removeDeviceId: string,
		@Payload('removeDeviceData') removeDeviceData: any,
		@Payload('removeDeviceLogicType') removeDeviceLogicType: string = 'default'
	) {
		return this.backendService.updateDevice(newDeviceId, newDeviceData).pipe(
			tap((res) => {
				this.upsertOne(res)
				this.ntfService.success(`Device Assigned Successfully`)
				this.updateDevice(
					removeDeviceId,
					removeDeviceData,
					removeDeviceLogicType
				)
			})
		)
	}

	@DataAction()
	updateDevice(
		@Payload('id') id: string,
		@Payload('data') data: any,
		@Payload('type') type: string = 'default'
	) {
		return this.backendService.updateDevice(id, data).pipe(
			tap((res) => {
				this.upsertOne(res)
				if (type === 'hide') {
					return
				} else if (type === 'default') {
					this.ntfService.success(`Device Unassigned Successfully`)
				} else if (type === 'calibrate') {
					this.ntfService.success(`Device Calibrated Successfully`)
				} else if (
					type !== 'calibrate' &&
					type !== 'default' &&
					type !== 'hide'
				) {
					this.ntfService.success(`Device Assigned Successfully`)
				}
			})
		)
	}

	@DataAction()
	toggleAllDevicesUpdatesFrequency(
		@Payload('value') value: FrequencyUpdatesTimings
	) {
		this.backendService.toggleAllDevicesUpdatesFrequency(value)
	}

	updateWithModifiedDevices() {
		this.patchState({ isLoading: true })
		return this.backendService.getAllDevices().pipe(
			tap((devices) => {
				this.patchState({ isLoading: false })
				this.upsertMany(devices)
			}),
			share(),
			finalize(() => this.patchState({ isLoading: false }))
		)
	}

	public override ngxsOnInit() {
		this.storeEvents.loggedIn$
			.pipe(
				tap(() => {
					if (this.deviceStateSubscription)
						this.deviceStateSubscription.unsubscribe()
					this.deviceStateSubscription =
						this.updateWithModifiedDevices().subscribe()
				})
			)
			.subscribe()

		// this.storeEvents.departmentChange$.pipe(
		// 	tap(() => this.patchState({ isLoading: true }))
		// ).subscribe();

		this.storeEvents.logout$
			.pipe(
				tap(() => {
					this.patchState({ deviceFilter: 'all' })
					this.reset()
					if (this.deviceStateSubscription)
						this.deviceStateSubscription.unsubscribe()
				})
			)
			.subscribe()
	}

	protected setPaginationSetting(type?: string): Observable<any> {
		const state = this.ctx.getState()
		const devices = cloneDeep(Object.values(state.entities))
		const patientIds = devices
			.map((patient: any) => patient.id)
			.filter((i: string | any) => i)
		this.patientState.loadPatientImages(patientIds)
		let deviceHaveAlert: DeviceDTO[] = []
		if (type && type === 'haveAlert') {
			// @ts-ignore
			const ids: string[] = this.alertState.entitiesArray
				.sort((a, b) => alertSeverityComparator(a.severity, b.severity))
				.map((alert: AlertDTO) => alert.alertedDevice?.id)
				.filter((i) => i)
			devices.forEach((device) => {
				const idx = ids.findIndex((id: string) => id === device.id)
				if (idx === -1) return
				deviceHaveAlert = [...deviceHaveAlert, device]
			})
			// this.setDeviceUsageSessionsSetting(
			// 	deviceHaveAlert.slice(state.pageSize - PAGE_SIZE, state.pageSize)
			// )
		} else {
			// this.setDeviceUsageSessionsSetting(
			// 	devices.slice(state.pageSize - PAGE_SIZE, state.pageSize)
			// )
		}
		return of()
	}

	protected loadEntitiesFromBackend(): Observable<void> {
		return EMPTY
	}

	private setDeviceUsageSessionsSetting(data: DeviceDTO[]) {
		const deviceIds = data.map((device: DeviceDTO) => device.id)
		return forkJoin([this.usageSessionState.loadDeviceUsageSessions(deviceIds)])
	}
}
