import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'vitalFullName'
})
export class VitalFullNamePipe implements PipeTransform {
	transform(value: any, ...args: unknown[]): string {
		if (value === 'BP') {
			return 'Blood pressure'
		} else if (value === 'HR') {
			return 'Heart rate'
		} else if (value === 'SPO2') {
			return 'SpO2'
		} else if (value === 'BT') {
			return 'Temperature'
		} else if (value === 'BG') {
			return 'Glucose'
		} else {
			return 'Respiration rate'
		}
	}
}
