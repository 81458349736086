import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core'
import {
	PccFacilityDTO,
	PccPatientDTO
} from '../../../../../shared/model/pcc.model'

@Component({
	selector: 'aiomed-pcc-patients-modal',
	templateUrl: './pcc-patients.component.html',
	styleUrls: ['./pcc-patients.component.scss']
})
export class PccPatientsComponent implements OnInit, OnChanges {
	@Input() isVisible: boolean = false
	@Input() pccPatients: PccPatientDTO[]
	@Input() hasMorePatients: boolean
	@Input() title: string = 'LINK PATIENT PointClickCare RECORDS'
	@Input() buttonText: string = 'Link'
	@Input() pccHasMoreFacilities: boolean
	@Input() searchText: string
	@Input() pccFacilities: PccFacilityDTO[]
	@Output('isVisibleEmitter') isVisibleEmitter = new EventEmitter()
	@Output('pageIndexChangeEmitter') pageIndexChange = new EventEmitter()
	@Output('pageFacilityIndexChangeEmitter') pageFacilityIndexChange =
		new EventEmitter()
	@Output('changeSearchInputEmitter') changeSearchInput = new EventEmitter()
	@Output('emrIdEmitter') emrIdEmitter = new EventEmitter()
	@Output('emrPatientEmitter') emrPatientEmitter = new EventEmitter()
	@Output('facilityIdEmitter') facilityIdEmitter = new EventEmitter()
	@Output('firstLoadingEmitter') firstLoadingEmitter = new EventEmitter()
	@Input() loading: boolean
	prevIdx: number | null = null
	showButton: boolean = false
	selectedProvince: number
	firstLoad: boolean = true

	constructor() {}

	ngOnInit(): void {
		this.setIntersectionObserverSetting()
		this.selectedProvince = this.pccFacilities[0].facId
		this.handlerSelectProvinceChange(this.pccFacilities[0].facId)
	}

	removeComponentState() {
		// this.pccPatients[this.prevIdx].check = false
		this.prevIdx = null
		this.showButton = false
		this.firstLoad = true
		if (!this.pccPatients.length) return
	}

	handlerClickCancelButton(): void {
		this.isVisibleEmitter.emit(false)
		this.removeComponentState()
		this.selectedProvince = 12
	}

	handlerClick(patient: PccPatientDTO) {
		if (this.prevIdx !== null && this.pccPatients[this.prevIdx]) {
			this.pccPatients[this.prevIdx].check = false
		}
		this.prevIdx = this.pccPatients.findIndex(
			(p) => p.patientId === patient.patientId
		)
		patient.check = true
		this.showButton = true
	}

	setIntersectionObserverSetting() {
		const options = {
			rootMargin: '200px',
			threshold: 0.5
		}
		const callback = (entries: any) => {
			entries.forEach((en: any) => {
				if (!en.isIntersecting || !this.hasMorePatients) return
				if (this.loading) {
					// this.firstLoad = false
					return
					// this.firstLoadingEmitter.emit(true)
					// // setTimeout(() => this.pageIndexChange.emit(true), 2000)
					// return
				}
				this.pageIndexChange.emit(true)
			})
		}
		const observer = new IntersectionObserver(callback, options)
		setTimeout(() => {
			// @ts-ignore
			observer.observe(document.querySelector('div.lazy'))
		}, 0)
	}

	handlerLinkButtonClick() {
		if (this.prevIdx !== null) {
			this.emrIdEmitter.emit(this.pccPatients[this.prevIdx].patientId)
			this.emrPatientEmitter.emit(this.pccPatients[this.prevIdx])
		}
		this.removeComponentState()
		this.isVisibleEmitter.emit(false)
	}

	handlerInputTextChange($event: string) {
		this.changeSearchInput.emit($event)
		this.removeComponentState()
	}

	trackByMethod(index: number, el: PccPatientDTO): number {
		return el.patientId
	}

	handlerSelectProvinceChange($event: number) {
		this.removeComponentState()
		this.facilityIdEmitter.emit($event)
	}

	loadMore() {
		if (!this.pccHasMoreFacilities) return
		this.pageFacilityIndexChange.emit(true)
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['pccPatients'] && this.prevIdx !== null) {
			this.pccPatients[this.prevIdx].check = true
		}
	}
}
