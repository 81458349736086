import { getUserLocale } from './functions'

export const checkTypoRules = {
	body_temperature: {
		min: getUserLocale() === 'en-US' ? 93 : 34,
		max: getUserLocale() === 'en-US' ? 106 : 41
	},
	heart_rate: {
		min: 40,
		max: 150
	},
	respiration_rate: {
		min: 5,
		max: 35
	},
	spo2: {
		min: 70,
		max: 100
	},
	bloodGlucose: {
		min: 40,
		max: 300
	},
	diastolicPressure: {
		min: 40,
		max: 120
	},
	systolicPressure: {
		min: 70,
		max: 220
	},
	get: function (type: string) {
		if (type === 'body_temperature') {
			return this.body_temperature
		} else if (type === 'heart_rate') {
			return this.heart_rate
		} else if (type === 'respiration_rate') {
			return this.respiration_rate
		} else if (type === 'spo2') {
			return this.spo2
		} else if (type === 'diastolicPressure') {
			return this.diastolicPressure
		} else if (type === 'systolicPressure') {
			return this.systolicPressure
		} else if (type === 'bloodGlucose') {
			return this.bloodGlucose
		}
		{
			return {
				min: 0,
				max: 0
			}
		}
	}
}
