import { Selector, State } from '@ngxs/store'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Injectable } from '@angular/core'
import { AlertState } from './alert/alert.state'
import { EntityDictionary } from '@angular-ru/cdk/entity'
import {
	Alert,
	AlertDTO,
	AlertResolution,
	alertUrgencyComparator,
	UpdateAlertInterface
} from '../shared/model/alert'
import { PatientState } from './patient/patient.state'
import { PatientDTO, PatientInterface } from '../shared/model/patient'
import { EntityCollation } from './root-store-common'
import { catchError, ignoreElements, mergeMap, Observable, of, tap } from 'rxjs'
import { FileState } from './file/file.state'
import { FileDTO } from '../shared/model/file'
import { DeviceState } from './device/device.state'
import { DeviceDTO } from '../shared/model/device.model'
import { AlertRuleDTO } from '../shared/model/alert-rules.model'
import { AlertRuleState } from './alert-rule/alert-rule.state'
import { AlertsBrowsingState } from './alerts-browsing/alerts-browsing.state'
import { UserState } from './user/user.state'
import { BackendService } from '../shared/services/backend.service'
import { TaskState } from './task/task.state'
import { TaskPatientInterface } from '../shared/model/task.model'
import { PreferenceState } from './preference/preference.state'
import { ScreenBlockedStatus } from '../shared/model/preference.model'
import {
	ObservationField,
	ObservationsLatestMap,
	PatientObservationDTO
} from '../shared/model/patient-observation'
import { LoginRequestInterface } from '../shared/model/auth.model'
import { PccState } from './pcc/pcc.state'
import { HttpClient } from '@angular/common/http'
import { environment } from '../environments/environment'
import { NotificationService } from '../shared/services/notification.service'
import { MeasurementState } from './measurement/measurement.state'
import { orderBy } from 'lodash-es'

@StateRepository()
@State({
	name: 'rootSelectors'
})
@Injectable()
export class RootStore {
	lastData: ObservationField[] = []

	constructor(
		private alertState: AlertState,
		private patientState: PatientState,
		private alertRule: AlertRuleState,
		private alertsBrowsingState: AlertsBrowsingState,
		private userState: UserState,
		private backendService: BackendService,
		private http: HttpClient,
		private pccState: PccState,
		private ntfService: NotificationService
	) {}

	@Selector([AlertState.alerts, PatientState.allDepartmentPatients])
	public static devicesAlertsCount(
		state: EntityCollation,
		alerts: EntityDictionary<string, AlertDTO>,
		patients: PatientDTO[]
	): number {
		let currentAlerts: AlertDTO[] = []
		patients.forEach((p) => {
			const alert = Object.values(alerts).find(
				(a) => a.patient && a.patient.id === p.id
			)
			if (alert) {
				currentAlerts = [...currentAlerts, alert]
			}
		})
		return currentAlerts
			.filter((a) => a.status === 'open')
			.filter((v: AlertDTO) => v.alertedDevice).length
	}

	@Selector([
		RootStore.hydratedAlertsWithoutEscalated,
		TaskState.currentTasks,
		PreferenceState.screenBlockedStatus
	])
	public static screenBlockedStatus(
		state: EntityCollation,
		alerts: EntityDictionary<string, AlertDTO>,
		tasks: TaskPatientInterface[],
		screenBlockedStatus: string
	): any {
		const havAlert = !!Object.values(alerts)
			.filter((a) => a.status === 'open')
			.filter((v: AlertDTO) => v.alertedDevice).length
		const activeTasks = !!tasks.filter((t) => !t.isLaterTimeLine).length

		if (havAlert && screenBlockedStatus === 'blocked') {
			return ScreenBlockedStatus.RedBlocked
		} else if (!havAlert && activeTasks && screenBlockedStatus === 'blocked') {
			return ScreenBlockedStatus.BlueBlocked
		} else if (!havAlert && !activeTasks && screenBlockedStatus === 'blocked') {
			return ScreenBlockedStatus.Blocked
		} else {
			return ''
		}
	}

	@Selector([MeasurementState.measurement, PatientState.patient])
	public static measurement(
		state: EntityCollation,
		measurement: EntityDictionary<string, PatientObservationDTO>,
		patient: PatientInterface | null
	): ObservationField[] {
		let data: ObservationField[] = [];
		if (patient) {
			Object.keys(measurement).forEach((key) => {
				if (key === patient?.id) {
					data = orderBy(Object.values(measurement[key].observations), 'timestamp', 'asc');
				}
			})
			return data;
		}
		return data;
	}

	@Selector([MeasurementState.measurement, PatientState.patient])
	public static latestPerVital(
		state: EntityCollation,
		measurement: EntityDictionary<string, PatientObservationDTO>,
		patient: PatientInterface | null
	): Partial<ObservationsLatestMap> {
		if (patient) {
			let data: Partial<ObservationsLatestMap> = {};
			Object.keys(measurement).forEach((key) => {
				if (key === patient?.id) {
					data = measurement[key].latestPerVital;
				}
			})
			return data;
		}
		return {};
	}

	@Selector([PccState.pccSentPatientVitals])
	public static pccSentPatientVitals(
		state: EntityCollation,
		pccSentPatientVitals: string[]
	): string[] {
		return pccSentPatientVitals
	}

	@Selector([
		AlertState.alerts,
		PatientState.entities,
		FileState.files,
		DeviceState.entities
	])
	public static hydratedAlertsWithoutEscalated(
		state: EntityCollation,
		alerts: EntityDictionary<string, AlertDTO>,
		patients: EntityDictionary<string, PatientDTO>,
		files: EntityDictionary<string, FileDTO>,
		devices: EntityDictionary<string, DeviceDTO>
	): Alert[] {
		let result = Object.values(alerts)
			.filter((a) => a.resolution != AlertResolution.Escalated)
			.map((a) => {
				const patient: PatientDTO | undefined = a.patient?.id
					? patients[a.patient.id]
					: undefined
				const avatar = patient?.avatar?.id
					? files[patient.avatar.id]
					: undefined
				const device: DeviceDTO | undefined = a.alertedDevice?.id
					? devices[a.alertedDevice.id]
					: undefined

				return {
					...a,
					patient: {
						...a.patient,
						...patient,
						...{
							avatar: {
								...patient?.avatar,
								...avatar
							}
						}
					},
					alertedDevice: {
						...a.alertedDevice,
						...device
					}
				} as Alert
			})

		result.sort(alertUrgencyComparator)
		return result
	}

	@Selector([AlertState.OpenAlerts, PatientState.allDepartmentPatients])
	public static patientsAlertsCount(
		state: EntityCollation,
		alerts: AlertDTO[],
		patients: PatientDTO[]
	): number {
		let currentPatientAlertsCount = 0
		patients.forEach((p) => {
			if (alerts.find((a) => a.patient && a.patient.id === p.id)) {
				currentPatientAlertsCount += 1
			}
		})
		return currentPatientAlertsCount
	}

	@DataAction()
	public updateAlert(
		@Payload('entityId') id: string,
		@Payload('update') data: UpdateAlertInterface
	): Observable<void> {
		const user = this.userState.getState().user
		const entityDiff = data.resolution
			? {
					...data,
					resolvedBy: {
						id: user?.id,
						name: `${user?.name.lastName} ${user?.name.firstName}`,
						parentEntityId: null,
						templateId: (user as any)._template.id
					}
			  }
			: {
					...data,
					resolvedBy: null
			  }
		return this.backendService.updateAlert(id, entityDiff).pipe(
			mergeMap((alert) => {
				this.alertState.removeOne(alert.id)
				this.alertsBrowsingState.upsertOne(alert)
				return of()
			}),
			ignoreElements()
		)
	}

	@DataAction()
	public checkBiotLoginAndUpdatePccEmrVitals(
		@Payload('req') req: LoginRequestInterface,
		@Payload('patientId') patientId: string,
		@Payload('data') data: ObservationField
	): Observable<void> {
		// @ts-ignore
		return this.http
			.post(`${environment.apiUrl}/ums/v2/users/login`, {
				...req
			})
			.pipe(
				tap(() => {
					this.pccState.updatePccEmrMeasurement({
						observedPatient: patientId,
						...data
					})
				}),
				catchError(() => {
					return of(null)
				}),
				ignoreElements()
			)
	}

	@DataAction()
	public createAlertRule(
		@Payload('alertRule') alertRule: AlertRuleDTO,
		@Payload('patientId') patientId: string
	) {
		return this.backendService.addAlertRule(alertRule).pipe(
			tap((res) => {
				// this.patientState.upsertOne()
				this.patientState.updatePatient(patientId, {
					alertRules: {
						id: res.id,
						name: res.name,
						templateId: 'c159c6bf-8aed-4965-b1cf-4b7015a19948'
					}
				})
				// @ts-ignore
				// const currentPatient: PatientDTO = Object.values(
				// 	this.patientState.entities
				// ).find((p) => p.id === patientId)
				// this.patientState.upsertOne({
				// 	...currentPatient,
				// 	alertRules: {
				// 		id: res.id,
				// 		name: res.name,
				// 		templateId: 'c159c6bf-8aed-4965-b1cf-4b7015a19948'
				// 	}
				// })
				this.alertRule.upsertOne(res)
				this.ntfService.success(`Alert rule has been created`)
			})
		)
	}
}
