<section class='warning-modal'>
  <div class='warning-modal__container'>
    <div class='warning-modal__title-box'>
      <i nz-icon nzType='icons:warning'></i>
      <div class='title'>Warning</div>
    </div>
    <div class='text'>Exiting this page without updating your data will result in data loss</div>
    <div class='warning-modal__button-box'>
      <button (click)='closeModalEmitter.emit(false)'>Exit</button>
      <button (click)='saveModalDataEmitter.emit(true)' class='save'>Save</button>
    </div>
  </div>
</section>
