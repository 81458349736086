import { Pipe, PipeTransform } from '@angular/core'
import { MedicalSignAlertRuleDTO } from '../../../shared/model/alert-rules.model'
import {
	getUserLocale,
	transformCelsiusToFahrenhei
} from '../../helpers/functions'

@Pipe({
	name: 'medicalCriticalSign'
})
export class MedicalCriticalSignPipe implements PipeTransform {
	transform(
		value: any,
		rules: MedicalSignAlertRuleDTO | any,
		type: string = 'default'
	): boolean {
		if (!value || !rules) return false
		if (type !== 'default' && getUserLocale() === 'en-US') {
			return (
				value > transformCelsiusToFahrenhei(rules.minCritical) ||
				value < transformCelsiusToFahrenhei(rules.maxCritical)
			)
		}
		return value > rules.minCritical || value < rules.maxCritical
	}
}
