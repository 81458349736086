import { Pipe, PipeTransform } from '@angular/core'
import {
	DeviceCriticalStatus,
	DeviceInterface
} from '../../shared/model/device.model'

@Pipe({
	name: 'deviceResolution'
})
export class DeviceResolutionPipe implements PipeTransform {
	transform(data: DeviceInterface, ...args: unknown[]): string {
		if (!data) return ''
		if (DeviceCriticalStatus.NoConnection === data.statusDetailInformation) {
			return 'Turn on or reset device'
		} else if (
			DeviceCriticalStatus.BadReading === data.statusDetailInformation
		) {
			return 'Clean and reposition device'
		} else if (
			DeviceCriticalStatus.LowBattery === data.statusDetailInformation ||
			DeviceCriticalStatus.NoBattery === data.statusDetailInformation
		) {
			return `Charge device`
		} else if (
			DeviceCriticalStatus.NoConfiguration === data.statusDetailInformation
		) {
			return `Calibrate device`
		}
		return ''
	}
}
