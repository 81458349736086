import { Id } from './common'
import { Metadata } from '@biot-client/biot-client-generic-entity'
import { ObservationFields } from './patient-observation'

export enum AlertRuleName {
	DefaultAlertRules = 'DefaultAlertRules'
}

export interface AlertRuleDTO extends Id {
	name: string
	heartRate: MedicalSignAlertRuleDTO
	bodyTemperature: MedicalSignAlertRuleDTO
	diastolicPressure: MedicalSignAlertRuleDTO
	systolicPressure: MedicalSignAlertRuleDTO
	respirationRate: MedicalSignAlertRuleDTO
	[ObservationFields.HeartRate]?: MedicalSignAlertRuleDTO
	[ObservationFields.BodyTemperature]?: MedicalSignAlertRuleDTO
	[ObservationFields.RespirationRate]?: MedicalSignAlertRuleDTO
	[ObservationFields.SpO2]?: MedicalSignAlertRuleDTO
	isTemplate?: boolean
	spO2: MedicalSignAlertRuleDTO
	bloodGlucose: MedicalSignAlertRuleDTO
	_name?: string
	_templateId?: string
	_ownerOrganization?: any
	_lastModifiedTime?: string
	exitBed: {
		monitorFrom: number
		monitorTill: number
	}
}

export interface AlertRuleBackend {
	data: AlertRuleDTO[]
	metadata: Metadata
}

export interface CreateAlertRule {
	_name?: string
	_templateId?: string
	_ownerOrganization?: any
	maxCriticalHeartRate: number
	minCriticalHeartRate: number
	maxNormalHeartRate: number
	minNormalHeartRate: number
	maxCriticalBodyTemperature: number
	maxNormalBodyTemperature: number
	minCriticalBodyTemperature: number
	minNormalBodyTemperature: number
	maxCriticalDiastolicPressure: number
	minCriticalDiastolicPressure: number
	maxNormalDiastolicPressure: number
	minNormalDiastolicPressure: number
	maxCriticalRespirationRate: number
	minCriticalRespirationRate: number
	maxNormalRespirationRate: number
	minNormalRespirationRate: number
	maxCriticalSpO2: number
	minCriticalSpO2: number
	maxNormalSpO2: number
	minNormalSpO2: number
	maxCriticalSystolicPressure: number
	maxNormalSystolicPressure: number
	minCriticalSystolicPressure: number
	minNormalSystolicPressure: number
	maxCriticalBloodGlucose: number
	maxNormalBloodGlucose: number
	minCriticalBloodGlucose: number
	minNormalBloodGlucose: number
	exitBedRiskMonitorFrom: number
	exitBedRiskMonitorTill: number
	criticalHighHeartRateDuration: number
	criticalLowHeartRateDuration: number
	criticalHighBodyTemperatureDuration: number
	criticalLowBodyTemperatureDuration: number
	criticalHighDiastolicDuration: number
	criticalLowDiastolicDuration: number
	criticalHighRespirationRateDuration: number
	criticalLowRespirationRateDuration: number
	criticalHighSpO2Duration: number
	criticalLowSpO2Duration: number
	criticalHighSystolicDuration: number
	criticalLowSystolicDuration: number
	criticalHighBloodGlucoseDuration: number
	criticalLowBloodGlucoseDuration: number
}

export interface UpdateAlertRule {
	maxCriticalHeartRate: number
	minCriticalHeartRate: number
	maxNormalHeartRate: number
	minNormalHeartRate: number
	maxCriticalBodyTemperature: number
	maxNormalBodyTemperature: number
	minCriticalBodyTemperature: number
	minNormalBodyTemperature: number
	maxCriticalDiastolicPressure: number
	minCriticalDiastolicPressure: number
	maxNormalDiastolicPressure: number
	minNormalDiastolicPressure: number
	maxCriticalRespirationRate: number
	minCriticalRespirationRate: number
	maxNormalRespirationRate: number
	minNormalRespirationRate: number
	maxCriticalSpO2: number
	minCriticalSpO2: number
	maxNormalSpO2: number
	minNormalSpO2: number
	maxCriticalSystolicPressure: number
	maxNormalSystolicPressure: number
	minCriticalSystolicPressure: number
	minNormalSystolicPressure: number
	maxCriticalBloodGlucose: number
	maxNormalBloodGlucose: number
	minCriticalBloodGlucose: number
	minNormalBloodGlucose: number
	exitBedRiskMonitorFrom: number
	exitBedRiskMonitorTill: number
	criticalHighHeartRateDuration: number
	criticalLowHeartRateDuration: number
	criticalHighBodyTemperatureDuration: number
	criticalLowBodyTemperatureDuration: number
	criticalHighDiastolicDuration: number
	criticalLowDiastolicDuration: number
	criticalHighRespirationRateDuration: number
	criticalLowRespirationRateDuration: number
	criticalHighSpO2Duration: number
	criticalLowSpO2Duration: number
	criticalHighSystolicDuration: number
	criticalLowSystolicDuration: number
	criticalHighBloodGlucoseDuration: number
	criticalLowBloodGlucoseDuration: number
}

export function toAlertRuleUpdate(data: AlertRuleDTO): UpdateAlertRule {
	return {
		maxCriticalHeartRate: data.heartRate.maxCritical,
		minCriticalHeartRate: data.heartRate.minCritical,
		maxNormalHeartRate: data.heartRate.maxNormal,
		minNormalHeartRate: data.heartRate.minNormal,
		maxCriticalBodyTemperature: data.bodyTemperature.maxCritical,
		maxNormalBodyTemperature: data.bodyTemperature.maxNormal,
		minCriticalBodyTemperature: data.bodyTemperature.minCritical,
		minNormalBodyTemperature: data.bodyTemperature.minNormal,
		maxCriticalDiastolicPressure: data.diastolicPressure.maxCritical,
		minCriticalDiastolicPressure: data.diastolicPressure.minCritical,
		maxNormalDiastolicPressure: data.diastolicPressure.maxNormal,
		minNormalDiastolicPressure: data.diastolicPressure.minNormal,
		maxCriticalRespirationRate: data.respirationRate.maxCritical,
		minCriticalRespirationRate: data.respirationRate.minCritical,
		maxNormalRespirationRate: data.respirationRate.maxNormal,
		minNormalRespirationRate: data.respirationRate.minNormal,
		maxCriticalSpO2: data.spO2.maxCritical,
		minCriticalSpO2: data.spO2.minCritical,
		maxNormalSpO2: data.spO2.maxNormal,
		minNormalSpO2: data.spO2.minNormal,
		maxCriticalSystolicPressure: data.systolicPressure.maxCritical,
		maxNormalSystolicPressure: data.systolicPressure.maxNormal,
		minCriticalSystolicPressure: data.systolicPressure.minCritical,
		minNormalSystolicPressure: data.systolicPressure.minNormal,
		maxCriticalBloodGlucose: data.bloodGlucose.maxCritical,
		maxNormalBloodGlucose: data.bloodGlucose.maxNormal,
		minCriticalBloodGlucose: data.bloodGlucose.minCritical,
		minNormalBloodGlucose: data.bloodGlucose.minNormal,
		exitBedRiskMonitorFrom: data.exitBed.monitorFrom,
		exitBedRiskMonitorTill: data.exitBed.monitorTill,
		criticalHighHeartRateDuration: data.heartRate.criticalHighDuration,
		criticalLowHeartRateDuration: data.heartRate.criticalLowDuration,
		criticalHighBodyTemperatureDuration:
			data.bodyTemperature.criticalHighDuration,
		criticalLowBodyTemperatureDuration:
			data.bodyTemperature.criticalLowDuration,
		criticalHighDiastolicDuration: data.diastolicPressure.criticalHighDuration,
		criticalLowDiastolicDuration: data.diastolicPressure.criticalLowDuration,
		criticalHighRespirationRateDuration:
			data.respirationRate.criticalHighDuration,
		criticalLowRespirationRateDuration:
			data.respirationRate.criticalLowDuration,
		criticalHighSpO2Duration: data.spO2.criticalHighDuration,
		criticalLowSpO2Duration: data.spO2.criticalLowDuration,
		criticalHighSystolicDuration: data.systolicPressure.criticalHighDuration,
		criticalLowSystolicDuration: data.systolicPressure.criticalLowDuration,
		criticalHighBloodGlucoseDuration: data.bloodGlucose.criticalHighDuration,
		criticalLowBloodGlucoseDuration: data.bloodGlucose.criticalLowDuration
	}
}

export function toAlertRuleCreate(data: AlertRuleDTO): CreateAlertRule {
	return {
		_name: data._name,
		_templateId: data._templateId,
		_ownerOrganization: data._ownerOrganization,
		maxCriticalHeartRate: data.heartRate.maxCritical,
		minCriticalHeartRate: data.heartRate.minCritical,
		maxNormalHeartRate: data.heartRate.maxNormal,
		minNormalHeartRate: data.heartRate.minNormal,
		maxCriticalBodyTemperature: data.bodyTemperature.maxCritical,
		maxNormalBodyTemperature: data.bodyTemperature.maxNormal,
		minCriticalBodyTemperature: data.bodyTemperature.minCritical,
		minNormalBodyTemperature: data.bodyTemperature.minNormal,
		maxCriticalDiastolicPressure: data.diastolicPressure.maxCritical,
		minCriticalDiastolicPressure: data.diastolicPressure.minCritical,
		maxNormalDiastolicPressure: data.diastolicPressure.maxNormal,
		minNormalDiastolicPressure: data.diastolicPressure.minNormal,
		maxCriticalRespirationRate: data.respirationRate.maxCritical,
		minCriticalRespirationRate: data.respirationRate.minCritical,
		maxNormalRespirationRate: data.respirationRate.maxNormal,
		minNormalRespirationRate: data.respirationRate.minNormal,
		maxCriticalSpO2: data.spO2.maxCritical,
		minCriticalSpO2: data.spO2.minCritical,
		maxNormalSpO2: data.spO2.maxNormal,
		minNormalSpO2: data.spO2.minNormal,
		maxCriticalSystolicPressure: data.systolicPressure.maxCritical,
		maxNormalSystolicPressure: data.systolicPressure.maxNormal,
		minCriticalSystolicPressure: data.systolicPressure.minCritical,
		minNormalSystolicPressure: data.systolicPressure.minNormal,
		maxCriticalBloodGlucose: data.bloodGlucose.maxCritical,
		maxNormalBloodGlucose: data.bloodGlucose.maxNormal,
		minCriticalBloodGlucose: data.bloodGlucose.minCritical,
		minNormalBloodGlucose: data.bloodGlucose.minNormal,
		exitBedRiskMonitorFrom: data.exitBed.monitorFrom,
		exitBedRiskMonitorTill: data.exitBed.monitorTill,
		criticalHighHeartRateDuration: data.heartRate.criticalHighDuration,
		criticalLowHeartRateDuration: data.heartRate.criticalLowDuration,
		criticalHighBodyTemperatureDuration:
			data.bodyTemperature.criticalHighDuration,
		criticalLowBodyTemperatureDuration:
			data.bodyTemperature.criticalLowDuration,
		criticalHighDiastolicDuration: data.diastolicPressure.criticalHighDuration,
		criticalLowDiastolicDuration: data.diastolicPressure.criticalLowDuration,
		criticalHighRespirationRateDuration:
			data.respirationRate.criticalHighDuration,
		criticalLowRespirationRateDuration:
			data.respirationRate.criticalLowDuration,
		criticalHighSpO2Duration: data.spO2.criticalHighDuration,
		criticalLowSpO2Duration: data.spO2.criticalLowDuration,
		criticalHighSystolicDuration: data.systolicPressure.criticalHighDuration,
		criticalLowSystolicDuration: data.systolicPressure.criticalLowDuration,
		criticalHighBloodGlucoseDuration: data.bloodGlucose.criticalHighDuration,
		criticalLowBloodGlucoseDuration: data.bloodGlucose.criticalLowDuration
	}
}

export interface MedicalSignAlertRuleDTO {
	maxCritical: number
	maxNormal: number
	minNormal: number
	minCritical: number
	criticalHighDuration: number
	criticalLowDuration: number
}
