import { Pipe, PipeTransform } from '@angular/core'
import moment from 'moment/moment'

@Pipe({
	name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
	transform(date: any, ...args: unknown[]): string {
		if (!date) return ''
		const now = moment()
		const publishedDate = moment(new Date(date))
		return moment.duration(publishedDate.diff(now)).humanize(true)
	}
}
