import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import {
	combineLatest,
	catchError,
	EMPTY,
	forkJoin,
	ignoreElements,
	Observable,
	of,
	Subscription,
	tap
} from 'rxjs'
import {
	ObservationField,
	ObservationFields,
	ObservationsLatestMap,
	PatientObservationDTO
} from '../../shared/model/patient-observation'
import { dataShaping, subtractHours } from '../../core/helpers/functions'
import { cloneDeep, orderBy } from 'lodash-es'
import { BackendService } from '../../shared/services/backend.service'
import {
	ManualMeasurementsDTO,
	MeasurementMessageFromDeviceDTO,
	MeasurementSummaryInterface
} from '../../shared/model/measurement'
import { startWith } from 'rxjs/operators'
import { DeviceDTO } from '../../shared/model/device.model'
import { StoreEventsService } from '../store-events.service'
import { getLatestTimestampFromMeasurements } from '../../core/helpers/get-latest-timestamp-from-measurements'

export const measurementFeatureName = 'measurement'

@StateRepository()
@State<CollatableEntityCollections<PatientObservationDTO>>({
	name: measurementFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class MeasurementState extends CollatableEntityCollectionsRepository<
	PatientObservationDTO,
	EntityCollation
> {
	subscriptionMeasurementsWSConnection$: Subscription
	subscriptionMeasurementsWSOnMessage$: Subscription
	deviceStatusFieldsWSOnMessage$: Observable<any> = this.backendService.deviceStatusFieldsWSOnMessage$;
	private measurementStateSubscription: Subscription;
	protected ectTransformData: {
		value: number
		timestamp: string | Date
	}[] = []
	protected ecgDate: {
		timestamp: string | Date
		frequency: number
		data: number[]
	}[] = []
	protected ecgCount: number = 0
	protected mqttConnection: any

	constructor(
		private backendService: BackendService,
		private actions: Actions,
		private storeEvents: StoreEventsService
	) {
		super()
	}

	@Selector()
	public static measurement(
		state: CollatableEntityCollections<PatientObservationDTO>
	): EntityDictionary<string, PatientObservationDTO> {
		return state.entities
	}

	@Selector()
	public static insightMeasurements(
		state: CollatableEntityCollections<PatientObservationDTO>
	) {
		return state.insightMeasurements
	}

	@Selector()
	public static measurementReports(
		state: CollatableEntityCollections<PatientObservationDTO>
	): MeasurementSummaryInterface[] {
		return state.reports
	}

	@Selector()
	public static manualMeasurements(
		state: CollatableEntityCollections<PatientObservationDTO>
	): ManualMeasurementsDTO[] {
		return state.manualMeasurement
	}

	@Selector()
	public static measurementHistorical(
		state: CollatableEntityCollections<PatientObservationDTO>
	): EntityDictionary<string, PatientObservationDTO> | null {
		return state.historicalMeasurements
	}

	@Selector()
	public static isLoading(
		state: CollatableEntityCollections<PatientObservationDTO>
	): boolean {
		return state.isLoading
	}

	@Selector()
	public static measurementHistoricalEcg(
		state: CollatableEntityCollections<PatientObservationDTO>
	):
		| {
				value: number
				timestamp: string | Date
		  }[]
		| [] {
		return state.historicalEcg
	}

	@Selector()
	public static substrHours(
		state: CollatableEntityCollections<PatientObservationDTO>
	): Date {
		return subtractHours(state.subtractHours)
	}

	public override ngxsOnInit() {
		this.storeEvents.loggedIn$.pipe(
			tap(() => {
				this.patchState({ isLoading: true });
				if (this.measurementStateSubscription) this.measurementStateSubscription.unsubscribe();
				this.measurementStateSubscription = combineLatest([this.backendService
					.subscribeMeasurementsWSConnection(), this.backendService.measurementsWSOnMessage$.asObservable().pipe(startWith(''))]).pipe(
						tap(([connected, data]) => {
							this.mqttConnection = connected;
							if (data !== '') {
								this.measurementsWSOnMessageCallback(data as unknown as ObservationField);
							}
						})
					).subscribe();
			})
		).subscribe();

		this.storeEvents.logout$.pipe(
			tap(() => {
				if (this.measurementStateSubscription) this.measurementStateSubscription.unsubscribe();
				this.reset();
			})
		).subscribe();
	}

	@DataAction()
	public loadPatientObservations(@Payload('patientIds') patientIds: string[]) {
		// this.ctx.patchState({
		// 	entities: {}
		// })
		return forkJoin(
			patientIds.map((i) =>
				this.backendService.getPatientMeasurements(
					i,
					this.ctx.getState().subtractHours
				)
			)
		).pipe(
			tap((a) => this.setMany(a)),
			ignoreElements()
		)
	}

	@DataAction()
	public getPatientInsightMeasurements(
		@Payload('patientId') patientId: string,
		@Payload('attributes') attributes: string[],
		@Payload('startTime') startTime: string,
		@Payload('endTime') endTime: string
	) {
		return this.backendService
			.getPatientInsightObservations(patientId, attributes, startTime, endTime)
			.pipe(
				tap((insightMeasurements: ObservationField[]) => {
					this.patchState({ insightMeasurements })
				}),
				ignoreElements()
			)
	}

	// @DataAction()
	// public loadPatientReportsObservations(
	// 	@Payload('idx') idx: number,
	// 	@Payload('patientIds') patientIds: Array<string[]>
	// ) {
	// 	return forkJoin(
	// 		patientIds[idx].map((i) =>
	// 			this.backendService.getPatientHistoricalObservations(
	// 				i,
	// 				new Date(),
	// 				this.ctx.getState().subtractHours,
	// 				10800
	// 			)
	// 		)
	// 	).pipe(
	// 		tap((reports) => {
	// 			this.setReportsSetting(reports)
	// 			if (patientIds[idx + 1]) {
	// 				this.loadPatientReportsObservations(idx + 1, patientIds)
	// 			}
	// 		}),
	// 		ignoreElements()
	// 	)
	// }

	// @DataAction()
	// LoadPatientCurrentShiftReportObservations(
	// 	@Payload('idx') idx: number,
	// 	@Payload('patientIds') patientIds: Array<string[]>,
	// 	@Payload('startTime') startTime: string,
	// 	@Payload('endTime') endTime: string
	// ) {
	// 	return forkJoin(
	// 		patientIds[idx].map((i) =>
	// 			this.backendService.getPatientReportObservations(
	// 				i,
	// 				startTime,
	// 				endTime,
	// 				10800
	// 			)
	// 		)
	// 	).pipe(
	// 		tap((reports) => {
	// 			this.setReportsSetting(reports)
	// 			if (patientIds[idx + 1]) {
	// 				this.LoadPatientCurrentShiftReportObservations(
	// 					idx + 1,
	// 					patientIds,
	// 					startTime,
	// 					endTime
	// 				)
	// 			}
	// 		}),
	// 		ignoreElements()
	// 	)
	// }

	@DataAction()
	public saveMeasurement(
		@Payload('data') data: MeasurementMessageFromDeviceDTO
	) {
		return this.backendService.addNewMeasurement(data).pipe(
			tap((a) => {
				const measurementEntities = cloneDeep(this.getState().entities) 
				// @ts-ignore
				if (measurementEntities[a.metadata.patientId]) {
					// @ts-ignore
					const patientObservationFields: ObservationField[] =
					//@ts-ignore
						(measurementEntities[
							//@ts-ignore
							a.metadata.patientId
						].latest = [
							// @ts-ignore
							...measurementEntities[a.metadata.patientId].latest,
							{ ...a.data, timestamp: a.metadata?.timestamp }
						].filter(
							(el) =>
								el.timestamp! >=
								subtractHours(this.getState().subtractHours).toISOString()
						))
						// @ts-ignore
					this.upsertOne({
						// @ts-ignore
						id: a.metadata.patientId,
						latest: orderBy([...patientObservationFields], 'timestamp', 'asc')
					})
				} else {
					this.upsertOne({
						// @ts-ignore
						id: a.metadata.patientId,
						// @ts-ignore
						latest: [{ ...a.data, timestamp: a.metadata?.timestamp }]
					})
				}
			}),
			ignoreElements()
		)
	}

	// @DataAction()
	// public lastObservations(
	// 	@Payload('patients') patients: PatientDTO[] | BackendPatientDTO[]
	// ) {
	// 	const tmpObj: {
	// 		id: string
	// 		latest: ObservationField[]
	// 	}[] = []
	// 	const setMeasurement = () => {
	// 		this.setMany(tmpObj)
	// 		this.patchState({
	// 			lastObservations: tmpObj
	// 		})
	// 	}
	// 	patients
	// 		.map((p) => ({
	// 			// @ts-ignore
	// 			id: !p.id ? p._id : p.id,
	// 			observation: [
	// 				{ key: ObservationFields.Activity, ...p.activity },
	// 				{ key: ObservationFields.BloodGlucose, ...p.bloodGlucose },
	// 				{ key: ObservationFields.HeartRate, ...p.heart_rate },
	// 				{ key: ObservationFields.BodyTemperature, ...p.body_temperature },
	// 				{ key: ObservationFields.Posture, ...p.posture },
	// 				{ key: ObservationFields.RespirationRate, ...p.respiration_rate },
	// 				{ key: ObservationFields.SpO2, ...p.spo2 },
	// 				{ key: ObservationFields.DiastolicPressure, ...p.diastolicPressure },
	// 				{ key: ObservationFields.SystolicPressure, ...p.systolicPressure },
	// 				{ key: ObservationFields.ExitBedRisk, ...p.exitBedRisk }
	// 			].filter((el) => el.value)
	// 		}))
	// 		.filter((el) => el.observation.length)
	// 		.forEach((p) => {
	// 			p.observation.forEach((o, index) => {
	// 				const idx = tmpObj.findIndex((o) => o.id === p.id)
	// 				if (idx === -1) {
	// 					const latest = [
	// 						{
	// 							[o.key]: o.value,
	// 							timestamp: o.timestamp
	// 						}
	// 					]
	// 					tmpObj.push({
	// 						id: p.id,
	// 						// @ts-ignore
	// 						latest
	// 					})
	// 					if (p.observation.length === index + 1) {
	// 						setMeasurement()
	// 					}
	// 					return
	// 				}
	// 				const observationIdx = tmpObj[idx].latest.findIndex(
	// 					(ob) => ob.timestamp === o.timestamp
	// 				)
	// 				if (!tmpObj[idx].latest.length || observationIdx === -1) {
	// 					// @ts-ignore
	// 					tmpObj[idx].latest.push({
	// 						[o.key]: o.value,
	// 						timestamp: o.timestamp
	// 					})
	//
	// 					tmpObj[idx].latest = orderBy(
	// 						[...tmpObj[idx].latest],
	// 						'timestamp',
	// 						'asc'
	// 					)
	//
	// 					if (p.observation.length === index + 1) {
	// 						setMeasurement()
	// 					}
	// 					return
	// 				}
	// 				tmpObj[idx].latest[observationIdx][o.key] = o.value
	// 				tmpObj[idx].latest = orderBy(
	// 					[...tmpObj[idx].latest],
	// 					'timestamp',
	// 					'asc'
	// 				)
	// 				if (p.observation.length === index + 1) {
	// 					setMeasurement()
	// 				}
	// 			})
	// 		})
	// 	return of()
	// }

	@DataAction()
	public loadHistoricalObservations(
		@Payload('patientId') patientId: string,
		@Payload('date') date: Date,
		@Payload('hours') hours: number,
		@Payload('intervalSeconds') intervalSeconds?: number
	) {
		return this.backendService
			.getPatientHistoricalObservations(patientId, date, hours, intervalSeconds)
			.pipe(
				tap((res) => {
					this.patchState({
						historicalMeasurements: {
							[patientId]: res
						}
					})
				}),
				ignoreElements()
			)
	}

	@DataAction()
	public getMeasurementSummary(
		@Payload('patientIds') patientIds: string[],
		@Payload('startTime') startTime: string,
		@Payload('endTime') endTime: string,
		@Payload('mode') mode: string
	) {
		return this.backendService
			.getMeasurementSummary(patientIds, startTime, endTime, mode)
			.pipe(
				tap((res) => {
					this.patchState({ isLoading: true });
					const measurementEntities = cloneDeep(this.getState().entities)
					let reports = cloneDeep(this.getState().reports).filter(
						(r: any) => r.observedPatient !== res.observedPatient
					)

					reports = [...reports, res].filter(
						(item) => Object.values(item.measurements).length
					)
					reports.forEach((i) => {
						
						const lastMeasurement = !measurementEntities?.[i.observedPatient]?.lastMeasurementTime || measurementEntities?.[i.observedPatient]?.lastMeasurementTime! <= i?.lastMeasurement! ? i?.lastMeasurement : measurementEntities?.[i.observedPatient]?.lastMeasurementTime;
						const latestPerVital = {
							...measurementEntities?.[i.observedPatient]?.latestPerVital,
							...this.setLatestVitalsFromReportsData(i.measurements, measurementEntities?.[i.observedPatient]?.latestPerVital),
						}

						const lastMeasurementTime = (latestPerVital?.[ObservationFields.SystolicPressure] && latestPerVital?.[ObservationFields.SystolicPressure]?.timestamp) || lastMeasurement! || getLatestTimestampFromMeasurements(i.measurements as unknown as {[key: string]: any });
						
						this.upsertOne({
							id: i.observedPatient,
							latest: [],
							observations: this.getState().entities?.[i.observedPatient]?.observations || [],
							latestPerVital: latestPerVital,
							lastMeasurementTime: new Date(lastMeasurementTime).toISOString(),
						});
						if (
							i.measurements[ObservationFields.SystolicPressure] &&
							i.measurements[ObservationFields.SystolicPressure].timestamp
						) {
							i.lastMeasurement =
								i.measurements[ObservationFields.SystolicPressure].timestamp
						} else {
							const data = orderBy(
								Object.values(i.measurements),
								'timestamp',
								'desc'
							).filter((m) => m.timestamp)
							if (data.length) {
								i.lastMeasurement = data[0].timestamp
							}
						}
					})
					this.patchState({ isLoading: false })
					this.ctx.patchState({
						reports
					})
				}),
				catchError(() => {
					this.patchState({ isLoading: false })
					return of()
				})
			)
	}

	// @DataAction()
	// public setMeasurementSpot(
	// 	@Payload('measurement')
	// 	measurement: {
	// 		body_temperature?: number
	// 		deviceId: string
	// 		diastolicPressure?: number
	// 		heart_rate?: number
	// 		patientId: string
	// 		respiration_rate?: number
	// 		systolicPressure?: number
	// 		timestamp: string
	// 	},
	// 	@Payload('manualDevice') manualDevice: DeviceDTO
	// ) {
	// 	const measurementSummary: MeasurementSummaryInterface = {
	// 		lastMeasurement: measurement.timestamp,
	// 		observedPatient: measurement.patientId,
	// 		measurements: {}
	// 	}
	// 	if (measurement.body_temperature) {
	// 		measurementSummary.measurements.bodyTemperature = {
	// 			isManual: measurement.deviceId === manualDevice.id,
	// 			value: measurement.body_temperature,
	// 			timestamp: measurement.timestamp
	// 		}
	// 	}
	// 	if (measurement.diastolicPressure) {
	// 		measurementSummary.measurements.diastolicPressure = {
	// 			isManual: measurement.deviceId === manualDevice.id,
	// 			value: measurement.diastolicPressure,
	// 			timestamp: measurement.timestamp
	// 		}
	// 	}
	// 	if (measurement.heart_rate) {
	// 		measurementSummary.measurements.heartRate = {
	// 			isManual: measurement.deviceId === manualDevice.id,
	// 			value: measurement.heart_rate,
	// 			timestamp: measurement.timestamp
	// 		}
	// 	}
	// 	if (measurement.respiration_rate) {
	// 		measurementSummary.measurements.respirationRate = {
	// 			isManual: measurement.deviceId === manualDevice.id,
	// 			value: measurement.respiration_rate,
	// 			timestamp: measurement.timestamp
	// 		}
	// 	}
	// 	if (measurement.systolicPressure) {
	// 		measurementSummary.measurements.systolicPressure = {
	// 			isManual: measurement.deviceId === manualDevice.id,
	// 			value: measurement.systolicPressure,
	// 			timestamp: measurement.timestamp
	// 		}
	// 	}
	// 	const reports = cloneDeep(this.getState().reports)
	// 	const currentPatientMeasurementSummaryIdx = reports.findIndex(
	// 		(r) => r.observedPatient === measurement.patientId
	// 	)
	// 	if (reports[currentPatientMeasurementSummaryIdx]) {
	// 		reports[currentPatientMeasurementSummaryIdx] = {
	// 			lastMeasurement: measurementSummary.lastMeasurement,
	// 			observedPatient: measurementSummary.observedPatient,
	// 			measurements: {
	// 				...reports[currentPatientMeasurementSummaryIdx].measurements,
	// 				...measurementSummary.measurements
	// 			}
	// 		}
	// 	} else {
	// 		reports.push(measurementSummary)
	// 	}
	// 	this.patchState({
	// 		reports
	// 	})
	// }

	@DataAction()
	public getManualObservations(
		@Payload('idx') idx: number,
		@Payload('patientIds') patientIds: Array<string[]>,
		@Payload('startTime') startTime: string,
		@Payload('endTime') endTime: string
	) {
		return forkJoin(
			patientIds[idx].map((i) =>
				this.backendService.getManualObservations(i, startTime, endTime)
			)
		).pipe(
			tap((reports: ManualMeasurementsDTO[]) => {
				if (Object.values(reports).length) {
					this.setManualMeasurementSetting(
						reports.filter((d) => Object.values(d).length)
					)
				}
				if (patientIds[idx + 1]) {
					this.getManualObservations(idx + 1, patientIds, startTime, endTime)
				}
			}),
			ignoreElements()
		)
	}

	@DataAction()
	public loadObservationEcg(
		@Payload('patientId') patientId: string,
		@Payload('date') date: Date
	) {
		return this.backendService.getPatientEcgObservations(patientId, date).pipe(
			tap((a) => {
				this.ectTransformData = []
				this.ecgDate = []
				this.ecgCount = 0
				if (!a.attributes.length) {
					this.ctx.patchState({
						historicalEcg: []
					})
					return
				}

				a.attributes[0].sources.forEach((item) =>
					item.measurementSessions.forEach((ms: any) => {
						ms.measurements.forEach((m: any) => this.ecgDate.push(m))
					})
				)

				this.setEcgDataSettings(
					this.ecgDate[this.ecgCount].data,
					this.ecgDate[this.ecgCount].timestamp,
					this.ecgDate[this.ecgCount].frequency
				)
			}),
			ignoreElements()
		)
	}

	protected setPaginationSetting(): Observable<any> {
		throw new Error('Method not implemented.')
	}

	protected measurementsWSOnMessageCallback(outMessage: ObservationField) {
		const measurementEntities = cloneDeep(this.getState().entities)
		if (measurementEntities[outMessage.patientId]) {
			// if (
			// 	measurementEntities[outMessage.patientId].latest[
			// 		measurementEntities[outMessage.patientId].latest.length - 1
			// 	]?.timestamp === outMessage.timestamp ||
			// 	!!measurementEntities[outMessage.patientId].latest.find(
			// 		(i) => i.timestamp === outMessage.timestamp
			// 	)
			// ) {
			// 	return
			// }
			const patientObservationFields: ObservationField[] = (measurementEntities[
				outMessage.patientId
			].latest = [
				...measurementEntities[outMessage.patientId].latest,
				{ ...outMessage }
			].filter(
				(el: ObservationField) =>
					el.timestamp >=
					subtractHours(this.getState().subtractHours).toISOString()
			))
			const latestPerVital = {
				...measurementEntities[outMessage.patientId]?.latestPerVital,
				...this.setLatestVitals(outMessage, measurementEntities[outMessage.patientId]?.latestPerVital),
			}
			let observations = {
				...measurementEntities[outMessage.patientId].observations,
				[new Date(outMessage.timestamp).getTime()]: { ...measurementEntities[outMessage.patientId].observations[new Date(outMessage.timestamp).getTime()], ...outMessage },
			}
			observations = Object.fromEntries(Object.entries(observations).filter(([key, value]) => new Date(+key).toISOString() >= subtractHours(this.getState().subtractHours).toISOString()));
			let lastMeasurementTime = outMessage.timestamp;
			if (this.getState().entities[outMessage.patientId]?.lastMeasurementTime && this.getState().entities[outMessage.patientId].lastMeasurementTime! > outMessage.timestamp) {
				lastMeasurementTime = this.getState().entities[outMessage.patientId].lastMeasurementTime!;
			}
			this.upsertOne({
				id: outMessage.patientId,
				latest: orderBy([...patientObservationFields], 'timestamp', 'asc'),
				observations,
				latestPerVital,
				lastMeasurementTime,
			})
			return
		}
		this.upsertOne({
			id: outMessage.patientId,
			latest: [{ ...outMessage }],
			observations: {
				[new Date(outMessage.timestamp).getTime()]: {
					...outMessage
				}
			},
			latestPerVital: this.setLatestVitals(outMessage) as ObservationsLatestMap,
			lastMeasurementTime: outMessage.timestamp,
		})
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}

	private setManualMeasurementSetting(reports: any) {
		if (!!this.ctx.getState().manualMeasurement) {
			this.ctx.patchState({
				manualMeasurement: [
					// @ts-ignore
					...this.ctx.getState().manualMeasurement,
					...reports
				]
			})
		} else {
			this.ctx.patchState({
				manualMeasurement: [...reports]
			})
		}
	}

	private setEcgDataSettings(
		data: number[],
		timestamp: string | Date,
		frequency: number
	) {
		let t = timestamp
		data.reverse().forEach((value: number, idx: number) => {
			this.ectTransformData.push({
				value: value,
				timestamp: (t = idx === 0 ? timestamp : dataShaping(t, frequency))
			})
			this.ectTransformData = orderBy(this.ectTransformData, 'timestamp', 'asc')
			if (
				idx === data.length - 1 &&
				this.ecgDate[this.ecgCount + 1] &&
				this.ecgDate[this.ecgCount + 1].data.length
			) {
				this.ecgCount += 1
				this.setEcgDataSettings(
					this.ecgDate[this.ecgCount].data,
					this.ecgDate[this.ecgCount].timestamp,
					this.ecgDate[this.ecgCount].frequency
				)
			}
			if (!this.ecgDate[this.ecgCount + 1]) {
				this.ctx.patchState({
					historicalEcg: orderBy(this.ectTransformData, 'timestamp', 'asc')
				})
			}
		})
	}

	private setLatestVitals(data: ObservationField, existingLatest?: ObservationsLatestMap): Partial<ObservationsLatestMap> {
		let obj: Partial<ObservationsLatestMap> = {};
		if (data.respiration_rate) {
			obj.respiration_rate = !existingLatest?.respiration_rate || existingLatest.respiration_rate.timestamp <= data.timestamp ? {
				value: data.respiration_rate,
				timestamp: data.timestamp,
				deviceId: data?.deviceId as unknown as string,
				isManual: (data?.deviceId as unknown as string).toLocaleLowerCase().includes('manual'),
			} : {
				...existingLatest.respiration_rate,
			}
		}
		if (data.diastolicPressure) {
			obj.diastolicPressure = !existingLatest?.diastolicPressure || existingLatest.diastolicPressure.timestamp <= data.timestamp ? {
				value: data.diastolicPressure,
				timestamp: data.timestamp,
				deviceId: data?.deviceId as unknown as string,
				isManual: (data?.deviceId as unknown as string).toLocaleLowerCase().includes('manual'),
			} : {
				...existingLatest.diastolicPressure,
			}
		}
		if (data.systolicPressure) {
			obj.systolicPressure = !existingLatest?.systolicPressure || existingLatest.systolicPressure.timestamp <= data.timestamp ? {
				value: data.systolicPressure,
				timestamp: data.timestamp,
				deviceId: data?.deviceId as unknown as string,
				isManual: (data?.deviceId as unknown as string).toLocaleLowerCase().includes('manual'),
			} : {
				...existingLatest.systolicPressure,
			}
		}
		if (data.bloodGlucose) {
			obj.bloodGlucose = !existingLatest?.bloodGlucose || existingLatest.bloodGlucose.timestamp <= data.timestamp ? {
				value: data.bloodGlucose,
				timestamp: data.timestamp,
				deviceId: data?.deviceId as unknown as string,
				isManual: (data?.deviceId as unknown as string).toLocaleLowerCase().includes('manual'),
			} : {
				...existingLatest.bloodGlucose,
			}
		}
		if (data.body_temperature) {
			obj.body_temperature = !existingLatest?.body_temperature || existingLatest.body_temperature.timestamp <= data.timestamp ? {
				value: data.body_temperature,
				timestamp: data.timestamp,
				deviceId: data?.deviceId as unknown as string,
				isManual: (data?.deviceId as unknown as string).toLocaleLowerCase().includes('manual'),
			} : {
				...existingLatest.body_temperature,
			}
		}
		if (data.exitBedRisk) {
			obj.exitBedRisk = !existingLatest?.exitBedRisk || existingLatest.exitBedRisk.timestamp <= data.timestamp ? {
				value: data.exitBedRisk,
				timestamp: data.timestamp,
				deviceId: data?.deviceId as unknown as string,
				isManual: (data?.deviceId as unknown as string).toLocaleLowerCase().includes('manual'),
			} : {
				...existingLatest.exitBedRisk,
			}
		}
		if (data.activity) {
			obj.activity = !existingLatest?.activity || existingLatest.activity.timestamp <= data.timestamp ? {
				value: data.activity,
				timestamp: data.timestamp,
				deviceId: data?.deviceId as unknown as string,
				isManual: (data?.deviceId as unknown as string).toLocaleLowerCase().includes('manual'),
			} : {
				...existingLatest.activity,
			}
		}
		if (data.monitoringStatus) {
			obj.monitoringStatus = !existingLatest?.monitoringStatus || existingLatest.monitoringStatus.timestamp <= data.timestamp ? {
				value: data.monitoringStatus,
				timestamp: data.timestamp,
				deviceId: data?.deviceId as unknown as string,
				isManual: (data?.deviceId as unknown as string).toLocaleLowerCase().includes('manual'),
			} : {
				...existingLatest.monitoringStatus,
			}
		}
		if (data.heart_rate) {
			obj.heart_rate = !existingLatest?.heart_rate || existingLatest.heart_rate.timestamp <= data.timestamp ? {
				value: data.heart_rate,
				timestamp: data.timestamp,
				deviceId: data?.deviceId as unknown as string,
				isManual: (data?.deviceId as unknown as string).toLocaleLowerCase().includes('manual'),
			} : {
				...existingLatest.heart_rate,
			}
		}
		if (data.posture) {
			obj.posture = !existingLatest?.posture || existingLatest.posture.timestamp <= data.timestamp ? {
				value: data.posture,
				timestamp: data.timestamp,
				deviceId: data?.deviceId as unknown as string,
				isManual: (data?.deviceId as unknown as string).toLocaleLowerCase().includes('manual'),
			} : {
				...existingLatest.posture,
			}
		}
		if (data.spo2) {
			obj.spo2 = !existingLatest?.spo2 || existingLatest.spo2.timestamp <= data.timestamp ? {
				value: data.spo2,
				timestamp: data.timestamp,
				deviceId: data?.deviceId as unknown as string,
				isManual: (data?.deviceId as unknown as string).toLocaleLowerCase().includes('manual'),
			} : {
				...existingLatest.spo2,
			}
		}
		return obj;
	}

	private setLatestVitalsFromReportsData(data: MeasurementSummaryInterface['measurements'], existingLatest: ObservationsLatestMap): Partial<ObservationsLatestMap> {
		let obj: Partial<ObservationsLatestMap> = {};
		if (data.respirationRate) {
			obj.respiration_rate = !existingLatest?.respiration_rate || existingLatest.respiration_rate.timestamp <= data.respirationRate.timestamp! ? {
				...data.respirationRate,
				timestamp: new Date(data.respirationRate.timestamp!).toISOString(),
			} : {
				...existingLatest.respiration_rate,
			}
		}
		if (data.diastolicPressure) {
			obj.diastolicPressure = !existingLatest?.diastolicPressure || existingLatest.diastolicPressure.timestamp <= data.diastolicPressure.timestamp! ? {
				...data.diastolicPressure,
				timestamp: new Date(data.diastolicPressure.timestamp!).toISOString(),
			} : {
				...existingLatest.diastolicPressure,
			}
		}
		if (data.systolicPressure) {
			obj.systolicPressure = !existingLatest?.systolicPressure || existingLatest.systolicPressure.timestamp <= data.systolicPressure.timestamp! ? {
				...data.systolicPressure,
				timestamp: new Date(data.systolicPressure.timestamp!).toISOString(),
			} : {
				...existingLatest.systolicPressure,
			}
		}
		if (data.bodyTemperature) {
			obj.body_temperature = !existingLatest?.body_temperature || existingLatest.body_temperature.timestamp <= data.bodyTemperature.timestamp! ? {
				...data.bodyTemperature,
				timestamp: new Date(data.bodyTemperature.timestamp!).toISOString(),
			} : {
				...existingLatest.body_temperature,
			}
		}
		if (data.heartRate) {
			obj.heart_rate = !existingLatest?.heart_rate || existingLatest.heart_rate.timestamp <= data.heartRate.timestamp! ? {
				...data.heartRate,
				timestamp: new Date(data.heartRate.timestamp!).toISOString(),
			} : {
				...existingLatest.heart_rate,
			}
		}
		if (data.spo2) {
			obj.spo2 = !existingLatest?.spo2 || existingLatest.spo2.timestamp <= data.spo2.timestamp! ? {
				...data.spo2,
				timestamp: new Date(data.spo2.timestamp!).toISOString(),
			} : {
				...existingLatest.spo2,
			}
		}
		return obj;
	}
}
