import { Component, EventEmitter, Input, Output } from '@angular/core'
import { PatientInterface } from '../../../../../shared/model/patient'

@Component({
	selector: 'aiomed-device-assigment-action',
	templateUrl: './device-assigment-action.component.html',
	styleUrls: ['./device-assigment-action.component.scss']
})
export class DeviceAssigmentActionComponent {
	@Input() isVisible: boolean = false
	@Input() patient: PatientInterface
	@Input() text: string
	@Input() title: string
	@Input() buttonConfirmText: string
	@Input() serialNumber: string
	@Output() deviceAssigmentActionConfirmEmitter = new EventEmitter()
}
