import { Pipe, PipeTransform } from '@angular/core'
import { AlertSeverity } from '../../../shared/model/alert'

@Pipe({
	name: 'alertSeverityIcon'
})
export class AlertSeverityIconPipe implements PipeTransform {
	AlertSeverity = AlertSeverity

	transform(data: any, ...args: unknown[]): string {
		if (!data) return ''
		if (data === AlertSeverity.Critical) {
			return 'alert'
		} else if (data === AlertSeverity.Major) {
			return 'warning'
		} else if (data === AlertSeverity.Minor) {
			return 'exclamation-circle'
		} else if (data === AlertSeverity.Notice) {
			return 'question-circle'
		}
		return 'info-circle'
	}
}
