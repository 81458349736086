<section class='alert-rule-unsaved-changes'>
  <nz-modal [(nzVisible)]='isVisible'
            nzClassName='custom-alert-rule-unsaved-changes-modal'>
    <div *nzModalContent>
      <div class='alert-rule-unsaved-changes-container'>
        <div class='alert-rule-unsaved-changes-title'><i nz-icon nzType='icons:warning'></i>UNSAVED CHANGES</div>
        <div class='alert-rule-unsaved-changes-sub-title'>Your changes haven’t been saved.</div>
        <div class='alert-rule-unsaved-changes-text'>Click “Cancel” and make sure you save settings before navigating
          away or click “Exit” to discard your edits
        </div>
        <div class='alert-rule-unsaved-changes-buttons'>
          <button (click)='alertRuleUnsavedChangesEmitter.emit(false)' class='cancel'>Cancel</button>
          <button (click)='alertRuleUnsavedChangesEmitter.emit(true)' class='confirm'>Exit</button>
        </div>
      </div>
    </div>
  </nz-modal>
</section>

