import { forwardRef } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'

export function abstractControlProvider(component: any) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => component),
    multi: true,
  }
}
