import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import { DataAction, StateRepository } from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import { EMPTY, Observable, tap } from 'rxjs'
import { BackendService } from '../../shared/services/backend.service'
import {
	PatientDTO,
	PatientInterface,
	patientsSort
} from '../../shared/model/patient'
import { BackendPatientDTO } from '../../shared/model/backend-device-model'
import { FileState } from '../file/file.state'
import { entitiesFilter } from '../../core/helpers/filter'
import { FileDTO } from '../../shared/model/file'
import { StoreEventsService } from '../store-events.service'

export const archivedPatientFeatureName = 'archivedPatient'

@StateRepository()
@State<CollatableEntityCollections<PatientDTO>>({
	name: archivedPatientFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class ArchivedPatientState extends CollatableEntityCollectionsRepository<
	PatientDTO,
	EntityCollation
> {
	constructor(
		private backendService: BackendService,
		private fileState: FileState,
		private actions: Actions,
		private storeEvents: StoreEventsService
	) {
		super()
	}

	@Selector()
	public static isLoading(
		state: CollatableEntityCollections<PatientDTO>
	): boolean {
		return state.isLoading
	}

	@Selector([FileState.files])
	public static archivedPatient(
		state: CollatableEntityCollections<PatientDTO>,
		files: EntityDictionary<string, FileDTO>
	) {
		return entitiesFilter(
			state.freeTextFilter,
			patientsSort(state.sort, Object.values(state.entities))
		)
			.slice(0, state.pageSize)
			.map((patient: PatientDTO) =>
				ArchivedPatientState.hydrate(patient, files)
			)
	}

	private static hydrate(
		patient: PatientDTO,
		files: EntityDictionary<string, FileDTO>
	): PatientInterface {
		// @ts-ignore
		return {
			...patient,
			hasMuteAlert: false,
			alerts: [],
			checked: false,
			avatar:
				patient.avatar &&
				files[patient.avatar.id] &&
				files[patient.avatar.id]?.signedUrl
					? files[patient.avatar.id]
					: null,
			treatmentPlan: [],
			symptoms: [],
			conditions: [],
			unreadMessages: 0,
			insights: [],
			criticalObservation: [],
			observations: {},
			reports: null,
			lastEMRUpdateTime: undefined,
			lastObservationsTime: null,
			defaultAlertRules: null,
			patientAlertRules: null
		}
	}

	public override ngxsOnInit() {
		this.storeEvents.logout$.pipe(
			tap(() => {
				this.reset();
			})
		).subscribe();
	}

	@DataAction()
	getAllArchivedPatients() {
		this.patchState({ isLoading: true })
		return this.backendService.getAllArchivedPatients().pipe(
			tap((p) => {
				this.upsertMany(p)
				const fileIds = p
					.map((patient: BackendPatientDTO | PatientDTO) => patient.avatar?.id)
					.filter((i: string | any) => i)
				this.fileState.loadEntities(fileIds)
				this.patchState({ isLoading: false })
			})
		)
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}
}
