import {
  AfterViewInit,
  Injector,
  Input,
  OnDestroy,
  Directive,
  Output,
  EventEmitter,
} from '@angular/core'
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  NgModel,
} from '@angular/forms'
import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

@Directive()
export abstract class BaseControlValueAccessor
  implements ControlValueAccessor, AfterViewInit, OnDestroy
{
  /**
   * Private value
   */
  value: any = ''

  /**
   * Maximum length value
   */
  @Input()
  maxlength: any = null

  /**
   * Value to force the field to set invalid styles
   */
  @Input()
  isInvalid = false

  @Input()
  isValid = false

  /**
   * Placeholder to be displayed in the field
   */
  @Input()
  placeholder = ''

  @Output() onBlur: EventEmitter<any> = new EventEmitter()
  @Output() focus: EventEmitter<any> = new EventEmitter()
  @Output() onEnter: EventEmitter<any> = new EventEmitter()

  @Input() isValidateSpaces: boolean = false

  /**
   * Externally transmitted value
   */
  @Input('value')
  set setValue(value: any) {
    this.writeValue(value)
  }

  @Input() showErrorMessages = true

  @Input() id = null

  /**
   * Setting disabled for field
   */
  @Input()
  set disabled(disabled: boolean) {
    if (disabled) {
      this.control.disable({ onlySelf: true })
    } else {
      this.control.enable({ onlySelf: true })
    }
  }

  get disabled() {
    return this.control.disabled
  }

  get invalid() {
    return this.isInvalid || this.control.invalid
  }

  get valid() {
    return this.isValid && this.control.valid
  }

  get touched() {
    return this.control.touched
  }

  get dirty() {
    return this.control.dirty
  }

  get isOnlySpaces() {
    return (
      this.isValidateSpaces && this.control.value && !this.control.value.trim()
    )
  }

  @Input()
  formControlName: string
  control = new FormControl('')
  protected subscription$: Subscription
  constructor(protected injector: Injector) {}

  markAsTouched() {
    this.control.markAsTouched({ onlySelf: true })
  }

  writeValue(value: any) {
    this.value = value
  }

  propagateChange(_: any) {
    this.value = _
  }

  propagateTouch(_: any) {
    this.value = _
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn
  }

  changeValue(value: any) {
    this.value = value
    this.propagateChange(value)
    this.propagateTouch(value)
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // @ts-ignore
      const ngControl: NgControl = this.injector.get(NgControl, null)

      if (ngControl && ngControl.control) {
        this.control = ngControl.control as FormControl
      }

      /**
       * Crutch for ngModel to work
       */
      let changed = false
      this.subscription$ = this.control.valueChanges
        .pipe(
          filter(() => {
            return changed ? (changed = false) : (changed = true)
          })
        )
        .subscribe((value) => {
          if (ngControl instanceof NgModel) {
            this.propagateChange(value)
            this.propagateTouch(value)
          }
        })
    })
  }

  ngOnDestroy() {
    if (this.subscription$) {
      this.subscription$.unsubscribe()
    }
  }
}
