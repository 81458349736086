import { ChangeDetectorRef, Component } from '@angular/core'
import { UserState } from '../../../../../store/user/user.state'
import { PatientState } from '../../../../../store/patient/patient.state'
import { AuthState } from '../../../../../store/auth/auth.state'
import { PreferenceState } from '../../../../../store/preference/preference.state'
import { TaskState } from '../../../../../store/task/task.state'
import { AlertState } from '../../../../../store/alert/alert.state'
import { MenuController } from '@ionic/angular'

@Component({
	selector: 'app-main-mobile',
	templateUrl: './main-mobile.component.html',
	styleUrls: ['./main-mobile.component.scss']
})
export class MainMobileComponent {
	constructor(
		private userState: UserState,
		private cdRef: ChangeDetectorRef,
		private patientState: PatientState,
		private authState: AuthState,
		private preferenceState: PreferenceState,
		private tasksState: TaskState,
		private alertState: AlertState,
		private menuController: MenuController
	) {}

	async handlerClick() {
		await this.menuController.toggle('mainMenu')
	}
}
