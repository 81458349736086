import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core'
import { ObservationFields } from '../../../../../shared/model/patient-observation'
import { PatientInterface } from '../../../../../shared/model/patient'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
	getUserLocale,
	transformCelsiusToFahrenhei,
	transformFahrenheiToCelsius
} from '../../../../../core/helpers/functions'
import { AuthState } from '../../../../../store/auth/auth.state'
import { PatientExportDTO } from '../../../../../shared/model/pcc.model'
import { ReportType } from '../../../../../shared/model/report.model'
import { checkTypoRules } from '../../../../../core/helpers/check-typo-rules'
import { checkEmrRules } from '../../../../../core/helpers/check-emr-rules'
import { cloneDeep } from 'lodash-es'

@Component({
	selector: 'aiomed-report-manual-emr-modal',
	templateUrl: './report-manual-emr-modal.component.html',
	styleUrls: ['./report-manual-emr-modal.component.scss']
})
export class ReportManualEmrModalComponent implements OnInit, OnChanges {
	@Input() patient: PatientInterface
	@Input() isVisible: boolean = false
	@Input() patientsExports: PatientExportDTO[]
	@Input() activeHour: {
		active: boolean
		text: string
		type: string
	}
	@Output('closeEMRVitalsModalEmitter') closeEMRVitalsModal = new EventEmitter()
	@Output() setManualVitalsEmitter = new EventEmitter()
	@Output() updateManualySetManualVitalsEmitter = new EventEmitter()
	@Output() closeModalNotSaved = new EventEmitter()
	checkedBloodPressure = false
	checkedHeartRate = false
	checkedSpo2 = false
	checkedBodyTemperature = false
	checkedRespirationRate = false
	checkedBloodGlucose = false
	ObservationFields = ObservationFields
	needLogin: boolean = true
	form: FormGroup
	passwordVisible = false
	password?: string
	diastolicValue: number | null = null
	systolicValue: number | null = null
	heartRateValue: number | null = null
	respirationRateValue: number | null = null
	bloodGlucoseValue: number | null = null
	spO2Value: number | null = null
	bodyTemperatureValue: number | null = null
	diastolicError: boolean = false
	systolicError: boolean = false
	heartRateError: boolean = false
	respirationRateError: boolean = false
	spO2Error: boolean = false
	bodyTemperatureError: boolean = false
	bloodGlucoseError: boolean = false
	changedData: boolean = false
	checkedAll: boolean = false
	firstData: {
		diastolic: number | any
		systolic: number | any
		heartRate: number | any
		respirationRate: number | any
		spo2: number | any
		bodyTemperature: number | any
		bloodGlucose: number | any
	} = {
		diastolic: null,
		systolic: null,
		heartRate: null,
		respirationRate: null,
		spo2: null,
		bodyTemperature: null,
		bloodGlucose: null
	}
	bpMethodData = [
		'Standing l/arm',
		'Standing r/arm',
		'Sitting l/arm',
		'Sitting r/arm',
		'Lying l/arm',
		'Lying r/arm',
		'Other'
	]
	bpMethodSelect = 'Sitting r/arm'
	hrMethodData = [
		'regular',
		'irregular - new onset',
		'irregular - chronic',
		'irregular - UTD onset',
		'UTD - unable to determine',
		'Not applicable'
	]
	hrMethodSelect = 'regular'
	spMethodData = [
		'Room Air',
		'Oxygen via Mask',
		'Oxygen via Nasal Cannula',
		'Trach',
		'CPAP',
		'BiPAP',
		'High Flow Oxygen',
		'Vent'
	]
	spMethodSelect = 'Room Air'
	tempMethodData = [
		'Oral',
		'Rectal',
		'Tympanic',
		'Axilla',
		'Temporal Artery',
		'Forehead (non-contact)'
	]
	tempMethodSelect = 'Oral'
	protected readonly ReportType = ReportType

	constructor(private fb: FormBuilder, private authState: AuthState) {
		this.form = this.fb.group({
			username: [null, Validators.compose([Validators.required])],
			password: [
				null,
				Validators.compose([Validators.required, Validators.minLength(8)])
			]
		})
	}

	private static toTransformFahrenheiToCelsius(value: number): number {
		return getUserLocale() === 'en-US'
			? transformFahrenheiToCelsius(value)
			: value
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes['patientsExports']) return
		const patientExport: PatientExportDTO = changes[
			'patientsExports'
		].currentValue.find(
			(e: PatientExportDTO) => e.observedPatient === this.patient.id
		)
		if (!patientExport) return
		const {
			bodyTemperatureMethod,
			spo2Method,
			heartRateMethod,
			bloodPressureMethod
		} = patientExport
		if (bodyTemperatureMethod) {
			this.tempMethodSelect = bodyTemperatureMethod
		}
		if (spo2Method) {
			this.spMethodSelect = spo2Method
		}
		if (heartRateMethod) {
			this.hrMethodSelect = heartRateMethod
		}
		if (bloodPressureMethod) {
			this.bpMethodSelect = bloodPressureMethod
		}
	}

	ngOnInit(): void {
		if (
			this.patient.measurement &&
			this.patient.measurement?.latestPerVital?.diastolicPressure
		) {
			const rules: any = checkTypoRules.get(ObservationFields.DiastolicPressure)
			this.firstData.diastolic = this.diastolicValue = Math.round(
				this.patient.measurement?.latestPerVital?.diastolicPressure?.value
			)
			this.diastolicError =
				this.diastolicValue < rules?.min || this.diastolicValue > rules?.max
		}
		if (
			this.patient.measurement &&
			this.patient.measurement?.latestPerVital?.systolicPressure
		) {
			const rules: any = checkTypoRules.get(ObservationFields.SystolicPressure)
			this.firstData.systolic = this.systolicValue = Math.round(
				this.patient.measurement?.latestPerVital?.systolicPressure?.value
			)
			this.systolicError =
				this.systolicValue < rules.min || this.systolicValue > rules.max
		}
		if (this.patient.measurement && this.patient.measurement?.latestPerVital?.heart_rate) {
			const rules: any = checkTypoRules.get(ObservationFields.HeartRate)
			this.firstData.heartRate = this.heartRateValue = Math.round(
				this.patient.measurement?.latestPerVital?.heart_rate?.value
			)
			this.heartRateError =
				this.heartRateValue < rules.min || this.heartRateValue > rules.max
		}
		if (
			this.patient.measurement &&
			this.patient.measurement?.latestPerVital?.respiration_rate
		) {
			const rules: any = checkTypoRules.get(ObservationFields.RespirationRate)
			this.firstData.respirationRate = this.respirationRateValue = Math.round(
				this.patient.measurement?.latestPerVital?.respiration_rate?.value
			)
			this.respirationRateError =
				this.respirationRateValue < rules.min ||
				this.respirationRateValue > rules.max
		}
		if (this.patient.measurement && this.patient.measurement?.latestPerVital?.spo2) {
			const rules: any = checkTypoRules.get(ObservationFields.SpO2)
			this.firstData.spo2 = this.spO2Value = Math.round(
				this.patient.measurement?.latestPerVital?.spo2?.value
			)
			this.spO2Error = this.spO2Value < rules.min || this.spO2Value > rules.max
		}
		if (
			this.patient.measurement &&
			this.patient.measurement?.latestPerVital?.bloodGlucose
		) {
			const rules: any = checkTypoRules.get(ObservationFields.BloodGlucose)
			this.firstData.bloodGlucose = this.bloodGlucoseValue = Math.round(
				this.patient.measurement?.latestPerVital?.bloodGlucose.value
			)
			this.bloodGlucoseError =
				this.bloodGlucoseValue < rules.min || this.bloodGlucoseValue > rules.max
		}
		if (
			this.patient.measurement &&
			this.patient.measurement?.latestPerVital?.body_temperature
		) {
			const rules: any = checkTypoRules.get(ObservationFields.BodyTemperature)
			getUserLocale() === 'en-US'
				? (this.firstData.bodyTemperature = this.bodyTemperatureValue =
						Math.floor(
							transformCelsiusToFahrenhei(
								this.patient.measurement?.latestPerVital?.body_temperature?.value
							) * 10
						) / 10)
				: (this.firstData.bodyTemperature = this.bodyTemperatureValue =
						Math.floor(
							this.patient.measurement?.latestPerVital?.body_temperature?.value * 10
						) / 10)

			this.bodyTemperatureError =
				this.bodyTemperatureValue < rules.min ||
				this.bodyTemperatureValue > rules.max
		}
		this.handlerCheckedAll(true, this.patient)
	}

	submitForm() {
		if (
			!this.checkedBloodPressure &&
			!this.checkedHeartRate &&
			!this.checkedSpo2 &&
			!this.checkedBodyTemperature &&
			!this.checkedRespirationRate &&
			!this.checkedBloodGlucose
		) {
			return
		}
		if (this.changedData && this.activeHour.type !== ReportType.Time) {
			this.updateManualySetManualVitalsEmitter.emit({
				observedPatient: this.patient.id,
				...this.setCheckedPatientCurrentObservations()
			})
		} else {
			this.setManualVitalsEmitter.emit({
				observedPatient: this.patient.id,
				...this.setCheckedPatientCurrentObservations()
			})
		}
		this.closeEMRVitalsModal.emit(false)
		// this.handlerClickCancelButton()
	}

	setCheckedPatientCurrentObservations() {
		let tmpObj: any = {}
		if (this.checkedBloodPressure) {
			tmpObj[ObservationFields.DiastolicPressure] = this.diastolicValue
			tmpObj[ObservationFields.SystolicPressure] = this.systolicValue
			if (!this.firstData.diastolic || !this.firstData.systolic) {
				tmpObj.pressureManual = true
			} else if (
				this.diastolicValue !== this.firstData.diastolic ||
				this.systolicValue !== this.firstData.systolic
			) {
				tmpObj.pressureManual = true
			}
			tmpObj.bloodPressureMethod = this.bpMethodSelect
			tmpObj.heartRateMethod = this.hrMethodSelect
			tmpObj.spo2Method = this.spMethodSelect
			tmpObj.bodyTemperatureMethod = this.tempMethodSelect
		}
		if (this.checkedHeartRate) {
			tmpObj.heartRate = this.heartRateValue
			tmpObj.bloodPressureMethod = this.bpMethodSelect
			tmpObj.heartRateMethod = this.hrMethodSelect
			tmpObj.spo2Method = this.spMethodSelect
			tmpObj.bodyTemperatureMethod = this.tempMethodSelect
			if (!this.firstData.heartRate) {
				tmpObj.heartRateManual = true
			} else if (this.heartRateValue !== this.firstData.heartRate) {
				tmpObj.heartRateManual = true
			}
		}
		if (this.checkedRespirationRate) {
			tmpObj.respirationRate = this.respirationRateValue
			tmpObj.bloodPressureMethod = this.bpMethodSelect
			tmpObj.heartRateMethod = this.hrMethodSelect
			tmpObj.spo2Method = this.spMethodSelect
			tmpObj.bodyTemperatureMethod = this.tempMethodSelect
			if (!this.firstData.respirationRate) {
				tmpObj.respirationRateManual = true
			} else if (this.respirationRateValue !== this.firstData.respirationRate) {
				tmpObj.respirationRateManual = true
			}
		}
		if (this.checkedSpo2) {
			tmpObj[ObservationFields.SpO2] = this.spO2Value
			tmpObj.bloodPressureMethod = this.bpMethodSelect
			tmpObj.heartRateMethod = this.hrMethodSelect
			tmpObj.spo2Method = this.spMethodSelect
			tmpObj.bodyTemperatureMethod = this.tempMethodSelect
			if (!this.firstData.spo2) {
				tmpObj.spo2Manual = true
			} else if (this.spO2Value !== this.firstData.spo2) {
				tmpObj.spo2Manual = true
			}
		}
		if (this.checkedBodyTemperature) {
			tmpObj.bodyTemperature =
				ReportManualEmrModalComponent.toTransformFahrenheiToCelsius(
					Number(this.bodyTemperatureValue)
				)
			tmpObj.bloodPressureMethod = this.bpMethodSelect
			tmpObj.heartRateMethod = this.hrMethodSelect
			tmpObj.spo2Method = this.spMethodSelect
			tmpObj.bodyTemperatureMethod = this.tempMethodSelect
			if (!this.firstData.bodyTemperature) {
				tmpObj.bodyTemperatureManual = true
			} else if (
				ReportManualEmrModalComponent.toTransformFahrenheiToCelsius(
					Number(this.bodyTemperatureValue)
				) !==
				ReportManualEmrModalComponent.toTransformFahrenheiToCelsius(
					this.firstData.bodyTemperature
				)
			) {
				tmpObj.bodyTemperatureManual = true
			}
		}

		if (this.checkedBloodGlucose) {
			tmpObj.bloodGlucose = this.bloodGlucoseValue
			tmpObj.bloodPressureMethod = this.bpMethodSelect
			tmpObj.heartRateMethod = this.hrMethodSelect
			tmpObj.spo2Method = this.spMethodSelect
			tmpObj.bodyTemperatureMethod = this.tempMethodSelect
			if (!this.firstData.bloodGlucose) {
				tmpObj.bloodGlucoseManual = true
			} else if (this.bloodGlucoseValue !== this.firstData.bloodGlucose) {
				tmpObj.bloodGlucoseManual = true
			}
		}
		return tmpObj
	}

	handlerClickCancelButton() {
		this.closeEMRVitalsModal.emit(false)
		this.checkedBloodPressure = false
		this.checkedHeartRate = false
		this.checkedBloodGlucose = false
		this.checkedSpo2 = false
		this.checkedBodyTemperature = false
	}

	handlerInputChange($event: any, checkedType: string, type: string) {
		const emrRules = cloneDeep(checkEmrRules)
		emrRules.set(
			!this.patient.patientAlertRules
				? this.patient.defaultAlertRules
				: this.patient.patientAlertRules
		)
		const systolicEMRRule: { min: number; max: number } = emrRules.get(
			ObservationFields.SystolicPressure
		)
		const diastolicEMRRule: { min: number; max: number } = emrRules.get(
			ObservationFields.DiastolicPressure
		)
		const heartRateEMRRule: { min: number; max: number } = emrRules.get(
			ObservationFields.HeartRate
		)
		const spO2EMRRule: { min: number; max: number } = emrRules.get(
			ObservationFields.SpO2
		)
		const bodyTemperatureEMRRule: any = emrRules.get(
			ObservationFields.BodyTemperature
		)
		const respirationRateEMRRule: { min: number; max: number } = emrRules.get(
			ObservationFields.RespirationRate
		)
		const bloodGlucoseEMRRule: { min: number; max: number } = emrRules.get(
			ObservationFields.BloodGlucose
		)
		if (type === ObservationFields.SystolicPressure) {
			const systolicRules: { min: number; max: number } = emrRules.get(
				ObservationFields.SystolicPressure
			)
			this.systolicError =
				(type === ObservationFields.SystolicPressure &&
					$event < systolicRules.min) ||
				(type === ObservationFields.SystolicPressure &&
					$event > systolicRules.max)
			const diastolicRules: { min: number; max: number } = emrRules.get(
				ObservationFields.DiastolicPressure
			)
			this.diastolicError =
				// @ts-ignore
				this.diastolicValue < diastolicRules.min ||
				// @ts-ignore
				this.diastolicValue > diastolicRules.max
			if (this.diastolicError || this.systolicError) {
				// @ts-ignore
				this[checkedType] = false
			}

			if (
				!this.diastolicError &&
				!this.systolicError &&
				this.diastolicValue &&
				this.diastolicValue >= diastolicEMRRule?.min &&
				this.diastolicValue <= diastolicEMRRule?.max &&
				$event >= systolicEMRRule?.min &&
				$event <= systolicEMRRule?.max
			) {
				// @ts-ignore
				this[checkedType] = true
			}
			this.changedData =
				$event !== this.firstData.systolic ||
				this.diastolicValue !== this.firstData?.diastolic ||
				this.heartRateValue !== this.firstData?.heartRate ||
				this.respirationRateValue !== this.firstData.respirationRate ||
				Number(this.bodyTemperatureValue) !==
					Number(this.firstData?.bodyTemperature) ||
				this.spO2Value !== this.firstData?.spo2 ||
				this.bloodGlucoseValue !== this.firstData?.bloodGlucose
		}
		if (type === ObservationFields.DiastolicPressure) {
			const diastolicRules: { min: number; max: number } = emrRules.get(
				ObservationFields.DiastolicPressure
			)
			this.diastolicError =
				(type === ObservationFields.DiastolicPressure &&
					$event < diastolicRules.min) ||
				(type === ObservationFields.DiastolicPressure &&
					$event > diastolicRules.max)
			const systolicRules: { min: number; max: number } = emrRules.get(
				ObservationFields.SystolicPressure
			)
			this.systolicError =
				// @ts-ignore
				this.systolicValue < systolicRules.min ||
				// @ts-ignore
				this.systolicValue > systolicRules.max
			if (this.diastolicError || this.systolicError) {
				// @ts-ignore
				this[checkedType] = false
			}
			if (
				!this.diastolicError &&
				!this.systolicError &&
				$event >= diastolicEMRRule?.min &&
				$event <= diastolicEMRRule?.max &&
				this.systolicValue &&
				this.systolicValue >= systolicEMRRule?.min &&
				this.systolicValue <= systolicEMRRule?.max
			) {
				// @ts-ignore
				this[checkedType] = true
			}
			this.changedData =
				this.systolicValue !== this.firstData.systolic ||
				$event !== this.firstData.diastolic ||
				this.heartRateValue !== this.firstData.heartRate ||
				this.respirationRateValue !== this.firstData.respirationRate ||
				Number(this.bodyTemperatureValue) !==
					Number(this.firstData.bodyTemperature) ||
				this.spO2Value !== this.firstData.spo2 ||
				this.bloodGlucoseValue !== this.firstData.bloodGlucose
		}
		if (type === ObservationFields.HeartRate) {
			const rules: any = checkTypoRules.get(ObservationFields.HeartRate)
			this.heartRateError =
				(type === ObservationFields.HeartRate && $event < rules.min) ||
				(type === ObservationFields.HeartRate && $event > rules.max)
			// @ts-ignore
			if (this.heartRateError) this[checkedType] = false

			if (
				!this.heartRateError &&
				$event >= heartRateEMRRule?.min &&
				$event <= heartRateEMRRule?.max
			) {
				// @ts-ignore
				this[checkedType] = true
			}
			this.changedData =
				this.systolicValue !== this.firstData.systolic ||
				this.diastolicValue !== this.firstData.diastolic ||
				$event !== this.firstData.heartRate ||
				this.respirationRateValue !== this.firstData.respirationRate ||
				Number(this.bodyTemperatureValue) !==
					Number(this.firstData?.bodyTemperature) ||
				this.spO2Value !== this.firstData?.spo2 ||
				this.bloodGlucoseValue !== this.firstData?.bloodGlucose
		}
		if (type === ObservationFields.SpO2) {
			const rules: { min: number; max: number } = emrRules.get(
				ObservationFields.SpO2
			)
			this.spO2Error =
				(type === ObservationFields.SpO2 && $event < rules.min) ||
				(type === ObservationFields.SpO2 && $event > rules.max)
			// @ts-ignore
			if (this.spO2Error) this[checkedType] = false
			if (
				!this.spO2Error &&
				$event >= spO2EMRRule?.min &&
				$event <= spO2EMRRule?.max
			) {
				// @ts-ignore
				this[checkedType] = true
			}
			this.changedData =
				this.systolicValue !== this.firstData.systolic ||
				this.diastolicValue !== this.firstData.diastolic ||
				this.heartRateValue !== this.firstData.heartRate ||
				this.respirationRateValue !== this.firstData.respirationRate ||
				Number(this.bodyTemperatureValue) !==
					Number(this.firstData.bodyTemperature) ||
				$event !== this.firstData.spo2 ||
				this.bloodGlucoseValue !== this.firstData.bloodGlucose
		}
		if (type === ObservationFields.RespirationRate) {
			const rules: { min: number; max: number } = emrRules.get(
				ObservationFields.RespirationRate
			)
			this.respirationRateError =
				(type === ObservationFields.RespirationRate && $event < rules.min) ||
				(type === ObservationFields.RespirationRate && $event > rules.max)
			// @ts-ignore
			if (this.respirationRateError) this[checkedType] = false
			if (
				!this.respirationRateError &&
				$event >= respirationRateEMRRule?.min &&
				$event <= respirationRateEMRRule?.max
			) {
				// @ts-ignore
				this[checkedType] = true
			}
			this.changedData =
				this.systolicValue !== this.firstData.systolic ||
				this.diastolicValue !== this.firstData.diastolic ||
				this.heartRateValue !== this.firstData.heartRate ||
				$event !== this.firstData.respirationRate ||
				Number(this.bodyTemperatureValue) !==
					Number(this.firstData.bodyTemperature) ||
				this.spO2Value !== this.firstData.spo2 ||
				this.bloodGlucoseValue !== this.firstData.bloodGlucose
		}
		if (type === ObservationFields.BloodGlucose) {
			const rules: { min: number; max: number } = emrRules.get(
				ObservationFields.BloodGlucose
			)
			this.bloodGlucoseError =
				(type === ObservationFields.BloodGlucose && $event < rules?.min) ||
				(type === ObservationFields.BloodGlucose && $event > rules?.max)
			if (this.bloodGlucoseError) {
				// @ts-ignore
				this[checkedType] = false
			}
			if (
				!this.bloodGlucoseError &&
				$event >= bloodGlucoseEMRRule?.min &&
				$event <= bloodGlucoseEMRRule?.max
			) {
				// @ts-ignore
				this[checkedType] = true
			}
			this.changedData =
				this.systolicValue !== this.firstData.systolic ||
				this.diastolicValue !== this.firstData.diastolic ||
				this.heartRateValue !== this.firstData.heartRate ||
				$event !== this.firstData.bloodGlucose ||
				Number(this.bodyTemperatureValue) !==
					Number(this.firstData.bodyTemperature) ||
				this.spO2Value !== this.firstData.spo2 ||
				this.respirationRateValue !== this.firstData.respirationRate
		}
		if (type === ObservationFields.BodyTemperature) {
			const rules: { min: number; max: number } = emrRules.get(
				ObservationFields.BodyTemperature
			)
			this.bodyTemperatureError =
				(type === ObservationFields.BodyTemperature && $event < rules.min) ||
				(type === ObservationFields.BodyTemperature && $event > rules.max)
			// @ts-ignore
			if (this.bodyTemperatureError) {
				// @ts-ignore
				this[checkedType] = false
			}
			if (
				!this.bodyTemperatureError &&
				$event >= bodyTemperatureEMRRule?.min &&
				$event <= bodyTemperatureEMRRule?.max
			) {
				// @ts-ignore
				this[checkedType] = true
			}
			this.changedData =
				this.systolicValue !== this.firstData.systolic ||
				this.diastolicValue !== this.firstData.diastolic ||
				this.heartRateValue !== this.firstData.heartRate ||
				this.respirationRateValue !== this.firstData.respirationRate ||
				Number($event) !== Number(this.firstData.bodyTemperature) ||
				this.spO2Value !== this.firstData.spo2 ||
				this.bloodGlucoseValue !== this.firstData.bloodGlucose
		}
	}

	protectionNumbers($event: KeyboardEvent) {
		if (
			[
				'0',
				'1',
				'2',
				'3',
				'4',
				'5',
				'6',
				'7',
				'8',
				'9',
				'0',
				'.',
				'Backspace'
			].includes($event.key)
		) {
		} else {
			$event.preventDefault()
		}
	}

	handlerCheckedAll($event: boolean, patient: PatientInterface) {
		if ($event) {
			const emrRules = cloneDeep(checkEmrRules)
			emrRules.set(
				!patient.patientAlertRules
					? patient.defaultAlertRules
					: patient.patientAlertRules
			)
			const systolicEMRRule: { min: number; max: number } = emrRules.get(
				ObservationFields.SystolicPressure
			)
			const diastolicEMRRule: { min: number; max: number } = emrRules.get(
				ObservationFields.DiastolicPressure
			)
			const heartRateEMRRule: { min: number; max: number } = emrRules.get(
				ObservationFields.HeartRate
			)
			const spO2EMRRule: { min: number; max: number } = emrRules.get(
				ObservationFields.SpO2
			)
			const bodyTemperatureEMRRule: { min: number; max: number } = emrRules.get(
				ObservationFields.BodyTemperature
			)
			const respirationRateEMRRule: { min: number; max: number } = emrRules.get(
				ObservationFields.RespirationRate
			)
			const bloodGlucoseEMRRule: { min: number; max: number } = emrRules.get(
				ObservationFields.BloodGlucose
			)
			if (
				this.systolicValue &&
				this.diastolicValue &&
				this.systolicValue >= systolicEMRRule?.min &&
				this.systolicValue <= systolicEMRRule?.max &&
				this.diastolicValue >= diastolicEMRRule?.min &&
				this.diastolicValue <= diastolicEMRRule?.max &&
				!this.diastolicError &&
				!this.systolicError
			) {
				this.checkedBloodPressure = true
			}
			if (
				this.heartRateValue &&
				this.heartRateValue >= heartRateEMRRule?.min &&
				this.heartRateValue <= heartRateEMRRule?.max &&
				!this.heartRateError
			) {
				this.checkedHeartRate = true
			}
			if (
				this.bloodGlucoseValue &&
				this.bloodGlucoseValue >= bloodGlucoseEMRRule?.min &&
				this.bloodGlucoseValue <= bloodGlucoseEMRRule?.max
			) {
				this.checkedBloodGlucose = true
			}
			if (
				this.spO2Value &&
				this.spO2Value >= spO2EMRRule?.min &&
				this.spO2Value <= spO2EMRRule?.max &&
				!this.spO2Error
			) {
				this.checkedSpo2 = true
			}
			if (
				this.bodyTemperatureValue &&
				this.bodyTemperatureValue >= bodyTemperatureEMRRule?.min &&
				this.bodyTemperatureValue <= bodyTemperatureEMRRule?.max &&
				!this.bodyTemperatureError
			) {
				this.checkedBodyTemperature = true
			}
			if (
				this.respirationRateValue &&
				this.respirationRateValue >= respirationRateEMRRule?.min &&
				this.respirationRateValue <= respirationRateEMRRule?.max &&
				!this.respirationRateError
			) {
				this.checkedRespirationRate = true
			}
		} else {
			this.checkedBloodPressure = false
			this.checkedHeartRate = false
			this.checkedBloodGlucose = true
			this.checkedSpo2 = false
			this.checkedBodyTemperature = false
			this.checkedRespirationRate = false
			this.checkedBloodGlucose = false
		}
	}
}
