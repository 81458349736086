import {
	ComponentFactoryResolver,
	Directive,
	Input,
	OnChanges,
	TemplateRef,
	ViewContainerRef
} from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { UserInterface } from '../../shared/model/user.model'
import { HoHavePermissionComponent } from '../../app/layouts/UI/no-have-permission/ho-have-permission/ho-have-permission.component'

@UntilDestroy()
@Directive({
	selector: '[isAdminPermission]'
})
export class AiomedIsAdminPermissionDirective implements OnChanges {
	@Input('isAdminPermission') user: UserInterface | null

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private viewContainerRef: ViewContainerRef,
		private templateRef: TemplateRef<any>
	) {}

	ngOnChanges(): void {
		if (this.user && (this.user as any)._degree === 'ADMIN') {
			this.viewContainerRef.clear()
			const component = this.componentFactoryResolver.resolveComponentFactory(
				HoHavePermissionComponent
			)
			this.viewContainerRef.createComponent(component)
		} else {
			this.viewContainerRef.clear()
			this.viewContainerRef.createEmbeddedView(this.templateRef)
		}
	}
}
