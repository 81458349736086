<section class='pcc-patients'>
  <nz-modal [(nzVisible)]='isVisible'
            nzClassName='custom-manual-update-emr-modal'>
    <div *nzModalContent>
      <div class='update-emr-popover__container'>
        <span (click)='handlerClickCancelButton()' class='icon-close' nz-icon
              nzType='icons:close'></span>
        <div class='title'>Report to EMR</div>
        <div class='update-emr-popover__top-content'>
          <div class='update-emr-popover__avatar'>
            <nz-avatar *ngIf='!patient["avatar"]; else haveAvatar' [nzText]='patient.name | shortName'
                       class='aiomed-avatar'
                       nzSize='default'></nz-avatar>
            <ng-template #haveAvatar>
              <nz-avatar [nzSrc]='patient["avatar"]["signedUrl"]'
                         nzSize='default'></nz-avatar>
            </ng-template>
          </div>
          <div class='update-emr-popover__title-content'>
            <div class='update-emr-popover__title' style='text-transform: uppercase;'>
              {{patient.lastName}}, {{patient.firstName}}<span>{{patient?.room}}</span>
            </div>
            <div class='update-emr-popover__sub-title'>
              Last updated on {{patient?.measurement?.lastMeasurementTime | time}}
            </div>
          </div>
        </div>
        <div *ngIf='needLogin' class='update-emr-popover__main-content'>
          <div class='update-emr-popover__item'>
            <label
              [(ngModel)]='checkedBloodPressure'
              [disabled]='!systolicValue || !diastolicValue || diastolicError || systolicError'
              [ngClass]="{'completed': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.bpStatus && patient.checkShiftSentEmrInformation?.bpStatus === 'SENT_TO_EMR', 'need-send': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.bpStatus && patient.checkShiftSentEmrInformation?.bpStatus !== 'SENT_TO_EMR', 'critical-time-send': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.bpStatus && patient.checkShiftSentEmrInformation?.bpStatus !== 'SENT_TO_EMR' && patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.status === 'CRITICAL_REPORT_TIME'}"
              nz-checkbox>
              <i
                *ngIf='!diastolicError && !systolicError && !(systolicValue | checkVitalEmrRules:  ObservationFields.SystolicPressure : patient) && !(diastolicValue | checkVitalEmrRules: ObservationFields.DiastolicPressure : patient)'
                nz-icon
                nzType='icons:modal-pressure'
              ></i>
              <i
                *ngIf='diastolicError || systolicError || (systolicValue | checkVitalEmrRules:  ObservationFields.SystolicPressure : patient) || (diastolicValue | checkVitalEmrRules: ObservationFields.DiastolicPressure : patient)'
                nz-icon
                nzType='icons:modal-pressure-red'
              ></i>
              <span
                [ngClass]="{'critical': diastolicError || systolicError || (systolicValue | checkVitalEmrRules:  ObservationFields.SystolicPressure : patient) || (diastolicValue | checkVitalEmrRules: ObservationFields.DiastolicPressure : patient)}"
                class='item-text'>BLOOD PRESSURE</span>
              <span
                [ngClass]="{'critical': diastolicError || systolicError || (systolicValue | checkVitalEmrRules:  ObservationFields.SystolicPressure : patient) || (diastolicValue | checkVitalEmrRules: ObservationFields.DiastolicPressure : patient)}"
                class='item-dashed item-dashed-sbp'></span>
            </label>
            <div
              [ngClass]="{'critical': diastolicError || systolicError || (systolicValue | checkVitalEmrRules:  ObservationFields.SystolicPressure : patient) || (diastolicValue | checkVitalEmrRules: ObservationFields.DiastolicPressure : patient)}"
              class='input-box'>
              <input (keydown)='protectionNumbers($event)'
                     (ngModelChange)='handlerInputChange($event, "checkedBloodPressure", ObservationFields.SystolicPressure)'
                     [(ngModel)]='systolicValue'
                     type='number'>/<input
              (keydown)='protectionNumbers($event)'
              (ngModelChange)='handlerInputChange($event, "checkedBloodPressure", ObservationFields.DiastolicPressure)'
              [(ngModel)]='diastolicValue' type='number'>
            </div>
            <div class='select-box'>
              <div class='select-title'>Position</div>
              <nz-select [(ngModel)]='bpMethodSelect' nzDropdownClassName='custom-emr-modal'>
                <nz-option *ngFor='let p of bpMethodData' [nzLabel]='p' [nzValue]='p'></nz-option>
              </nz-select>
            </div>
            <span
              *ngIf='systolicError || diastolicError'
              class='warning'> <i
              nz-icon
              nzType='icons:modal-error'
            ></i>Double-check vital info for accuracy</span>
            <span
              *ngIf='!systolicError && !diastolicError && (systolicValue | checkVitalEmrRules:  ObservationFields.SystolicPressure : patient) || !systolicError && !diastolicError && (diastolicValue | checkVitalEmrRules: ObservationFields.DiastolicPressure : patient)'
              class='warning'><i
              nz-icon
              nzType='icons:modal-error'
            ></i>Abnormal blood pressure value.</span>
          </div>
          <div class='update-emr-popover__item'>
            <label
              [(ngModel)]='checkedHeartRate'
              [disabled]='!heartRateValue || heartRateError'
              [ngClass]="{'completed': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.hrStatus && patient.checkShiftSentEmrInformation?.hrStatus === 'SENT_TO_EMR', 'need-send': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.hrStatus && patient.checkShiftSentEmrInformation?.hrStatus !== 'SENT_TO_EMR', 'critical-time-send': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.hrStatus && patient.checkShiftSentEmrInformation?.hrStatus !== 'SENT_TO_EMR' && patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.status === 'CRITICAL_REPORT_TIME'}"
              nz-checkbox>
              <i
                [ngClass]="{'critical': heartRateError || (heartRateValue | checkVitalEmrRules: ObservationFields.HeartRate : patient)}"
                [nzType]='ObservationFields.HeartRate | alertSubjectIcon'
                nz-icon
              ></i>
              <span
                [ngClass]="{'critical': heartRateError || (heartRateValue | checkVitalEmrRules: ObservationFields.HeartRate : patient)}"
                class='item-text'>HEART RATE</span>
              <span
                [ngClass]="{'critical': heartRateError || (heartRateValue | checkVitalEmrRules: ObservationFields.HeartRate : patient)}"
                class='item-dashed item-dashed-hr'></span>
            </label>
            <input (keydown)='protectionNumbers($event)'
                   (ngModelChange)='handlerInputChange($event, "checkedHeartRate", ObservationFields.HeartRate)'
                   [(ngModel)]='heartRateValue'
                   [ngClass]="{'critical': heartRateError || (heartRateValue | checkVitalEmrRules: ObservationFields.HeartRate : patient)}"
                   type='number'>
            <div class='select-box'>
              <div class='select-title'>Method</div>
              <nz-select [(ngModel)]='hrMethodSelect' nzDropdownClassName='custom-emr-modal'>
                <nz-option *ngFor='let p of hrMethodData' [nzLabel]='p' [nzValue]='p'></nz-option>
              </nz-select>
            </div>
            <span *ngIf='heartRateError'
                  class='warning'> <i
              nz-icon
              nzType='icons:modal-error'
            ></i>Double-check vital info for accuracy</span>
            <span
              *ngIf='!heartRateError && (heartRateValue | checkVitalEmrRules: ObservationFields.HeartRate : patient)'
              class='warning'><i
              nz-icon
              nzType='icons:modal-error'
            ></i>Abnormal heart rate value.</span>
          </div>
          <div class='update-emr-popover__item'>
            <label
              [(ngModel)]='checkedSpo2'
              [disabled]='!spO2Value || spO2Error'
              [ngClass]="{'completed': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.spo2Status && patient.checkShiftSentEmrInformation?.spo2Status === 'SENT_TO_EMR', 'need-send': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.spo2Status && patient.checkShiftSentEmrInformation?.spo2Status !== 'SENT_TO_EMR', 'critical-time-send': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.spo2Status && patient.checkShiftSentEmrInformation?.spo2Status !== 'SENT_TO_EMR' && patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.status === 'CRITICAL_REPORT_TIME'}"
              nz-checkbox>
              <i
                *ngIf='!spO2Error && !(spO2Value | checkVitalEmrRules: ObservationFields.SpO2 : patient)'
                nz-icon
                nzType='icons:modal-spo'
              ></i>
              <i
                *ngIf='spO2Error || (spO2Value | checkVitalEmrRules: ObservationFields.SpO2 : patient)'
                nz-icon
                nzType='icons:modal-spo-red'
              ></i>
              <span
                [ngClass]="{'critical': spO2Error || (spO2Value | checkVitalEmrRules: ObservationFields.SpO2 : patient)}"
                class='item-text'>SPO2</span>
              <span
                [ngClass]="{'critical': spO2Error || (spO2Value | checkVitalEmrRules: ObservationFields.SpO2 : patient)}"
                class='item-dashed item-dashed-sp'></span>
            </label>
            <input (keydown)='protectionNumbers($event)'
                   (ngModelChange)='handlerInputChange($event, "checkedSpo2", ObservationFields.SpO2)'
                   [(ngModel)]='spO2Value'
                   [ngClass]="{'critical': spO2Error || (spO2Value | checkVitalEmrRules: ObservationFields.SpO2 : patient)}"
                   type='number'>
            <div class='select-box'>
              <div class='select-title'>Method</div>
              <nz-select [(ngModel)]='spMethodSelect' nzDropdownClassName='custom-emr-modal'>
                <nz-option *ngFor='let p of spMethodData' [nzLabel]='p' [nzValue]='p'></nz-option>
              </nz-select>
            </div>
            <span *ngIf='spO2Error' class='warning'> <i
              nz-icon
              nzType='icons:modal-error'
            ></i>Double-check vital info for accuracy</span>
            <span
              *ngIf='!spO2Error && (spO2Value | checkVitalEmrRules: ObservationFields.SpO2 : patient)'
              class='warning'><i
              nz-icon
              nzType='icons:modal-error'
            ></i>Abnormal spO2 value.</span>
          </div>
          <div class='update-emr-popover__item'>
            <label
              [(ngModel)]='checkedBloodGlucose'
              [disabled]='!bloodGlucoseValue || bloodGlucoseError'
              [ngClass]="{'completed': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.bgStatus && patient.checkShiftSentEmrInformation?.bgStatus === 'SENT_TO_EMR', 'need-send': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.bgStatus && patient.checkShiftSentEmrInformation?.bgStatus !== 'SENT_TO_EMR', 'critical-time-send': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.bgStatus && patient.checkShiftSentEmrInformation?.bgStatus !== 'SENT_TO_EMR' && patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.status === 'CRITICAL_REPORT_TIME'}"
              nz-checkbox>
              <i
                *ngIf='!bloodGlucoseError && !(bloodGlucoseValue | checkVitalEmrRules: ObservationFields.BloodGlucose : patient)'
                nz-icon
                nzType='icons:modal-blood-g'
              ></i>
              <i
                *ngIf='bloodGlucoseError || (bloodGlucoseValue | checkVitalEmrRules: ObservationFields.BloodGlucose : patient)'
                nz-icon
                nzType='icons:modal-blood-g-red'
              ></i>
              <span
                [ngClass]="{'critical': bloodGlucoseError || (bloodGlucoseValue | checkVitalEmrRules: ObservationFields.BloodGlucose : patient)}"
                class='item-text'>BLOOD GLUCOSE</span>
              <span
                [ngClass]="{'critical': bloodGlucoseError || (bloodGlucoseValue | checkVitalEmrRules: ObservationFields.BloodGlucose : patient)}"
                class='item-dashed item-dashed-bg'></span>
            </label>
            <input (keydown)='protectionNumbers($event)'
                   (ngModelChange)='handlerInputChange($event, "checkedBloodGlucose", ObservationFields.BloodGlucose)'
                   [(ngModel)]='bloodGlucoseValue'
                   [ngClass]="{'critical': bloodGlucoseError || (bloodGlucoseValue | checkVitalEmrRules: ObservationFields.BloodGlucose : patient)}"
                   type='number'>
            <span *ngIf='bloodGlucoseError'
                  class='warning'>
              <i
                nz-icon
                nzType='icons:modal-error'
              ></i>
             Double-check vital info for accuracy
            </span>
            <span
              *ngIf='!bloodGlucoseError && (bloodGlucoseValue | checkVitalEmrRules: ObservationFields.BloodGlucose : patient)'
              class='warning'><i
              nz-icon
              nzType='icons:modal-error'
            ></i>Abnormal blood glucose value.</span>
          </div>
          <div class='update-emr-popover__item'>
            <label
              [(ngModel)]='checkedBodyTemperature'
              [disabled]='!bodyTemperatureValue || bodyTemperatureError'
              [ngClass]="{'completed': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.btStatus && patient.checkShiftSentEmrInformation?.btStatus === 'SENT_TO_EMR', 'need-send': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.btStatus && patient.checkShiftSentEmrInformation?.btStatus !== 'SENT_TO_EMR', 'critical-time-send': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.btStatus && patient.checkShiftSentEmrInformation?.btStatus !== 'SENT_TO_EMR' && patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.status === 'CRITICAL_REPORT_TIME'}"
              nz-checkbox>
              <i
                *ngIf='!bodyTemperatureError && !(bodyTemperatureValue | checkVitalEmrRules: ObservationFields.BodyTemperature : patient)'
                nz-icon
                nzType='icons:modal-temp'
              ></i>
              <i
                *ngIf='bodyTemperatureError || (bodyTemperatureValue | checkVitalEmrRules: ObservationFields.BodyTemperature : patient)'
                nz-icon
                nzType='icons:modal-temp-red'
              ></i>
              <span
                [ngClass]="{'critical': bodyTemperatureError || (bodyTemperatureValue | checkVitalEmrRules: ObservationFields.BodyTemperature : patient)}"
                class='item-text'>BODY TEMPERATURE</span>
              <span
                [ngClass]="{'critical': bodyTemperatureError || (bodyTemperatureValue | checkVitalEmrRules: ObservationFields.BodyTemperature : patient)}"
                class='item-dashed item-dashed-bt'></span>
            </label>
            <input (keydown)='protectionNumbers($event)'
                   (ngModelChange)='handlerInputChange($event, "checkedBodyTemperature", ObservationFields.BodyTemperature)'
                   [(ngModel)]='bodyTemperatureValue'
                   [ngClass]="{'critical': bodyTemperatureError || (bodyTemperatureValue | checkVitalEmrRules: ObservationFields.BodyTemperature : patient)}"
                   type='number'>
            <div class='select-box'>
              <div class='select-title'>Route</div>
              <nz-select [(ngModel)]='tempMethodSelect' nzDropdownClassName='custom-emr-modal'>
                <nz-option *ngFor='let p of tempMethodData' [nzLabel]='p' [nzValue]='p'></nz-option>
              </nz-select>
            </div>
            <span
              *ngIf='bodyTemperatureError'
              class='warning'>
              <i
                nz-icon
                nzType='icons:modal-error'
              ></i>
             Double-check vital info for accuracy
            </span>
            <span
              *ngIf='!bodyTemperatureError && (bodyTemperatureValue | checkVitalEmrRules: ObservationFields.BodyTemperature : patient)'
              class='warning'><i
              nz-icon
              nzType='icons:modal-error'
            ></i>Abnormal body temperature value.</span>
          </div>
          <div class='update-emr-popover__item'>
            <label
              [(ngModel)]='checkedRespirationRate'
              [disabled]='!respirationRateValue || respirationRateError'
              [ngClass]="{'completed': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.rrStatus && patient.checkShiftSentEmrInformation?.rrStatus === 'SENT_TO_EMR', 'need-send': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.rrStatus && patient.checkShiftSentEmrInformation?.rrStatus !== 'SENT_TO_EMR', 'critical-time-send': patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.rrStatus && patient.checkShiftSentEmrInformation?.rrStatus !== 'SENT_TO_EMR' && patient?.checkShiftSentEmrInformation && patient.checkShiftSentEmrInformation?.status === 'CRITICAL_REPORT_TIME'}"
              nz-checkbox>
              <i
                *ngIf='!respirationRateError && !(respirationRateValue | checkVitalEmrRules: ObservationFields.RespirationRate : patient)'
                nz-icon
                nzType='icons:modal-respiration'
              ></i>
              <i
                *ngIf='respirationRateError || (respirationRateValue | checkVitalEmrRules: ObservationFields.RespirationRate : patient)'
                nz-icon
                nzType='icons:modal-respiration-red'
              ></i>
              <span
                [ngClass]="{'critical': respirationRateError || (respirationRateValue | checkVitalEmrRules: ObservationFields.RespirationRate : patient)}"
                class='item-text'>Respiration Rate</span>
              <span
                [ngClass]="{'critical': respirationRateError || (respirationRateValue | checkVitalEmrRules: ObservationFields.RespirationRate : patient)}"
                class='item-dashed item-dashed-rr'></span>
            </label>
            <input (keydown)='protectionNumbers($event)'
                   (ngModelChange)='handlerInputChange($event, "checkedRespirationRate", ObservationFields.RespirationRate)'
                   [(ngModel)]='respirationRateValue'
                   [ngClass]="{'critical': respirationRateError || (respirationRateValue | checkVitalEmrRules: ObservationFields.RespirationRate : patient)}"
                   type='number'>
            <span
              *ngIf='respirationRateError'
              class='warning'> <i
              nz-icon
              nzType='icons:modal-error'
            ></i>Double-check vital info for accuracy</span>
            <span
              *ngIf='!respirationRateError && (respirationRateValue | checkVitalEmrRules: ObservationFields.RespirationRate : patient)'
              class='warning'><i
              nz-icon
              nzType='icons:modal-error'
            ></i>Abnormal respiration rate value.</span>
          </div>
        </div>
        <button (click)='submitForm()'
                [disabled]='!this.checkedBloodPressure &&
			!this.checkedHeartRate &&
			!this.checkedSpo2 &&
			!this.checkedBodyTemperature &&
			!this.checkedRespirationRate &&
      !this.checkedBloodGlucose'
                class='btn-prime update-emr'
                nz-button nzBlock nzType='primary'
                type='submit'>{{changedData && activeHour.type !== ReportType.Time ? 'Update and Report to EMR' : 'Report to EMR'}}
        </button>
      </div>
    </div>
  </nz-modal>
</section>

