<section class='pcc-patients'>
  <nz-modal (nzOnCancel)='handlerClickCancelButton()' [(nzVisible)]='isVisible' nzClassName='custom-pcc-patients-modal'>
    <div *nzModalTitle>
      <span class='pcc-patient-title' style='text-transform: uppercase'>{{title}}</span>
      <div class='pcc-patient-container'>
        <div class='custom-pcc-input-search'>
          <i nz-icon nzType='icons:mobile-seach-icon'></i>
          <input (ngModelChange)='handlerInputTextChange($event)' [ngModel]='searchText' [value]='searchText'
                 placeholder='Search Patient' type='text'>
        </div>
        <div class='custom-pcc-facility'>
          <span>Facility: </span>
          <div>
            <nz-select (ngModelChange)='handlerSelectProvinceChange($event)' (nzScrollToBottom)='loadMore()'
                       [(ngModel)]='selectedProvince'
                       [nzDropdownClassName]='"update-emr-popover-selector"'
                       style='width: 200px'>
              <nz-option *ngFor='let facility of pccFacilities' [nzLabel]='facility.facilityName'
                         [nzValue]='facility.facId'></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>
    <div *nzModalContent>
      <nz-table [nzData]='pccPatients' [nzScroll]="{ y: '260px' }" [nzShowPagination]='false'>
        <thead>
        <tr>
          <th class='item-name' nzWidth='140px'>{{'table.name' | translate}}</th>
          <th nzWidth='170px'>{{'table.room' | translate}}</th>
          <th nzWidth='100px'>Age</th>
          <th nzWidth='100px'>
            {{'table.gender' | translate}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr (click)='handlerClick(patient)' *ngFor='let patient of pccPatients; trackBy: trackByMethod'>
          <td [ngClass]="{'link-patient': patient.check}">
            <div>{{patient.lastName}}, {{patient.firstName}}</div>
          </td>
          <td [ngClass]="{'link-patient': patient.check}">
            <div>{{patient.unitDesc}}{{patient.roomDesc}}-{{patient.bedDesc}}</div>
          </td>
          <td [ngClass]="{'link-patient': patient.check}">
            <div>{{patient.birthDate | ageFromBirth}}</div>
          </td>
          <td [ngClass]="{'link-patient': patient.check}">
            <div>{{patient.gender | titlecase}}</div>
          </td>
        </tr>
        <div class='lazy' style='opacity: 0'>load ...</div>
        </tbody>
      </nz-table>
      <button (click)='handlerLinkButtonClick()' [ngClass]="{'show-button': showButton}"
              class='pcc-link-button'>{{buttonText}}
      </button>
    </div>
  </nz-modal>
</section>
