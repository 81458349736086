import {
	ActionType,
	getActionTypeFromInstance,
	NgxsNextPluginFn,
	NgxsPlugin
} from '@ngxs/store'
import * as Logrocket from 'logrocket'
import { Injectable } from '@angular/core'
import { catchError, EMPTY, Observable, tap, throwError } from 'rxjs'

@Injectable()
export class LogRocketPlugin implements NgxsPlugin {
	reduxMiddleware: any
	currentState: any = null
	store: any = {}

	constructor() {
		if (window.location.hostname !== 'localhost') {
			this.reduxMiddleware = Logrocket.reduxMiddleware({
				stateSanitizer: function (state) {
					//TODO: conditional sanitization depending on size
					return {
						...state,
						alert: undefined
					}
				}
			})

			this.store = this.reduxMiddleware({
				getState: () => this.currentState
			})
		}
	}

	handle(state: any, action: ActionType, next: NgxsNextPluginFn) {
		const type = getActionTypeFromInstance(action)
		let n: Observable<any> = next(state, action)
		return n.pipe(
			catchError((error) => {
				if (
					(error &&
						error.stack &&
						error.stack.includes('patients_module_ts.js')) ||
					(error.error &&
						error.error.message &&
						error.error.message.includes('createInvalidObservableTypeError')) ||
					(error.error &&
						error.error.message &&
						error.error.message.includes('IconNotFoundError')) ||
					(error.error &&
						error.error.message &&
						error.error.message.includes('ShiftPlanerListComponent_Template'))
				) {
					return EMPTY
				}
				return throwError(error)
			}),
			tap((nextState) => {
				this.currentState = { ...state }
				// @ts-ignore
				let a = { type, ...action }
				if (typeof this.store === 'function') {
					this.store(() => nextState)(a)
				}
			})
		)
	}
}
