<nz-modal [(nzVisible)]='isModalVisible'
          nzClassName='custom-pcc-update-emr-modal'>
  <div *nzModalContent>
    <div class='update-emr-popover__container'>
        <span (click)='handlerClickCancelButton()' class='icon-close' nz-icon nzTheme='outline'
              nzType='close'></span>
      <div class='update-emr-popover__top-content'>
        <div class='update-emr-popover__avatar'>
          <nz-avatar *ngIf='!patient["avatar"]; else haveAvatar' [nzText]='patient.name | shortName'
                     class='aiomed-avatar'
                     nzSize='default'></nz-avatar>
          <ng-template #haveAvatar>
            <nz-avatar [nzSrc]='patient["avatar"]["signedUrl"]'
                       nzSize='default'></nz-avatar>
          </ng-template>
        </div>
        <div class='update-emr-popover__title-content'>
          <div class='update-emr-popover__title' style='text-transform: uppercase;'>
            {{patient.lastName}}, {{patient.firstName}}<span>{{patient?.room}}</span>
          </div>
          <div class='update-emr-popover__sub-title'>
            Last updated on {{patient?.reports?.lastMeasurement | time}}
          </div>
        </div>
      </div>
      <div *ngIf='needLogin' class='update-emr-popover__main-content'>
        <div class='update-emr-popover__item'>
          <label>
            <i
              *ngIf='!diastolicError && !systolicError'
              nz-icon
              nzType='icons:modal-pressure'
            ></i>
            <i
              *ngIf='diastolicError || systolicError'
              nz-icon
              nzType='icons:modal-pressure-red'
            ></i>
            <span
              [ngClass]="{'critical': diastolicError || systolicError}"
              class='item-text'>BLOOD PRESSURE</span>
            <span
              [ngClass]="{'critical': diastolicError || systolicError}"
              class='item-dashed item-dashed-sbp'></span>
          </label>
          <div [ngClass]="{'critical': diastolicError || systolicError}" class='input-box'>
            <input (keydown)='protectionNumbers($event)'
                   (ngModelChange)='handlerInputChange($event, ObservationFields.SystolicPressure)'
                   [(ngModel)]='systolicValue'
                   type='number'>/<input
            (keydown)='protectionNumbers($event)'
            (ngModelChange)='handlerInputChange($event, ObservationFields.DiastolicPressure)'
            [(ngModel)]='diastolicValue' type='number'>
          </div>
          <span *ngIf='systolicError || diastolicError' class='warning'><i
            nz-icon
            nzType='icons:modal-error'
          ></i>Abnormal blood pressure value.</span>
        </div>
        <div class='update-emr-popover__item'>
          <label>
            <i
              [ngClass]="{'critical': heartRateError}"
              [nzType]='ObservationFields.HeartRate | alertSubjectIcon'
              nz-icon
            ></i>
            <span
              [ngClass]="{'critical': heartRateError}"
              class='item-text'>HEART RATE</span>
            <span
              [ngClass]="{'critical': heartRateError}"
              class='item-dashed item-dashed-hr'></span>
          </label>
          <input (keydown)='protectionNumbers($event)'
                 (ngModelChange)='handlerInputChange($event, ObservationFields.HeartRate)'
                 [(ngModel)]='heartRateValue' [ngClass]="{'critical': heartRateError}"
                 type='number'>
          <span *ngIf='heartRateError' class='warning'> <i
            nz-icon
            nzType='icons:modal-error'
          ></i>Abnormal heart rate value.</span>
        </div>
        <div class='update-emr-popover__item'>
          <label>
            <i
              *ngIf='!spO2Error'
              nz-icon
              nzType='icons:modal-spo'
            ></i>
            <i
              *ngIf='spO2Error'
              nz-icon
              nzType='icons:modal-spo-red'
            ></i>
            <span
              [ngClass]="{'critical': spO2Error}"
              class='item-text'>SPO2</span>
            <span
              [ngClass]="{'critical': spO2Error}"
              class='item-dashed item-dashed-sp'></span>
          </label>
          <input (keydown)='protectionNumbers($event)'
                 (ngModelChange)='handlerInputChange($event, ObservationFields.SpO2)'
                 [(ngModel)]='spO2Value' [ngClass]="{'critical': spO2Error}"
                 type='number'>
          <span *ngIf='spO2Error' class='warning'> <i
            nz-icon
            nzType='icons:modal-error'
          ></i>Abnormal SpO2 value.</span>
        </div>
        <div class='update-emr-popover__item'>
          <label>
            <i
              *ngIf='!bodyTemperatureError'
              nz-icon
              nzType='icons:modal-temp'
            ></i>
            <i
              *ngIf='bodyTemperatureError'
              nz-icon
              nzType='icons:modal-temp-red'
            ></i>
            <span
              [ngClass]="{'critical': bodyTemperatureError}"
              class='item-text'>BODY TEMPERATURE</span>
            <span
              [ngClass]="{'critical': bodyTemperatureError}"
              class='item-dashed item-dashed-bt'></span>
          </label>
          <input (keydown)='protectionNumbers($event)'
                 (ngModelChange)='handlerInputChange($event, ObservationFields.BodyTemperature)'
                 [(ngModel)]='bodyTemperatureValue'
                 [ngClass]="{'critical': bodyTemperatureError}" type='number'>
          <span *ngIf='bodyTemperatureError' class='warning'>
              <i
                nz-icon
                nzType='icons:modal-error'
              ></i>
              Abnormal body temperature value.
            </span>
        </div>
        <div class='update-emr-popover__item'>
          <label>
            <i
              *ngIf='!respirationRateError'
              nz-icon
              nzType='icons:modal-respiration'
            ></i>
            <i
              *ngIf='respirationRateError'
              nz-icon
              nzType='icons:modal-respiration-red'
            ></i>
            <span
              [ngClass]="{'critical': respirationRateError}"
              class='item-text'>Respiration Rate</span>
            <span
              [ngClass]="{'critical': respirationRateError}"
              class='item-dashed item-dashed-rr'></span>
          </label>
          <input (keydown)='protectionNumbers($event)'
                 (ngModelChange)='handlerInputChange($event, ObservationFields.RespirationRate)'
                 [(ngModel)]='respirationRateValue'
                 [ngClass]="{'critical': respirationRateError}" type='number'>
          <span *ngIf='respirationRateError' class='warning'> <i
            nz-icon
            nzType='icons:modal-error'
          ></i>Abnormal respiration rate value.</span>
        </div>
      </div>
      <button (click)='submitForm()'
              [disabled]='(!this.diastolicValue &&
				!this.systolicValue &&
				!this.heartRateValue &&
				!this.respirationRateValue &&
				!this.spO2Value &&
				!this.bodyTemperatureValue) ||
			this.bodyTemperatureError ||
			this.respirationRateError ||
			this.heartRateError ||
			this.diastolicError ||
			this.systolicError ||
			this.spO2Error'
              class='btn-prime update-emr'
              nz-button nzBlock nzType='primary'
              type='submit'>Update
      </button>
    </div>
  </div>
</nz-modal>
