import { Routes } from '@angular/router'
import { NotAuthenticated } from '../../core/guards/not-authenticated.guard'
import { IsAuthenticated } from '../../core/guards/is-authenticated.guard'
import { ROUTE_DATA } from '../../core/helpers/variables'
import { MainMobileComponent } from '../../app/layouts/mobile-ionic-components/component/main-mobile/main-mobile.component'

export const MobileRoutes: Routes = [
	{
		path: 'login',
		canActivate: [NotAuthenticated],
		canLoad: [NotAuthenticated],
		loadChildren: () =>
			import('../../app/pages/mobile/auth/auth.module').then(
				(m) => m.AuthModule
			)
	},
	{
		path: '',
		component: MainMobileComponent,
		canLoad: [IsAuthenticated],
		canActivate: [IsAuthenticated],
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'home'
			},
			{
				path: 'home',
				loadChildren: () =>
					import('../../app/pages/mobile/patients/patients.module').then(
						(m) => m.PatientsModule
					)
			}
		]
	},
	{
		path: 'patients',
		canLoad: [IsAuthenticated],
		canActivate: [IsAuthenticated],
		data: {
			...ROUTE_DATA.devices
		},
		component: MainMobileComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import(
						'../../app/pages/mobile/all-patients/all-patients.module'
					).then((m) => m.AllPatientsModule)
			}
		]
	},
	{
		path: 'patient/:patientId',
		canLoad: [IsAuthenticated],
		canActivate: [IsAuthenticated],
		component: MainMobileComponent,
		data: {
			...ROUTE_DATA.patient
		},
		loadChildren: () =>
			import('../../app/pages/mobile/patient/patient.module').then(
				(m) => m.PatientModule
			)
	}
]
