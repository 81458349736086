import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PatientInterface } from '../../../../../shared/model/patient'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { AuthState } from '../../../../../store/auth/auth.state'
import {
	getUserLocale,
	transformCelsiusToFahrenhei,
	transformFahrenheiToCelsius
} from '../../../../../core/helpers/functions'
import { ObservationFields } from 'projects/aiomed/src/shared/model/patient-observation'

@Component({
	selector: 'aiomed-manual-enter-modal',
	templateUrl: './manual-enter-modal.component.html',
	styleUrls: ['./manual-enter-modal.component.scss']
})
export class ManualEnterModalComponent implements OnInit {
	@Input() patient: PatientInterface
	@Input() isModalVisible: boolean = false
	@Output('closeModal') closeModalEmitter = new EventEmitter()
	@Output() setManualVitalsEmitter = new EventEmitter()
	@Output() closeModalNotSaved = new EventEmitter()
	ObservationFields = ObservationFields
	selectedProvince = 0
	needLogin: boolean = true
	form: FormGroup
	passwordVisible = false
	password?: string
	diastolicError: boolean = false
	systolicError: boolean = false
	heartRateError: boolean = false
	respirationRateError: boolean = false
	spO2Error: boolean = false
	bodyTemperatureError: boolean = false
	diastolicValue: number | null = null
	systolicValue: number | null = null
	heartRateValue: number | null = null
	respirationRateValue: number | null = null
	spO2Value: number | null = null
	bodyTemperatureValue: number | null = null
	firstData: {
		diastolic: number | any
		systolic: number | any
		heartRate: number | any
		respirationRate: number | any
		spo2: number | any
		bodyTemperature: number | any
	} = {
		diastolic: null,
		systolic: null,
		heartRate: null,
		respirationRate: null,
		spo2: null,
		bodyTemperature: null
	}

	constructor(private fb: FormBuilder, private authState: AuthState) {
		this.form = this.fb.group({
			username: [null, Validators.compose([Validators.required])],
			password: [
				null,
				Validators.compose([Validators.required, Validators.minLength(8)])
			]
		})
	}

	private static toTransformFahrenheiToCelsius(value: number): number {
		return getUserLocale() === 'en-US'
			? transformFahrenheiToCelsius(value)
			: value
	}

	ngOnInit(): void {
		if (this.patient && this.patient.reports?.measurements?.diastolicPressure) {
			this.firstData.diastolic = this.diastolicValue = Math.round(
				this.patient.reports?.measurements?.diastolicPressure?.value
			)
		}
		if (this.patient && this.patient.reports?.measurements?.systolicPressure) {
			this.firstData.systolic = this.systolicValue = Math.round(
				this.patient.reports?.measurements?.systolicPressure?.value
			)
		}
		if (this.patient && this.patient.reports?.measurements?.heartRate) {
			this.firstData.heartRate = this.heartRateValue = Math.round(
				this.patient.reports?.measurements?.heartRate?.value
			)
		}
		if (this.patient && this.patient.reports?.measurements?.respirationRate) {
			this.firstData.respirationRate = this.respirationRateValue = Math.round(
				this.patient.reports?.measurements?.respirationRate?.value
			)
		}
		if (this.patient && this.patient.reports?.measurements?.spo2) {
			this.firstData.spo2 = this.spO2Value = Math.round(
				this.patient.reports?.measurements?.spo2?.value
			)
		}
		if (this.patient && this.patient.reports?.measurements?.bodyTemperature) {
			getUserLocale() === 'en-US'
				? // @ts-ignore
				  (this.firstData.bodyTemperature = this.bodyTemperatureValue =
						Math.floor(
							transformCelsiusToFahrenhei(
								this.patient.reports?.measurements?.bodyTemperature?.value
							) * 10
						) / 10)
				: // @ts-ignore
				  (this.firstData.bodyTemperature = this.bodyTemperatureValue =
						this.patient.reports?.measurements?.bodyTemperature?.value.toFixed(
							1
						))
		}
	}

	async submitForm() {
		if (
			(!this.diastolicValue &&
				!this.systolicValue &&
				!this.heartRateValue &&
				!this.respirationRateValue &&
				!this.spO2Value &&
				!this.bodyTemperatureValue) ||
			this.bodyTemperatureError ||
			this.respirationRateError ||
			this.heartRateError ||
			this.diastolicError ||
			this.systolicError ||
			this.spO2Error
		) {
			return
		}
		this.setManualVitalsEmitter.emit({
			observedPatient: this.patient.id,
			...this.setCheckedPatientCurrentObservations()
		})
	}

	setCheckedPatientCurrentObservations() {
		let tmpObj: any = {}
		if (
			this.diastolicValue &&
			this.diastolicValue <= 120 &&
			this.diastolicValue >= 40 &&
			this.diastolicValue !== this.firstData.diastolic
		) {
			tmpObj[ObservationFields.DiastolicPressure] = this.diastolicValue
		}
		if (
			this.systolicValue &&
			this.systolicValue <= 220 &&
			this.systolicValue >= 70 &&
			this.systolicValue !== this.firstData.systolic
		) {
			tmpObj[ObservationFields.SystolicPressure] = this.systolicValue
		}
		if (
			this.heartRateValue &&
			this.heartRateValue <= 150 &&
			this.heartRateValue >= 40 &&
			this.heartRateValue !== this.firstData.heartRate
		) {
			tmpObj.heartRate = this.heartRateValue
		}
		if (
			this.respirationRateValue &&
			this.respirationRateValue <= 35 &&
			this.respirationRateValue >= 5 &&
			this.respirationRateValue !== this.firstData.respirationRate
		) {
			tmpObj.respirationRate = this.respirationRateValue
		}
		if (
			this.spO2Value &&
			this.spO2Value <= 100 &&
			this.spO2Value >= 80 &&
			this.spO2Value !== this.firstData.spo2
		) {
			tmpObj[ObservationFields.SpO2] = this.spO2Value
		}
		if (
			this.bodyTemperatureValue &&
			getUserLocale() === 'en-US' &&
			this.bodyTemperatureValue <= 106 &&
			this.bodyTemperatureValue >= 93 &&
			this.bodyTemperatureValue !== this.firstData.bodyTemperature
		) {
			tmpObj.bodyTemperature =
				ManualEnterModalComponent.toTransformFahrenheiToCelsius(
					this.bodyTemperatureValue
				)
		}
		if (
			this.bodyTemperatureValue &&
			getUserLocale() !== 'en-US' &&
			this.bodyTemperatureValue <= 41 &&
			this.bodyTemperatureValue >= 34 &&
			this.bodyTemperatureValue !== this.firstData.bodyTemperature
		) {
			tmpObj.bodyTemperature = this.bodyTemperatureValue
		}
		return tmpObj
	}

	async handlerClickCancelButton() {
		let haveChangedData = false
		const {
			systolic,
			diastolic,
			spo2,
			respirationRate,
			bodyTemperature,
			heartRate
		} = this.firstData
		if (
			this.systolicValue !== systolic ||
			this.diastolicValue !== diastolic ||
			this.spO2Value !== spo2 ||
			this.respirationRateValue !== respirationRate ||
			this.bodyTemperatureValue !== bodyTemperature ||
			this.heartRateValue !== heartRate
		) {
			haveChangedData = true
		}

		if (haveChangedData) {
			if (!Object.values(this.setCheckedPatientCurrentObservations()).length) {
				this.closeModalEmitter.emit(false)
			} else {
				this.closeModalNotSaved.emit({
					observedPatient: this.patient.id,
					...this.setCheckedPatientCurrentObservations()
				})
			}
			// this.closeModalNotSaved.emit()
		} else {
			this.closeModalEmitter.emit(false)
		}
	}

	handlerInputChange($event: number, type: string) {
		if (type === ObservationFields.SystolicPressure) {
			this.systolicError =
				(type === ObservationFields.SystolicPressure && $event > 220) ||
				(type === ObservationFields.SystolicPressure && $event < 70)
			this.diastolicError =
				// @ts-ignore
				this.diastolicValue > 120 ||
				// @ts-ignore
				this.diastolicValue < 40

			if (
				this.firstData.systolic === $event &&
				this.firstData.diastolic === this.diastolicValue
			) {
				this.systolicError = false
				this.diastolicError = false
			}
		}
		if (type === ObservationFields.DiastolicPressure) {
			this.diastolicError =
				(type === ObservationFields.DiastolicPressure && $event > 120) ||
				(type === ObservationFields.DiastolicPressure && $event < 40)

			this.systolicError =
				// @ts-ignore
				this.systolicValue > 220 ||
				// @ts-ignore
				this.systolicValue < 70
			if (
				this.firstData.systolic === this.systolicValue &&
				this.firstData.diastolic === $event
			) {
				this.systolicError = false
				this.diastolicError = false
			}
		}
		if (type === ObservationFields.HeartRate) {
			this.heartRateError =
				(type === ObservationFields.HeartRate && $event > 150) ||
				(type === ObservationFields.HeartRate && $event < 40)
			if (this.firstData.heartRate === $event) this.heartRateError = false
		}
		if (type === ObservationFields.SpO2) {
			this.spO2Error =
				(type === ObservationFields.SpO2 && $event > 100) ||
				(type === ObservationFields.SpO2 && $event < 80)
			if (this.firstData.spo2 === $event) this.spO2Error = false
		}
		if (type === ObservationFields.RespirationRate) {
			this.respirationRateError =
				(type === ObservationFields.RespirationRate && $event > 35) ||
				(type === ObservationFields.RespirationRate && $event < 5)
			if (this.firstData.respirationRate === $event)
				this.respirationRateError = false
		}
		if (type === ObservationFields.BodyTemperature) {
			getUserLocale() === 'en-US'
				? (this.bodyTemperatureError =
						(type === ObservationFields.BodyTemperature && $event > 106) ||
						(type === ObservationFields.BodyTemperature && $event < 93))
				: (this.bodyTemperatureError =
						(type === ObservationFields.BodyTemperature && $event > 41) ||
						(type === ObservationFields.BodyTemperature && $event < 34))
			if (this.firstData.bodyTemperature === $event)
				this.bodyTemperatureError = false
		}
	}

	async handlerClickCancelModal() {
		await this.handlerClickCancelButton()
	}

	protectionNumbers($event: KeyboardEvent) {
		if (
			[
				'0',
				'1',
				'2',
				'3',
				'4',
				'5',
				'6',
				'7',
				'8',
				'9',
				'0',
				'.',
				'Backspace'
			].includes($event.key)
		) {
		} else {
			$event.preventDefault()
		}
	}
}
