import {
	Directive,
	Input,
	OnChanges,
	TemplateRef,
	ViewContainerRef
} from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { UserInterface } from '../../shared/model/user.model'

@UntilDestroy()
@Directive({
	selector: '[isMdPermission]'
})
export class AiomedIsMdPermissionDirective implements OnChanges {
	@Input('isMdPermission') user: UserInterface | null

	constructor(
		private viewContainerRef: ViewContainerRef,
		private templateRef: TemplateRef<any>
	) {}

	ngOnChanges(): void {
		if (this.user && (this.user as any)._degree === 'MEDICAL_DOCTOR') {
			this.viewContainerRef.clear()
			this.viewContainerRef.createEmbeddedView(this.templateRef)
		} else {
			this.viewContainerRef.clear()
		}
	}
}
