import {
	CombineTreatmentPlansDTO,
	CombineTreatmentPlansVitalsDTO
} from '../../shared/model/treatment-plan'
import { PatientExportDTO } from '../../shared/model/pcc.model'
import { CheckShiftSentEmrInformationInterface } from '../../shared/model/report.model'
import moment from 'moment/moment'
import { cloneDeep } from 'lodash-es'
import { checkShiftTime } from './check-shift-time'

export function checkVitalsToTreatmentPlan(
	treatmentPlan: CombineTreatmentPlansDTO,
	data: PatientExportDTO[],
	currentDate?: string
): CheckShiftSentEmrInformationInterface | null {
	const date = !currentDate ? moment() : moment(currentDate)
	if (
		treatmentPlan.morningShiftTime &&
		date.get('hours') >= checkShiftTime.getShiftTime('morning_shift').min &&
		date.get('hours') < checkShiftTime.getShiftTime('morning_shift').max
	) {
		const tmpData = setTreatmentPlanSetting(treatmentPlan.morningVitalsData)
		const tmpObj: CheckShiftSentEmrInformationInterface = cloneDeep(
			tmpData.tmpObj
		)
		const needToCheckLength: number = tmpData.needToCheckLength
		const currentShiftExportData = data.filter(
			(exports) =>
				exports.creationTime >=
					moment(new Date())
						.set('hours', checkShiftTime.getShiftTime('morning_shift').min)
						.set('minutes', 0)
						.toISOString() &&
				exports.creationTime <
					moment(new Date())
						.set('hours', checkShiftTime.getShiftTime('morning_shift').max)
						.set('minutes', 0)
						.toISOString()
		)
		const checkShiftSentEmrInformation =
			setShiftCheckVitalsTreatmentPlanSetting(
				currentShiftExportData,
				tmpObj,
				needToCheckLength
			)
		if (
			new Date().toISOString() >=
				moment(new Date())
					.set('hours', checkShiftTime.getShiftTime('morning_shift').max - 1)
					.set('minutes', 30)
					.toISOString() &&
			checkShiftSentEmrInformation.status === 'NEED_EXPORT_TO_EMR'
		) {
			checkShiftSentEmrInformation.status = 'CRITICAL_REPORT_TIME'
		}
		if (needToCheckLength === 0)
			checkShiftSentEmrInformation.status = 'NOT_REQUIRED'
		return checkShiftSentEmrInformation
	} else if (
		treatmentPlan.dayShiftTime &&
		date.get('hours') >= checkShiftTime.getShiftTime('day_shift').min &&
		date.get('hours') < checkShiftTime.getShiftTime('day_shift').max
	) {
		const tmpData = setTreatmentPlanSetting(treatmentPlan.dayVitalsData)
		const tmpObj: CheckShiftSentEmrInformationInterface = cloneDeep(
			tmpData.tmpObj
		)
		const needToCheckLength: number = tmpData.needToCheckLength
		const currentShiftExportData = data.filter(
			(exports) =>
				exports.creationTime >=
					moment(new Date())
						.set('hours', checkShiftTime.getShiftTime('day_shift').min)
						.set('minutes', 0)
						.toISOString() &&
				exports.creationTime <
					moment(new Date())
						.set('hours', checkShiftTime.getShiftTime('day_shift').max)
						.set('minutes', 0)
						.toISOString()
		)
		const checkShiftSentEmrInformation =
			setShiftCheckVitalsTreatmentPlanSetting(
				currentShiftExportData,
				tmpObj,
				needToCheckLength
			)
		if (
			new Date().toISOString() >=
				moment(new Date())
					.set('hours', checkShiftTime.getShiftTime('day_shift').max - 1)
					.set('minutes', 30)
					.toISOString() &&
			checkShiftSentEmrInformation.status === 'NEED_EXPORT_TO_EMR'
		) {
			checkShiftSentEmrInformation.status = 'CRITICAL_REPORT_TIME'
		}
		if (needToCheckLength === 0)
			checkShiftSentEmrInformation.status = 'NOT_REQUIRED'
		return checkShiftSentEmrInformation
	} else if (
		treatmentPlan.nightShiftTime &&
		date.get('hours') >= checkShiftTime.getShiftTime('night_shift').min
	) {
		const tmpData = setTreatmentPlanSetting(treatmentPlan.nightVitalsData)
		const tmpObj: CheckShiftSentEmrInformationInterface = cloneDeep(
			tmpData.tmpObj
		)
		const needToCheckLength: number = tmpData.needToCheckLength
		const currentShiftExportData = data.filter(
			(exports) =>
				exports.creationTime >=
				moment(new Date())
					.set('hours', checkShiftTime.getShiftTime('night_shift').min)
					.set('minutes', 0)
					.toISOString()
		)
		const checkShiftSentEmrInformation =
			setShiftCheckVitalsTreatmentPlanSetting(
				currentShiftExportData,
				tmpObj,
				needToCheckLength
			)
		if (needToCheckLength === 0)
			checkShiftSentEmrInformation.status = 'NOT_REQUIRED'
		return checkShiftSentEmrInformation
	} else if (
		treatmentPlan.nightShiftTime &&
		date.get('hours') < checkShiftTime.getShiftTime('night_shift').max
	) {
		const tmpData = setTreatmentPlanSetting(treatmentPlan.nightVitalsData)
		const tmpObj: CheckShiftSentEmrInformationInterface = cloneDeep(
			tmpData.tmpObj
		)
		const needToCheckLength: number = tmpData.needToCheckLength
		const currentShiftExportData = data.filter(
			(exports) =>
				exports.creationTime >=
					moment(new Date())
						.set('hours', checkShiftTime.getShiftTime('night_shift').min)
						.set('minutes', 0)
						.subtract(1, 'days')
						.toISOString() &&
				exports.creationTime <
					moment(new Date())
						.set('hours', checkShiftTime.getShiftTime('night_shift').max)
						.set('minutes', 0)
						.toISOString()
		)
		const checkShiftSentEmrInformation =
			setShiftCheckVitalsTreatmentPlanSetting(
				currentShiftExportData,
				tmpObj,
				needToCheckLength
			)
		if (
			new Date().toISOString() >=
				moment(new Date())
					.set('hours', checkShiftTime.getShiftTime('night_shift').max - 1)
					.set('minutes', 30)
					.toISOString() &&
			checkShiftSentEmrInformation.status === 'NEED_EXPORT_TO_EMR'
		) {
			checkShiftSentEmrInformation.status = 'CRITICAL_REPORT_TIME'
		}
		if (needToCheckLength === 0)
			checkShiftSentEmrInformation.status = 'NOT_REQUIRED'
		return checkShiftSentEmrInformation
	} else {
		// @ts-ignore
		return null
	}
}

function setTreatmentPlanSetting(
	treatmentPlan: CombineTreatmentPlansVitalsDTO
): {
	needToCheckLength: number
	tmpObj: CheckShiftSentEmrInformationInterface
} {
	let needToCheckLength = 0
	const tmpObj: CheckShiftSentEmrInformationInterface = {
		status: 'NEED_EXPORT_TO_EMR'
	}

	if (treatmentPlan.BT) {
		needToCheckLength += 1
		tmpObj.bt = false
		tmpObj.btStatus = 'NEED_SEND_TO_EMR'
	}
	if (treatmentPlan.BG) {
		needToCheckLength += 1
		tmpObj.bg = false
		tmpObj.bgStatus = 'NEED_SEND_TO_EMR'
	}
	if (treatmentPlan.SPO2) {
		needToCheckLength += 1
		tmpObj.spo2 = false
		tmpObj.spo2Status = 'NEED_SEND_TO_EMR'
	}
	if (treatmentPlan.BP) {
		needToCheckLength += 1
		tmpObj.bp = false
		tmpObj.bpStatus = 'NEED_SEND_TO_EMR'
	}
	if (treatmentPlan.HR) {
		needToCheckLength += 1
		tmpObj.hr = false
		tmpObj.hrStatus = 'NEED_SEND_TO_EMR'
	}
	if (treatmentPlan.RR) {
		needToCheckLength += 1
		tmpObj.rr = false
		tmpObj.rrStatus = 'NEED_SEND_TO_EMR'
	}

	return {
		needToCheckLength,
		tmpObj
	}
}

function setShiftCheckVitalsTreatmentPlanSetting(
	currentShiftExportData: PatientExportDTO[],
	reportTreatmentPlan: CheckShiftSentEmrInformationInterface,
	needToCheckLength: number
): CheckShiftSentEmrInformationInterface {
	let checkedLength = 0
	currentShiftExportData.forEach((e) => {
		if (
			e.bodyTemperature &&
			reportTreatmentPlan.btStatus === 'NEED_SEND_TO_EMR'
		) {
			reportTreatmentPlan.bt = true
			if (
				currentShiftExportData[currentShiftExportData.length - 1]
					?.export_status !== 'failed'
			) {
				reportTreatmentPlan.btStatus = 'SENT_TO_EMR'
			}
		}
		if (
			e.systolicPressure &&
			e.diastolicPressure &&
			reportTreatmentPlan.bpStatus === 'NEED_SEND_TO_EMR'
		) {
			reportTreatmentPlan.bp = true
			if (
				currentShiftExportData[currentShiftExportData.length - 1]
					?.export_status !== 'failed'
			) {
				reportTreatmentPlan.bpStatus = 'SENT_TO_EMR'
			}
		}
		if (e.heartRate && reportTreatmentPlan.hrStatus === 'NEED_SEND_TO_EMR') {
			reportTreatmentPlan.hr = true
			if (
				currentShiftExportData[currentShiftExportData.length - 1]
					?.export_status !== 'failed'
			) {
				reportTreatmentPlan.hrStatus = 'SENT_TO_EMR'
			}
		}
		if (e.bloodGlucose && reportTreatmentPlan.bgStatus === 'NEED_SEND_TO_EMR') {
			reportTreatmentPlan.bg = true
			if (
				currentShiftExportData[currentShiftExportData.length - 1]
					?.export_status !== 'failed'
			) {
				reportTreatmentPlan.bgStatus = 'SENT_TO_EMR'
			}
		}
		if (
			e.respirationRate &&
			reportTreatmentPlan.rrStatus === 'NEED_SEND_TO_EMR'
		) {
			reportTreatmentPlan.rr = true
			if (
				currentShiftExportData[currentShiftExportData.length - 1]
					?.export_status !== 'failed'
			) {
				reportTreatmentPlan.rrStatus = 'SENT_TO_EMR'
			}
		}
		if (e.spo2 && reportTreatmentPlan.spo2Status === 'NEED_SEND_TO_EMR') {
			reportTreatmentPlan.spo2 = true
			if (
				currentShiftExportData[currentShiftExportData.length - 1]
					?.export_status !== 'failed'
			) {
				reportTreatmentPlan.spo2Status = 'SENT_TO_EMR'
			}
		}
	})
	reportTreatmentPlan.hasSomeVitals = false
	if (
		currentShiftExportData.length &&
		currentShiftExportData[currentShiftExportData.length - 1].export_status !==
			'failed'
	) {
		const exp = currentShiftExportData[currentShiftExportData.length - 1]
		reportTreatmentPlan.hasSomeVitals = !!(
			exp.spo2 ||
			exp.respirationRate ||
			exp.bloodGlucose ||
			exp.heartRate ||
			exp.systolicPressure ||
			exp.diastolicPressure ||
			exp.bodyTemperature
		)
	}
	Object.keys(reportTreatmentPlan).forEach((k) => {
		if (
			k === 'status' ||
			k === 'rrStatus' ||
			k === 'hrStatus' ||
			k === 'bpStatus' ||
			k === 'btStatus' ||
			k === 'spo2Status' ||
			k === 'bgStatus' ||
			k === 'hasSomeVitals' ||
			// @ts-ignore
			!reportTreatmentPlan[k]
		)
			return
		checkedLength += 1
		if (needToCheckLength === checkedLength) {
			if (
				currentShiftExportData[currentShiftExportData.length - 1]
					?.export_status !== 'failed'
			) {
				reportTreatmentPlan.status = 'COMPLETED'
			}
		} else {
			reportTreatmentPlan.status = 'NEED_EXPORT_TO_EMR'
		}
	})
	return reportTreatmentPlan
}
