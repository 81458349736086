import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PatientInterface } from '../../model/patient'
import { UserInterface } from '../../model/user.model'
import { AlertDTO } from '../../model/alert'
import { DismissAlertModel } from '../../model/dismissAlert.model'

@Directive()
export class AlertModal implements OnInit {
	@Input() isVisible: boolean = false
	@Input() title: string = 'Alert resolved'
	@Input() patient: PatientInterface
	@Input() currentUser: UserInterface | null
	@Input() alerts: AlertDTO[]
	@Input() agoFormatter: any
	@Input() dismissAlert: EventEmitter<DismissAlertModel[]>
	@Output('dismissAlertVisibleEmitter') dismissAlertVisibleEmitter =
		new EventEmitter()
	@Output('dismissAlertPatientEmitter') dismissAlertPatient = new EventEmitter()
	resolution = 'false'
	timeValue = 1
	alertInputValue: string
	alertValidateStatus: string = 'success'
	dismissAlerts: { alertId: string; resolution: string; time: any }[] = []

	ngOnInit(): void {
		this.setCheckBoxClick()
	}

	increment($event: string): void {
		if ($event === 'decrease' && this.timeValue >= 1) {
			this.timeValue = 1
		} else if ($event === 'decrease' && this.timeValue < 1) {
			this.timeValue -= 1
		} else {
			this.timeValue += 1
		}
		if (!this.dismissAlerts.length) return
		this.dismissAlerts.forEach((data) => (data.time = this.timeValue))
	}

	handlerAlertInputChange({ srcElement }: any): void {
		this.alertInputValue = srcElement.value
	}

	handlerResolutionChange($event: string) {
		if (!this.dismissAlerts.length) return
		this.dismissAlerts.forEach((data) => (data.resolution = $event))
	}

	handlerClickCancelButton(): void {
		this.dismissAlertVisibleEmitter.emit(false)
		this.alertInputValue = ''
		this.dismissAlerts = []
	}

	submitForm(): void {
		// if ('12345' !== this.alertInputValue) {
		// 	this.alertValidateStatus = 'error'
		// 	return
		// }
		this.alertValidateStatus = 'success'
		if (this.alerts.length === 1) {
			this.dismissAlerts = [
				{
					alertId: this.alerts[0].id,
					resolution: this.resolution,
					time: this.timeValue
				}
			]
		}
		if (!this.dismissAlert) {
			this.dismissAlertPatient.emit(this.dismissAlerts)
		} else {
			this.dismissAlert.emit(this.dismissAlerts)
		}
		this.dismissAlertVisibleEmitter.emit(false)
		this.alertInputValue = ''
	}

	handlerAlertClick(alert: AlertDTO | undefined) {
		if (!alert) return
		const currentAlert = this.dismissAlerts.find(
			(el) => el.alertId === alert.id
		)
		if (currentAlert) {
			this.dismissAlerts = this.dismissAlerts.filter(
				(da) => da.alertId !== currentAlert.alertId
			)
			return
		}
		this.dismissAlerts.push({
			alertId: alert.id,
			resolution: this.resolution,
			time: this.timeValue
		})
	}

	setCheckBoxClick(): void {
		setTimeout(() => {
			const elements = document.querySelectorAll('input[type=checkbox]')
			elements.forEach((e) =>
				e.addEventListener('click', (event: any) => {
					const el = this.alerts.find(
						(a) => a.id === event.srcElement.labels[0].attributes[3].value
					)
					this.handlerAlertClick(el)
				})
			)
		}, 1000)
	}
}
