import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
	selector: 'aiomed-alert-rule-unsaved-changes',
	templateUrl: './alert-rule-unsaved-changes.component.html',
	styleUrls: ['./alert-rule-unsaved-changes.component.scss']
})
export class AlertRuleUnsavedChangesComponent implements OnInit {
	@Input() isVisible: boolean = false
	@Output() alertRuleUnsavedChangesEmitter = new EventEmitter()

	constructor() {}

	ngOnInit(): void {}
}
