import { PccUpdateEmrMeasurement } from './pcc.model'
import { NewReadSocketModel } from './new-read-socket-model'

export interface ReportInterface {
	requestPatientsVitals: string[]
	requestVitals: string[]
	monitorPatientIds: string[]
	textFilter: string
	type: string
	time: string | null
	loading: boolean
	forceReadDevicesStatus: NewReadSocketModel[]
	isForceReadProcess: boolean
}

export enum ReportType {
	Time = 'time',
	CurrentShift = 'current_shift'
}

export enum ReportMode {
	Average = 'AVERAGE',
	Latest = 'LATEST'
}

export enum ReportVitalsFields {
	RepositionPatient = 'reposition_patient',
	ChangeDiaper = 'change_diaper',
	MeasureHeartRate = 'measure_heart_rate',
	MeasureBodyTemperature = 'measure_body_temperature',
	MeasureBloodPressure = 'measure_blood_pressure',
	MeasureBloodSugar = 'measure_blood_sugar',
	MeasureRespirationRate = 'measure_respiration_rate',
	MeasureSpo2 = 'measure_spo2',
	MeasureAllVitals = 'measure_all_vitals'
}

export interface ReportsVitalsInterface {
	patientActions: {
		patientId: string
		treatmentActions: string[]
	}[]
}

export interface CheckShiftSentEmrInformationInterface {
	bp?: boolean
	bpStatus?: 'SENT_TO_EMR' | 'NEED_SEND_TO_EMR'
	bt?: boolean
	btStatus?: 'SENT_TO_EMR' | 'NEED_SEND_TO_EMR'
	hr?: boolean
	hrStatus?: 'SENT_TO_EMR' | 'NEED_SEND_TO_EMR'
	rr?: boolean
	rrStatus?: 'SENT_TO_EMR' | 'NEED_SEND_TO_EMR'
	spo2?: boolean
	spo2Status?: 'SENT_TO_EMR' | 'NEED_SEND_TO_EMR'
	bg?: boolean
	bgStatus?: 'SENT_TO_EMR' | 'NEED_SEND_TO_EMR'
	hasSomeVitals?: boolean
	status:
		| 'NEED_EXPORT_TO_EMR'
		| 'COMPLETED'
		| 'CRITICAL_REPORT_TIME'
		| 'FAILED_EMR'
		| 'NOT_REQUIRED'
}

export interface ManualVitalsInterface extends PccUpdateEmrMeasurement {
	bodyTemperatureManual?: boolean
	pressureManual?: boolean
	heartRateManual?: boolean
	respirationRateManual?: boolean
	spo2Manual?: boolean
	bloodGlucoseManual?: boolean
}
