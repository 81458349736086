import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import { Injectable, NgZone } from '@angular/core'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import {
	catchError,
	ignoreElements,
	interval,
	map,
	Observable,
	of,
	Subscription,
	takeUntil,
	tap,
	throwError
} from 'rxjs'
import {
	LoginService,
	MFALoginService,
	MfaLoginRequestV2,
	SelfUserService
} from '@biot-client/biot-client-ums'
import { Router } from '@angular/router'
import { HttpErrorResponse } from '@angular/common/http'
import {
	AuthStateInterface,
	LoginRequestInterface,
	LoginResponseInterface
} from '../../shared/model/auth.model'
import { ForgotPasswordRequestV2 } from '../../../../biot-client-ums/src'
import { PreferenceState } from '../preference/preference.state'
import { isMobile } from '../../core/helpers/functions'
import { BackendService } from '../../shared/services/backend.service'
import { NzMessageService } from 'ng-zorro-antd/message'
import { DeviceDetectorService } from 'ngx-device-detector'
import { JwtHelperService } from '@auth0/angular-jwt'
import moment from 'moment'
import { DepartmentDTO } from '../../shared/model/permission.model'

export const authFeatureName = 'auth'

@StateRepository()
@State<AuthStateInterface>({
	name: authFeatureName,
	defaults: {
		accessJwt: null,
		refreshJwt: null,
		isLoading: false,
		mfaRequired: false,
		needChooseDepartment: false
	}
})
@Injectable()
export class AuthState extends NgxsDataRepository<AuthStateInterface> {
	subscriptionTokenDetect$: Subscription

	constructor(
		private loginApiService: LoginService,
		private mfaLoginService: MFALoginService,
		private selfUserAPIService: SelfUserService,
		private preferenceState: PreferenceState,
		private router: Router,
		private zone: NgZone,
		private backendService: BackendService,
		private message: NzMessageService,
		private deviceService: DeviceDetectorService
	) {
		super()
	}

	@Selector()
	static isLoading(state: AuthStateInterface): boolean {
		return state.isLoading
	}

	@Selector()
	static isAuthenticated(state: AuthStateInterface): boolean {
		return (
			state.accessJwt != null &&
			!state.mfaRequired &&
			!state.needChooseDepartment
		)
	}

	@Selector()
	static isMfaRequired(state: AuthStateInterface): boolean {
		return state.mfaRequired
	}

	@Selector()
	static needChooseDepartment(state: AuthStateInterface): boolean {
		return state.needChooseDepartment
	}

	public isAuthenticated(): boolean {
		return this.snapshot.accessJwt != null
	}

	public needChooseDepartment(): boolean {
		return this.snapshot.needChooseDepartment
	}

	public isMfaRequired(): boolean {
		return this.snapshot.mfaRequired
	}

	public accessToken(): string | undefined {
		return this.snapshot.accessJwt?.token
	}

	public refreshToken(): string | undefined {
		return this.snapshot.refreshJwt?.token
	}

	@DataAction()
	public logout(): void {
		this.zone.run(() => {
			this.router.navigateByUrl('/login')
		})
		this.ctx.patchState({ needChooseDepartment: false })
		this.preferenceState.setNeedChooseDepartment(true)
		this.backendService.logout()
		this.backendService.triggerLogout()
		this.reset()
		if (this.subscriptionTokenDetect$) {
			this.subscriptionTokenDetect$.unsubscribe()
		}
	}

	@DataAction()
	public refreshAccessToken(dispatchRefreshToken?: boolean): Observable<string> {
		const refreshToken = this.snapshot.refreshJwt?.token

		if (refreshToken)
			return this.loginApiService
				.refreshToken({
					refreshToken: refreshToken
				})
				.pipe(
					takeUntil(this.backendService.destroy$),
					map((res: LoginResponseInterface) => {
						this.handleLoginResponse(res)
						this.patchState({
							accessJwt: res.accessJwt
						})
						if (dispatchRefreshToken) {
							this.dispatch({ type: 'REFRESH TOKEN' });
						}
						return res.accessJwt?.token!
					}),
					catchError((err) => {
						throw err
					})
				)
		else {
			return throwError(
				() => new HttpErrorResponse({ statusText: 'Missing refresh token' })
			)
		}
	}

	public override ngxsAfterBootstrap() {
		if (
			!this.ctx.getState().accessJwt ||
			this.preferenceState.isMfaRequired() ||
			this.preferenceState.needChooseDepartment()
		)
			return

		if (this.isAccessTokenValid()) {
			this.dispatch({ type: 'LOGGED IN' })
		} else {
			this.refreshAccessToken(true);
		}
		if (this.subscriptionTokenDetect$) {
			this.subscriptionTokenDetect$.unsubscribe()
		}
		this.subscriptionTokenDetect$ = interval(9000).subscribe(() =>
			this.checkTokenStatus()
		)
	}

	@DataAction()
	public mfaLogin(
		@Payload('code') code: MfaLoginRequestV2
	): Observable<LoginResponseInterface | null> {
		return this.mfaLoginService.mfaLogin(code).pipe(
			tap((res: LoginResponseInterface) => {
				this.handleLoginResponse(res)
				if (this.isMobile()) {
					this.canLogIntoSystem()
					return
				}
				this.ctx.patchState({ needChooseDepartment: true })
			}),
			catchError((err) => {
				throw err
			})
		)
	}

	@DataAction()
	public forgotPassword(
		@Payload('data') data: ForgotPasswordRequestV2
	): Observable<void> {
		return this.selfUserAPIService
			.forgotPassword(
				`
         ${window.location.origin}/reset
				`,
				data
			)
			.pipe(
				catchError((err) => {
					throw err
				}),
				ignoreElements()
			)
	}

	@DataAction()
	public setNewPassword(
		@Payload('token') token: string,
		@Payload('operation') operation: string,
		@Payload('entityId') entityId: string,
		@Payload('password') password: string,
		@Payload('username') username?: string
	): Observable<void> {
		return this.backendService
			.resetPassword(token, operation, entityId, password, username)
			.pipe(
				tap(() => {
					this.message.success('Your password has been changed!')
					this.zone.run(() => {
						this.router.navigateByUrl('/login')
					})
				}),
				catchError((err) => {
					throw err
				}),
				ignoreElements()
			)
	}

	@DataAction()
	public mfaResend(): Observable<LoginResponseInterface | null> {
		return this.mfaLoginService.mfaResend().pipe(
			catchError(() => {
				this.reset()
				return of(null)
			})
		)
	}

	@DataAction()
	public canLogIntoSystem(department: DepartmentDTO | null = null): void {
		this.zone.run(() => {
			this.router.navigateByUrl(department?.isAutomatic === false ? '/reports' : '/');
		})
		this.preferenceState.setNeedChooseDepartment(false)
		this.preferenceState.setPreferenceDepartment(department)
		this.dispatch({ type: 'LOGGED IN' })
		if (this.subscriptionTokenDetect$) {
			this.subscriptionTokenDetect$.unsubscribe()
		}
		this.subscriptionTokenDetect$ = interval(9000)
			.pipe(takeUntil(this.backendService.destroy$))
			.subscribe(() => this.checkTokenStatus())
	}

	@DataAction()
	public resetMfaRequired(
		@Payload('mfaRequired') mfaRequired: boolean
	): Observable<void> {
		this.ctx.patchState({
			mfaRequired,
			accessJwt: null,
			refreshJwt: null,
			isLoading: false
		})
		this.preferenceState.setMfaRequired(false)
		return of()
	}

	@DataAction()
	checkTokenStatus(): any {
		const helper = new JwtHelperService()
		const expirationDate = helper.getTokenExpirationDate(
			// @ts-ignore
			this.getState().accessJwt?.token
		)
		const accessJwt = this.ctx.getState().accessJwt
		const mfaRequired = this.ctx.getState().mfaRequired
		const needChooseDepartment = this.ctx.getState().needChooseDepartment
		const expirationMoment = moment(expirationDate)
		const currentMoment = moment(new Date())
		const differenceInMinutes = expirationMoment.diff(currentMoment, 'minutes')
		if (
			accessJwt != null &&
			!mfaRequired &&
			!needChooseDepartment &&
			differenceInMinutes <= 0
		) {
			this.refreshAccessToken(true)
		} else if (
			accessJwt != null &&
			!mfaRequired &&
			!needChooseDepartment &&
			differenceInMinutes <= 5
		) {
			this.refreshAccessToken()
		}
	}

	@DataAction()
	public login(
		@Payload('req') req: LoginRequestInterface
	): Observable<LoginResponseInterface | null> {
		this.ctx.patchState({
			isLoading: true
		})
		return this.loginApiService.login(req).pipe(
			tap((res: LoginResponseInterface) => {
				this.handleLoginResponse(res)
				if (!res.mfaRequired) {
					if (this.isMobile()) {
						this.canLogIntoSystem()
						return
					}
					this.ctx.patchState({ needChooseDepartment: true })
				}
			}),
			catchError(() => {
				this.reset()
				return of(null)
			})
			// tap(() => this.router.navigateByUrl('/'))
		)
	}

	@DataAction()
	public setNeedChooseDepartment(@Payload('need') need: boolean) {
		this.ctx.patchState({ needChooseDepartment: need });
	}

	isMobile() {
		const userAgent =
			window.navigator.userAgent ||
			navigator.userAgent ||
			navigator.vendor ||
			// @ts-ignore
			window.opera
		return (
			!!(!this.deviceService.isDesktop(userAgent) && isMobile.any()) ||
			(!!userAgent.match(/Version\/[\d\.]+.*Safari/) && isMobile.any())
		)
	}

	private handleLoginResponse(res: LoginResponseInterface): void {
		// @ts-ignore
		this.preferenceState.setMfaRequired(res.mfaRequired)
		this.ctx.patchState({
			mfaRequired: res.mfaRequired,
			accessJwt: res.accessJwt,
			refreshJwt: res.refreshJwt,
			isLoading: false
		})
	}

	private isAccessTokenValid(): boolean {
		const helper = new JwtHelperService()
		const expirationDate = helper.getTokenExpirationDate(
			// @ts-ignore
			this.getState().accessJwt?.token
		);
		const accessJwt = this.ctx.getState().accessJwt;
		const mfaRequired = this.ctx.getState().mfaRequired;
		const needChooseDepartment = this.ctx.getState().needChooseDepartment;
		const expirationMoment = moment(expirationDate);
		const currentMoment = moment(new Date());
		const differenceInMinutes = expirationMoment.diff(currentMoment, 'minutes');
		if (accessJwt != null && !mfaRequired && !needChooseDepartment && differenceInMinutes <= 0) {
			return false;
		}
		return true;
	}
}
