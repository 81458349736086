import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { combineLatest, EMPTY, ignoreElements, Observable, of, Subscription, tap } from 'rxjs'
import { UserStateInterface } from './types/userState.interface'
import { FileState } from '../file/file.state'
import {
	UserDTO,
	UserInterface,
	UserProfileDTO
} from '../../shared/model/user.model'
import { AuthState } from '../auth/auth.state'
import { BackendService } from '../../shared/services/backend.service'
import { EntityDictionary } from '@angular-ru/cdk/entity'
import { FileDTO } from '../../shared/model/file'
import { catchError } from 'rxjs/operators'
import LogRocket from 'logrocket'
import { StoreEventsService } from '../store-events.service'

export const userFeatureName = 'user'

@StateRepository()
@State<UserStateInterface>({
	name: userFeatureName,
	defaults: {
		user: null,
		isLoading: false,
		messagingToken: null,
		userProfileId: null,
		medicalAssistants: [],
		beforeLogIntUser: null
	}
})
@Injectable()
export class UserState extends NgxsDataRepository<UserStateInterface> {
	private userStateSubscription: Subscription;

	constructor(
		private backendService: BackendService,
		private fileState: FileState,
		private authState: AuthState,
		private actions: Actions,
		private storeEvents: StoreEventsService
	) {
		super()
	}

	@Selector([FileState.files])
	static currentUser(
		state: UserStateInterface,
		files: any
	): UserInterface | null {
		if (!state.user || !state.user.avatar || !Object.values(files).length) {
			return state.user
		}
		return {
			...state.user,
			signedUrl: files[state.user.avatar.id].signedUrl
		}
	}

	@Selector()
	static beforeLogIntUser(state: UserStateInterface): UserInterface | null {
		return state.beforeLogIntUser
	}

	@Selector()
	static userProfile(state: UserStateInterface): any {
		return state.userProfileId
	}

	@Selector([FileState.files])
	static medicalAssistants(
		state: UserStateInterface,
		files: EntityDictionary<string, FileDTO>
	): UserInterface[] | null {
		return !state.medicalAssistants
			? null
			: state.medicalAssistants.map((caregiver) => ({
					...caregiver,
					avatar: caregiver?.avatar?.id ? files[caregiver.avatar.id] : null
			  }))
	}

	public haveCurrentUser(): boolean {
		return !!this.snapshot.user
	}

	public override ngxsOnInit() {

		this.storeEvents.loggedInAndRefreshToken$.pipe(
			tap(() => {
				if (this.userStateSubscription) this.userStateSubscription.unsubscribe();
				this.userStateSubscription = combineLatest([this.getCurrentUser(), this.getCaregivers()]).pipe(catchError(() => EMPTY)).subscribe();
			})
		).subscribe();

		this.storeEvents.logout$.pipe(
			tap(() => {
				this.patchState({
					user: null,
					isLoading: false,
					medicalAssistants: []
				});
				this.reset();
				if (this.userStateSubscription) this.userStateSubscription.unsubscribe();
			})
		).subscribe();
	}

	@DataAction({ subscribeRequired: false })
	public getCaregivers(): Observable<UserDTO[] | null> {
		if (!this.authState.isAuthenticated()) {
			this.ctx.patchState({ medicalAssistants: [] })
			return of(null)
		}
		return this.backendService.getAllCaregivers().pipe(
			tap((medicalAssistants) => {
				if (!medicalAssistants) return
				this.ctx.patchState({ medicalAssistants })
				this.fileState.loadEntities(
					medicalAssistants
						.map((patient) => patient.avatar?.id)
						.filter((i: string | any) => i)
				)
			})
		)
	}

	@DataAction({ subscribeRequired: false })
	public getBeforeAuthUser(): Observable<UserDTO | null> {
		return this.backendService.getUserSelf().pipe(
			tap((beforeLogIntUser) => {
				this.ctx.patchState({
					beforeLogIntUser
				})
			})
		)
	}

	@DataAction({ subscribeRequired: false })
	public getCurrentUser(): Observable<UserDTO | null> {
		this.ctx.patchState({
			isLoading: true
		})
		if (!this.authState.isAuthenticated()) {
			this.ctx.patchState({ user: null, isLoading: false })
			return of(null)
		}
		return this.backendService.getUserSelf().pipe(
			tap((user) => {
				if (user.avatar) {
					this.fileState.loadEntities([user.avatar.id])
				}
				this.loadUserProfile(user)
				this.ctx.patchState({
					user,
					isLoading: false
				})

				LogRocket.identify(user.id, {
					name: user.name.firstName + ' ' + user.name.lastName,
					email: user.email!,
					locale: user.locale || '',
					gender: user.gender || '',
					dateOfBirth: user.dateOfBirth || '',
					address: JSON.stringify(user.address),
					onDutyDepartment: JSON.stringify({
						id: user.onDutyDepartment?.id,
						name: user.onDutyDepartment?.name || ''
					}),
					_degree: user._degree || '',
					_ownerOrganization: user._ownerOrganization
				})
			})
			// catchError(() => {
			// 	this.ctx.patchState({ user: null, isLoading: false })
			// 	return of(null)
			// })
		)
	}

	// @DataAction()
	// public updateUser(@Payload('data') data: UserDTO): Observable<void> {
	// 	// @ts-ignore
	// 	// e47a2373-fc97-4497-a0ad-991bca99be64
	// 	return (
	// 		this.caregiverAPIService
	// 			// @ts-ignore
	// 			.updateCaregiver('e47a2373-fc97-4497-a0ad-991bca99be64', data)
	// 			.pipe(
	// 				tap((res: CaregiverResponse) => {
	// 					const user = UserState.toUserDTO(res)
	// 					if (user.avatar) {
	// 						this.fileState.loadEntities([user.avatar.id])
	// 					}
	// 					this.ctx.patchState({
	// 						user,
	// 						isLoading: false
	// 					})
	// 				}),
	// 				mapToVoid()
	// 			)
	// 	)
	// }

	@DataAction()
	public loadUserProfile(@Payload('user') user: UserDTO) {
		return this.backendService.getUserProfile().pipe(
			tap((res) => {
				const currentUserProfile = res
					.filter((u) => u.caregiver && u.caregiver.id)
					.find((res: UserProfileDTO) => res.caregiver.id === user.id)
				if (!currentUserProfile) {
					this.createUserProfile(null)
				}
				this.patchState({
					messagingToken:
						!currentUserProfile || !currentUserProfile.messagingToken
							? null
							: currentUserProfile.messagingToken,
					userProfileId:
						!currentUserProfile || !currentUserProfile._id
							? null
							: currentUserProfile._id
				})
			})
		)
	}

	@DataAction()
	public createUserProfile(
		@Payload('messagingToken') messagingToken: string | null
	) {
		const user = this.getState().user
		return (
			this.backendService
				// @ts-ignore
				.addUserProfile(user, messagingToken)
				.pipe(
					tap((userProfileId) => {
						this.patchState({
							messagingToken,
							userProfileId
						})
					})
				)
		)
	}

	@DataAction()
	public updateUserProfile(
		@Payload('messagingToken') messagingToken: string | null
	): Observable<void> {
		const userProfileId = this.getState().userProfileId
		if (!userProfileId) return of()
		return this.backendService
			.setUserProfileMessagingToken(userProfileId as string, messagingToken)
			.pipe(
				tap((userProfileId) => {
					this.patchState({
						messagingToken,
						userProfileId: userProfileId
					})
				}),
				ignoreElements()
			)
	}

	// @DataAction()
	// public deleteUserProfile(): Observable<void> {
	// 	const userProfileId = this.getState().userProfileId
	// 	if (!userProfileId) return of()
	// 	return this.backendService.deleteUserProfile(userProfileId).pipe(
	// 		tap(() => {
	// 			this.patchState({
	// 				messagingToken: null,
	// 				userProfileId: null
	// 			})
	// 		}),
	// 		ignoreElements()
	// 	)
	// }
}
