import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'posture'
})
export class PosturePipe implements PipeTransform {
	transform(data: any, ...args: unknown[]): string {
		if (!data) return ''
		let postureImage = ''
		if (data === -1) {
			postureImage = 'ns:falling-above-average'
		} else if (data === 0) {
			postureImage = 'ns:posture-horizontal-above-average'
		} else if (data === 1) {
			postureImage = 'ns:posture-inclined-above-average'
		} else {
			postureImage = 'ns:posture-steps-above-average'
		}
		return postureImage
	}
}
