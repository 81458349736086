import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import moment from 'moment'
import { cloneDeep } from 'lodash-es'

@Component({
	selector: 'aiomed-emr-history-time',
	templateUrl: './emr-history-time.component.html',
	styleUrls: ['./emr-history-time.component.scss']
})
export class EmrHistoryTimeComponent implements OnInit {
	@Input() isVisible: boolean = false
	@Output() emrHistoryConfirmEmitter = new EventEmitter()
	@Output() emrHistoryCancelEmitter = new EventEmitter()
	time: Date | null = new Date()
	timeTypes: {
		active: boolean
		text: string
		type: string
	}[] = [
		{ active: true, text: 'Today', type: 'today' },
		{ active: false, text: 'Yesterday', type: 'yesterday' }
	]
	isError = false
	isOpened = false

	constructor() {}

	log(value: Date): void {
		const activeTimeType = this.timeTypes.find((t) => t.active)
		this.isError = !!(
			activeTimeType &&
			activeTimeType.type === 'today' &&
			moment().set('milliseconds', 0) < moment(value).set('milliseconds', 0)
		)
	}

	ngOnInit(): void {}

	handlerTimeTypeClick(timeType: {
		active: boolean
		text: string
		type: string
	}) {
		this.timeTypes.forEach((t) => (t.active = false))
		timeType.active = true
		this.time = new Date()
		this.isError = false
	}

	handlerButtonConfirmClick() {
		let time = cloneDeep(moment(this.time).toISOString())
		const activeTimeType = this.timeTypes.find((t) => t.active)
		if (activeTimeType && activeTimeType.type === 'yesterday') {
			time = moment(time).subtract(1, 'days').toISOString()
		}
		this.emrHistoryConfirmEmitter.emit({
			type: activeTimeType?.type,
			time
		})
	}
}
