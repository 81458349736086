import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges
} from '@angular/core'
import { ObservationFields } from '../../../../../shared/model/patient-observation'
import { PatientInterface } from '../../../../../shared/model/patient'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
	getUserLocale,
	transformFahrenheiToCelsius
} from '../../../../../core/helpers/functions'
import { AuthState } from '../../../../../store/auth/auth.state'
import { environment } from '../../../../../environments/environment'

@Component({
	selector: 'aiomed-emr-vitals-modal',
	templateUrl: './emr-vitals-modal.component.html',
	styleUrls: ['./emr-vitals-modal.component.scss']
})
export class EMRVitalsModalComponent implements OnChanges, OnDestroy {
	@Input() patient: PatientInterface
	@Input() isVisible: boolean = false
	@Input() tokenStatus: string | null
	@Input() measurementHistorical:
		| {
				[key in ObservationFields]: {
					lastUpdated: string
					isCritical?: boolean
					value: number | string
				}
		  }
		| null
	@Output('closeEMRVitalsModalEmitter') closeEMRVitalsModal = new EventEmitter()
	@Output('changeSelectedProvinceEmitter') changeSelectedProvince =
		new EventEmitter()
	@Output('checkedObservationsEmitter') checkedObservations = new EventEmitter()
	checkedBloodPressure = false
	checkedHeartRate = false
	checkedGlucose = false
	checkedSpo2 = false
	checkedBodyTemperature = false
	checkedRespirationRate = false
	ObservationFields = ObservationFields
	selectedProvince = 0
	patientMeasurementHistorical: any
	needLogin: boolean = true
	form: FormGroup
	passwordVisible = false
	password?: string
	currentPatient: PatientInterface

	constructor(private fb: FormBuilder, private authState: AuthState) {
		this.form = this.fb.group({
			username: [null, Validators.compose([Validators.required])],
			password: [
				null,
				Validators.compose([Validators.required, Validators.minLength(8)])
			]
		})
	}

	private static toTransformFahrenheiToCelsius(value: number): number {
		return getUserLocale() === 'en-US'
			? transformFahrenheiToCelsius(value)
			: value
	}

	submitForm() {
		if (
			!this.checkedBloodPressure &&
			!this.checkedHeartRate &&
			!this.checkedGlucose &&
			!this.checkedSpo2 &&
			!this.checkedBodyTemperature &&
			!this.checkedRespirationRate
		) {
			return
		} else if (this.needLogin) {
			this.needLogin = false
			return
		} else if (
			this.selectedProvince === 0 &&
			!this.needLogin &&
			this.form.valid &&
			Object.keys(this.setCheckedPatientCurrentObservations()).length
		) {
			this.checkedObservations.emit({
				formData: this.form.value,
				observations: this.setCheckedPatientCurrentObservations()
			})
			this.handlerClickCancelButton()
		} else if (
			this.selectedProvince !== 0 &&
			!this.needLogin &&
			this.form.valid &&
			Object.keys(this.setCheckedPatientHistoricalObservations()).length
		) {
			this.checkedObservations.emit({
				formData: this.form.value,
				observations: this.setCheckedPatientHistoricalObservations()
			})
			this.handlerClickCancelButton()
		}
	}

	setCheckedPatientCurrentObservations() {
		let tmpObj: any = {}
		if (
			!this.patient.observations ||
			!Object.values(this.patient.observations).length
		)
			return tmpObj
		if (this.checkedBloodPressure) {
			tmpObj[ObservationFields.SystolicPressure] =
				this.patient.observations[ObservationFields.SystolicPressure].value
			tmpObj[ObservationFields.DiastolicPressure] =
				this.patient.observations[ObservationFields.DiastolicPressure].value
		}
		if (this.checkedHeartRate) {
			tmpObj.heartRate =
				this.patient.observations[ObservationFields.HeartRate].value
		}
		if (this.checkedRespirationRate) {
			tmpObj.respirationRate =
				this.patient.observations[ObservationFields.RespirationRate].value
		}
		if (this.checkedGlucose) {
			tmpObj[ObservationFields.BloodGlucose] =
				this.patient.observations[ObservationFields.BloodGlucose].value
		}
		if (this.checkedSpo2) {
			tmpObj[ObservationFields.SpO2] =
				this.patient.observations[ObservationFields.SpO2].value
		}
		if (this.checkedBodyTemperature) {
			tmpObj.bodyTemperature =
				EMRVitalsModalComponent.toTransformFahrenheiToCelsius(
					this.patient.observations[ObservationFields.BodyTemperature].value
				)
		}
		return tmpObj
	}

	setCheckedPatientHistoricalObservations() {
		let tmpObj: any = {}
		if (
			!this.patientMeasurementHistorical ||
			!Object.values(this.patientMeasurementHistorical).length
		)
			return tmpObj
		if (this.checkedBloodPressure) {
			tmpObj[ObservationFields.SystolicPressure] =
				this.patientMeasurementHistorical[
					ObservationFields.SystolicPressure
				].value
			tmpObj[ObservationFields.DiastolicPressure] =
				this.patientMeasurementHistorical[
					ObservationFields.DiastolicPressure
				].value
		}
		if (this.checkedHeartRate) {
			tmpObj.heartRate =
				this.patientMeasurementHistorical[ObservationFields.HeartRate].value
		}
		if (this.checkedRespirationRate) {
			tmpObj.respirationRate =
				this.patientMeasurementHistorical[
					ObservationFields.RespirationRate
				].value
		}
		if (this.checkedGlucose) {
			tmpObj[ObservationFields.BloodGlucose] =
				this.patientMeasurementHistorical[ObservationFields.BloodGlucose].value
		}
		if (this.checkedSpo2) {
			tmpObj[ObservationFields.SpO2] =
				this.patientMeasurementHistorical[ObservationFields.SpO2].value
		}
		if (this.checkedBodyTemperature) {
			tmpObj.bodyTemperature =
				EMRVitalsModalComponent.toTransformFahrenheiToCelsius(
					this.patientMeasurementHistorical[ObservationFields.BodyTemperature]
						.value
				)
		}
		return tmpObj
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.measurementHistorical) {
			this.patientMeasurementHistorical = this.measurementHistorical
		} else {
			// @ts-ignore
			this.patientMeasurementHistorical = null
		}
	}

	handlerSelectProvinceChange($event: number) {
		if ($event === 0) {
			// @ts-ignore
			this.patientMeasurementHistorical = null
		}
		this.changeSelectedProvince.emit($event)
	}

	handlerClickCancelButton() {
		this.checkedBloodPressure = false
		this.checkedHeartRate = false
		this.checkedGlucose = false
		this.checkedSpo2 = false
		this.checkedBodyTemperature = false
		this.selectedProvince = 0
		this.patientMeasurementHistorical = null
		this.closeEMRVitalsModal.emit(false)
	}

	ngOnDestroy(): void {
		this.handlerClickCancelButton()
	}

	linkToPcc() {
		window.open(
			`${environment.authApiUrl}/pcc/login?return_url=${
				window.location.origin
			}/patient/${this.patient.id}&token=${this.authState.accessToken()}`
		)
	}
}
