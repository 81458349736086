import { Pipe, PipeTransform } from '@angular/core'
import { TaskTreatmentAction } from '../../../shared/model/task.model'

@Pipe({
	name: 'taskTreatmentActionName'
})
export class TaskTreatmentActionNamePipe implements PipeTransform {
	transform(data: string, ...args: unknown[]): string {
		if (data === TaskTreatmentAction.ChangeDiaper) {
			return 'Change Diaper'
		} else if (data === TaskTreatmentAction.RepositionPatient) {
			return 'Turn Patient'
		} else if (data === TaskTreatmentAction.MeasureAllVitals) {
			return 'Enter Manually Vitals'
		}
		return ''
	}
}
