import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'timesDay'
})
export class TimesDayPipe implements PipeTransform {
	transform(date: any, ...args: unknown[]): string {
		if (!date) return ''
		const hours = date.getHours()
		if (hours >= 5 && hours <= 11) return 'Good morning'
		else if (hours >= 12 && hours <= 17) return 'Good afternoon'
		else if (hours >= 17 && hours <= 23) return 'Good evening'
		else return 'Good night'
	}
}
